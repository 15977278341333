import React from "react";
import PropTypes from "prop-types";
import {formateDate, setValueByProp} from "../../utils/utils";
import {Upload, message} from "antd";
import type, {RcFile, UploadFile, UploadProps} from 'antd/es/upload/interface';
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";

class MyUpload extends React.Component {
    constructor(props) {
        super(props);
        this.sValue = this.sValue.bind(this);
        this.onChange = this.onChange.bind(this);
        this.state = {
            value: this.props.value,
            imageUrl: '',
            loading: false,
        }
        console.log(props);
    }

    sValue = (value) => {
        this.setState({
            value: value,
        })
    }
    beforeUpload = (file) => {
        console.log(file);

        let isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        let isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };
    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    uploadButton = () => {
        return (
            <div>
                {this.state.loading ? <LoadingOutlined/> : <PlusOutlined/>}
                <div
                    style={{
                        marginTop: 8,
                    }}
                >
                    Upload
                </div>
            </div>
        )
    };

    render() {
        let {value, imageUrl} = this.state;
        return <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            beforeUpload={this.beforeUpload}
            onChange={this.onChange}
        >
            {imageUrl !== '' ? <img src={imageUrl} alt="avatar" style={{width: '104px',height:'104px'}}/> : this.uploadButton()}
        </Upload>
    }

    onChange(date) {
        this.getBase64(date.file.originFileObj, (base64) => {
            // console.log(data);
            this.setState({
                imageUrl: base64,
            });
            this.props.onChange(base64);
        });
        // this.setState({
        //     value: date,
        // })
        // this.props.onChange(date);
    }
}

MyUpload.defaultProps = {
    onChange: (value) => {
    },
};
MyUpload.propTypes = {
    onChange: PropTypes.func,
};
export default MyUpload;

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";

class Pojo extends MyPojo {
    constructor() {
        //产线信息
        super('fmproductline');
        this.controller = 'fm';
        this.cName = '产线信息';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk: true,
            },
            {
                comName: 'fcaption',
                name: '名称',
            },
            {
                comName: 'ftypeid',
                name: '类型',
                dataType: DataType.Com,
                state: {
                    1: '产线',
                    2: '罐',
                    3: '池',
                    4: '缸',
                    5: '其他',
                },
            },
            {
                comName: 'flabelvalues',
                name: '标签卡',
                tdShow: false,
            },
            {
                comName: 'fvolume',
                name: '体积',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'farea',
                name: '面积',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'fdescribe',
                name: '描述',
                tdShow: false,
            },
            {
                comName: 'fstateid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '空闲',
                    2: '使用',
                    3: '停用',
                },
            },
            {
                comName: 'fdeptpk',
                name: '车间',
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'sysorginfo',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',//-{forgaddress}
                }),
                exComParent: 'fdepartment',
            },
            {
                comName: 'forgpk',
                name: '组织编号',
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'sysorginfo',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}-{forgaddress}',
                }),
                show: false,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
        ]);
    }
}

export default Pojo;

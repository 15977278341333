export default class TitleDetailTablePojo {
    titleController = '';
    titleRowNames = [];
    titlePk = '';
    detailController = '';
    detailAction = '';
    detailPk = '';
    titleRow = undefined;
    detailRows = [];
    detailRowNames = [];
    detailPRowName = '';
    getDetailPRowName = '';
    detailParams = {
        page: 0,
        rows: 0,
    };

    constructor({
                    titleController = '',
                    titleRowNames = [],
                    titlePk = '',
                    detailController = '',
                    detailAction = '',
                    detailPk = '',
                    titleRow = undefined,
                    detailRows = [],
                    detailRowNames = [],
                    detailPRowName = '',
                    detailParams = undefined,
                    getDetailPRowName = '',
                } = {}) {
        this.titleController = titleController;
        this.titleRowNames = titleRowNames;
        this.titlePk = titlePk;
        this.detailController = detailController;
        this.detailAction = detailAction;
        this.detailPk = detailPk;
        this.titleRow = titleRow;
        this.detailRows = detailRows;
        this.detailRowNames = detailRowNames;
        this.detailPRowName = detailPRowName;
        this.getDetailPRowName = getDetailPRowName;
        if (detailParams !== undefined) {
            this.detailParams = detailParams;
        }
    }
}

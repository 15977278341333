import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {initItem, item} from '../../calzzs/initItems'

class Pojo extends MyPojo {
    constructor() {
        //操作日志
        super('sysoptlog');
        this.controller = 'sys';
        this.cName = '操作日志';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk: true,
            },
            {
                comName: 'fstaffno',
                name: '员工编号',
            },
            {
                comName: 'fip',
                name: 'IP',
            },
            {
                comName: 'flogger',
                name: '控制器',
            },
            {
                comName: 'ftypeid',
                name: '类型',
                dataType: DataType.Com,
                state: {
                    1: '成功',
                    2: '失败',
                },
            },
            {
                comName: 'fmessage',
                name: '信息内容',
            },
            {
                comName: 'felapsed',
                name: '耗时（ms）',
                dataType: DataType.NUM,
            },
            {
                comName: 'fopttime',
                name: '操作时间',
                dataType: DataType.DataTime,
            },
        ]);
    }
}

export default Pojo;

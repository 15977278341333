import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item} from '../../calzzs/initItems'

class Pojo extends MyPojo {
    constructor() {
        //工序信息
        super('fmprocess');
        this.controller = 'fm';
        this.cName = '工序信息';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk: true,
            },
            {
                comName: 'fcaption',
                name: '名称',
            },
            {
                showLength: 30,
                comName: 'fgoodsnumber',
                name: '部件编号',
                isInsert: false,
                isUpdate: false,
            },
            {
                width: 300,
                showLength: 30,
                comName: 'fgoodsnumber',
                name: '部件名称',
                dataType: DataType.ComGETServer,
                comF: {
                    controller: 'fminventory',
                    action: 'getList',
                    id: 'fnumber',
                    text: '{fcaption}({fnumber})',
                },
                exComParent: 'fgoodscaption',
            },
            {
                comName: 'flabelvalues',
                name: '标签卡',
                tdShow: false,
            },
            {
                comName: 'fdescribe',
                name: '描述',
                tdShow: false,
            },
            {
                comName: 'ftypeid',
                name: '类型',
                dataType: DataType.Com,
                state: {
                    1: '包装打码',
                    2: '调配压油',
                    3: '配制投料',
                    4: '出渣',
                    5: '过滤',
                    6: '发酵',
                    7: '溶盐',
                    8: '投放原料',
                    9: '原料处理',
                    10: '制曲',
                    11: '种曲',
                    12: '菌种',
                },
            },
            {
                comName: 'fistraceid',
                name: '是否赋码',
                dataType: DataType.Com,
                state: {
                    1: '是',
                    2: '否',
                },
                tdShow: false,
            }, {
                comName: 'fstateid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '启用',
                    2: '停用',
                },
            }, {
                comName: 'fproductlinepk',
                name: '产线',
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fmproductline',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                }),
                exComParent:'fproductline',
                tdShow: false,
            }, {
                comName: 'fspeed',
                name: '平均速率',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'fperiod',
                name: '生产周期',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'flow1',
                name: '最小阈值',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'fheigh1',
                name: '最大阈值',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'flow2',
                name: '最小阈值',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'fheigh2',
                name: '最大阈值',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'flow3',
                name: '最小阈值',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'fheigh3',
                name: '最大阈值',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'flow4',
                name: '最小阈值',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'fheigh4',
                name: '最大阈值',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'forgpk',
                name: '组织编号',
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'sysorginfo',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}-{forgaddress};{fmanager};',
                }),
                show: false,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
        ]);
    }
}

export default Pojo;

import React from "react";
import {Button, Col, Row} from "antd";
import PropTypes from "prop-types";
import TableUtil from "../Common/TableUtil";

const tableUtil = new TableUtil();

class MDetailWin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bodyDom: undefined,
        }
    }

    componentDidMount() {
        // console.log(this.props.pojo);
        // console.log(this.props.row);
        let bodyDoms = [];
        for (let i in this.props.pojo.columns) {
            let userColumn = this.props.pojo.columns[i];
            if (userColumn.show) {
                bodyDoms.push(
                    <Col className="gutter-row" span={8}>
                        <Row>
                            <Col className="gutter-row" span={8}>
                                <span style={{fontWeight: 'bold'}}>{userColumn.name}:</span>
                            </Col>
                            <Col className="gutter-row" span={16}>
                                {tableUtil.getValue(userColumn, this.props.row, false)}
                            </Col>
                        </Row>
                    </Col>
                );
            }
        }
        let bodyDom = (
            <div style={{margin: '10px 30px 20px 50px', backgroundColor: '#ffffff', fontSize: '15px',}}>
                <Row gutter={[2, 2]}>
                    {bodyDoms}
                </Row>
            </div>
        )
        this.setState({
            bodyDom: bodyDom,
        })
    }

    render() {
        let {bodyDom} = this.state;
        return (
            <div style={{textAlign: 'left'}}>
                <div style={{width: '100%'}}>
                    <Button type="primary" danger onClick={() => {
                        this.props.onClose();
                    }}>
                        返回
                    </Button>
                </div>
                <div style={{overflow: 'auto', maxHeight: '80vh',}}>
                    <div>
                        {bodyDom}
                    </div>
                    {this.props.detailBody}
                </div>
            </div>
        )
    }
}

MDetailWin.defaultProps = {
    onClose: () => {
    },
    detailBody: undefined,
    pojo: undefined,
    row: undefined,
}
MDetailWin.propTypes = {
    onClose: PropTypes.func,
    detailBody: PropTypes.object,
    pojo: PropTypes.object,
    row: PropTypes.object,
}
export default MDetailWin;

import React from "react";
import PropTypes from "prop-types";
import MyPojo from "../MyClass/pojo";
import {Tree} from "antd";
import {filterData, getArrEqual, refactorTreeData, searchTreeCotyledon} from "../../utils/utils";

class MyTree extends React.Component {
    allCotyledonIds = [];
    outKeys = [];
    constructor(props) {
        super(props);
        this.treeInit = this.treeInit.bind(this);
        this.init = this.init.bind(this);
        this.onCheck = this.onCheck.bind(this);
        this.setCheckedKeys = this.setCheckedKeys.bind(this);
        this.state = {
            keyProp:'',
            valueProp:'',
            parentProp:'',
            //子叶IDs
            cotyledonIds: [],
            treeData: [],
            pojo:this.props.pojo,
            checkedKeys:['625e8f3b-4890-4f19-b545-5780be549523'],
        }


    }
    init = () => {
        this.state.pojo.init()
            .then((res) => {
                console.log(this.state.pojo);
                if(this.state.pojo.pkColumn !== undefined && this.state.pojo.nameColumn !== undefined) {
                    this.setState({
                        keyProp: this.props.keyProp !== '' ? this.props.keyProp : this.state.pojo.pkColumn.prop,
                        valueProp: this.props.keyProp !== '' ? this.props.valueProp : this.state.pojo.nameColumn.prop,
                        parentProp: this.props.parentProp !== '' ? this.props.parentProp : this.state.pojo.parentColumn.prop,
                    }, () => {
                        this.treeInit();
                    })
                }
            });
    }
    treeInit = () => {
        this.state.pojo.getTree()
            .then((res) => {
                this.allCotyledonIds = searchTreeCotyledon({
                    tree: res.data.rows,
                    prop: this.state.keyProp,
                });
                let rData = refactorTreeData(
                    {
                        tree:res.data.rows,
                        refactorPojo:{
                            key:this.state.keyProp,
                            title:this.state.valueProp,
                            pKey:this.state.parentProp,
                            item:''
                        }
                    });
                this.setState({
                    treeData:rData,
                })
            })
            .catch((res) => {
                console.log(res);
            })
    }
    componentDidMount(){
        this.props.onRef && this.props.onRef(this);
    }
    componentWillUnmount() {
        console.log("组件Ball将要卸载1");
    }
    onCheck = (checkedKeys,e) => {
        //console.log(checkedKeys);
        this.setState({
            checkedKeys:checkedKeys,
        })
        //console.log(e);
        let nowKeys = e.halfCheckedKeys.concat(checkedKeys);
        let fKeys = filterData(this.outKeys,nowKeys);
        //console.log(fKeys);
        this.outKeys = nowKeys;
        if(e.checked){
            console.log('添加');
            console.log(fKeys);
        }else{
            console.log('取消');
            console.log(fKeys);
        }
    }
    setCheckedKeys = (keys) => {
        this.outKeys = keys;
        keys = getArrEqual(this.allCotyledonIds,keys);
        this.setState({
            checkedKeys:keys,
        })
    }
    render() {
        let {treeData,checkedKeys} = this.state;
        let tree = (
            <Tree
                checkable
                defaultExpandAll
                checkedKeys={checkedKeys}
                treeData={treeData}
                onCheck={this.onCheck}
            />
        )
        return (
            <>
                {
                    treeData.length > 0 && tree
                }
            </>
        )
    }
}

MyTree.defaultProps = {
    onRef: (ref) => {
    },
    keyProp:'',
    valueProp:'',
    parentProp:'',
}
MyTree.propTypes = {
    pojo: PropTypes.instanceOf(MyPojo).isRequired,
    onRef: PropTypes.func,
    keyProp:PropTypes.string,
    valueProp:PropTypes.string,
    parentProp:PropTypes.string,
}
export default MyTree

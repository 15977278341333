import React from "react";
import PropTypes from "prop-types";
import {DatePicker} from "antd";
import moment from "moment";
import {formateDate, setValueByProp} from "../../utils/utils";

class MyDatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.sValue = this.sValue.bind(this);
        this.state = {
            value: this.props.value,
        }
        console.log(props);
    }

    sValue = (value) => {
        this.setState({
            value: value,
        })
    }

    render() {
        let {value} = this.state;
        let sValue = undefined;
        if (value !== undefined && value !== '' && value._isAMomentObject === undefined) {
            if (value === 253402099200000 || value === '9999-12-30 00:00:00') {
                value = formateDate(new Date());
            } else {
                value = formateDate(value);
            }
            // console.log(value);
            sValue = moment(value, 'YYYY-MM-DD HH:mm:ss');
            // setValueByProp(row, column.prop, ref);
        } else {
            sValue = value;
        }
        // console.log(sValue);
        return <DatePicker
            format={this.props.showTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'}
            showTim={this.props.showTime}
            onChange={(date, dateString) => {
                // console.log(dateString);
                this.onChange(date, dateString);
            }}
            value={sValue}
        />
    }

    onChange(date, dateString) {
        this.setState({
            value: dateString,
        })
        this.props.onChange(dateString);
    }
}

MyDatePicker.defaultProps = {
    showTime: true,
    onChange: (value) => {
    },
};
MyDatePicker.propTypes = {
    showTime: PropTypes.bool,
    onChange: PropTypes.func,
};
export default MyDatePicker;

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item} from '../../calzzs/initItems'

class Pojo extends MyPojo {
    constructor() {
        //物流信息
        super('lvmlogistics');
        this.controller = 'lvm';
        this.cName = '物流信息';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fnumber',
                name: '唯一编号',
                isPk: true,
            },
            {
                comName: 'fnumber',
                name: '单号',
            },
            {
                comName: 'fstateid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '保存',
                    2: '预约',
                    3: '审核1',
                    4: '审核2',
                    5: '签到',
                    6: '叫号',
                    7: '进厂',
                    8: '进厂称重',
                    9: '质检移车',
                    10: '质检中',
                    11: '已质检',
                    12: '装卸移车',
                    13: '装卸中',
                    14: '已装卸',
                    15: '出厂称重',
                    16: '放行',
                    17: '出厂',
                },
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fexecutedate',
                name: '执行日期',
                dataType: DataType.DataTime,
            },
            {
                comName: 'forderno',
                name: '业务单号',
            },
            {
                comName: 'ftypeid',
                name: '类型',
                dataType: DataType.Com,
                state: {
                    1: '提货',
                    2: '送货',
                    3: '调拨',
                },
            },
            {
                comName: 'fisweightid',
                name: '是否称重',
                dataType: DataType.Com,
                state: {
                    1: '是',
                    2: '否',
                },
            },
            {
                comName: 'fcarrierpk',
                name: '承运商',
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'lvmcarrier',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                }),
                exComParent: 'fdepartment',
                show: false,
            },
            {
                comName: 'fcarriercaption',
                name: '承运商',
            },
            {
                comName: 'ftrucktype',
                name: '车辆类别',
                dataType: DataType.Com,
                state: {
                    1: '大型',
                    2: '中型',
                    3: '小型',
                },
            },
            {
                comName: 'ftrucknumber',
                name: '车号',
            },
            {
                comName: 'fdriver',
                name: '承运司机',
            },
            {
                comName: 'fdrivertel',
                name: '司机电话',
            },
            {
                comName: 'fidcard',
                name: '身份证',
            },
            {
                comName: 'ftrailernumber',
                name: '挂车号',
            },
            {
                comName: 'fcontainernumber',
                name: '柜号',
            },
            {
                comName: 'flocknumber',
                name: '锁号',
            },
            {
                comName: 'fplantime',
                name: '计划到达时间',
                dataType: DataType.DataTime,
            },
            {
                comName: 'fopenid',
                name: 'openid',
                show: false,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fappointmenttime',
                name: '预约时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fsignintime',
                name: '签到时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fverifytime1',
                name: '审核时间1',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fverifyman1',
                name: '审核人1',
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fverifyresult1',
                name: '审核结果1',
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fverifytime2',
                name: '审核时间2',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fverifyman2',
                name: ' 审核人2',
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fverifyresult2',
                name: '审核结果2',
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fverifytime3',
                name: '审核时间3',
                dataType: DataType.DataTime,
                show: false,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fverifyman3',
                name: '审核人3(叫号)',
                show: false,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fverifytime4',
                name: '审核时间4',
                dataType: DataType.DataTime,
                show: false,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fverifyman4',
                name: '审核人4(检验)',
                show: false,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fverifytime5',
                name: '审核时间5',
                dataType: DataType.DataTime,
                show: false,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fverifyman5',
                name: '审核人5(移车)',
                show: false,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fverifytime6',
                name: '审核时间6',
                dataType: DataType.DataTime,
                show: false,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fverifyman6',
                name: '审核人6(放行)',
                show: false,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fentertime',
                name: '进厂时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fleavetime',
                name: '出厂时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fenterweight',
                name: '进厂重量',
                dataType: DataType.NUM,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fleaveweight',
                name: '出厂重量',
                dataType: DataType.NUM,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fplatformpk',
                name: '月台编号',
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'forgpk',
                name: '组织编号',
                show: false,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
        ]);
    }
}

export default Pojo;

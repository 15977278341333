import React from 'react';
import './index.less';
import {Row, Button, Table, Tag} from 'antd';
import {get} from "../../axios/Response";
//使用 useDidCache 和 useDidRecover 来对应 被缓存 和 被恢复 两种生命周期
import {dropByCacheKey, getCachingKeys, useDidCache, useDidRecover} from "react-router-cache-route";

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        width: 150,
        fixed: 'left',
    },
    {
        title: 'Age',
        dataIndex: 'age',
        width: 100,
    },
    {
        title: 'Address',
        dataIndex: 'address',
        width: 200,
    },
    {
        title: 'Address',
        dataIndex: 'address',
        width: 200,
    },
    {
        title: 'Address',
        dataIndex: 'address',
        width: 200,
    },
    {
        title: 'Address',
        dataIndex: 'address',
        width: 200,
    },
    {
        title: 'Address',
        dataIndex: 'address',
        width: 200,
    },
    {
        title: 'Address',
        dataIndex: 'address',
        width: 200,
    },
    {
        title: 'Address',
        dataIndex: 'address',
        width: 200,
    },
    {
        title: 'Address',
        dataIndex: 'ddd.aaa'.split('.'),
        width: 200,
        render: (text, record, index) => {
            //console.log(record);
            //console.log(text);
            //console.log(index);
            return (
                <a>
                    {text}
                </a>
            )
        },
    },
    {
        title: 'Tags',
        key: 'tags',
        width: 200,
        dataIndex: 'tags',
        fixed: 'right',
        render: tags => (
            <>
                {tags.map(tag => {
                    let color = tag.length > 5 ? 'geekblue' : 'green';
                    if (tag === 'loser') {
                        color = 'volcano';
                    }
                    return (
                        <Tag color={color} key={tag}>
                            {tag.toUpperCase()}
                        </Tag>
                    );
                })}
            </>
        ),
    },
];

const data = [];
for (let i = 0; i < 46; i++) {
    data.push({
        key: i,
        name: `Edward King11 ${i}`,
        age: 32,
        address: `London, Park Lane no. ${i}`,
        tags: ['nice', 'developer'],
        ddd:{
            aaa:i + 1
        }
    });
}

class index extends React.Component {
    state = {
        selectedRowKeys: [], // Check here to configure the default column
        loading: false,
        pagination: {
            current: 1,
            pageSize: 20,
        },
    };
    componentDidMount() {
        let cacheKeys = getCachingKeys();
        for(let i = 0; i !== cacheKeys.length; i++){
            dropByCacheKey(cacheKeys[i]);
        }
        console.log(getCachingKeys());
    }
    useDidRecover() {
        console.log('被回复');
        let cacheKeys = getCachingKeys();
        for(let i = 0; i !== cacheKeys.length; i++){
            dropByCacheKey(cacheKeys[i]);
        }
        console.log(getCachingKeys());
    }
    start = () => {
        this.setState({loading: true});
        // ajax request after empty completing
        setTimeout(() => {
            this.setState({
                selectedRowKeys: [],
                loading: false,
            });
        }, 1000);
    };

    onSelectChange = selectedRowKeys => {
        //console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({selectedRowKeys});
    };
    init(){
        // get('https://sms.dengpei888.com/yunduo/sysuser/getInit',{})
        //     .then((data) => {
        //         //console.log(data);
        //     })
        //     .catch((err) => {
        //         //console.log(err);
        //     });
    };

    tableChange(...e){
        //console.log(e);
    }
    render() {
        //console.log(data);
        const {loading, selectedRowKeys,pagination} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        this.init();
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <div style={{width:'calc(100vw - 12.66666667vw)'}}>
                <div style={{marginBottom: 16}}>
                    <Button type="primary" onClick={this.start} disabled={!hasSelected} loading={loading}>
                        Reload
                    </Button>
                    <span style={{marginLeft: 8}}>{hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}</span>
                </div>
                <Table rowSelection={rowSelection}
                       pagination={pagination}
                       columns={columns}
                       size={'small'}
                       dataSource={data}
                       onChange={this.tableChange}
                       scroll={{ y: '76vh'}}/>
            </div>
        );
    }
}

export default index;

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item, StatesID} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";

class Pojo extends MyPojo {
    getTitleList = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + 'pmorder' + '/getinfo', params)
                .then((ref) => {
                    resolve(ref['data']['fentryEXlist'] === undefined ? [] : ref['data']['fentryEXlist']);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }

    constructor() {
        //生产订单条目
        super('pmorderentry');
        this.controller = 'pm';
        this.cName = '生产订单条目';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk: true,
            },
            {
                comName: 'fpk',
                name: '编号',
                isInsert: false,
                isUpdate: false,
                width:180,
                showLength:30,
            },
            {
                comName: 'fgoodsnumber',
                name: '存货编号',
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fgoodsnumber',
                name: '存货名称',
                dataType: DataType.ComGETServer,
                width: 400,
                showLength: 30,
                comF: new ComF({
                    controller: 'fminventory',
                    action: 'getList',
                    id: 'fnumber',
                    text: '{fcaption}({fnumber})',
                }),
                exComParent: 'fgoodscaption',
            },
            {
                comName: 'fsupk',
                name: '计量单位',
                width: 100,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fmpackingunit',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}|包装单位数:{fpuqty}|码板单位数:{fpsuqty}|码板包装数:{fpsupuqty}',//件数:{fiuqty}|
                    isInitialize: false,
                }),
                exComParent: 'fsucaption',
            },{
                comName: 'fpmqty',
                name: '已生产',
                width: 80,
                dataType: DataType.NUM,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'frealqty',
                name: '已入库',
                width: 80,
                dataType: DataType.NUM,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'freturnqty',
                name: '退货数量',
                width: 80,
                isInsert: false,
                isUpdate: false,
                // show:false,
            },
            {
                comName: 'fordernumber',
                name: '单号',
                isInsert: false,
                isUpdate: false,
                show:false,
            },
            {
                comName: 'ferpentyrid',
                name: 'ERP条目id',
                isInsert: false,
                isUpdate: false,
                width: 100,
                // show:false,
            },
            {
                comName: 'fteampk',
                name: '班组',
                width: 150,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'sysorginfo',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                }),
                exComParent: 'fteam',
            },
            {
                comName: 'fplanstarttime',
                name: '计划开始时间',
                dataType: DataType.DataTime,
            },
            {
                comName: 'fplanendtime',
                name: '计划完成时间',
                dataType: DataType.DataTime,
            },
            {
                comName: 'fproductlinepk',
                name: '线体',
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fmproductline',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                }),
                exComParent: 'fproductline',
            },
            // {
            //     comName: 'fbatchnumber',
            //     name: '批号',
            //     isInsert: false,
            //     isUpdate: false,
            // },
            {
                comName: 'fsuqty',
                name: '计划生产',//单位数量
                width: 100,
                dataType: DataType.NUM,
                // show:false,
                // isInsert: false,
                // isUpdate: false,
            },
            // {
            //     comName: 'fpupk',
            //     name: '包装单位',
            //     width: 200,
            //     dataType: DataType.ComGETServer,
            //     comF: new ComF({
            //         controller: 'fmpackingunit',
            //         action: 'getList',
            //         id: 'fpk',
            //         text: '{fcaption}',
            //         isInitialize: false,
            //     }),
            //     exComParent: 'fpucaption',
            // },
            // {
            //     comName: 'fpsupk',
            //     name: '码板单位',
            //     width: 200,
            //     dataType: DataType.ComGETServer,
            //     comF: new ComF({
            //         controller: 'fmpackingunit',
            //         action: 'getList',
            //         id: 'fpk',
            //         text: '{fcaption}',
            //         isInitialize: false,
            //     }),
            //     exComParent: 'fpsucaption',
            // },

            {
                comName: 'fgoodsstateid',
                name: '存货状态',
                dataType: DataType.Com,
                state: StatesID.getPState(),
                defaultValue:1,
            },
            {
                comName: 'fdescription',
                name: '存货状态说明',
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                show: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert: false,
                isUpdate: false,
                show: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                show: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert: false,
                isUpdate: false,
                show: false,
            },
        ]);
    }
}

export default Pojo;

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {initItem, item} from '../../calzzs/initItems'
class Pojo extends MyPojo {
    constructor() {
        //编号管理
        super('sysinvnumber');
        this.controller = 'sys';
        this.cName = '编号管理';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk:true,
            },
            {
                comName: 'fleaderstr',
                name: '编号前缀 ',
            },
            {
                comName: 'fcategoryid',
                name: '编号类型',
                dataType: DataType.Com,
                state: {
                    1: '件码',
                    2: '单位码',
                    3: '包装码',
                    4: '垛码',
                    5: '批次',
                },
            },
            {
                comName: 'fflow',
                name: '序号',
                dataType: DataType.NUM,
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
        ]);
    }
}
export default Pojo;

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {initItem, item} from '../../calzzs/initItems'
class Pojo extends MyPojo {
    constructor() {
        //销售计划
        super('mmplan');
        this.controller = 'mm';
        this.cName = '销售计划';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk:true,
            },
            {
                comName: 'fnumber',
                name: '单号',
            },
            {
                comName: 'fbusinesstime',
                name: '业务时间',
                dataType: DataType.DataTime,
            },
            {
                comName: 'fcustomerpk',
                name: '客户编号',
            },
            {
                comName: 'fdeptpk',
                name: '业务部门',
            },
            {
                comName: 'fsalesman',
                name: '业务员',
            },
            {
                comName: 'fsalearea',
                name: '销售区域',
            },
            {
                comName: 'fbusinesstype',
                name: '业务类型',
            },
            {
                comName: 'fsaletype',
                name: '销售类型',
            },
            {
                comName: 'fsettleway',
                name: '结算方式',
            },
            {
                comName: 'fpayway',
                name: '支付方式',
            },
            {
                comName: 'fshipway',
                name: '运送方式',
            },
            {
                comName: 'fcurrency',
                name: '币种',
            },
            {
                comName: 'fmemo',
                name: '备注',
            },
            {
                comName: 'fverifyman1',
                name: '审核人1',
            },
            {
                comName: 'fverifytime1',
                name: '审核时间1',
                dataType: DataType.DataTime,
            },
            {
                comName: 'fverifyman2',
                name: '审核人2',
            },
            {
                comName: 'fverifytime2',
                name: '审核时间2',
                dataType: DataType.DataTime,
            },
            {
                comName: 'fverifyman3',
                name: '审核人3',
            },
            {
                comName: 'fverifytime3',
                name: '审核时间3',
                dataType: DataType.DataTime,
            },
            {
                comName: 'fverifyman4',
                name: '审核人4',
            },
            {
                comName: 'fverifytime4',
                name: '审核时间4',
                dataType: DataType.DataTime,
            },
            {
                comName: 'fverifyman5',
                name: '审核人5',
            },
            {
                comName: 'fverifytime5',
                name: '审核时间5',
                dataType: DataType.DataTime,
            },
            {
                comName: 'fverifyman6',
                name: '审核人6',
            },
            {
                comName: 'fverifytime6',
                name: '审核时间6',
                dataType: DataType.DataTime,
            },
            {
                comName: 'fstatusid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '保存',
                    2: '审核1',
                    3: '审核2',
                    4: '审核3',
                    5: '审核4',
                    6: '审核5',
                    7: '审核6',
                },
            },
            {
                comName: 'forderpk',
                name: '依据单据编号',
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
        ]);
    }
}
export default Pojo;

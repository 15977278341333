import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {initItem, item} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";

class Pojo extends MyPojo {
    updatepws = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/updatepws', params)
                .then((res) => {
                    console.log(res);
                    resolve(res);
                })
                .catch((res) => {
                    console.log(res);
                    reject(res);
                });
        });
    }

    constructor() {
        //人员
        super('sysstaff');
        this.controller = 'sys';
        this.cName = '人员';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fname',
                name: '姓名',
            }, {
                isInsert: false,
                isUpdate: false,
                comName: 'fdeptpk',
                name: '部门',
                dataType: DataType.ComGETServer,
                comF: {
                    controller: 'sysorginfo',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}-{forgaddress};{fmanager};',
                },
                exComParent: 'department',
                show: false,
                showSearch: false,
            }, {
                comName: 'ffunpk',
                name: '职能',
                dataType: DataType.ComGETServer,
                comF: {
                    controller: 'sysfunction',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                },
                exComParent: 'function',
                tdShow: false,
            }, {
                comName: 'flevelid',
                name: '管理级别',
                dataType: DataType.Com,
                state: {
                    1: '超级管理员',
                    2: '系统管理员',
                    3: '部门管理员',
                    4: '员工',
                    5: '外来人员',
                },
                stateFiltration: (row, data) => {
                    console.log(data);
                    if (data.type === 'edit' || data.type === 'add') {
                        return {
                            2: '系统管理员',
                            3: '部门管理员',
                            4: '员工',
                            5: '外来人员',
                        }
                    } else {
                        return undefined;
                    }
                },
                tdShow: false,
            }, {
                comName: 'fnumber',
                name: '工号',
            }, {
                comName: 'fstateid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '在职',
                    2: '停职',
                    3: '离职',
                    4: '临时',
                },
            }, {
                comName: 'fidcard',
                name: '身份证',
            }, {
                comName: 'fopenid',
                name: 'openid',
                show: false,
                isInsert: false,
                isUpdate: false,
            }, {
                comName: 'fpassword',
                name: '密码',
                show: false,
                isInsert: false,
                isUpdate: false,
                showSearch: false,
            }, {
                comName: 'fphoto',
                name: '相片',
                dataType: DataType.Img,
                tdShow: false,
                showSearch: false,
            }, {
                comName: 'fpk',
                name: '唯一编号',
                isPk: true,
            }, {
                comName: 'ftelephone',
                name: '电话',
            }, {
                comName: 'fcreateman',
                name: '创建人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            }, {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            }, {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            }, {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
        ]);
    }
}

export default Pojo;

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {initItem, item} from '../../calzzs/initItems'
class Pojo extends MyPojo {
    constructor() {
        //职能信息
        super('sysfunction');
        this.controller = 'sys';
        this.cName = '职能信息';
        this.orderRowName = 'fcaption'
        this.orderType = 'ASC'
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk:true,
            },
            {
                comName: 'fcaption',
                name: '职能名称',
            },
            {
                comName: 'fdescribe',
                name: '职能描述',
            },
            {
                comName: 'ftypeid',
                name: '类型',
                dataType: DataType.Com,
                state: {
                    1: '固定',
                    2: '自定义',
                },
                isInsert:false,
                isUpdate:false,
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
        ]);
    }
}
export default Pojo;

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item, StatesID} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";
class Pojo extends MyPojo {
    //下达任务
    finish = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/finish', params)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }
    createbatchcode_pm = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls['wm'] + 'wmcodeopt' + '/createbatchcode_pm', params)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }
    createbatchitemcode_pm = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls['wm'] + 'wmcodeopt' + '/createbatchitemcode_pm', params)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }
    constructor() {
        //生产任务管理
        super('pmtask');
        this.controller = 'pm';
        this.cName = '生产任务管理';
        this.orderRowName = 'fpk'
        this.orderType = 'DESC'
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk:true,
            },{
                comName: 'fpk',
                name: '生产批号',
            },
            {
                comName: 'forderentrypk',
                name: '生产订单',//生产订单条目编号(与生产入库单一起关联生产订单)
                showLength: 30,
                width: 200,
                isInsert:false,
                isUpdate:false,
            },
            {
                comName: 'fstateid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '待生产',
                    2: '生产中',
                    3: '已关闭',
                },
            },
            {
                width: 300,
                showLength: 30,
                comName: 'fgoodsnumber',
                name: '存货名称',
                dataType: DataType.ComGETServer,
                comF: {
                    controller: 'fminventory',
                    action: 'getList',
                    id: 'fnumber',
                    text: '{fcaption}({fnumber})',
                },
                exComParent: 'fgoodscaption',
            },
            {
                comName: 'fsuqty',
                name: '计划生产',
                dataType: DataType.NUM,
            },
            {
                comName: 'fpmqty',
                name: '已生产',
                dataType: DataType.NUM,
            },
            {
                comName: 'fsupk',
                name: '计量单位',
                width: 200,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fmpackingunit',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fgoodsnumber}({fcaption})代号:{fpucode}',
                    isInitialize: false,
                }),
                exComParent: 'fsucaption',
            },
            {
                comName: 'fproductiondate',
                name: '生产日期',
                dataType: DataType.DataTime,
            },
            {
                comName: 'fproductlinepk',
                name: '线体',
                width: 200,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fmproductline',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                }),
                exComParent: 'fproductline',
            },
            {
                comName: 'fteampk',
                name: '班组',
                width: 200,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'sysorginfo',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                }),
                exComParent: 'fteam',
            },
            {
                showLength: 30,
                comName: 'fgoodsnumber',
                name: '存货编号',
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fgoodsstateid',
                name: '存货状态',
                dataType: DataType.Com,
                state: StatesID.getPState(),
                defaultValue:1,
                tdShow: false,
            },
            // {
            //     comName: 'fbatchnumber',
            //     name: '批号',//(由生产订单带入)
            // },
            {
                comName: 'fiucount',
                name: '件数/单位',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'fpsucount',
                name: '单位数/托板',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'fpucount',
                name: '单位数/包装',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'fpucountpsu',
                name: '包装数/托板',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'fdescription',
                name: '描述说明',
                tdShow: false,
            },
            {
                comName: 'fstarttime',
                name: '开始时间',
                dataType: DataType.DataTime,
                tdShow: false,
            },
            {
                comName: 'fendtime',
                name: '结束时间',
                dataType: DataType.DataTime,
                tdShow: false,
            },
            // {
            //     comName: 'forgpk',
            //     name: '组织编号',
            // },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
        ]);
    }
}
export default Pojo;

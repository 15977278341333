import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
import reportWebVitals from './reportWebVitals';
import App from "./App";
import IRouter from "./IRouter";
import store from './store';
ReactDOM.render(
    <React.StrictMode>
        <IRouter/>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

let request = indexedDB.open('test1',1);
request.onerror = (e) => {
    console.log('数据库打开失败');
    console.log(e);
}
request.onsuccess = (e) => {
    console.log('数据库打开成功');
    console.log(e);
    console.log(request);
    let db = request.result;
    store.dispatch({
        type:'db',
        value:db
    });
    //let objectStore = db.createObjectStore('person', { keyPath: 'id' });
}
request.onupgradeneeded = (e) => {
    console.log('数据库升级');
    let db = e.target.result;
    console.log(db);
    let objectStore;
    if (!db.objectStoreNames.contains('person')) {
        objectStore = db.createObjectStore('person', { keyPath: 'id' });
    }
    store.dispatch({
        type:'db',
        value:db
    });
    // let request = db.transaction(['system'], 'readwrite')
    //     .objectStore('system')
    //     .add({ id: 1, name: '张三', age: 24, email: 'zhangsan@example.com' });
    //
    // request.onsuccess = function (event) {
    //     console.log('数据写入成功');
    // };
    //
    // request.onerror = function (event) {
    //     console.log('数据写入失败');
    // }
}
Date.prototype.Format = function (fmt) { //author: meizz
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";

class Pojo extends MyPojo {
    //同步数据
    synchronization = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/synchronization', params)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }
    //设备维护
    maintenance = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/maintenance', params)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }
    //设备事件
    monitoring = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/monitoring', params)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }
    constructor() {
        //设备信息
        super('emequipment');
        this.controller = 'em';
        this.cName = '设备信息';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk: true,
            },
            {
                comName: 'fnumber',
                name: '编码',
            },
            {
                comName: 'fcaption',
                name: '设备名称',
            },
            {
                comName: 'ftypeid',
                name: '设备类型',
                dataType: DataType.Com,
                state: {
                    1: '工控机',
                    2: '赋码设备',
                    3: '移动设备',
                    4: '读码设备',
                    5: '包装设备',
                    6: '灌装设备',
                    7: '检测设备',
                    8: '监控设备',
                    9: '显示设备',
                    10: '其他',
                },
            },
            {
                comName: 'fip',
                name: 'ip地址',
            },
            {
                comName: 'flabelvalues',
                name: '标签卡值',
                tdShow: false,
            },
            {
                comName: 'fdescribe',
                name: '描述',
                tdShow: false,
            },
            {
                comName: 'fstarttime',
                name: '启用时间',
                dataType: DataType.DataTime,
            },
            {
                comName: 'finterval',
                name: '保养间隔（天）',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'fprewarntime',
                name: '提前报警时间（天）',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'fperiodtime',
                name: '有效期',
                dataType: DataType.DataTime,
                tdShow: false,
            },
            {
                comName: 'fstateid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '启用',
                    2: '停用',
                    3: '警告',
                    4: '维护',
                },
            },
            {
                comName: 'fprocesspk',
                name: '工序',
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fmprocess',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                }),
                exComParent: 'fprocess',
                tdShow: false,
            },
            {
                comName: 'fplatformpk',
                name: '月台',
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fmplatform',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                }),
                exComParent: 'fplatform',
                tdShow: false,
            },
            {
                comName: 'fdeptpk',
                name: '部门',
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'sysorginfo',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                }),
                exComParent: 'fdepartment',
                tdShow: false,
            },
            {
                comName: 'forgpk',
                name: '组织',
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'sysorginfo',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                }),
                show: false,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
        ]);
    }
}

export default Pojo;

import {object, string} from "prop-types";
import {getValueByProp, setValueByProp} from '../utils/utils'

export enum ObjType {
    Node = 'Node',
    Array = 'Array',
    Fun = 'Fun',
}

type RefType = {
    [key: string]: any;
};

export class ToObjFun {
    public row: RefType;
    public toROw: RefType;
    public objItems: ObjToObjItem[];

    constructor(
        row: RefType,
        toROw: RefType,
        objItems: ObjToObjItem[],
    ) {
        this.row = row;
        this.toROw = toROw;
        this.objItems = objItems;
        // console.log(this.row);
        // console.log(getValueByProp(this.row,'cc'));
        // setValueByProp(this.row,'cc','tttttscccc');
        // console.log(this.row);
    }

    public getData(): void {
        for (let i: number = 0; i !== this.objItems.length; i++) {
            let item: ObjToObjItem = this.objItems[i];
            switch (item.objType) {
                case ObjType.Node:
                    this.toROw[item.toName] = this.row[item.name];
                    break;
                case ObjType.Array:
                    let list: RefType[] = this.row[item.name];
                    let toList: RefType[] = [];
                    for (let i: number = 0; i !== list.length; i++) {
                        toList[i] = item.desRow;
                        new ToObjFun(list[i], toList[i], item.oTOItems).getData();
                    }
                    break;
                case ObjType.Fun:
                    new ToObjFun(this.row[item.name], this.toROw[item.toName], item.oTOItems).getData();
                    break;
                default:
                    break;
            }
        }
    }
}

export class ObjToObjItem {
    public name: string;
    public toName: string;
    public objType: ObjType;
    public oTOItems: ObjToObjItem[];
    public desRow: RefType;

    constructor(
        name: string,
        toName: string,
        objType: ObjType = ObjType.Node,
        oTOItems: ObjToObjItem[] = [],
        desRow: RefType = {}
    ) {
        this.name = name;
        this.toName = toName;
        this.objType = objType;
        this.oTOItems = oTOItems;
        this.desRow = desRow;
    }
}


import React, {useState} from 'react';
import './index.less';
import {Row, Button, Table, Tag, Radio, Modal, Tabs} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import {get} from "../../axios/Response";
import MyTable from "../../my_module/table/table";
// import Pojo from "./pojo"
import MyDataGrid from "../../my_module/datagrid/datagrid";
import MyButton from "../../my_module/MyClass/button";
import Nestgrid from "../../my_module/nestgrid/nestgrid";
import Pojos from "../pojos"

const {TabPane} = Tabs;

//调整单管理
class index extends React.Component {
    constructor(props) {
        super(props);
        this.onDGRef = this.onDGRef.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        // this.addOpenIntercept = this.addOpenIntercept.bind(this);
        // this.editOpenIntercept = this.editOpenIntercept.bind(this);
        this.state = {
            // addModalVisible: false,
            pojo: Pojos.getPojo('wmchange'),//new Pojo(),
            dg: undefined,
        }
        console.log(this.pojo);
    }

    exTrBus = [
        // new MyButton(
        //     '审批',
        //     'getItemByID11',
        //     undefined,
        //     (row) => {
        //         console.log(row);
        //         return true;
        //     }
        // )
    ]
    onDGRef = (ref) => {
        this.setState({
            dg: ref,
        }, () => {
            //this.state.dg.refInit();
        })
    }
    componentDidUpdate = (prevProps, prevState) => {
    }
    // addModal = () => {
    //     let model =
    //         <Modal title="Basic Modal" visible={this.state.addModalVisible}
    //                onCancel={() => {
    //                    this.setState({
    //                        addModalVisible:false,
    //                    })
    //                }}>
    //             <p>Some contents...</p>
    //             <p>Some contents...</p>
    //             <p>Some contents...</p>
    //         </Modal>;
    //     return model;
    // }
    // addOpenIntercept = (ref) => {
    //     console.log(ref);
    //     // this.setState({
    //     //     addModalVisible:true,
    //     // })
    //     return false;
    // }
    // editOpenIntercept = (ref) => {
    //     console.log(ref);
    //     return false;
    // }
    render() {
        return (
            <div>
                {/*{this.addModal()}*/}
                <MyDataGrid
                    // addOpenIntercept={this.addOpenIntercept}
                    // editOpenIntercept={this.editOpenIntercept}
                    onRef={this.onDGRef}
                    pojo={this.state.pojo}
                    exTrBus={this.exTrBus}/>
            </div>
        );
    }
}

export default index;

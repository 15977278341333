import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {initItem, item} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";

class Pojo extends MyPojo {
    generator = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/generator', params)
                .then((res) => {
                    console.log(res);
                    resolve(res);
                })
                .catch((res) => {
                    console.log(res);
                    reject(res);
                });
        });
    }

    constructor() {
        //包装单位信息
        super('fmpackingunit');
        this.orderRowName = 'fgoodsnumber';
        this.controller = 'fm';
        this.cName = '存货单位信息';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk: true,
            },
            {
                showLength: 30,
                comName: 'fgoodsnumber',
                name: '存货编号',
                isInsert: false,
                isUpdate: false,
            },
            {
                width: 300,
                showLength: 30,
                comName: 'fgoodsnumber',
                name: '存货名称',
                dataType: DataType.ComGETServer,
                comF: {
                    controller: 'fminventory',
                    action: 'getList',
                    id: 'fnumber',
                    text: '{fcaption}({fnumber})',
                },
                exComParent: 'fgoodscaption',
            },
            {
                comName: 'fcaption',
                name: '计量单位',
            },
            // {
            //     comName: 'fissu',
            //     name: '计量单位',
            //     dataType: DataType.Com,
            //     state: {
            //         1: '是',
            //         2: '否',
            //     },
            //     defaultValue: 2,
            // },
            // {
            //     comName: 'fispu',
            //     name: '包装单位',
            //     dataType: DataType.Com,
            //     state: {
            //         1: '是',
            //         2: '否',
            //     },
            //     defaultValue: 2,
            // },
            // {
            //     comName: 'fispsu',
            //     name: '码板单位',
            //     dataType: DataType.Com,
            //     state: {
            //         1: '是',
            //         2: '否',
            //     },
            //     defaultValue: 2,
            // },

            {
                comName: 'fdefault',
                name: '默认单位',
                dataType: DataType.Com,
                state: {
                    1: '是',
                    2: '否',
                },
                defaultValue: 1,
            },
            {
                comName: 'fiuqty',
                name: '件数',
                dataType: DataType.NUM,
            },
            // {
            //     comName: 'fsuqty',
            //     name: '计量单位数',//(当计量单位时为件数)
            //     dataType: DataType.NUM,
            // },
            {
                comName: 'fpuqty',
                name: '包装单位数',
                dataType: DataType.NUM,
            },
            {
                comName: 'fpsuqty',
                name: '码板单位数',
                dataType: DataType.NUM,
                // tdShow: false,
            },
            {
                comName: 'fpsupuqty',
                name: '码板包装数',
                dataType: DataType.NUM,
                // tdShow: false,
            },
            {
                comName: 'fiscode',
                name: '是否套码',
                dataType: DataType.Com,
                state: {
                    1: '是',
                    2: '否',
                },
                defaultValue: 2,
                // tdShow: false,
            },
            {
                comName: 'ftiers',
                name: '层数',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'ftierqty',
                name: '每层数量',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'fweight',
                name: '重量',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'fvolume',
                name: '体积',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'flength',
                name: '长',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'fwidth',
                name: '宽',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'fheight',
                name: '高',
                dataType: DataType.NUM,
                tdShow: false,
            },
            // {
            //     comName: 'fpucode',
            //     name: '代号',//(可用于赋码时标识)
            //     show: false,
            //     tdShow: false,
            // },
            // {
            //     comName: 'forgpk',
            //     name: '组织编号',
            //     dataType: DataType.ComGETServer,
            //     comF:{
            //         controller : 'sysorginfo',
            //         action : 'getList',
            //         id : 'fpk',
            //         text : '{fcaption}-{forgaddress};{fmanager};',
            //     },
            // },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
        ]);
    }
}

export default Pojo;

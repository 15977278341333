import React from 'react';
import {Menu} from 'antd';
import './Navleft.less';
import {Link, withRouter} from "react-router-dom";
import {} from '@ant-design/icons';

import '../../api/config';
import {dropByCacheKey, getCachingKeys} from "react-router-cache-route";
import MyTable from "../../my_module/table/table";
import {breadthTreeSearch, getValueByProp} from "../../utils/utils";

const {SubMenu} = Menu;

class NavLeft extends React.Component {
    constructor(props) {
        super(props);
        this.createMenu = this.createMenu.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.setSelectKeys = this.setSelectKeys.bind(this);
        this.state = {
            openKeys: [],
            menu: [],
            selectKeys: [],
        };
        this.rootKeys = [];
        this.menuData = [];
        //console.log(global.constants.controllers);
        // if(global.constants.controllers.length === 0){
        //     this.props.history.push('/');
        // }
    }

    setSelectKeys(key) {
        console.log(key);
        console.log(this.menuData);
        let selectNode = breadthTreeSearch({
            tree: [this.menuData],
            prop: 'data.fcontroller',
            value: key,
            resultFilter: (node) => {
                return node['data']['ftypeid'] === 2
            }
        });
        console.log(selectNode);
        let openKeys = [
            ...this.rootKeys,
            getValueByProp(selectNode, 'fnumber'),
            getValueByProp(selectNode,'data.fparentpk'),
        ];
        this.setState({
            selectKeys: [key],
            openKeys: openKeys,
        },() => {
            console.log(this.state);
        })
    }

    static defaultProps = {
        // menulist: []
    }
    // componentWillUpdate = () => {
    //     let pathnames = this.props.location.pathname.split('/');
    //     let selectKeys = [pathnames[pathnames.length - 1]];
    //     this.setState({
    //         selectKeys: selectKeys,
    //     });
    // }
    componentDidMount() {
        console.log(' 触发了钩子 ');
        this.createMenu();
        let pathnames = this.props.location.pathname.split('/');
        let selectKeys = [pathnames[pathnames.length - 1]];
        this.setState({
            selectKeys: selectKeys,
        })
        this.props.onRef(this);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const siLogin = localStorage.getItem('siLogin');
        if(siLogin === '1'){
            this.componentDidMount();
            localStorage.setItem('siLogin','');
        }
    }

    setPath = (pathname) => {
        console.log(pathname);
    }
    onSelect = (p) => {
        console.log(p);
        let key = p.key;
        let selectKeys = [key];
        // console.log(key);
        this.setState({
            selectKeys: selectKeys,
        })
    }
    createMenu = () => {
        this.menuData = JSON.parse(localStorage.getItem('controllers'));
        let openKeys = [];
        let submenuIndex = 0;
        let menu = [];
        this.rootKeys = [];
        const create = (menuData, el) => {
            if (!(menuData instanceof Array)) {
                menuData = [menuData];
            }
            for (let i = 0; i !== menuData.length; i++) {
                if (menuData[i].data === undefined) {
                    continue;
                }
                if (menuData[i].data.fstateid !== 1) {
                    continue;
                }
                if ((menuData[i].children && menuData[i].children.length > 0 && menuData[i].data.ftypeid === 1) || menuData[i].data.fcontroller === '') {
                    let children = [];
                    create(menuData[i].children, children);
                    submenuIndex++;
                    let icon = undefined;
                    // key={'sub${submenuIndex}'}
                    if (menuData[i].data.fitemtype === 0) {
                        openKeys.push(menuData[i].fnumber);
                        this.rootKeys.push(menuData[i].fnumber);
                    }
                    el.push(
                        <SubMenu
                            onTitleClick={(e) => {
                                console.log(e.key);
                                // console.log(e.domEvent);
                            }}
                            key={menuData[i].fnumber}
                            title={(
                                <span style={{height: '100%', display: 'block'}}>
                                {/*<Icon type={menuData[i].icon}/>*/}
                                    {menuData[i].text}
                            </span>
                            )}>
                            {children}
                        </SubMenu>
                    )
                } else {
                    if (menuData[i].data.ftypeid === 2) {
                        // console.log(this.state.selectKeys);
                        // if(this.state.selectKeys.indexOf(menuData[i].data.fcontroller) >= 0){
                        //     this.rootKeys.push(menuData[i].fnumber);
                        // }
                        el.push(
                            //'/' + SystemInit.homeRoot + '/main/' + menuData[i].data.fcontroller + ''
                            //menuData[i].fnumber
                            <Menu.Item key={menuData[i].data.fcontroller}
                                       title={menuData[i].text}>
                                <Link to={'/' + SystemInit.homeRoot + '/main/' + menuData[i].data.fcontroller + ''}>
                                    {/*{menuData[i].icon ? <Icon type={menuData[i].icon}/> : null}*/}
                                    <span>{menuData[i].text}</span>
                                </Link>
                            </Menu.Item>
                        )
                    }
                    // if (menuData[i].data.ftypeid === 2) {
                    //     el.push(
                    //         <Menu.Item key={'/' + SystemInit.homeRoot + '/main/' + menuData[i].data.fcontroller + ''} title={menuData[i].data.fcaption}>
                    //             <Link to={'/' + SystemInit.homeRoot + '/main/' + menuData[i].data.fcontroller + ''}>
                    //                 {/*{menuData[i].icon ? <Icon type={menuData[i].icon}/> : null}*/}
                    //                 <span>{menuData[i].data.fcaption}</span>
                    //             </Link>
                    //         </Menu.Item>
                    //     )
                    // }
                    // if (menuData[i].fType === 4) {
                    //     el.push(
                    //         <Menu.Item key={menuData[i].fController + '/' + menuData[i].fAction} title={menuData[i].fName}>
                    //             <Link to={menuData[i].fController + '/' + menuData[i].fAction}>
                    //                 {/*{menuData[i].icon ? <Icon type={menuData[i].icon}/> : null}*/}
                    //                 <span>{menuData[i].fName}</span>
                    //             </Link>
                    //         </Menu.Item>
                    //     )
                    // } else {
                    //     el.push(
                    //         <Menu.Item key={menuData[i].fController + '/' + menuData[i].fAction} title={menuData[i].fName}>
                    //             <span>{menuData[i].fName}</span>
                    //         </Menu.Item>
                    //     )
                    // }
                }
            }
        };
        create(this.menuData, menu);
        // console.log(openKeys);
        this.setState({
            openKeys: openKeys,
            menu: menu,
        })
        // return menu;
    };//this.props.menuList//global.constants.controllers

    render() {
        // console.log({...this.props});
        // console.log(this.state);
        console.log(this.state);
        return (
            <div>
                <div className="logo">
                    <img src="/assets/logo-ant.svg" alt=""/>
                    <h1>后台管理</h1>
                </div>
                <Menu
                    onSelect={this.onSelect}
                    inlineCollapsed={false}
                    className={'nav-left-menu'}
                    selectedKeys={this.state.selectKeys}
                    openKeys={this.state.openKeys}
                    onOpenChange={(openKeys) => {
                        let sOpenKeys = [];
                        if(openKeys.length > this.rootKeys.length){
                            sOpenKeys = [
                                ...this.rootKeys,
                                openKeys[openKeys.length - 1]
                            ]
                        }else{
                            sOpenKeys = this.rootKeys;
                        }
                        // console.log(sOpenKeys);
                        this.setState({
                            openKeys: sOpenKeys,
                        },() => {
                            // console.log(this.rootKeys);
                            // console.log(this.state.openKeys);
                        })
                    }}
                    theme="dark"
                    mode="inline" {...this.props}>
                    {this.state.menu}
                </Menu>
            </div>
        );
    }
}

NavLeft.defaultProps = {
    onRef: (ref) => {
    },
}
NavLeft.propTypes = {}
export default withRouter(NavLeft);

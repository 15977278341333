import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {initItem, item} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";
import {setAttToExAtt} from "../../utils/utils";
class Pojo extends MyPojo {
    getgroupright = (obj) => {
        return new Promise((resolve, reject) => {
            //组唯一编号
            let frolegrouppk = obj['frolegrouppk'] === undefined ? '' : obj['frolegrouppk'];
            postObj(SystemInit.serviceUrls[this.controller] + 'sysrolegroupright' + '/getgroupright', {
                frolegrouppk: frolegrouppk,
            })
                .then((ref) => {
                    setAttToExAtt([ref.data],'data','data.info');
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }
    getmenusbyrolegroup = (obj) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + 'sysrolegroupright' + '/getmenusbyrolegroup', {
            })
                .then((ref) => {
                    setAttToExAtt([ref.data],'data','data.info');
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }
    getmenus = (obj) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + 'sysrolegroupright' + '/getmenus', {
            })
                .then((ref) => {
                    setAttToExAtt([ref.data],'data','data.info');
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }
    constructor() {
        //功能项信息
        super('sysfeature');
        this.controller = 'sys';
        this.cName = '功能项信息';
        this.tableType = TableType.TG;
        this.prefixName = 'data.info';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk:true,
            },
            {
                comName: 'fcaption',
                name: '功能项名称',
            },
            {
                comName: 'fparentpk',
                name: '父编号',
                isParent:true,
                show:false,
            },
            {
                comName: 'ftypeid',
                name: '类型',
                dataType: DataType.Com,
                state: {
                    1: '导航',
                    2: '菜单',
                    3: '按钮',
                    4: '其他',
                },
            },
            {
                comName: 'fcontroller',
                name: '控制器',
                width: 250,
                showLength: 35,
            },
            {
                comName: 'faction',
                name: '方法',
            },
            {
                comName: 'fitemtype',
                name: '菜单类型',
                dataType: DataType.Com,
                state: {
                    1: '非菜单项',
                    2: '分类菜单',
                    3: '明细菜单',
                },
            },
            {
                comName: 'fclienttype',
                name: '前端类型',
                dataType: DataType.Com,
                state: {
                    1: 'browser',
                    2: 'client',
                },
                tdShow: false,
            },
            {
                comName: 'fstateid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '启用',
                    2: '停用',
                },
            },
            {
                comName: 'fisinvoking',
                name: '是否调用控制器方法',
                dataType: DataType.Com,
                state: {
                    1: '是',
                    2: '否',
                },
                tdShow: false,
            },
            {
                comName: 'fisadmin',
                name: '是否管理员权限',
                dataType: DataType.Com,
                state: {
                    1: '是',
                    2: '否',
                },
                tdShow: false,
            },
            {
                comName: 'findex',
                name: '排序',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
        ]);
    }
}
export default Pojo;

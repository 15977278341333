import PropTypes from 'prop-types';
import React from "react";
import MyTable from "../table/table";
import {Row, Col, Divider, TreeSelect, Table, Button, Form} from 'antd';
import MTreeSelect from "../mTreeSelect/mTreeSelect";
import Pojos from "../../ylwmspages/pojos";
import MyButton from "../MyClass/button";
import SelectTablePojo from "../Common/SelectTablePojo";
import SelectTableBt from "../Common/SelectTableBt";

class Select_Tables extends React.Component {
    constructor(props) {
        super(props);
        this.onMyTable1Ref = this.onMyTable1Ref.bind(this);
        this.onMyTable2Ref = this.onMyTable2Ref.bind(this);
        this.s1SelectKey = '';
        this.t1SelectKeys = [];
        this.s2SelectKey = '';
        this.t2SelectKeys = [];
        this.state = {
            t1Pojo: Pojos.getPojo(this.props.t1Pojo.tableController),
            t2Pojo: Pojos.getPojo(this.props.t2Pojo.tableController),
            table1: undefined,
            table2: undefined,
        }
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    onMyTable1Ref = (ref) => {
        this.table1 = ref;
        this.refInit();
    }
    onMyTable2Ref = (ref) => {
        this.table2 = ref;
        this.refInit();
    }
    refInit = () => {
        this.state.t1Pojo.init()
            .then((res) => {
                // console.log(res);
                this.setState({
                    t1Pojo: this.state.pojo,
                }, () => {
                    this.table1.init();
                    if (this.props.t1Pojo.selectController === '') {
                        this.table1.getList({
                            ...this.props.t1Pojo.selectParams
                        });
                    }
                });
            })
            .catch((res) => {
                console.log(res);
            });
        this.state.t2Pojo.init()
            .then((res) => {
                // console.log(res);
                this.setState({
                    t2Pojo: this.state.pojo,
                }, () => {
                    this.table2.init();
                    if (this.props.t2Pojo.selectController === '') {
                        this.table2.getList({
                            ...this.props.t2Pojo.selectParams
                        });
                    }
                    // this.table2.getList();
                });
            })
            .catch((res) => {
                console.log(res);
            });
    }
    t1GetList = () => {
        this.table1.getList(
            {
                ...this.props.t1Pojo.selectParams,
                ...this.props.t1Pojo.exTbParams(this.s1SelectKey),
            }
        )
    }
    t2GetList = () => {
        this.table2.getList(
            {
                ...this.props.t2Pojo.selectParams,
                ...this.props.t2Pojo.exTbParams(this.s2SelectKey),
            }
        )
    }

    render() {
        let bus = [];
        for (let i = 0; i !== this.props.sBus.length; i++) {
            let sBu = this.props.sBus[i];
            bus.push(
                <Button style={{width: '90%'}} onClick={() => {
                    sBu.fun(this.s1SelectKey, this.t1SelectKeys, this.s2SelectKey, this.t2SelectKeys);
                    this.t1SelectKeys = [];
                    this.t2SelectKeys = [];
                }} type="dashed" size={'small'}>
                    {sBu.name}
                </Button>
            )
        }
        let ts1 = [];
        if (this.props.t1Pojo.selectController !== '') {
            ts1.push(
                <Form.Item
                    label={this.props.t1Pojo.title}
                >
                    <MTreeSelect
                        controller={this.props.t1Pojo.selectController}
                        action={this.props.t1Pojo.selectAction}
                        text={this.props.t1Pojo.selectText}
                        id={this.props.t1Pojo.selectId}
                        params={this.props.t1Pojo.selectParams}
                        onChange={(value) => {
                            this.s1SelectKey = value;
                            this.t1GetList();
                            this.props.t1Pojo.selectOnChange(value);
                        }}
                    />
                </Form.Item>
            )
        }
        let ts2 = [];
        if (this.props.t2Pojo.selectController !== '') {
            ts2.push(
                <Form.Item
                    label={this.props.t2Pojo.title}
                >
                    <MTreeSelect
                        controller={this.props.t2Pojo.selectController}
                        action={this.props.t2Pojo.selectAction}
                        text={this.props.t2Pojo.selectText}
                        id={this.props.t2Pojo.selectId}
                        params={this.props.t2Pojo.selectParams}
                        onChange={(value) => {
                            this.s2SelectKey = value;
                            this.t2GetList();
                            this.props.t2Pojo.selectOnChange(value);
                        }}
                    />
                </Form.Item>
            )
        }

        // height: '85vh',
        return (
            <Row style={{}}>
                <Col span={6} order={3}>
                    <div style={{
                        // backgroundColor: '#ebe5e5',
                        width: '100%',
                        // height: '100%',
                    }}>
                        {ts1}
                        <div style={{
                            // backgroundColor: '#ebe5e5',
                            width: '100%',
                            // height: '100%',
                            // maxHeight: '87vh',
                            // overflowY: 'auto',
                        }}>
                            <MyTable
                                rowSelectionOnChange={(selectedRowKeys, selectedRows) => {
                                    this.t1SelectKeys = selectedRowKeys;
                                }}
                                showRowNames={this.props.t1Pojo.showRowNames}
                                exPk={this.props.t1Pojo.tablePk}
                                isPage={false}
                                rowSelectionType={'checkbox'}
                                getListName={this.props.t1Pojo.tableAction}
                                pojo={Pojos.getPojo(this.props.t1Pojo.tableController)}
                                onRef={this.onMyTable1Ref}
                                getCheckboxProps={this.props.t1Pojo.getCheckboxProps}
                            />
                        </div>
                    </div>
                </Col>
                <Col span={2} order={2}>
                    <div style={{
                        textAlign: "center",
                    }}>
                        {bus}
                        {/*<div>*/}
                        {/*    <Button style={{width: '90%'}} onClick={() => {*/}
                        {/*        console.log(this.state.t1SelectKeys);*/}
                        {/*        console.log(this.state.t2SelectKeys);*/}
                        {/*        console.log(this.state.ts1Value);*/}
                        {/*        console.log(this.state.ts2Value);*/}
                        {/*    }} type="dashed" size={'small'}>*/}
                        {/*        设置1*/}
                        {/*    </Button>*/}
                        {/*    <Button style={{width: '90%'}} type="dashed" size={'small'}>*/}
                        {/*        设置1*/}
                        {/*    </Button>*/}
                        {/*    <Button style={{width: '90%'}} type="dashed" size={'small'}>*/}
                        {/*        设置1*/}
                        {/*    </Button>*/}
                        {/*</div>*/}
                        <div>
                        </div>
                    </div>
                </Col>
                <Col span={16} order={1}>
                    <div style={{
                        // backgroundColor: '#ebe5e5',
                        width: '100%',
                        // height: '100%',
                    }}>
                        {ts2}
                        <div style={{
                            // backgroundColor: '#ebe5e5',
                            width: '100%',
                            // height: '100%',
                            // maxHeight: '87vh',
                            // overflowY: 'auto',
                        }}>
                            <MyTable
                                rowSelectionOnChange={(selectedRowKeys, selectedRows) => {
                                    this.t2SelectKeys = selectedRowKeys;
                                }}
                                showRowNames={this.props.t2Pojo.showRowNames}
                                exPk={this.props.t2Pojo.tablePk}
                                isPage={false}
                                rowSelectionType={'checkbox'}
                                getListName={this.props.t2Pojo.tableAction}
                                pojo={Pojos.getPojo(this.props.t2Pojo.tableController)}
                                onRef={this.onMyTable2Ref}
                                getCheckboxProps={this.props.t2Pojo.getCheckboxProps}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        )
    };
}

Select_Tables.defaultProps = {
    t1Pojo: undefined,
    t2Pojo: undefined,
    sBus: [],
    onRef: (ref) => {
    },
}
Select_Tables.propTypes = {
    t1Pojo: PropTypes.instanceOf(SelectTablePojo).isRequired,
    t2Pojo: PropTypes.instanceOf(SelectTablePojo).isRequired,
    sBus: PropTypes.arrayOf(SelectTableBt),
    onRef: PropTypes.func,
}
export default Select_Tables;

import {Image, Popover} from "antd";
import {getDataReToText, getLocal, getLocalTime, getSearchList, getValueByProp} from "../../utils/utils";
import {DataType} from "../../api/mainPojo";
import React from "react";
import Pojos from "../../ylwmspages/pojos";

export default class TableUtil {
    getColumnRender = (item, pojo, getPk, isEnd = false) => {
        if (item.dataType === DataType.DataTime) {
            item.width = 200;
        }
        if (item.dataType === DataType.DataTime && item.showTime === false) {
            item.width = 80;
        }
        let column = {
            title: item.name,
            key: item.prop.split('.'),
            width: item.width,
            editable: true,
            onCell: (record, rowIndex) => ({
                record: record,
                pk: getPk(record),
                column: item,
                index: rowIndex,
                isEnd: isEnd,
            }),
        }
        column.render = (text, record, index) => {
            return this.getValue(item, record);
        }
        // if (item.isFile || item.isExFile || item.isUrlFile) {
        //     column.render = (text, record, index) => {
        //         if (item.isFile || item.isExFile) {
        //             return (
        //                 <Image
        //                     width={item.width}
        //                     src={SystemInit.imageUrl + getValueByProp(record, item.prop)}
        //                 />
        //             )
        //         } else if (item.isUrlFile) {
        //             return (
        //                 <Image
        //                     width={item.width}
        //                     src={getValueByProp(record, item.prop)}
        //                 />
        //             )
        //         }
        //     }
        // } else {
        //     column.render = (text, record, index) => {
        //         switch (item.dataType) {
        //             case DataType.Com:
        //                 return (
        //                     <span>
        //                         {item.state[getValueByProp(record, item.prop)]}
        //                     </span>
        //                 );
        //                 break;
        //             case DataType.Boolean:
        //                 return (
        //                     <span>
        //                         {getValueByProp(record, item.prop) ? item.initiate_t :
        //                             item.initiate_f}
        //                     </span>
        //                 );
        //                 break;
        //             case DataType.Request:
        //                 let searchRows = getSearchList(item.requestNames, 'id', getValueByProp(record, item.prop));
        //                 if (searchRows.length === 0) {
        //                     return (<span></span>);
        //                 } else {
        //                     return (
        //                         <span>
        //                             {getSearchList(item.requestNames, 'id', getValueByProp(record, item.prop))[0].name}
        //                         </span>
        //                     );
        //                 }
        //                 break;
        //             case DataType.ComSearch:
        //                 return (
        //                     <span>
        //                         {getValueByProp(record, item.comSearchDefaultRowName)}
        //                     </span>
        //                 );
        //                 break;
        //             case DataType.NUM:
        //             case DataType.String:
        //                 if (item.isEx) {
        //                     let value = getValueByProp(record, item.exComParent);
        //                     return (
        //                         <span>
        //                             {value}
        //                         </span>
        //                     );
        //                 }
        //                 let value = getValueByProp(record, item.prop) === undefined ? '' : getValueByProp(record, item.prop);
        //                 return this.getOmit(value, item.showLength);
        //                 break;
        //             case DataType.DataTime:
        //                 // console.log('datatime');
        //                 return (
        //                     <span>
        //                         {getLocalTime(getValueByProp(record, item.prop))}
        //                     </span>
        //                 );
        //                 break;
        //             case DataType.ComGETServer:
        //                 // console.log(record);
        //                 let comValue = getValueByProp(record, item.exComParent);
        //                 // return (
        //                 //     <span>
        //                 //         {getValueByProp(record, item.exComParent)}
        //                 //         {/*{getValueByProp(record,*/}
        //                 //         {/*    (pojo.prefixName === '' ? '' : pojo.prefixName + '.') +*/}
        //                 //         {/*    'r.' + item.comName + '.info.' + item.comF.name)}*/}
        //                 //     </span>
        //                 // );
        //                 return this.getOmit(comValue, item.showLength);
        //                 break;
        //             default:
        //             // console.log('default');
        //         }
        //     }
        // }
        return column;
    }
    getValue = (item, record, isShowLength = true) => {
        if (item.isFile || item.isExFile || item.isUrlFile || item.dataType === DataType.Img) {
            if (item.isFile || item.isExFile) {
                return (
                    <Image
                        width={item.width}
                        src={SystemInit.imageUrl + getValueByProp(record, item.prop)}
                    />
                )
            } else if (item.isUrlFile || item.dataType === DataType.Img) {
                return (
                    <Image
                        width={item.width}
                        src={getValueByProp(record, item.prop)}
                    />
                )
            }
        } else {
            switch (item.dataType) {
                case DataType.Com:
                    // console.log(getValueByProp(record, item.prop));
                    // console.log(record);
                    // console.log(item.state);
                    // console.log(item);
                    return (
                        <span style={{
                            wordBreak: 'break-all',
                        }}>
                                {item.state[getValueByProp(record, item.prop)]}
                            </span>
                    );
                    break;
                case DataType.Boolean:
                    return (
                        <span style={{
                            wordBreak: 'break-all',
                        }}>
                                {getValueByProp(record, item.prop) ? item.initiate_t :
                                    item.initiate_f}
                            </span>
                    );
                    break;
                case DataType.Request:
                    let searchRows = getSearchList(item.requestNames, 'id', getValueByProp(record, item.prop));
                    if (searchRows.length === 0) {
                        return (<span></span>);
                    } else {
                        return (
                            <span style={{
                                wordBreak: 'break-all',
                            }}>
                                    {getSearchList(item.requestNames, 'id', getValueByProp(record, item.prop))[0].name}
                                </span>
                        );
                    }
                    break;
                case DataType.ComSearch:
                    return (
                        <span style={{
                            wordBreak: 'break-all',
                        }}>
                                {getValueByProp(record, item.comSearchDefaultRowName)}
                            </span>
                    );
                    break;
                case DataType.NUM:
                case DataType.String:
                    if (item.isEx) {
                        let value = getValueByProp(record, item.exComParent);
                        return (
                            <span style={{
                                wordBreak: 'break-all',
                            }}>
                                    {value}
                                </span>
                        );
                    }
                    let value = getValueByProp(record, item.prop) === undefined ? '' : getValueByProp(record, item.prop);
                    if (isShowLength) {
                        return this.getOmit(value, item.showLength);
                    } else {
                        return (
                            <span style={{
                                wordBreak: 'break-all',
                            }}>
                                {value}
                            </span>
                        );
                    }
                    break;
                case DataType.DataTime:
                    // console.log('datatime');
                    return (
                        <span style={{
                            wordBreak: 'break-all',
                        }}>
                                {item.showTime ? getLocalTime(getValueByProp(record, item.prop)) : getLocal(getValueByProp(record, item.prop))}
                            </span>
                    );
                    break;
                case DataType.ComGETServer:
                    // console.log(record);
                    let comValue = getValueByProp(record, item.exComParent);
                    // return (
                    //     <span>
                    //         {getValueByProp(record, item.exComParent)}
                    //         {/*{getValueByProp(record,*/}
                    //         {/*    (pojo.prefixName === '' ? '' : pojo.prefixName + '.') +*/}
                    //         {/*    'r.' + item.comName + '.info.' + item.comF.name)}*/}
                    //     </span>
                    // );
                    return this.getOmit(comValue, item.showLength);
                    break;
                default:
                    return undefined;
                // console.log('default');
            }
        }
    }
    getOmit = (value, showLength) => {
        // console.log(value);
        let typeStr = Object.prototype.toString.call(value);
        if (typeStr === '[object Array]') {
            if (typeStr.length > 0) {
                value = value.toString();
            } else {
                value = '';
            }
        }
        if (value === undefined) {
            return undefined;
        }
        let sValue = '';
        if (value.length > showLength) {
            sValue = value.substr(0, showLength);
            let content = (
                <div style={{width: '400px'}}>
                    <p>
                        {value}
                    </p>
                </div>
            );
            return (
                <Popover content={content} title="详细">
                    <span>
                        {sValue}<a style={{color: '#1100ff'}}>[...]</a>
                    </span>
                </Popover>
            );
        } else {
            sValue = value;
            return (
                <span>
                    {sValue}
                </span>
            );
        }
    }
}
// //console.log(item);
// let column = {
//     title: item.name,
//     key: item.prop.split('.'),
//     width: 150,
//     editable: true,
//     onCell: (record, rowIndex) => ({
//         editT: true,
//         record: record,
//     }),
// }
// if (item.isFile || item.isExFile || item.isUrlFile) {
//     column.render = (text, record, index) => {
//         if (item.isFile || item.isExFile) {
//             return (
//                 <Image
//                     width={item.width}
//                     src={SystemInit.imageUrl + getValueByProp(record, item.prop)}
//                 />
//             )
//         } else if (item.isUrlFile) {
//             return (
//                 <Image
//                     width={item.width}
//                     src={getValueByProp(record, item.prop)}
//                 />
//             )
//         }
//     }
// } else {
//     column.render = (text, record, index) => {
//         switch (item.dataType) {
//             case DataType.Com:
//                 return (
//                     <span>
//                                         {item.state[getValueByProp(record, item.prop)]}
//                                     </span>
//                 );
//                 break;
//             case DataType.Boolean:
//                 return (
//                     <span>
//                                         {getValueByProp(record, item.prop) ? item.initiate_t :
//                                             item.initiate_f}
//                                     </span>
//                 );
//                 break;
//             case DataType.Request:
//                 let searchRows = getSearchList(item.requestNames, 'id', getValueByProp(record, item.prop));
//                 if (searchRows.length === 0) {
//                     return (<span></span>);
//                 } else {
//                     return (
//                         <span>
//                                             {getSearchList(item.requestNames, 'id', getValueByProp(record, item.prop))[0].name}
//                                         </span>
//                     );
//                 }
//                 break;
//             case DataType.ComSearch:
//                 return (
//                     <span>
//                                         {getValueByProp(record, item.comSearchDefaultRowName)}
//                                     </span>
//                 );
//                 break;
//             case DataType.NUM:
//             case DataType.String:
//                 if (item.isEx) {
//                     let value = getValueByProp(record, item.exComParent);
//                     return (
//                         <span>
//                                                 {value}
//                                             </span>
//                     );
//                 }
//                 let value = getValueByProp(record, item.prop) === undefined ? '' : getValueByProp(record, item.prop);
//                 let sValue = '';
//                 if (value.length > 6) {
//                     sValue = value.substr(0, 6);
//                     let content = (
//                         <div style={{width: '400px'}}>
//                             <p>
//                                 {value}
//                             </p>
//                         </div>
//                     );
//                     return (
//                         <Popover content={content} title="详细">
//                                                 <span>
//                                                     {sValue}<a style={{color: '#1100ff'}}>[...]</a>
//                                                 </span>
//                         </Popover>
//                     );
//                 } else {
//                     sValue = value;
//                     return (
//                         <span>
//                                                 {sValue}
//                                             </span>
//                     );
//                 }
//                 break;
//             case DataType.DataTime:
//                 // console.log('datatime');
//                 return (
//                     <span>
//                                         {getLocalTime(getValueByProp(record, item.prop))}
//                                     </span>
//                 );
//                 break;
//             case DataType.ComGETServer:
//                 return (
//                     <span>
//                                         {getValueByProp(record,
//                                             (this.props.pojo.prefixName === '' ? '' : this.props.pojo.prefixName + '.') +
//                                             'r.' + item.comName + '.info.' + item.comF.name)}
//                                     </span>
//                 );
//                 break;
//             default:
//             // console.log('default');
//         }
//     }
// }
// columns.push(column);

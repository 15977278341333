import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item, StatesID} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";

class Pojo extends MyPojo {
    // getpackdatagrid(params){
    //     return this.getList(params,'getpackdatagrid')
    // }
    getpackdatagrid(params) {
        // return this.getList(params,'getpackdatagrid');
        if (this.orderRowName instanceof Array) {
            for (let i = 0; i !== this.orderRowName.length; i++) {
                if (this.columnsMap[this.orderRowName[i]] !== undefined) {
                    params['orderby'] = this.orderRowName[i] + ' ' + this.orderType;
                    break
                }
            }
        }else {
            if (this.columnsMap[this.orderRowName] !== undefined) {
                params['orderby'] = this.orderRowName + ' ' + this.orderType;
            }
        }
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + 'wmcodeopt/getpackdatagrid', params)
                .then((res) => {
                    resolve(res);
                })
                .catch((res) => {
                    reject(res);
                });
        });
    }

    constructor() {
        //包装码信息
        super('wmpackcode');
        this.controller = 'wm';
        this.cName = '包装码信息';
        this.orderRowName = 'fregistertime'
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk: true,
                showOrderSelect: false,
                show: false,
                showSearch: false,
            },
            {
                comName: 'fpk',
                name: '编码',
                width: 250,
                showLength: 30,
            },
            {
                comName: 'fnumber',
                name: '编号',
                isInsert: false,
                isUpdate: false,
                show: false,
            },
            // {
            //     comName: 'flabelvalues',
            //     name: '标签卡值',
            // },
            {
                comName: 'fgoodsnumber',
                name: '存货编号',
                showLength: 30,
                showOrderSelect: false,
            },
            {
                comName: 'fgoodsnumber',
                name: '存货名称',
                width: 300,
                showLength: 30,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fminventory',
                    action: 'getList',
                    id: 'fnumber',
                    text: '{fcaption}',
                }),
                exComParent: 'fgoodscaption',
            },
            {
                comName: 'fcustomer',
                name: '客户名称',
                isEx: true,
                exComParent: 'fcustomer',
                tdShow: false,
            },
            {
                comName: 'forderentrypk',
                name: '生产订单',
                showLength: 30,
                width: 200,
                isEx: true,
                exComParent: 'forderentrypk',
            },
            {
                comName: 'fregistertime',
                name: '登记时间',
                dataType: DataType.DataTime,
            },
            {
                comName: 'fbatchnumber',
                name: '批号',
            },
            {
                comName: 'fstoragepk',
                name: '储位',
                width: 200,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fmstorage',
                    action: 'getList',
                    id: 'fcaption',
                    text: '{fcaption}',
                    multiple: true,
                    // isInitialize: false,
                }),
                exComParent: 'fstorage',
            },
            {
                comName: 'finvoicenumber',
                name: '发货单号',
            },
            {
                comName: 'fgoodsstateid',
                name: '存货状态',
                dataType: DataType.Com,
                state: StatesID.getPState(),
                defaultValue:1,
            },
            {
                comName: 'fsucqty',
                name: '单位码数',
                dataType: DataType.NUM,
            },
            {
                comName: 'fsuqty',
                name: '单位数量',
                dataType: DataType.NUM,
            },
            // {
            //     comName: 'ftiercode',
            //     name: '层码',
            // },
            {
                comName: 'fpackagecode',
                name: '垛码',
            },
            {
                comName: 'fpackagetime',
                name: '码垛时间',
                dataType: DataType.DataTime,
                tdShow: false,
            },
            // {
            //     comName: 'ftiertime',
            //     name: '码层时间',
            //     dataType: DataType.DataTime,
            // },
            {
                comName: 'flosstime',
                name: '破损时间',
                dataType: DataType.DataTime,
                tdShow: false,
            },
            {
                comName: 'fqctime',
                name: '抽检时间',
                dataType: DataType.DataTime,
                tdShow: false,
            },
            {
                comName: 'finvoicenumber',
                name: '发货单号',
                tdShow: false,
            },
            // {
            //     comName: 'fquerytime',
            //     name: '查询时间',
            //     dataType: DataType.DataTime,
            // },
            // {
            //     comName: 'fqutryqty',
            //     name: '查询次数',
            // },
            {
                comName: 'fstateid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '保存',
                    2: '抽检',
                    3: '破损',
                    4: '在库',
                    5: '拣货',
                    6: '装车',
                    7: '发货',
                    8: '调拨',
                    9: '返工',
                },
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
        ]);
    }
}

export default Pojo;

import {DataType} from "../api/mainPojo";
import moment from "moment";

export const formateDate = (time) => {
    if (!time)
        return '';
    let date = new Date(time);
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
};
export const setRowValue = (row, props, value, index) => {
    //console.log(row);
    if (row[props[index]] === undefined) {
        if (index < props.length) {
            row[props[index]] = {};
            row[props[index]] = setRowValue(row[props[index]], props, value, ++index);
            return row;
        } else {
            //row[props[index]] = value;
            return value;
        }
    } else {
        row[props[index]] = setRowValue(row[props[index]], props, value, ++index);
    }
    return row;
}
//获取复杂类型里的值
export const getValueByProp = (row, prop) => {
    // console.log(row);
    // console.log(prop);
    //console.log(JSON.stringify(row));
    if (row === undefined || row === null) {
        return '';
    }
    let arr = Object.getOwnPropertyNames(row);
    if (arr.length === 0) {
        return "";
    }
    //console.log(prop);
    if (row === null || row === undefined) {
        return "";
    }
    let pRow = row;
    try {
        let props = prop.split('.');
        for (let i = 0; i !== props.length - 1; i++) {
            pRow = pRow[props[i]];
        }
        if (pRow === null) {
            return '';
        }
        return pRow[props[props.length - 1]];
    } catch (e) {
        console.warn(e);
        // console.log(row);
        // console.log(prop);
        return '';
    }
};
export const setValueByProp = (row, prop, value) => {
    // console.log(prop);
    if (row === undefined) {
        return row;
    }
    if (prop === undefined) {
        return row;
    }
    let props = Object.prototype.toString.call(prop) === '[object Array]' ? prop : prop.split('.');
    // console.log(props);
    if (props.length === 1) {
        row[props[0]] = value;
        return row;
    } else {
        row[props[0]] = setValueByProp(row[props[0]], props.slice(1), value);
    }
    // console.log(row);
    return row;
}
//合并
export const extend = (o, n) => {
    let retObj = {};
    for (let p in n) {
        if (n.hasOwnProperty(p) && (!o.hasOwnProperty(p))) {
            retObj[p] = n[p];
        } else {
            retObj[p] = o[p];
        }
    }
    return retObj;
};
//定义属性 并 注册到注册表 && 冻结
export const exComType = () => {
    return Object.freeze({
        DateRange: Symbol.for('日期范围'),
    })
};
export const getSearchList = (list, prop, text) => {
    if (text === '') {
        return [];
    }
    let searchList = [];
    let reg = new RegExp(text);
    for (let key in list) {
        let val = getValueByProp(list[key], prop);
        if (reg.test(val)) {
            searchList.push(list[key]);
        }
    }
    return searchList;
};
//获取父级pk集合
export const getPids = ({
                            tree = [],
                            root = '',
                            idProp = 'id',
                            pidProp = 'pid',
                            pushProp = 'children',
                            ids = []
                        } = {}) => {
    console.log(ids);
    if (ids.length === 0) {
        return [];
    }
    let pids = [];
    let getPNode = (id) => {
        pids.push(id);
        let node = breadthTreeSearch({
            tree: tree,
            prop: idProp,
            value: id,
        });
        let rowPPk = getValueByProp(node, pidProp);
        if (rowPPk !== '' && rowPPk !== root) {
            getPNode(rowPPk)
        }
    }
    //先稳最高级
    for (let i = 0; i !== ids.length; i++) {
        let rootId = '';
        let id = ids[i];
        let node = breadthTreeSearch({
            tree: tree,
            prop: idProp,
            value: id,
        });
        if (node === null || node === undefined || node[pushProp] === undefined) {
            return [];
        }
        //查询 选中记录的上级 就是 选中记录的 顶级
        let pid = getValueByProp(node, pidProp);
        if (ids.indexOf(getValueByProp(node, pidProp)) <= 0) {
            rootId = pid;
        }
        if (pids.indexOf(rootId) && rootId !== '' && rootId !== root) {
            // pids.push(rootId);
            getPNode(rootId);
        }
    }
    return Array.from(new Set(pids));
};
//将列表转换成树
export const getTree = (list, root, idParName = 'id', pidParName = 'pid', pushParName = 'children') => {
    //console.log(pidParName);
    //递归方法
    let getNode = (id) => {
        let node = [];
        for (let i in list) {
            if (list[i][pidParName] === id) {
                let nodes = getNode(list[i][idParName]);
                console.log(nodes);
                if (nodes.length > 0) {
                    list[i][pushParName] = nodes;
                }
                node.push(list[i]);
            }
        }
        if (node.length === 0) {
            return [];
        } else {
            return node;
        }
    }
    //使用根节点
    return getNode(root);
};
//搜索树元素
export const breadthTreeSearch = (
    {
        tree = [],
        prop = '',
        value = '',
        resultFilter = (node) => {
            return true
        }
    } = {}) => {
    let isGet = false;
    let retNode = null;
    let search = (tree, prop, value, resultFilter) => {
        for (let i = 0; i !== tree.length; i++) {
            if (tree[i].children && tree[i].children.length > 0) {
                search(tree[i].children, prop, value, resultFilter);
            }
            let rowValue = getValueByProp(tree[i], prop, value);
            if ((value === rowValue && resultFilter(tree[i])) || isGet) {
                isGet || (retNode = tree[i]);
                isGet = true;
                break;
            }
        }
    }
    search(tree, prop, value, resultFilter);
    return retNode;
}
/**
 * 获取数组的不同 子函数
 * @param arr1
 * @param arr2
 */
export const filterFun = (arr1, arr2) => {
    let result = [];
    for (let i = 0; i !== arr1.length; i++) {
        if (arr2.indexOf(arr1[i]) === -1) {
            result.push(arr1[i]);
        }
    }
    return result;
}
/**
 * 获取数组的不同
 * @param arr1
 * @param arr2
 */
export const filterData = (arr1, arr2) => {
    return filterFun(arr1, arr2).concat(filterFun(arr2, arr1));
}
/**
 * 获取两个数组相同的元素
 * @param arr1
 * @param arr2
 * @returns {[]}
 */
export const getArrEqual = (arr1, arr2) => {
    let newArr = [];
    for (let i = 0; i !== arr2.length; i++) {
        for (let j = 0; j !== arr1.length; j++) {
            if (arr1[j] === arr2[i]) {
                newArr.push(arr1[j]);
            }
        }
    }
    return newArr;
}
/***
 * 返回子叶节点
 * @param tree 树
 * @param resultFilter 过滤器
 * @param prop 指定属性
 * @returns {[]}
 */
export const searchTreeCotyledon = (
    {
        tree = [],
        prop = '',
        resultFilter = (node) => {
            return true
        }
    } = {}
) => {
    let rets = [];
    let search = (tree, prop, resultFilter) => {
        for (let i = 0; i !== tree.length; i++) {
            if (tree[i].children && tree[i].children.length > 0) {
                search(tree[i].children, prop, resultFilter);
            } else {
                if (resultFilter(tree[i])) {
                    if (prop === '') {
                        rets.push(tree[i]);
                    } else {
                        rets.push(getValueByProp(tree[i], prop))
                    }
                }
            }
        }
    }
    search(tree, prop, resultFilter);
    return rets;
}
export const refactorTreeData = (
    {
        tree = [],
        refactorPojo = {},
        resultFilter = () => {
            return true
        }
    } = {}
) => {
    let rets = [];
    let subfunction = (tree, resultFilter, rets) => {
        let retChildrens = [];
        for (let i = 0; i !== tree.length; i++) {
            let retItem = setItem(tree[i]);
            retItem.children = [];
            if (tree[i].children && tree[i].children.length > 0) {
                retItem.children = subfunction(tree[i].children, resultFilter, []);
            }
            rets.push(retItem);
        }
        return rets;
    }
    let setItem = (item) => {
        let retItem = {};
        for (let a in refactorPojo) {
            if (refactorPojo[a] === '') {
                retItem[a] = item;
            } else {
                retItem[a] = getValueByProp(item, refactorPojo[a]);
            }
        }
        return retItem;
    }
    rets = subfunction(tree, resultFilter, rets)
    return rets;
}

//查询树 获取 指定属性 和 所在 对象
export const searchTreeToList = (
    tree,
    conditionFilter = (node) => {
        return true
    },
    resultFilter = (node) => {
        return true
    },
    keyRowName = '') => {
    let conditionNode = undefined;
    let find = (arr) => {
        for (let i in arr) {
            if (conditionFilter(arr[i])) {
                conditionNode = arr[i];
                break;
            }
            if (arr[i].children !== undefined) {
                if (arr[i].children.length > 0) {
                    find(arr[i].children);
                }
            }
        }
    }
    let retItem = {};
    let resultFind = (arr) => {
        for (let i in arr) {
            if (resultFilter(arr[i])) {
                retItem[arr[i][keyRowName]] = arr[i];
            }
            if (arr[i].children !== undefined) {
                if (arr[i].children.length > 0) {
                    resultFind(arr[i].children);
                }
            }
        }
    }
    find(tree);
    if (conditionNode === undefined) {
        return {};
    } else {
        resultFind(conditionNode.children);
        return retItem;
    }
};
export const initActions = () => {
    let tree = JSON.parse(localStorage.getItem('controllers'));//global.constants.controllers;
    console.log(tree);
    if (!(tree instanceof Array)) {
        tree = [tree];
    }
    let allControllerAction = {};
    let find = (arr) => {
        for (let i in arr) {
            if (arr[i].data === undefined) {
                continue;
            }
            if (arr[i].data.ftypeid === 2) {
                if (arr[i].children.length > 0) {
                    allControllerAction[arr[i].data.fcontroller] = {};
                    for (let k in arr[i].children) {
                        if (arr[i].children[k].data.ftypeid === 3) {
                            allControllerAction[arr[i].data.fcontroller][arr[i].children[k].data.faction] = arr[i].children[k];
                        }
                    }
                }
            }
            find(arr[i].children);
        }
    }
    find(tree);
    console.log(allControllerAction);
    //global.constants.allControllerAction = allControllerAction;
    localStorage.setItem('allControllerAction', JSON.stringify(allControllerAction));
}
//获取控制器下的所有权限
export const getActions = (controller) => {
    //global.constants.controllers
    let retItem = searchTreeToList(JSON.parse(localStorage.getItem('controllers')),
        (node) => {
            return node.controller === controller;
        },
        (node) => {
            return node.type === 2
        },
        'activice'
    )
    console.log(retItem);
}
//将对象里的所有数组字段转成字符串
export const objArrToStr = (obj) => {
    if (obj === undefined) {
        return;
    }
    let objKeys = Object.keys(obj);
    for (let i = 0; i !== objKeys.length; i++) {
        let key = objKeys[i];
        let typeStr = Object.prototype.toString.call(obj[key]);
        if (typeStr === '[object Array]') {
            obj[key] = obj[key].toString();
        } else if (typeStr === '[object Object]') {
            objArrToStr(obj[key]);
        }
    }
}
//复杂对象合并
export const deepmerge = (o1, o2) => {
    let o2Keys = Object.keys(o2);
    for (let i = 0; i !== o2Keys.length; i++) {
        let key = o2Keys[i];
        let typeStr = Object.prototype.toString.call(o2[key]);
        if (typeStr === '[object String]' ||
            typeStr === '[object Number]' ||
            typeStr === '[object Date]' ||
            typeStr === '[object Boolean]') {
            o1[key] = o2[key];
        } else if (typeStr === '[object Array]') {
            if (o1[key] === undefined) {
                o1[key] = o2[key];
            } else {
                if (Object.prototype.toString.call(o1[key]) === typeStr) {
                    o1[key].push.apply(o1[key], o2[key]);
                } else {
                    o1[key] = o2[key];
                }
            }
        } else if (typeStr === '[object Object]') {
            if (o1[key] === undefined) {
                o1[key] = o2[key];
            } else {
                o1[key] = deepmerge(o1[key], o2[key]);
            }
        }
    }
    return o1;
}
//自定义类型转 转 string
export const eObjToStr = (obj) => {
    // console.log(obj);
    // objArrToStr(obj);
    // console.log(obj);
    if (obj === undefined || obj === null) {
        return obj;
    }
    let setObj = (o) => {
        let keys = Object.keys(o);
        for (let i = 0; i !== keys.length; i++) {
            let key = keys[i];
            let typeStr = Object.prototype.toString.call(o[key]);
            if (o[key]) {
                if (o[key]._isAMomentObject !== undefined) {
                    o[key] = o[key].format('YYYY-MM-DD HH:mm:ss');
                } else if (o[key]._isTreeSelectRef !== undefined) {
                    // console.log('自定义类型转');
                    // console.log(o[key].cProp);
                    // console.log(o[key].prop);
                    // console.log(o[key].value);
                    // console.log(o[key].key);
                    setValueByProp(obj, o[key].prop, o[key].value);
                    setValueByProp(obj, o[key].cProp, o[key].key);
                    // console.log(obj);
                } else {
                    if (typeStr === '[object Object]') {
                        setObj(o[key]);
                    } else if (typeStr === '[object Array]') {
                        for (let k = 0; k !== o[key].length; k++) {
                            setObj(o[key][k]);
                        }
                    }
                }
            }
        }
    }
    setObj(obj);
    // console.log(obj);
}
// export const eObjToFormData = (obj) => {
//     let setObj = (o) => {
//         let keys = Object.keys(o);
//         for (let i = 0; i !== keys.length; i++) {
//             let key = keys[i];
//             let typeStr = Object.prototype.toString.call(o[key]);
//             if (o[key]) {
//                 if (o[key]._isAMomentObject !== undefined) {
//                     o[key] = o[key].format('YYYY-MM-DD HH:mm:ss');
//                     o[key] = moment(o[key], 'YYYY-MM-DD HH:mm:ss')
//                 } else if (o[key]._isTreeSelectRef !== undefined) {
//                     // console.log('自定义类型转');
//                     // console.log(o[key].cProp);
//                     // console.log(o[key].prop);
//                     // console.log(o[key].value);
//                     // console.log(o[key].key);
//                     setValueByProp(obj, o[key].prop, o[key].value);
//                     setValueByProp(obj, o[key].cProp, o[key].key);
//                     // console.log(obj);
//                 } else {
//                     if (typeStr === '[object Object]') {
//                         setObj(o[key]);
//                     } else if (typeStr === '[object Array]') {
//                         for (let k = 0; k !== o[key].length; k++) {
//                             setObj(o[key][k]);
//                         }
//                     }
//                 }
//             }
//         }
//     }
//     setObj(obj);
//     // console.log(obj);
// }
export const getSearchItem = (value, comName, dataType) => {
    return {
        value: value,
        comName: comName,
        dataType: dataType,
    }
}
//创建查询 对象
//"value":"1","comName":"iD","dataType":"EqualTo"
export const newSearch = (sArr) => {
    let ref = {
        search: [],
    }
    let sList = [];
    for (let i = 0; i !== sArr.length; i++) {
        let sItem = sArr[i];
        sList.push({
            value: sItem.value,
            comName: sItem.comName,
            dataType: sItem.dataType['name']
        })
    }
    ref.search = JSON.stringify(sList);
    return ref;
}

function add0(m) {
    return m < 10 ? '0' + m : m
}
export const getLocalAddDayTime00 = (nS,day = 0) => {
    // console.log(nS);
    let time = new Date(nS);
    let y = time.getFullYear();
    let m = time.getMonth() + 1;
    let d = time.getDate() + day;
    let h = time.getHours();
    let mm = time.getMinutes();
    let s = time.getSeconds();
    let ref = y + '-' + add0(m) + '-' + add0(d) + ' 00:00:00.000';
    if (ref === '9999-12-30 00:00:00.000') {
        return '';
    }
    return ref;
}
export const getLocalTime = (nS) => {
    // console.log(nS);
    let time = new Date(nS);
    let y = time.getFullYear();
    let m = time.getMonth() + 1;
    let d = time.getDate();
    let h = time.getHours();
    let mm = time.getMinutes();
    let s = time.getSeconds();
    let ref = y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
    if (ref === '9999-12-30 23:59:59') {
        return '';
    }
    return ref;
}
export const getLocal = (nS) => {
    // console.log(nS);
    let time = new Date(nS);
    let y = time.getFullYear();
    let m = time.getMonth() + 1;
    let d = time.getDate();
    // let h = time.getHours();
    // let mm = time.getMinutes();
    // let s = time.getSeconds();
    let ref = y + '-' + add0(m) + '-' + add0(d);
    if (ref === '9999-12-30') {
        return '';
    }
    return ref;
}
export const setRow = (columns, row) => {
    for (let i in columns) {
        let column = columns[i];
        let value;
        switch (column.dataType) {
            case DataType.String:
                value = column.defaultValue === undefined ? '' : column.defaultValue;
                break;
            case DataType.Boolean:
                value = column.defaultValue === undefined ? false : column.defaultValue;
                break;
            case DataType.ComSearch:
                value = column.defaultValue === undefined ? '' : column.defaultValue;
                break;
            case DataType.Request:
                value = column.defaultValue === undefined ? '' : column.defaultValue;
                break;
            case DataType.Com:
                value = column.defaultValue === undefined ? 0 : column.defaultValue;
                break;
            case DataType.ComGETServer:
                value = column.defaultValue === undefined ? '' : column.defaultValue;
                break;
            case DataType.DataTime:
                value = moment(new Date(), 'YYYY-MM-DD');//moment('9999-12-30 23:59:59', 'YYYY-MM-DD');//'9999-12-30 23:59:59';//formateDate(new Date());
                break;
            case DataType.LongText:
                value = column.defaultValue === undefined ? '' : column.defaultValue;
                break;
            case DataType.NUM:
                value = column.defaultValue === undefined ? '0' : column.defaultValue;
                break;
            default:
                value = undefined;
        }
        let props = column.prop.split('.');
        //let row = setRowValue({},props,value,0);
        setRowValue(row, props, value, 0);
    }
    //console.log(this.row);
}

//region 正则查询 下拉显示
export const getTreeSelectData = (data, id, text, serverPojo, treeData, prop) => {
    try {
        let children = 'children';
        for (let i = 0; i !== data.length; i++) {
            let item = data[i];
            let label = getDataReToText(item, prop, text);
            // console.log(label);
            // console.log(id);
            let treeItem = {
                title: label,
                value: getValueByProp(item, prop + id),
            }
            if (item[children] !== undefined && item[children].length > 0) {
                treeItem[children] = [];
                getTreeSelectData(item[children], id, text, serverPojo, treeItem[children], prop);
            }
            treeData.push(
                treeItem
            )
        }
        return treeData;
    } catch (e) {
        console.log(e);
        return [];
    }
}
/**
 * 追加属性
 * list 数据
 * att 需要转的属性
 * toAtt 目标属性
 */
export const setAttToExAtt = (list, att, toAtt) => {
    try {
        let children = 'children';
        for (let i = 0; i !== list.length; i++) {
            let item = list[i];
            setValueByProp(item, toAtt, JSON.parse(JSON.stringify(item[att])));
            if (item[children] !== undefined && item[children].length > 0) {
                setAttToExAtt(item[children], att, toAtt);
            }
        }
    } catch (e) {
        console.log(e);
    }
}
export const getDataReToText = (data, prop, text) => {
    let label = text;
    let re = /\{[^\}]+\}/g;
    let encode = text.match(re);
    for (let k in encode) {
        let encodeValue = encode[k].replace(/\{/g, '').replace(/\}/g, '');
        let itemProp = encodeValue.indexOf('T.') >= 0 ? encodeValue.substring(2) : prop + encodeValue;
        let encodeText = getValueByProp(data, itemProp);
        //console.log(encode);
        label = label.replace(new RegExp(encode[k], 'g'), encodeText);
    }
    return label;
}
//endregion

//初始化form数据 初始化 为 form 组件对应的 数据
export const iniFormRow = (columns, row) => {
    for (let i = 0; i !== columns.length; i++) {
        let column = columns[i];
        if (column.dataType === DataType.DataTime) {
            let value = getValueByProp(row, column.prop);
            if (value._isAMomentObject === undefined) {
                if (value === 253402099200000 || value === '9999-12-30 23:59:59') {
                    value = formateDate(new Date());
                } else {
                    value = formateDate(value);
                }
                // console.log(value);
                let ref = moment(value, 'YYYY-MM-DD HH:mm:ss');
                setValueByProp(row, column.prop, ref);
            }
        }
    }
}
//导出csv
export const downloadFun = (fileName, str) => {
    const dtStr = new Date().Format('yyyyMMddhhmm');
    console.log(dtStr);
    let blob = new Blob([str], {type: "text/plain;charset=utf-8"});
    blob = new Blob([String.fromCharCode(0xFEFF), blob], {type: blob.type});
    let object_url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = object_url;
    link.download = dtStr + fileName + '.csv'; // "导出.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
export const getCsvStr = (fFun, rows, headers, fields, fileName, cb) => {
    //$.messager.progress();
    const dataE = fFun(rows, fields);
    console.log(dataE);
    //console.log(dataE);
    let str = '';
    if (headers.length > 0) {
        for (let i = 0; i !== headers.length; i++) {
            str += headers[i] + ',';
        }
        str += '\n';
    }
    for (let i = 0; i !== dataE.length; i++) {
        for (let j = 0; j !== dataE[i].length; j++) {
            if (dataE[i][j] === "9999-12-31 23:59:59") {
                str += ','
            } else {
                str += dataE[i][j] + ','
            }

        }
        str += '\n'
    }
    console.log(str);
    cb(fileName, str);
}

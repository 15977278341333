import React from 'react';

export const MyContext = React.createContext({
    setPage: ({
                  page = '',
                  name = '',
              }) => {
        // console.log(page);
    },
});

import React, {useState} from 'react';
import './index.less';
import {Row, Button, Table, Tag, Radio, Modal, Tabs, Form, message} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import {get} from "../../axios/Response";
import MyTable from "../../my_module/table/table";
// import Pojo from "./pojo"
import MyDataGrid from "../../my_module/datagrid/datagrid";
import MyButton from "../../my_module/MyClass/button";
import Nestgrid from "../../my_module/nestgrid/nestgrid";
import Pojos from "../pojos"
import {getButtomModal} from "../../my_module/MyClass/domUtil";
import FormUtil from "../../my_module/Common/FormUtil";
import {DataType} from "../../api/mainPojo";
import {downloadFun, getCsvStr, getValueByProp} from "../../utils/utils";
import {ComF} from "../../calzzs/initItems";

const {TabPane} = Tabs;

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};

//生产任务管理
class index extends React.Component {
    constructor(props) {
        super(props);
        this.onDGRef = this.onDGRef.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        // this.addOpenIntercept = this.addOpenIntercept.bind(this);
        // this.editOpenIntercept = this.editOpenIntercept.bind(this);
        this.ftypeid = 1;
        this.fqty = 0;
        this.fissuit = 2;
        this.itemfqty = 0;
        this.fprocesspk = '';
        this.state = {
            // addModalVisible: false,
            pojo: Pojos.getPojo('pmtask'),//new Pojo(),
            dg: undefined,
        }
        console.log(this.pojo);
    }

    exTrBus = [
        getButtomModal(
            {
                title: '结束生产',
                content: '确定结束生产?',
                name: 'finish',
                okFun: (row) => {
                    console.log(row);
                    return new Promise((resolve, reject) => {
                        this.state.pojo.finish({
                            fpk: row['fpk'],
                        })
                            .then((ref) => {
                                resolve(ref);
                            })
                            .catch((ref) => {
                                reject(ref);
                            })
                    });
                },
                thenFun: (ref) => {
                    this.state.dg.getList();
                }
            }
        ),
        getButtomModal(
            {
                title: '生成码',
                // content: '确定生成2W个码?',
                content:
                    (<Form {...layout}>
                        {
                            [
                                new FormUtil().getFormItem(
                                    {
                                        column: {
                                            comName: 'ftypeid',
                                            prop: 'ftypeid',
                                            name: '类型',
                                            dataType: DataType.Com,
                                            defaultValue: 1,
                                            state: {
                                                1: '垛码',
                                                2: '包装码',
                                                3: '单位码',
                                            },
                                        },
                                        onChange: (value, ...data) => {
                                            // console.log(value);
                                            // console.log(data);
                                            this.ftypeid = value === null ? 1 : value;
                                        }
                                    },
                                ),
                                new FormUtil().getFormItem(
                                    {
                                        dvalue: this.fqty,
                                        column: {
                                            comName: 'fqty',
                                            prop: 'fqty',
                                            name: '数量',
                                            dataType: DataType.NUM,
                                        },
                                        onChange: (value, ...data) => {
                                            // console.log(value);
                                            // console.log(data);
                                            this.fqty = value === null ? 0 : value;
                                        }
                                    },
                                ),
                                new FormUtil().getFormItem(
                                    {
                                        column: {
                                            comName: 'fissuit',
                                            prop: 'fissuit',
                                            name: '是否按套',
                                            dataType: DataType.Com,
                                            defaultValue: 2,
                                            state: {
                                                1: '是',
                                                2: '否',
                                            },
                                        },
                                        onChange: (value, ...data) => {
                                            // console.log(value);
                                            // console.log(data);
                                            this.fissuit = value === null ? 1 : value;
                                        }
                                    },
                                ),
                            ]
                        }
                    </Form>),
                name: 'createbatchcode_pm',
                okFun: (row) => {
                    return new Promise((resolve, reject) => {
                        if (this.ftypeid === 0) {
                            message.error('请选择类型!');
                            reject(undefined);
                        } else {
                            this.state.pojo.createbatchcode_pm({
                                ftypeid: this.ftypeid,
                                fqty: this.fqty,
                                fbatchnumber: row['fpk'],
                                fissuit: this.fissuit,
                            })
                                .then((ref) => {
                                    // console.log(ref);
                                    let index = 0;
                                    getCsvStr(
                                        (rows, fields) => {
                                            let dataE = [];
                                            // console.log(dataE);
                                            for (let i = 0; i !== rows.length; i++) {
                                                const row = rows[i];
                                                let _fsubcodes = row['fsubcodes'];
                                                let _fnestcodes = row['fnestcodes'];
                                                if (_fsubcodes !== undefined && _fsubcodes.length > 0) {
                                                    for (let k = 0; k !== _fsubcodes.length; k++) {
                                                        dataE[index] = [];
                                                        for (let j in fields) {
                                                            const key = fields[j];
                                                            const value = getValueByProp(row, key);
                                                            dataE[index].push(value);
                                                        }
                                                        dataE[index].push(_fsubcodes[k]);
                                                        index++;
                                                    }
                                                } else if (_fnestcodes !== undefined && _fnestcodes.length > 0) {
                                                    for (let k in _fnestcodes) {
                                                        let _fnestcode = _fnestcodes[k];
                                                        let nfsubcodes = _fnestcode['fsubcodes'];
                                                        if (nfsubcodes !== undefined && nfsubcodes.length > 0) {
                                                            for (let z = 0; z !== nfsubcodes.length; z++) {
                                                                dataE[index] = [];
                                                                for (let j in fields) {
                                                                    const key = fields[j];
                                                                    const value = getValueByProp(row, key);
                                                                    // const svalue = getValueByProp(_fnestcode, key);
                                                                    dataE[index].push(value);
                                                                    // dataE[index].push(svalue);
                                                                }
                                                                dataE[index].push(nfsubcodes[z]);
                                                                index++;
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    dataE[index] = [];
                                                    for (let j in fields) {
                                                        const key = fields[j];
                                                        const value = getValueByProp(row, key);
                                                        dataE[i].push(value);
                                                    }
                                                    index++;
                                                }
                                            }
                                            return dataE;
                                        },
                                        ref.data,
                                        [],
                                        ['fbatchnumber', 'fcode', 'fgoodscaption'],
                                        '生成码',
                                        downloadFun,
                                    )
                                    resolve(ref);
                                })
                                .catch((ref) => {
                                    reject(ref);
                                })
                        }
                    });
                },
                thenFun: (ref) => {
                    //this.state.dg.getList();
                },
                confirma: false,
            }
        ),
        getButtomModal(
            {
                title: '部件码',
                // content: '确定生成2W个码?',
                content:
                    (<Form {...layout}>
                        {
                            [
                                new FormUtil().getFormItem(
                                    {
                                        column: {
                                            comName: 'fprocesspk',
                                            prop: 'fprocesspk',
                                            name: '工序',
                                            dataType: DataType.ComGETServer,
                                            comF: new ComF({
                                                controller: 'fmprocess',
                                                action: 'getList',
                                                id: 'fpk',
                                                text: '{fcaption}',
                                            }),
                                        },
                                        onChange: (value, ...data) => {
                                            // console.log(value);
                                            // console.log(data);
                                            this.fprocesspk = value.key;
                                        }
                                    },
                                ),
                                new FormUtil().getFormItem(
                                    {
                                        dvalue: this.itemfqty,
                                        column: {
                                            comName: 'itemfqty',
                                            prop: 'itemfqty',
                                            name: '数量',
                                            dataType: DataType.NUM,
                                        },
                                        onChange: (value, ...data) => {
                                            // console.log(value);
                                            // console.log(data);
                                            this.itemfqty = value === null ? 0 : value;
                                        }
                                    },
                                ),
                            ]
                        }
                    </Form>),
                name: 'createbatchcode_pm',
                okFun: (row) => {
                    return new Promise((resolve, reject) => {
                        if (this.ftypeid === 0) {
                            message.error('请选择工序!');
                            reject(undefined);
                        } else {
                            this.state.pojo.createbatchitemcode_pm({
                                fqty: this.itemfqty,
                                fbatchnumber: row['fpk'],
                                fprocesspk: this.fprocesspk,
                            })
                                .then((ref) => {
                                    // console.log(ref);
                                    let index = 0;
                                    getCsvStr(
                                        (rows, fields) => {
                                            let dataE = [];
                                            // console.log(dataE);
                                            for (let i = 0; i !== rows.length; i++) {
                                                const row = rows[i];
                                                dataE[index] = [];
                                                for (let j in fields) {
                                                    const key = fields[j];
                                                    const value = getValueByProp(row, key);
                                                    dataE[i].push(value);
                                                }
                                                index++;
                                            }
                                            return dataE;
                                        },
                                        ref.data,
                                        [],
                                        ['fbatchnumber', 'fcode', 'fgoodscaption'],
                                        '部件码',
                                        downloadFun,
                                    )
                                    resolve(ref);
                                })
                                .catch((ref) => {
                                    reject(ref);
                                })
                        }
                    });
                },
                thenFun: (ref) => {
                    //this.state.dg.getList();
                },
                confirma: false,
            }
        ),
    ]
    onDGRef = (ref) => {
        this.setState({
            dg: ref,
        }, () => {
            //this.state.dg.refInit();
        })
    }
    componentDidUpdate = (prevProps, prevState) => {
    }
    // addModal = () => {
    //     let model =
    //         <Modal title="Basic Modal" visible={this.state.addModalVisible}
    //                onCancel={() => {
    //                    this.setState({
    //                        addModalVisible:false,
    //                    })
    //                }}>
    //             <p>Some contents...</p>
    //             <p>Some contents...</p>
    //             <p>Some contents...</p>
    //         </Modal>;
    //     return model;
    // }
    // addOpenIntercept = (ref) => {
    //     console.log(ref);
    //     // this.setState({
    //     //     addModalVisible:true,
    //     // })
    //     return false;
    // }
    // editOpenIntercept = (ref) => {
    //     console.log(ref);
    //     return false;
    // }
    render() {
        return (
            <div>
                {/*{this.addModal()}*/}
                <MyDataGrid
                    // addOpenIntercept={this.addOpenIntercept}
                    // editOpenIntercept={this.editOpenIntercept}
                    // isSetTrBus={false}
                    isEdit={false}
                    isDel={false}
                    isAdd={false}
                    onRef={this.onDGRef}
                    pojo={this.state.pojo}
                    exTrBus={this.exTrBus}/>
            </div>
        );
    }
}

export default index;

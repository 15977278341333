import React from 'react';
import './nestgrid.less'
import Datagrid from "../datagrid/datagrid";
import {Row, Col, Slider, Tree} from 'antd';
import MyPojo from "../MyClass/pojo";
import MyDataGrid from "../datagrid/datagrid";
import PropTypes from 'prop-types';
import MyTree from '../mytree/mytree';
import RightControllerPojo from "../../pages/rightscontroller/pojo";
import NestGridDetail from './nestgrid_detail';
class Nestgrid extends React.Component{
    titleT = undefined;
    trT = undefined;
    constructor(props) {
        super(props);
    }
    onRowClick = (row) => {
        this.props.onRowClick(row);
    }
    // mytrRef = (ref) => {
    //     console.log(ref);
    //     ref.init();
    //     this.titleT = ref;
    // }
    getDetailRender = () => {
        let details = [];
        for(let a in this.props.detailPojos){
            details.push(this.props.detailPojos[a].render);
        }
        return details;
    }
    render() {
        return (
            <Row className={'components-grid-demo-playground'} style={{width:'100%'}} gutter={[1, 1]}>
                <Col span={12}>
                    <MyDataGrid onRowClick={this.onRowClick} onRef={node => this.titleT = node} pojo={this.props.titlePojo}></MyDataGrid>
                </Col>
                <Col span={12} style={{height:'76vh',overflowY:'auto'}}>
                    {this.getDetailRender()}
                </Col>
            </Row>
        )
    }
    componentDidMount(){
        //this.trT.init();
        this.props.onRef && this.props.onRef(this);
    }
}
Nestgrid.defaultProps = {
    onTitleRef: (ref) => {
    },
    onRowClick:(ref) => {},
}
Nestgrid.propTypes = {
    titlePojo:PropTypes.instanceOf(MyPojo).isRequired,
    detailPojos:PropTypes.arrayOf(NestGridDetail).isRequired,
    onRowClick:PropTypes.func,
}
export default Nestgrid;

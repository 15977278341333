import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item, StatesID} from '../../calzzs/initItems'
class Pojo extends MyPojo {
    constructor() {
        //盘点单管理
        super('wmcheck');
        this.controller = 'wm';
        this.cName = '盘点单管理';
        this.pojoItem = new initItem();
        this.orderRowName = 'fcheckdate';
        this.orderType = 'DESC';
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk:true,
            },
            // {
            //     comName: 'fnumber',
            //     name: '单号',
            //     tdShow: false,
            // },
            {
                comName: 'fcheckdate',
                name: '盘点日期',
                dataType: DataType.DataTime,
            },
            {
                comName: 'fstateid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '保存',
                    2: '审核',
                },
            },
            {
                comName: 'fgoodsnumber',
                name: '存货编号',
                showLength: 30,
                isInsert:false,
                isUpdate:false,
            },
            {
                comName: 'fgoodsnumber',
                name: '存货名称',
                width: 300,
                showLength: 30,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fminventory',
                    action: 'getList',
                    id: 'fnumber',
                    text: '{fcaption}',
                }),
                exComParent: 'fgoodscaption',
            },
            {
                comName: 'fbatchnumber',
                name: '批号',
            },
            {
                comName: 'fgoodsstateid',
                name: '存货状态',
                dataType: DataType.Com,
                state: StatesID.getPState(),
                defaultValue:1,
            },
            // {
            //     comName: 'fdescription',
            //     name: '备注说明',
            // },
            {
                comName: 'fstoragepk',
                name: '储位',
                dataType: DataType.ComGETServer,
                comF: {
                    controller: 'fmstorage',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                },
                exComParent: 'fstorage',
            },
            {
                comName: 'fsupk',
                name: '单位',
                width: 200,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fmpackingunit',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}:{T.fgoodscaption}',
                    // isInitialize: false,
                }),
                exComParent: 'fsucaption',
            },
            {
                comName: 'fsuqty',
                name: '系统数量',
                dataType: DataType.NUM,
            },
            {
                comName: 'frealqty',
                name: '盘点数量',
                dataType: DataType.NUM,
            },
            {
                comName: 'fmemo',
                name: '备注',
            },
            {
                comName: 'fverifytime',
                name: '审核时间',
                tdShow: false,
            },
            {
                comName: 'fverifyman',
                name: '审核人',
                tdShow: false,
            },
            {
                comName: 'forgpk',
                name: '组织编号',
                show: false,
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
        ]);
    }
}
export default Pojo;

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item} from '../../calzzs/initItems'
class Pojo extends MyPojo {
    constructor() {
        //工序监测过程
        super('pmprocesscheck');
        this.controller = 'pm';
        this.cName = '工序监测过程';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk:true,
            },
            {
                comName: 'fprocesspk',
                name: '工序',
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fmprocess',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                }),
                exComParent: 'fprocess',
            },
            {
                comName: 'ftaskpk',
                name: '生产批号',
            },
            {
                comName: 'feqpk',
                name: '设备',
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'emequipment',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                }),
                exComParent: 'equipment',
            },
            {
                comName: 'ftypeid',
                name: '类型',
                dataType: DataType.Com,
                state: {
                    1: '温度',
                    2: '湿度',
                    3: '气压',
                    4: '水分',
                    5: '气体',
                    6: '盐分',
                    7: '称重',
                    8: '其他',
                },
            },
            {
                comName: 'fdatavalue',
                name: '数值',
                dataType: DataType.NUM,
            },
            {
                comName: 'fcontent',
                name: '内容',
                tdShow: false,
            },
            {
                comName: 'fdescribe',
                name: '描述',
                tdShow: false,
            },
            {
                comName: 'fmarkid',
                name: '标志',
                dataType: DataType.Com,
                state: {
                    1: '正常',
                    2: '异常',
                },
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
        ]);
    }
}
export default Pojo;

const defaultState = {
    db:undefined,
}
export default (state = defaultState,action) => {
    if(action.type === 'db'){
        console.log(JSON.stringify(state));
        const newState = JSON.parse(JSON.stringify(state));//深度拷贝
        console.log(newState.db);
        newState.db = action.value;
        return newState;
    }
    return state;
}

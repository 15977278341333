import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";

class Pojo extends MyPojo {
    getproductdaily = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.controller + '/getproductdaily', params)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }

    constructor() {
        //生产日报表
        super('getproductdaily');
        this.isToExcel = true
        this.controller = 'rpbase';
        this.delRowName = 'daily';
        this.cName = '生产日报表';
        this.pojoItem = new initItem();
        this.orderRowName = ''
        this.showPK = true;
        this.prefixName = ''
        this.getListActionName = 'getproductdaily'
        this.addPojoItemInitItems([
            {
                comName: 'fgoodsnumber',
                name: '存货名称',
                width: 300,
                showLength: 30,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fminventory',
                    action: 'getList',
                    id: 'fnumber',
                    text: '{fcaption}',
                }),
                exComParent: 'fgoodscaption',
            },
            {
                comName: 'fgoodsnumber',
                name: '存货编号',
                showLength: 40,
                width:100,
                showSearch: false,
            },
            {
                comName: 'daily',
                name: '生产日期',
                showSearch: false,
                dataType: DataType.DataTime,
                showTime: false,
            },
            {
                comName: 'fregistertime',
                name: '日期',
                show: false,
                dataType: DataType.DataTime,
                showTime: false,
            },
            {
                comName: 'fcreateman',
                name: '产线',
            },
            {
                comName: 'fproductqty',
                name: '生产数量',
                width:100,
                showSearch: false,
            },
        ]);
    }
}

export default Pojo;

import React from "react";
import MForm from "../mform/mform";
import {Button, Modal} from "antd";
import PropTypes from 'prop-types';

class FormModal extends React.Component {
    constructor(props) {
        super(props);
        this.submitFunc = this.submitFunc.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.state = {
            row: {},
            visible: this.props.visible,
        }
    }

    submitFunc = () => {
        this.props.submitFunc();
    }
    closeFunc = () => {
        this.props.closeFunc();
        this.closeModal();
    }
    closeModal = () => {
        this.setState({
            visible: false,
        })
    }
    openModal = (row = {}) => {
        row = row === undefined ? {} : row;
        this.setState({
            visible: true,
            row: row,
        })
    }

    render() {
        const {form, title} = this.props;
        const {visible} = this.state;
        // console.log(visible);
        return (
            <Modal
                width={'35vw'}
                visible={visible}
                title={title}
                onOk={this.submitFunc}
                onCancel={this.closeFunc}
                destroyOnClose={true}
                footer={[
                    <Button key="back" onClick={this.closeModal}>
                        关闭
                    </Button>,
                    <Button key="submit" type="primary" onClick={this.submitFunc}>
                        提交
                    </Button>
                ]}
            >
                {form}
            </Modal>
        )
    }
}

FormModal.defaultProps = {
    title: '表单',
    submitFunc: (row) => {
    },
    closeFunc: () => {
    },
    visible: false,
}
FormModal.propTypes = {
    title: PropTypes.string,
    form: PropTypes.instanceOf(MForm).isRequired,
    submitFunc: PropTypes.func,
    closeFunc: PropTypes.func,
    visible: PropTypes.bool,
}
export default FormModal;

import React from "react";
import {Modal} from "antd";
import PropTypes from "prop-types";

class MyModel extends React.Component {
    constructor(props) {
        super(props);
        this.handleCancel = this.handleCancel.bind(this);
        this.state = {
            isModalVisible: false,
        }
    }

    handleCancel = () => {
        this.setState({
            isModalVisible: false,
        })
    }
    showModal = () => {
        this.setState({
            isModalVisible: true,
        })
    }
    componentDidMount = () => {
        this.props.onRef(this);
    }
    render() {
        let {isModalVisible} = this.state;
        let {handleOk, child,title} = this.props;
        return (
            <Modal
                title={title}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={this.handleCancel}
            >
                {child}
            </Modal>
        )
    }
}

MyModel.defaultProps = {
    handleOk: () => {
    },
    child: undefined,
    onRef: (ref) => {
    },
    title:'',
}
MyModel.propTypes = {
    handleOk: PropTypes.func,
    child: PropTypes.object,
    onRef: PropTypes.func,
    title:PropTypes.string,
}
export default MyModel;

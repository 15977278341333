import React from "react";
import PropTypes from "prop-types";
class NullDom extends React.Component {
    componentDidMount() {
        this.props.onRef(this);
    }

    render() {
        return <a></a>
    }
}
NullDom.defaultProps = {
    onRef: (ref) => {
    },
};
NullDom.propTypes = {
    onRef: PropTypes.func,
};
export default NullDom;

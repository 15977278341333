import {get, post, postFormObj, postObj} from "../../axios/Response";
// import {SystemInit} from "../../api/system_init";
import {TableType} from "../../api/mainPojo";

import {getActions, getTree, getValueByProp, initActions} from '../../utils/utils';
import {item} from "../../calzzs/initItems";

class MyPojo {
    isToExcel = false;
    //service key
    controller = '';
    columns = [];
    columnsMap = {};
    clientItems = [];
    tableName = '';
    exTableName = '';
    orderType = 'DESC';//关键字 ASC 表示按升序分组，关键字 DESC 表示按降序分组
    orderRowName = ['fpk', 'fnumber'];
    pkColumn = undefined;
    nameColumn = undefined;
    parentColumn = undefined;
    tableType = TableType.GD;
    prefixName = 'info';
    pojoItem = undefined;
    cName = '';
    infoColumn = 'fpk';
    delRowName = '';
    showPK = false;
    getListActionName = 'getdatatree'
    iniForFilter = (row) => {
    };
    // getParge
    addPojoItemInitItems = (objs) => {
        for (let key in objs) {
            this.pojoItem.initItems.push(Object.assign(new item(), objs[key]));
        }
    }
    filterAddColumnFunc = (column) => {
        return true;
    }
    filterEditColumnFunc = (column, row) => {
        return true;
    }

    constructor(tableName,
                {
                    filterAddColumnFunc = this.filterAddColumnFunc,
                    filterEditColumnFunc = this.filterEditColumnFunc,
                    exTableName = '',
                    iniForFilter = (row) => {
                    }
                } = {}
    ) {
        this.tableName = tableName;
        this.exTableName = exTableName === '' ? tableName : exTableName
        if (filterAddColumnFunc !== undefined) {
            this.filterAddColumnFunc = filterAddColumnFunc;
        }
        if (filterEditColumnFunc !== undefined) {
            this.filterEditColumnFunc = filterEditColumnFunc;
        }
        this.iniForFilter = iniForFilter;
    }

    columnsInit = () => {

    }
    //过滤新建columns
    filterAddColumns = () => {
        let columns = [];
        for (let i in this.columns) {
            let column = this.columns[i];
            if (column.isInsert && this.filterAddColumnFunc(column)) {
                columns.push(column);
            }
        }
        this.addColumns = columns;
    }
    //过滤新建columns
    filterEditColumns = (row) => {
        let columns = [];
        for (let i in this.columns) {
            let column = this.columns[i];
            if (column.isUpdate && this.filterEditColumnFunc(column, row)) {
                columns.push(column);
            }
        }
        this.editColumns = columns;
        return this.editColumns;
    }
    getPkValue = (row) => {
        return getValueByProp(row, this.getProp('fpk'));//this.pkColumn.prop
    }
    init = (params = {}) => {
        return new Promise((resolve, reject) => {
            if (this.columns.length > 0
                && this.pkColumn !== undefined
                && this.nameColumn !== undefined
                && this.parentColumn !== undefined) {
                resolve(this.columns);
            } else {
                if (this.pojoItem === undefined) {
                    postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/init', params)
                        .then((res) => {
                            this.columns = res.data.initItems;
                            this.clientItems = res.data.clientItems;
                            this.iniFor();
                            // console.log(this);
                            this.filterAddColumns();
                            resolve(res.data);
                        })
                        .catch((res) => {
                            reject(res);
                        });
                } else {
                    try {
                        this.columns = this.pojoItem.initItems;
                        this.clientItems = this.pojoItem.clientItems;
                        this.iniFor();
                        // console.log(this);
                        this.filterAddColumns();
                        resolve(this.pojoItem);
                    } catch (e) {
                        reject(e);
                    }
                }
            }
        });
    }
    iniFor = () => {
        for (let i in this.columns) {
            this.iniForFilter(this.columns[i]);
            this.columns[i]['propRow'] = this.columns[i].comName;
            this.columns[i].prop = (this.prefixName === '' ? '' : this.prefixName + '.') + this.columns[i].comName;
            if (this.columns[i].isPk) {
                this.pkColumn = this.columns[i];
            }
            if (this.columns[i].isName) {
                this.nameColumn = this.columns[i];
            }
            if (this.columns[i].isParent) {
                this.parentColumn = this.columns[i];
            }
            this.columnsMap[this.columns[i].comName] = this.columns[i];
        }
    }
    getProp = (columnName) => {
        return (this.prefixName === '' ? '' : this.prefixName + '.') + columnName;
    }
    getColumnValue = (item, columnName) => {
        let cP = (this.prefixName === '' ? '' : this.prefixName + '.') + columnName;
        return getValueByProp(item, cP);
    }
    getList = (params = {}, connName = '') => {
        // console.log(connName);
        return new Promise((resolve, reject) => {
            if (this.tableType === TableType.TG) {
                postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/' + (connName === '' ? 'getdatatree' : connName), {
                    // orderType: this.orderType,
                    // orderRowName: this.orderRowName,
                    ...params,
                })
                    .then((res) => {
                        // if (this.tableType === TableType.TG) {
                        //     res.data = getTree(res.rows, '', 'iD', 'pID', 'children')
                        // } else {
                        //     res.data = res.data;
                        // }
                        // res.data = res.data;
                        // console.log(res);
                        resolve(res);
                    })
                    .catch((res) => {
                        reject(res);
                    });
            } else {
                console.log(this.orderRowName);
                if (this.orderRowName instanceof Array) {
                    for (let i = 0; i !== this.orderRowName.length; i++) {
                        if (this.columnsMap[this.orderRowName[i]] !== undefined) {
                            params['orderby'] = this.orderRowName[i] + ' ' + this.orderType;
                            break
                        }
                    }
                }else {
                    if (this.columnsMap[this.orderRowName] !== undefined) {
                        params['orderby'] = this.orderRowName + ' ' + this.orderType;
                    }
                }
                postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/' + (connName === '' ? 'getdatagrid' : connName), {
                    // orderType: this.orderType,
                    // orderRowName: this.orderRowName,
                    ...params,
                })
                    .then((res) => {
                        // if (this.tableType === TableType.TG) {
                        //     res.data = getTree(res.rows, '', 'iD', 'pID', 'children')
                        // } else {
                        //     res.data = res.data;
                        // }
                        // res.data = res.data;
                        // console.log(res);
                        resolve(res);
                    })
                    .catch((res) => {
                        reject(res);
                    });
            }

        });
    }
    getTree = (params = {}) => {
        return new Promise((resolve, reject) => {
            if (this.tableType === TableType.GD) {
                reject(undefined);
            } else {
                this.getList(params)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((res) => {
                        reject(res);
                    })
            }
        })
    }
    getInfo = (params = {}) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/getinfo', params)
                .then((res) => {
                    resolve(res);
                })
                .catch((res) => {
                    reject(res);
                });
        });
    }
    delete = (params = {}) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/delete', params)
                .then((res) => {
                    resolve(res);
                })
                .catch((res) => {
                    reject(res);
                });
        });
    }
    update = (obj = undefined, formData = {}) => {
        if (obj === undefined) {
            return undefined;
        }
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/update', obj, formData)
                .then((res) => {
                    resolve(res);
                })
                .catch((res) => {
                    reject(res);
                });
        });
    }
    create = (obj = undefined) => {
        if (obj === undefined) {
            return undefined;
        }
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/register', obj)
                .then((res) => {
                    resolve(res);
                })
                .catch((res) => {
                    reject(res);
                });
        });
    }
}

export default MyPojo;

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {initItem, item} from '../../calzzs/initItems'
class Pojo extends MyPojo {
    constructor() {
        //系统设置
        super('sysconfigure');
        this.controller = 'sys';
        this.cName = '系统设置';
        this.prefixName = '';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk:true,
            },
            {
                comName: 'fcaption',
                name: '系统名称',
            },
            {
                comName: 'furl',
                name: '追溯网址',
            },
            {
                comName: 'falarmqty',
                name: '预警次数',
                dataType: DataType.NUM,
            },
            {
                comName: 'ffakeqty',
                name: '假冒次数',
                dataType: DataType.NUM,
            },
            {
                comName: 'fcoderule',
                name: '编码规则',//（G/M(1)+日期(8)+产地代码(3)）
            },
            {
                comName: 'fbatchserialnum',
                name: '批次序号位数',
                dataType: DataType.NUM,
            },
            {
                comName: 'fpackageserialnum',
                name: '垛码序号位数',
                dataType: DataType.NUM,
            },
            {
                comName: 'ftierserialnum',
                name: '层码序号位数',
                dataType: DataType.NUM,
            },
            {
                comName: 'fpackserialnum',
                name: '包装码随机位数',
                dataType: DataType.NUM,
            },
            {
                comName: 'funitserialnum',
                name: '单位码随机位数',
                dataType: DataType.NUM,
            },
            {
                comName: 'fitemserialnum',
                name: '件码随机位数',
                dataType: DataType.NUM,
            },
            {
                comName: 'forderserialnum',
                name: '单号序号位数',
                dataType: DataType.NUM,
            },
            {
                comName: 'fsynctime',
                name: '数据同步时间',
                dataType: DataType.DataTime,
            },
            {
                comName: 'fcheckoutdate',
                name: '结转日',
                dataType: DataType.DataTime,
            },
            {
                comName: 'fcheckouttime',
                name: '结账时间',
                dataType: DataType.DataTime,
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
        ]);
    }
}
export default Pojo;

import React, {Component} from 'react';
import {MyContext} from "../MyClass/TestContext";
import {getCachingKeys} from "react-router-cache-route";

export default (DifferenceComponent,data = {}) => {
    class PageCommon extends Component {
        static contextType = MyContext;
        constructor(props) {
            super(props);
            if (props.cacheLifecycles) {
                //被缓存
                props.cacheLifecycles.didCache(this.componentDidCache)
                //被恢复
                props.cacheLifecycles.didRecover(this.componentDidRecover)
            }
            this.setProp = this.setProp.bind(this);
            this.ref = React.createRef();
            this.state = {
                ref: undefined,
                node: <DifferenceComponent ref={this.setProp} data={this.props}/>,
            }
        }

        componentDidCache = () => {
            // console.log('List cached--------------------------------------------------------');
            // if (this.state.ref !== null) {
            //     this.context.setPage({
            //         page: this.state.ref.state.pojo.tableName,
            //         name: this.state.ref.state.pojo.cName,
            //     });
            // }
        }

        componentDidRecover = () => {
            // console.log('List recovered--------------------------------------------------------');
            // console.log(getCachingKeys());
            if (this.state.ref !== null) {
                this.context.setPage({
                    // page: this.state.ref.state.pojo.tableName,
                    page: this.state.ref.state.pojo.exTableName,
                    name: this.state.ref.state.pojo.cName,
                });
            }
        }
        setProp = (ref) => {
            // console.log(ref);
            if (ref !== null) {
                this.setState({
                    ref: ref,
                }, () => {
                    if (this.state.ref !== null) {
                        this.context.setPage({
                            page: this.state.ref.state.pojo.exTableName,
                            name: this.state.ref.state.pojo.cName,
                        });
                    }
                })
            }
        }

        componentDidMount() {
            // console.log(this.props);
        }

        componentWillUnmount() {

        }

        render() {
            // console.log(data);
            // console.log(DifferenceComponent);
            return (
                <DifferenceComponent ref={this.setProp} data={
                    {
                        ...data,
                        ...this.props
                    }
                }/>
                // <>
                //     {this.state.node}
                // </>
            )
        }
    }

    return PageCommon;
}

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item, StatesID} from '../../calzzs/initItems'

class Pojo extends MyPojo {
    constructor() {
        //出入库记录
        super('wmstockrecord');
        this.controller = 'wm';
        this.cName = '出入库记录';
        this.orderRowName = 'fstockdate';
        this.orderType = 'DESC';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk: true,
            },
            {
                comName: 'fentrypk',
                width:250,
                name: '单据',//货单条目编号(包含盘盈盘亏、调整单)
            },
            {
                comName: 'fstockdate',
                name: '发生时间',//出入库日期
                dataType: DataType.DataTime,
            },
            {
                comName: 'ftypeid',
                name: '任务类型',
                dataType: DataType.Com,
                state: {
                    1: '入库',
                    2: '出库',
                    3: '盘点',
                    4: '调整',
                },
            },
            {
                comName: 'fstateid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '保存',
                    2: '结存',
                },
            },
            {
                comName: 'fgoodsnumber',
                name: '存货编号',
                showLength: 30,
            },
            {
                comName: 'fgoodsnumber',
                name: '存货名称',
                width: 300,
                showLength: 30,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fminventory',
                    action: 'getList',
                    id: 'fnumber',
                    text: '{fcaption}',
                }),
                exComParent: 'fgoodscaption',
            },
            {
                comName: 'fbatchnumber',
                name: '批号',
            },
            {
                comName: 'fsuqty',
                name: '单位数量',
                dataType: DataType.NUM,
            },
            {
                comName: 'frealqty',
                name: '操作数量',
                dataType: DataType.NUM,
            },
            {
                //结构修改了 没有了
                comName: 'fclassespk',
                name: '班组',
                isInsert: false,
                isUpdate: false,
                show: false,
            },
            {
                comName: 'fgoodsstateid',
                name: '存货状态',
                dataType: DataType.Com,
                state: StatesID.getPState(),
            },
            // {
            //     comName: 'fdescription',
            //     name: '存货状态说明',
            // },
            {
                comName: 'fstoragepk',
                name: '储位',
                dataType: DataType.ComGETServer,
                comF: {
                    controller: 'fmstorage',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                },
                exComParent: 'fstorage',
            },
            // {
            //     comName: 'fsupk',
            //     name: '单位',
            //     width: 200,
            //     dataType: DataType.ComGETServer,
            //     comF: new ComF({
            //         controller: 'fmpackingunit',
            //         action: 'getList',
            //         id: 'fpk',
            //         text: '{fcaption}',
            //         isInitialize: false,
            //     }),
            //     exComParent: 'fsucaption',
            // },
            // {
            //     comName: 'frealqty',
            //     name: '实际数量',
            //     dataType: DataType.NUM,
            // },
            {
                comName: 'fmemo',
                name: '备注',
            },
            {
                comName: 'fcarryovertime',
                name: '结存时间',
                dataType: DataType.DataTime,
                tdShow: false,
            },
            {
                comName: 'forgpk',
                name: '组织编号',
                show: false,
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
        ]);
    }
}

export default Pojo;

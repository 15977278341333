import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";
class Pojo extends MyPojo {
    //同步数据
    synchronization = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/synchronization', params)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }
    constructor() {
        //月台信息
        super('fmplatform');
        this.controller = 'fm';
        this.cName = '月台信息';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk:true,
            },
            {
                comName: 'fcaption',
                name: '名称',
            },
            {
                comName: 'ftypeid',
                name: '类型',
                dataType: DataType.Com,
                state: {
                    1: '月台',
                    2: '库门',
                    3: '车位',
                },
            },
            {
                comName: 'ffunctionid',
                name: '功能',
                dataType: DataType.Com,
                state: {
                    1: '装货',
                    2: '卸货',
                    3: '验货',
                    4: '移库',
                    5: '临时',
                },
            },
            {
                comName: 'fmaterialtypeid',
                name: '物料类型',
                dataType: DataType.Com,
                state: {
                    1: '产成品',
                    2: '半成品',
                    3: '原料',
                    4: '辅料',
                    5: '包材',
                    6: '宣传用品',
                    7: '配件',
                    8: '其他',
                },
            },
            {
                comName: 'fdescribe',
                name: '描述',
                tdShow: false,
            },
            {
                comName: 'flabelvalues',
                name: '标签卡值',
                tdShow: false,
            },
            {
                comName: 'fstateid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '空闲',
                    2: '占用',
                    3: '停用',
                },
            },
            {
                comName: 'fstoragepk',
                name: '仓储',
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fmstorage',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                }),
                exComParent:'fstorage',
            },
            {
                comName: 'forgpk',
                name: '组织编号',
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'sysorginfo',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}-{forgaddress}',
                }),
                isInsert:false,
                isUpdate:false,
                show:false,
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
        ]);
    }
}
export default Pojo;

import React from 'react';
import zhCN from 'antd/lib/locale/zh_CN';
import {ConfigProvider} from "antd";
import {MyContext} from "./my_module/MyClass/TestContext";
import moment from 'moment';
import 'moment/locale/zh-cn';
class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        moment.locale('zh-cn');
    }
    render() {
        return (
            <ConfigProvider locale={zhCN}>
                <div>
                    {this.props.children}
                </div>
            </ConfigProvider>
        );
    }
}

export default App;

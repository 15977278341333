export const getActions = (pojo, bus, row = undefined) => {
    let retActions = [];
    let constructor = JSON.parse(localStorage.getItem('allControllerAction'))[pojo.exTableName];//global.constants.allControllerAction[pojo.tableName];
    // console.log(constructor);
    if (constructor !== undefined) {
        for (let i in bus) {
            let action = constructor[bus[i].name];
            if (bus[i].confirm || (action !== undefined && (row === undefined || bus[i].testFunc(row)))) {
                retActions.push(bus[i]);
            }
        }
    }else{
        return bus;
    }
    return retActions;
}

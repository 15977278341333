import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {initItem, item} from '../../calzzs/initItems'
class Pojo extends MyPojo {
    constructor() {
        //标签卡信息
        super('fmlabelcard');
        this.controller = 'fm';
        this.delRowName = 'fnumber';
        this.cName = '标签卡信息';
        this.pojoItem = new initItem();
        this.showPK = true;
        this.addPojoItemInitItems([
            // {
            //     comName: 'fpk',
            //     name: '唯一编号',
            //     isPk:true,
            // },
            {
                comName: 'fnumber',
                name: '卡编号',
                isPk:true,
                show:false,
            },
            {
                comName: 'fnumber',
                name: '卡编号',
            },
            {
                comName: 'ftypeid',
                name: '类型',
                dataType: DataType.Com,
                state: {
                    1: '托盘卡',
                    2: '仓位卡',
                    3: '工序卡',
                    4: '月台卡',
                    5: '设备卡',
                    6: '其他',
                },
            },
            {
                comName: 'fmarkid',
                name: '标识',
                dataType: DataType.Com,
                state: {
                    1: '自购',
                    2: '租用',
                    3: '流转',
                },
            },
            {
                comName: 'fpositionid',
                name: '存放位置',
                dataType: DataType.Com,
                state: {
                    1: '在库',
                    2: '离库',
                },
            },
            {
                comName: 'fstateid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '空卡',
                    2: '绑定',
                    3: '退还',
                    4: '报废',
                },
            },
            {
                comName: 'fremarks',
                name: '备注',
                tdShow: false,
            },
            {
                comName: 'forgpk',
                name: '组织编号',
                isInsert:false,
                isUpdate:false,
                show:false,
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
        ]);
    }
}
export default Pojo;

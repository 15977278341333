import React from 'react';
import {Button, Col, Form, Input, message, Modal, Row, Tag, Tooltip} from 'antd';
import {formateDate} from "../../utils/utils";
import {get} from "../../axios/Response";
import './Header.less'
import {Link} from "react-router-dom";
import {ExclamationCircleOutlined, UnorderedListOutlined} from "@ant-design/icons";
import Pojos from "../../ylwmspages/pojos";

const {confirm} = Modal;

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.setPName = this.setPName.bind(this);
        this.oldPsw = '';
        this.newPsw = '';
        this.state = {
            userName: "",
            pName: '首页',
            staffPojo: Pojos.getPojo('sysstaff'),
        };
    }

    setPName(name) {
        this.setState({
            pName: name,
        })
    }

    // 获取时间
    componentDidMount() {
        setInterval(() => {
            let sysTime = formateDate(new Date().getTime());
            this.setState({
                sysTime
            })
        }, 1000)
        this.getWeatherApiData();
        this.props.onRef(this);
    }

    // 获取天气
    getWeatherApiData() {
        // let city = "天津";
        // get("http://api.map.baidu.com/telematics/v3/weather?location=" + encodeURIComponent(city) + "&output=json&ak=3p49MVra6urFRGOT9s8UBWr2", {})
        //     .then((res) => {
        //         console.log(res);
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     })
    }

    render() {
        return (
            <div className="header">
                <Row className="breadcrumb">
                    <Col span="4" className="breadcrumb-title">
                        <Tooltip>
                            <Button onClick={this.props.setCollapse} type="primary" icon={<UnorderedListOutlined/>}/>
                        </Tooltip>
                        <Tag color="#2db7f5">{this.state.pName}</Tag>
                    </Col>
                    <Col span="20" className="breadcrumb-detail">
                        <span className="date">{this.state.sysTime}</span>
                        <span className="weather-img">
                            {/*<img src={this.state.dayPictureUrl} alt=""/>*/}
                            欢迎您：{localStorage.getItem('name').replaceAll('"', '')}
                        </span>
                        <span className="weather-detail">{this.state.weather}</span>
                        {/*<span>欢迎，{this.state.userName}</span>*/}
                        <Button type="dashed" danger onClick={() => {
                            confirm({
                                title: '修改密码',
                                icon: <ExclamationCircleOutlined/>,
                                content: (
                                    <Form
                                        name="basic"
                                        labelCol={{span: 8}}
                                        wrapperCol={{span: 16}}
                                        initialValues={{remember: true}}
                                        autoComplete="off"
                                    >
                                        <Form.Item
                                            label="旧密码"
                                            name="username"
                                        >
                                            <Input
                                                onChange={(value) => {
                                                    this.oldPsw = value.target.value;
                                                }}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label="新密码"
                                            name="password"
                                        >
                                            <Input
                                                onChange={(value) => {
                                                    this.newPsw = value.target.value;
                                                }}
                                            />
                                        </Form.Item>
                                    </Form>
                                ),
                                onOk: () => {
                                    return new Promise((resolve, reject) => {
                                        if (this.oldPsw === '') {
                                            message.error('请填写旧密码');
                                            reject(null);
                                            return;
                                        }
                                        if (this.newPsw === '') {
                                            message.error('请填写新密码');
                                            reject(null);
                                            return;
                                        }
                                        this.state.staffPojo.updatepws({
                                            fpwsold: this.oldPsw,
                                            fpwsnew: this.newPsw,
                                        })
                                            .then((ref) => {
                                                message.success(ref.msg);
                                                resolve(null);
                                            })
                                            .catch((ref) => {
                                                // message.error(ref.msg);
                                                reject(null);
                                            })
                                    });
                                },
                                onCancel: () => {
                                    console.log('Cancel');
                                },
                            });
                        }}>修改密码</Button>
                        <Link to={'/' + SystemInit.homeRoot + '/'}>
                            {/*<a href="#">退出</a>*/}
                            <Button type="primary">退出</Button>
                        </Link>
                    </Col>
                </Row>
            </div>
        );
    }
}

Header.defaultProps = {
    onRef: (ref) => {
    },
    setCollapse: () => {
    }
}
export default Header;

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";

class Pojo extends MyPojo {
    deliverok = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/deliverok', params)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }

    constructor() {
        //中奖信息
        super('mmlotteryinfo');
        this.controller = 'mm';
        this.cName = '中奖信息';
        this.infoColumn = ''
        this.orderRowName = 'flotterycode'
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            // {
            //     comName: 'fpk',
            //     name: '唯一编号',
            //     isPk: true,
            //     show: false,
            // },
            {
                comName: 'flotterycode',
                name: '中奖码',
                tdShow: false,
            },
            {
                comName: 'fopenid',
                name: '中奖人',
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'mmconsumer',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fname}',
                }),
                exComParent: 'consumer',
            },
            {
                comName: 'faddress',
                name: '扫码地点',
            },
            {
                isEx: true,
                comName: 'ftelephone',
                name: '中奖人电话',
                dataType: DataType.String,
                exComParent: 'ftelephone',
                tdShow: false,
            },
            {
                isEx: true,
                comName: 'faddress',
                name: '中奖人地址',
                dataType: DataType.String,
                exComParent: 'faddress',
                tdShow: false,
            },
            {
                comName: 'fprizepk',
                name: '奖品',
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'mmprize',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                }),
                exComParent: 'fprize',
            },
            {
                comName: 'fruleentrypk',
                name: '规则条目编号',
                tdShow: false,
            },
            {
                comName: 'fstateid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '已派送',
                    2: '未派送',
                },
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
        ]);
    }
}

export default Pojo;

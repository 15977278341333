import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item, StatesID} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";

class Pojo extends MyPojo {
    getstateinventory(params) {
        // return this.getList(params,'getpackdatagrid');
        if (this.orderRowName instanceof Array) {
            for (let i = 0; i !== this.orderRowName.length; i++) {
                if (this.columnsMap[this.orderRowName[i]] !== undefined) {
                    params['orderby'] = this.orderRowName[i] + ' ' + this.orderType;
                    break
                }
            }
        } else {
            if (this.columnsMap[this.orderRowName] !== undefined) {
                params['orderby'] = this.orderRowName + ' ' + this.orderType;
            }
        }
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + 'wmcheckout/getstateinventory', params)
                .then((res) => {
                    resolve(res);
                })
                .catch((res) => {
                    reject(res);
                });
        });
    }

    getrealinventory(params) {
        if (this.orderRowName instanceof Array) {
            for (let i = 0; i !== this.orderRowName.length; i++) {
                if (this.columnsMap[this.orderRowName[i]] !== undefined) {
                    params['orderby'] = this.orderRowName[i] + ' ' + this.orderType;
                    break
                }
            }
        } else {
            if (this.columnsMap[this.orderRowName] !== undefined) {
                params['orderby'] = this.orderRowName + ' ' + this.orderType;
            }
        }
        // return this.getList(params,'getpackdatagrid');
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + 'wmcheckout/getrealinventory', params)
                .then((res) => {
                    resolve(res);
                })
                .catch((res) => {
                    reject(res);
                });
        });
    }
    checkout(params) {
        // return this.getList(params,'getpackdatagrid');
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + 'wmcheckout/checkout', params)
                .then((res) => {
                    resolve(res);
                })
                .catch((res) => {
                    reject(res);
                });
        });
    }
    constructor({
                    iniForFilter = (row) => {
                    }
                } = {}) {
        //结存表管理
        super('wmcheckout', {
            iniForFilter: iniForFilter
        });
        this.controller = 'wm';
        this.cName = '结存表管理';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk: true,
            },
            {
                comName: 'fcheckoutdate',
                name: '结存日期',
                dataType: DataType.DataTime,
                tdShow: false,
            },
            {
                comName: 'fstateid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '期初',
                    2: '往期',
                },
                tdShow: false,
            },
            {
                comName: 'fgoodsnumber',
                name: '存货编号',
                showLength: 30,
            },
            {
                comName: 'fgoodsnumber',
                name: '存货名称',
                width: 300,
                showLength: 30,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fminventory',
                    action: 'getList',
                    id: 'fnumber',
                    text: '{fcaption}',
                }),
                exComParent: 'fgoodscaption',
            },
            {
                comName: 'fbatchnumber',
                name: '批号',
            },
            {
                width: 300,
                showLength: 30,
                comName: 'fstoragepk',
                name: '储位',
                dataType: DataType.ComGETServer,
                comF: {
                    controller: 'fmstorage',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                },
                exComParent: 'fstorage',
            },
            {
                comName: 'fsupk',
                name: '单位',
                width: 200,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fmpackingunit',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fgoodsnumber}({fcaption})代号:{fpucode}',
                    isInitialize: false,
                }),
                exComParent: 'fsucaption',
                tdShow: false,
            },
            {
                comName: 'fsuqty',
                name: '单位总数量',//（待检数+封货数+可拣数）
                dataType: DataType.NUM,
            },
            {
                comName: 'finspectedqty',
                name: '待检数',
                dataType: DataType.NUM,
            },
            {
                comName: 'finspecteddate',
                name: '待检日期',
                dataType: DataType.DataTime,
                tdShow: false,
            },
            {
                comName: 'fsealedqty',
                name: '封货数',
                dataType: DataType.NUM,
            },
            {
                comName: 'fsealeddate',
                name: '封货日期',
                dataType: DataType.DataTime,
                tdShow: false,
            },
            {
                comName: 'foldstateid',
                name: '封货前状态',
                dataType: DataType.Com,
                state: StatesID.getPState([9]),
                tdShow: false,
            },
            {
                comName: 'fpickingqty',
                name: '可拣数',//（散货+正常+异常码+专执+试验+二次封箱+特价+旧日期）
            },
            {
                comName: 'fbulkqty',
                name: '散货数',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'fnormalqty',
                name: '正常数',
                dataType: DataType.NUM,
            },
            {
                comName: 'fycmqty',
                name: '异常码',
                dataType: DataType.NUM,
            },
            {
                comName: 'fdesignateqty',
                name: '专执',
                dataType: DataType.NUM,
            },
            {
                comName: 'ftestqty',
                name: '试验',
                dataType: DataType.NUM,
            },
            {
                comName: 'fresealqty',
                name: '二次封箱',
                dataType: DataType.NUM,
            },
            {
                comName: 'fspecialqty',
                name: '特价',
                dataType: DataType.NUM,
            },
            {
                comName: 'folddateqty',
                name: '旧日期',
                dataType: DataType.NUM,
            },
            // {
            //     comName: 'fdescription',
            //     name: '备注说明',
            // },
            {
                comName: 'forgpk',
                name: '组织编号',
                show: false,
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
        ]);
    }
}

export default Pojo;

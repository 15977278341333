import React, {useState} from 'react';
import './index.less';
import {Row, Button, Table, Tag, Radio, Modal, Tabs} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import {get} from "../../axios/Response";
import MyTable from "../../my_module/table/table";
// import Pojo from "./pojo"
import MyDataGrid from "../../my_module/datagrid/datagrid";
import MyButton from "../../my_module/MyClass/button";
import Nestgrid from "../../my_module/nestgrid/nestgrid";
import Pojos from "../pojos"
import {getQueryWheres, WhereType} from "../../api/mainPojo";
import {getLocalAddDayTime00} from "../../utils/utils";

import ReactEcharts from 'echarts-for-react';

const {TabPane} = Tabs;

//收发货量趋势图
class index extends React.Component {
    constructor(props) {
        super(props);
        this.onDGRef = this.onDGRef.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        // this.addOpenIntercept = this.addOpenIntercept.bind(this);
        // this.editOpenIntercept = this.editOpenIntercept.bind(this);
        this.state = {
            // addModalVisible: false,
            pojo: Pojos.getPojo('getstocksum'),//new Pojo(),
            dg: undefined,
            option: {},
        }
        console.log(this.pojo);
    }

    exTrBus = [
        // new MyButton(
        //     '审批',
        //     'getItemByID11',
        //     undefined,
        //     (row) => {
        //         console.log(row);
        //         return true;
        //     }
        // )
    ]
    onDGRef = (ref) => {
        this.setState({
            dg: ref,
        }, () => {
            //this.state.dg.refInit();
        })
    }
    componentDidUpdate = (prevProps, prevState) => {
    }
    getOtion = (rows) => {
        console.log(rows)
        //finvoiceqty 发
        //freceiptqty 收
        //daily 日期
        let source = []
        let titles = []
        // source.push(titles)
        let finvoiceqtyDatas = []
        let freceiptqtyDatas = []
        for (let i = 0; i !== rows.length; i++) {
            titles.push(rows[i]['daily'])
            finvoiceqtyDatas.push(rows[i]['finvoiceqty'])
            freceiptqtyDatas.push(rows[i]['freceiptqty'])
        }
        let option = {
            title: {
                text: '收发货量趋势图'
            },
            tooltip: {
                trigger: 'axis',
                // formatter: function (params) {//核心部分 formatter 可以为字符串也可以是回调
                //     console.log(params)
                //     return params[0].name + '<br/>' + params[0].seriesName + ':' + params[0].data + '(盒或罐)' + '<br/>' +
                //         '' + params[1].seriesName + ':' + params[1].data + '(盒或罐)'
                // }
            },
            legend: {
                data: ['发货量', '收货量']
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: titles
            },
            yAxis: {
                type: 'value',
                name: "单位（盒）",
            },
            series: [
                {
                    name: '发货量',
                    type: 'line',
                    stack: 'Total',
                    data: finvoiceqtyDatas,
                    label: {
                        normal: {
                            // show: true,
                            position: 'top',
                            textStyle: {
                                color: '#0d27a5'
                            },
                            // formatter: function (params) {//核心部分 formatter 可以为字符串也可以是回调
                            //     return params.value + '盒或罐'
                            // }
                        },
                    },
                    itemStyle: {
                        normal: {
                            color: '#0d27a5', //改变折线点的颜色
                            lineStyle: {
                                color: '#0d27a5' //改变折线颜色
                            }
                        }
                    },
                },
                {
                    name: '收货量',
                    type: 'line',
                    stack: 'Total',
                    data: freceiptqtyDatas,
                    label: {
                        normal: {
                            // show: true,
                            position: 'top',
                            textStyle: {
                                color: '#268947'
                            },
                            // formatter: function (params) {//核心部分 formatter 可以为字符串也可以是回调
                            //     return params.value + '盒或罐'
                            // }
                        },
                    },
                    itemStyle: {
                        normal: {
                            color: '#268947', //改变折线点的颜色
                            lineStyle: {
                                color: '#268947' //改变折线颜色
                            }
                        }
                    },
                },
            ]
        };
        // return option;
        this.setState({
            option: option,
        })
    }
    // addModal = () => {
    //     let model =
    //         <Modal title="Basic Modal" visible={this.state.addModalVisible}
    //                onCancel={() => {
    //                    this.setState({
    //                        addModalVisible:false,
    //                    })
    //                }}>
    //             <p>Some contents...</p>
    //             <p>Some contents...</p>
    //             <p>Some contents...</p>
    //         </Modal>;
    //     return model;
    // }
    // addOpenIntercept = (ref) => {
    //     console.log(ref);
    //     // this.setState({
    //     //     addModalVisible:true,
    //     // })
    //     return false;
    // }
    // editOpenIntercept = (ref) => {
    //     console.log(ref);
    //     return false;
    // }
    render() {
        return (
            <div>
                {/*{this.addModal()}*/}
                <MyDataGrid
                    // addOpenIntercept={this.addOpenIntercept}
                    // editOpenIntercept={this.editOpenIntercept}
                    isOrderBy={false}
                    getListName={'getstocksum'}
                    onRef={this.onDGRef}
                    pojo={this.state.pojo}
                    exTrBus={this.exTrBus}
                    isAdd={false}
                    isEdit={false}
                    isDel={false}
                    isPage={false}
                    isDetail={false}
                    isSetTrBus={false}
                    showReload={false}
                    testGetListParams={(params) => {
                        // console.log(params)
                        let querywhere = []
                        let iffregistertime = false
                        if (params['querywhere'] !== undefined) {
                            querywhere = JSON.parse(params['querywhere'])
                            // console.log(querywhere)
                            for (let k in querywhere) {
                                if (querywhere[k]['comname'] === 'ftacticstime') {
                                    iffregistertime = true
                                }
                            }
                        }
                        // console.log(iffregistertime)

                        if (!iffregistertime) {
                            querywhere.push(
                                ...getQueryWheres([
                                    {
                                        value: [getLocalAddDayTime00(new Date()), getLocalAddDayTime00(new Date(), 1)],
                                        comname: 'ftacticstime',
                                        comtype: WhereType.Between(),
                                    }
                                ])
                            )
                        }
                        params['querywhere'] = querywhere
                        return params
                    }}
                    initializeList={true}
                    onListHook={(rows) => {
                        console.log(rows)
                        this.getOtion(rows)
                    }}
                    myDom={(
                        <ReactEcharts
                            option={this.state.option}
                            style={{height: '80vh', width: '90%'}}
                            className='react_for_echarts'/>
                    )}
                    // exHeadBus={[
                    //     new MyButton(
                    //         '同步',
                    //         'maintain',
                    //         () => {
                    //             console.log('synchronization');
                    //             this.state.pojo.synchronization()
                    //                 .then((ref) => {
                    //                     this.state.dg.getList();
                    //                 });
                    //         },
                    //         (row) => {
                    //             console.log(row);
                    //             return true;
                    //         }
                    //     ),
                    // ]}
                />
            </div>
        );
    }
}

export default index;

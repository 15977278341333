import React from "react";
import PropTypes from "prop-types";
import SelectTablePojo from "../Common/SelectTablePojo";
import TitleDetailTablePojo from "../Common/TitleDetailTablePojo";
import {Button, Col, Divider, Form, Layout, message, Radio, Row, Select} from "antd";
import Pojos from "../../ylwmspages/pojos";
import {
    formateDate,
    setRowValue,
    deepmerge,
    getValueByProp,
    setValueByProp,
    setRow,
    eObjToStr, iniFormRow, objArrToStr
} from "../../utils/utils";
import FormUtil from "../Common/FormUtil";
import MyTable from "../table/table";

import {createFromIconfontCN} from "@ant-design/icons";

const IconFont = createFromIconfontCN({
    scriptUrl: process.env.PUBLIC_URL + '/font_2940893_4ujmnqudkik.js',
});

const {Header, Footer, Sider, Content} = Layout;

const {Option} = Select;

class Title_detail_table extends React.Component {
    constructor(props) {
        super(props);
        this.onDetailRef = this.onDetailRef.bind(this);
        this.titleFormRef = undefined;
        this.state = {
            titlePojo: Pojos.getPojo(this.props.tdtPojo.titleController),
            detailPojo: Pojos.getPojo(this.props.tdtPojo.detailController),
            row: this.props.tdtPojo.titleRow,
            titleFormItems: [],
            detailTable: undefined,
        }
    }

    componentDidMount() {
        // console.log(this.state.titlePojo);
        // console.log(this.state.detailPojo);
        let titleFormItems = [];
        let row = this.state.row;
        if (this.state.row === undefined) {
            row = {};
            setRow(this.state.titlePojo.columns, row);
        }
        // iniFormRow(this.state.titlePojo.columns,row);
        this.setState({
            row: row,
        }, () => {
            //新增
            let columns = this.state.titlePojo.columns;
            let formUtil = new FormUtil({
                row: this.state.row,
            })
            for (let i = 0; i !== columns.length; i++) {
                let column = columns[i];
                // console.log(this.props.tdtPojo.titleRowNames);
                if (!column.isPk && (this.props.tdtPojo.titleRowNames.length > 0 && this.props.tdtPojo.titleRowNames.includes(column.comName) && column.isInsert)
                    || (this.props.tdtPojo.titleRowNames.length === 0 && column.isInsert)) {
                    titleFormItems.push(formUtil.getFormItem({
                        column: column,
                    }));
                }
            }
            this.setState({
                titleFormItems: titleFormItems,
            })
        })
        this.props.onRef(this);
    }

    getEditingRow = (row, data) => {
        console.log(data);
        console.log(row);
    }

    onDetailRef(ref) {
        this.setState({
            detailTable: ref,
        }, () => {
            this.state.detailTable.init();
            if (this.state.row !== undefined) {
                let getDProp = {};
                let titlePkValue = this.state.titlePojo.getColumnValue(this.state.row, this.props.tdtPojo.getDetailPRowName);
                if (titlePkValue !== '') {
                    getDProp[this.props.tdtPojo.detailPRowName] = titlePkValue;
                    this.state.detailTable.getList(
                        {
                            ...this.props.tdtPojo.detailParams,
                            ...getDProp
                        }
                    );
                }
            }
        })
    }

    onValuesChange = (changedValues, allValues) => {
        eObjToStr(allValues);
        let row = deepmerge(this.state.row, allValues);
        this.setState({
            row: row,
        }, () => {
            console.log(this.state.row);
        })
    }

    render() {
        const {titleFormItems, detailPojo} = this.state;
        // console.log(titleFormItems);
        // console.log(this.state.row);
        return (
            <div style={{textAlign: 'left'}}>
                {/*<Divider style={{margin: '0 0 5px 0'}}/>*/}
                <Layout>
                    <div style={{padding: '0px 0 20px 0', textAlign: 'left', paddingRight: '30px',}}>
                        <Button type="primary" onClick={() => {
                            this.state.detailTable.setEditData();
                            let titleData = getValueByProp(this.state.row, this.state.titlePojo.prefixName);
                            objArrToStr(titleData);
                            let detailList = this.state.detailTable.getData();
                            console.log(detailList);
                            for(let k in detailList){
                                objArrToStr(detailList[k]);
                            }
                            this.props.iData(titleData, detailList);
                            titleData = {
                                ...titleData,
                                ...this.props.titleExData,
                            }
                            // console.log(titleData);
                            let rowPkValue = this.state.titlePojo.getPkValue(this.state.row);
                            console.log(rowPkValue);
                            console.log(this.state.row);
                            if (rowPkValue === '' || rowPkValue === undefined) {
                                this.state.titlePojo.create(titleData)
                                    .then((ref) => {
                                        console.log(ref);
                                        message.success(ref.msg);
                                        this.props.onClose();
                                    })
                                    .catch((ref) => {
                                        console.log(ref);
                                        message.error(ref.msg);
                                    });
                            } else {
                                this.state.titlePojo.update(titleData)
                                    .then((ref) => {
                                        console.log(ref);
                                        message.success(ref.msg);
                                        this.props.onClose();
                                    })
                                    .catch((ref) => {
                                        console.log(ref);
                                        message.error(ref.msg);
                                    });
                                ;
                            }
                        }}>提交</Button>
                        <Button type="primary" danger onClick={() => {
                            this.props.onClose();
                        }}>
                            返回
                        </Button>
                    </div>
                    <Header style={{padding: '0 0 0px 0', marginBottom: '5px',height:'auto'}}>
                        <Row style={{margin:'0 70px 10px 10px'}}>
                            <Col span={24}>
                                <Form
                                    size={'small'}
                                    name="basic"
                                    style={{
                                        width: '100%'
                                    }}
                                    labelCol={{span: 8}}
                                    wrapperCol={{span: 16}}
                                    initialValues={this.state.row}//remember: true
                                    autoComplete="off"
                                    ref={(ref) => {
                                        console.log(this.state.row);
                                        this.titleFormRef = ref;
                                    }}
                                    onValuesChange={this.onValuesChange}
                                >
                                    <Row>
                                        {titleFormItems.map(formItem => (
                                            <Col span={8}>
                                                {formItem}
                                            </Col>
                                        ))}
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Header>
                    <Content>
                        <Divider style={{margin: '0 0 5px 0'}}/>
                        <Button type="" shape="round"
                                icon={<IconFont type="appWeb-square-minus1" style={{fontSize: '20px'}}/>} size={'small'}
                                onClick={() => {
                                    // console.log(this.state.detailTable.selectKey);
                                    // console.log(this.state.detailTable.selectIndex);
                                    this.state.detailTable.delTr(this.state.detailTable.selectIndex);
                                }}/>
                        <Button type="" shape="round"
                                icon={<IconFont type="appWeb-square-plus1" style={{fontSize: '20px'}}/>} size={'small'}
                                onClick={() => {
                                    this.state.detailTable.editTrAdd();
                                }}/>
                        {/*导入:*/}
                        {/*<Select*/}
                        {/*    style={{width: 120,paddingLeft:'10px',}}*/}
                        {/*    onChange={(value, option) => {*/}
                        {/*        console.log(value);*/}
                        {/*        console.log(option);*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    {*/}
                        {/*        [*/}
                        {/*            {*/}
                        {/*                label: '生产单',*/}
                        {/*                value: 'aa',*/}
                        {/*                options: {*/}
                        {/*                    asd: 'asd'*/}
                        {/*                }*/}
                        {/*            },*/}
                        {/*            {*/}
                        {/*                label: '生产单1',*/}
                        {/*                value: 'aa1',*/}
                        {/*                options: {*/}
                        {/*                    asd: 'asd1'*/}
                        {/*                }*/}
                        {/*            },*/}
                        {/*        ].map((item, index) => (*/}
                        {/*            <Option value={item.value} opt={item}>{item.label}</Option>*/}
                        {/*        ))*/}
                        {/*    }*/}
                        {/*</Select>*/}
                        <MyTable
                            isMinHeight={false}
                            editShouldUpdate={this.props.editShouldUpdate}
                            editingTreeSelectRefFun={this.props.editingTreeSelectRefFun}
                            editingOnChange={(value, column, row, ...data) => {
                                let editFromItemMap = this.state.detailTable.editFromItemMap;
                                this.props.editingOnChange(value, column, row, editFromItemMap, data);
                            }}
                            onEditingRow={this.getEditingRow}
                            showRowNames={this.props.tdtPojo.detailRowNames}
                            exPk={this.props.tdtPojo.detailPk}
                            isPage={false}
                            getListName={this.props.tdtPojo.detailAction}
                            onRef={this.onDetailRef}
                            pojo={detailPojo}
                            isOpenEdit={true}
                        />
                    </Content>
                    <Footer style={{padding: '10px 0 0 0'}}>
                        <Divider/>
                    </Footer>
                </Layout>
            </div>
        )
    }
}

Title_detail_table.defaultProps = {
    iData: (titleData, detailList) => {
        titleData['list'] = detailList;
    },
    tdtPojo: undefined,
    onClose: () => {
    },
    //双击修改 时的 回调
    editingOnChange: (value, column, row, treeSelectMap, ...data) => {
    },
    editingTreeSelectRefFun: (comName, treeSelectMap) => {
    },
    onRef: (ref) => {
    },
    editShouldUpdate: (prev, cur) => {
    },
    titleExData: {},
}
Title_detail_table.propTypes = {
    tdtPojo: PropTypes.instanceOf(TitleDetailTablePojo).isRequired,
    iData: PropTypes.func,
    onClose: PropTypes.func,
    editingOnChange: PropTypes.func,
    editingTreeSelectRefFun: PropTypes.func,
    onRef: PropTypes.func,
    editShouldUpdate: PropTypes.func,
    titleExData: PropTypes.object,
}
export default Title_detail_table;

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item} from '../../calzzs/initItems'

class Pojo extends MyPojo {
    constructor() {
        //过账策略管理
        super('wmtactics');
        this.controller = 'wm';
        this.cName = '过账策略管理';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk: true,
            },
            {
                comName: 'fgoodsnumber',
                name: '存货编号',
                showLength: 30,
            },
            {
                comName: 'fgoodsnumber',
                name: '存货名称',
                width: 300,
                showLength: 30,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fminventory',
                    action: 'getList',
                    id: 'fnumber',
                    text: '{fcaption}',
                }),
                exComParent: 'fgoodscaption',
            },
            {
                comName: 'ftypeid',
                name: '类型',
                dataType: DataType.Com,
                state: {
                    1: '强制批次fifo',
                    2: '非强制批次fifo',
                    3: '无批次强制su码fifo',
                    4: '无批次非强制su码fifo',
                },
            },
            {
                comName: 'fequipmentpk',
                name: '设备',//(支持多设备,逗号分隔)
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'emequipment',
                    action: 'getList',
                    id: 'fnumber',
                    text: '{fcaption}',
                    multiple: true,
                }),
                exComParent: 'equipment',
            },
            {
                comName: 'fordertime',
                name: '下达时间',
                dataType: DataType.DataTime,
                tdShow: false,
            },
            {
                comName: 'forderman',
                name: '下达操作人',
                tdShow: false,
            },
            {
                comName: 'ffinishtime',
                name: '完结时间',
                dataType: DataType.DataTime,
                tdShow: false,
            },
            {
                comName: 'ffinishman',
                name: '完结操作人',
                tdShow: false,
            },
            {
                comName: 'forgpk',
                name: '组织编号',
                show: false,
            },
            {
                comName: 'fstateid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '保存',
                    2: '执行',
                    3: '结束',
                },
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
        ]);
    }
}

export default Pojo;

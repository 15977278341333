import React from 'react'
import {withRouter} from 'react-router-dom'
import './index.less'
import styles from './index.module.less';
import {Form, Input, Button, Checkbox, Layout, Modal, message, Tabs, Row, Col} from "antd"
// import {SystemInit} from '../../../public/system_init';
import {get, post, postFormObj, postObj} from "../../axios/Response";
import {getActions, getTree, initActions} from '../../utils/utils';
import '../../api/config';
import Pojo from "../../ylwmspages/sysstaff/pojo"
import {dropByCacheKey, getCachingKeys} from "react-router-cache-route";
// import {MyContext} from "../../my_module/MyClass/TestContext";
const {TabPane} = Tabs;
const {Header, Footer, Sider, Content} = Layout;
const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};
const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

class index extends React.Component {
    state = {
        visible: false,
        updata: {
            depNo: '',
            depName: '',
            username: '',
            userNo: '',
            password: '',
        },
        username: '',
        password: '',
    };

    componentDidMount() {
        // ipcRenderer.on('ping', (e) => { //接收响应
        //     console.log(e)
        // })
        // ipcRenderer.sendToHost('pong')//向webview所在页面的进程传达消息
        // window.sendToElectron("ping");
        // if (window.require) {
        //     const {ipcRenderer} = window.require('electron');
        //     ipcRenderer.sendToHost('print') // 向原生发送信息
        // }
        //c++接口
        // console.log(window.aa());
        // console.log(window.cmd());
    }

    showModal = () => {
        this.setState({
            visible: true
        });
    };
    handleCancel = () => {
        console.log(this.state.updata);
        postFormObj(SystemInit.serviceUrl + 'sysuser/initSys',
            this.state.updata)
            .then((data) => {
                console.log(data);
                this.setState({
                    visible: false
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    constructor(props) {
        super(props);
        console.log(new Pojo());
    }

    login = () => {
        postObj(SystemInit.serviceUrls.sso + 'sso/login', {
            floginno: this.state.username,
            fpassword: this.state.password,
            fclient: 1,
        })
            .then((data) => {
                message.info(data.msg);
                localStorage.setItem('token', data.data);
                localStorage.setItem('name', JSON.stringify(data.msg.split(':')[0]));
                localStorage.setItem('controllers', "");
                localStorage.setItem('siLogin', '1');
                localStorage.setItem('siLogin_', '1');
                post(SystemInit.serviceUrls.sys + 'sysrolegroupright/getmenusbyrolegroup', {})
                    .then((data) => {
                        // let controllers = getTree(data.rows, '', 'iD', 'pID');
                        // console.log(controllers);
                        // //global.constants.controllers = controllers;
                        localStorage.setItem('controllers', JSON.stringify(data.data));
                        initActions();
                        // getActions('rcontroller');
                        let path = '/main'
                        if(SystemInit.homeRoot !== ''){
                            path = '/' + SystemInit.homeRoot + '/main'
                        }
                        this.props.history.push('/' + SystemInit.homeRoot + '/main');
                    })
            })
            .catch((err) => {
                console.log(err);
            })
    };

    render() {
        const {visible} = this.state;
        return (
            <>
                <div className={styles.bg}>
                    <div>

                    </div>
                    <div className={styles.login_card}>
                        <div style={{height: '100px'}}>
                            {/*<div style={{float:'left'}}>*/}
                            {/*    <img width={'100px'} src={process.env.PUBLIC_URL + '/logo.png'} alt="logo"/>*/}
                            {/*</div>*/}
                            {/*WMS管理系统*/}
                            {/*南塑建材车辆预约管理系统*/}
                            <h1 className={styles.title}>WMS管理系统</h1>
                        </div>
                        <Tabs defaultActiveKey="1" centered style={{margin: '0 auto'}}>
                            <TabPane tab="账户密码登录" key="1">
                                <Form
                                    className="login-form"
                                    initialValues={{remember: true}}
                                    name="basic"
                                >
                                    <Form.Item
                                        label=""
                                        name="username"
                                        style={{borderBottom: '1px solid #DCDCDC'}}
                                        rules={[{required: true, message: '请填写用户名!'}]}
                                    >
                                        <Input
                                            placeholder="请输入用户名"
                                            bordered={false}
                                            onChange={
                                                (e) => {
                                                    this.setState(
                                                        {
                                                            username: e.target.value
                                                        }
                                                    )
                                                }
                                            } value={this.state.username}/>
                                    </Form.Item>
                                    {/*<Form.Item*/}
                                    {/*    name="username"*/}
                                    {/*    rules={[{required: true, message: '请输入手机号 / 用户名!'}]}*/}
                                    {/*    style={{borderBottom: '1px solid #DCDCDC'}}*/}
                                    {/*>*/}
                                    {/*    <Input placeholder="请输入手机号 / 用户名" bordered={false}/>*/}
                                    {/*</Form.Item>*/}
                                    <Form.Item
                                        label=""
                                        name="password"
                                        style={{borderBottom: '1px solid #DCDCDC'}}
                                        rules={[{required: true, message: '请填写密码!'}]}
                                    >
                                        <Input.Password
                                            bordered={false}
                                            placeholder="请输入密码"
                                            onChange={
                                                (e) => {
                                                    this.setState(
                                                        {
                                                            password: e.target.value
                                                        }
                                                    )
                                                }
                                            } value={this.state.password}/>
                                    </Form.Item>
                                    {/*<Form.Item*/}
                                    {/*    name="password"*/}
                                    {/*    rules={[{required: true, message: '请输入密码!'}]}*/}
                                    {/*    style={{borderBottom: '1px solid #DCDCDC'}}*/}
                                    {/*>*/}
                                    {/*    <Input*/}
                                    {/*        bordered={false}*/}
                                    {/*        type="password"*/}
                                    {/*        placeholder="请输入密码"*/}
                                    {/*    />*/}
                                    {/*</Form.Item>*/}


                                    {/*<Form.Item>*/}
                                    {/*    <a style={{ color: '#8C8D9B' }} href="">创建账号</a>*/}
                                    {/*</Form.Item>*/}

                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" onClick={this.login} block
                                                style={{height: '56PX', borderRadius: '12PX'}}>
                                            登录
                                        </Button>
                                    </Form.Item>
                                </Form>

                            </TabPane>

                            {/*<TabPane tab="验证码登录" key="2">*/}
                            {/*    <Form*/}
                            {/*        name="normal_login"*/}
                            {/*        className="login-form"*/}
                            {/*        initialValues={{ remember: true }}*/}
                            {/*    >*/}
                            {/*        <Form.Item*/}
                            {/*            name="phone"*/}
                            {/*            rules={[{ required: true, message: '请输入手机号!' }]}*/}
                            {/*            style={{ borderBottom: '1px solid #DCDCDC' }}*/}
                            {/*        >*/}
                            {/*            <Input placeholder="请输入手机号" bordered={false} />*/}
                            {/*        </Form.Item>*/}
                            {/*        <Form.Item*/}
                            {/*            name="captcha"*/}
                            {/*            rules={[{ required: true, message: '请输入验证码!' }]}*/}
                            {/*            style={{ borderBottom: '1px solid #DCDCDC' }}*/}
                            {/*        >*/}
                            {/*            <Row>*/}
                            {/*                <Col span={18}>*/}
                            {/*                    <Input*/}
                            {/*                        bordered={false}*/}
                            {/*                        type="password"*/}
                            {/*                        placeholder="请输入验证码"*/}
                            {/*                    />*/}
                            {/*                </Col>*/}
                            {/*                <Col span={6} style={{ float: 'right' }}>*/}
                            {/*                    <Button type="link" style={{ color: '#151830', fontWeight: 'bold' }}>发送验证码</Button>*/}
                            {/*                </Col>*/}
                            {/*            </Row>*/}
                            {/*        </Form.Item>*/}


                            {/*        <Form.Item>*/}
                            {/*            <a style={{ color: '#8C8D9B' }} href="">创建账号</a>*/}
                            {/*        </Form.Item>*/}

                            {/*        <Form.Item>*/}
                            {/*            <Button type="primary" htmlType="submit" block style={{ height: '56PX', borderRadius: '12PX' }}>*/}
                            {/*                登录*/}
                            {/*            </Button>*/}
                            {/*        </Form.Item>*/}


                            {/*    </Form>*/}

                            {/*</TabPane>*/}

                        </Tabs>

                        {/*<Button size="large" shape="circle"><img src="/weixin.png" alt="微信图片" /></Button>*/}

                    </div>
                    <Footer className={styles.footer}>
                        <text>
                            <a href={'https://beian.miit.gov.cn/'} style={{
                                color:'#fff',
                            }}>粤ICP备20050541号</a>
                        </text>
                    </Footer>
                </div>
                {/*<Layout>*/}
                {/*    <Header></Header>*/}
                {/*    <Content>*/}
                {/*        <Form*/}
                {/*            {...layout}*/}
                {/*            name="basic"*/}
                {/*            initialValues={{remember: true}}*/}
                {/*        >*/}
                {/*            <Form.Item*/}
                {/*                label="用户名"*/}
                {/*                name="username"*/}
                {/*                rules={[{required: true, message: '请填写用户名!'}]}*/}
                {/*            >*/}
                {/*                <Input onChange={*/}
                {/*                    (e) => {*/}
                {/*                        this.setState(*/}
                {/*                            {*/}
                {/*                                username: e.target.value*/}
                {/*                            }*/}
                {/*                        )*/}
                {/*                    }*/}
                {/*                } value={this.state.username}/>*/}
                {/*            </Form.Item>*/}

                {/*            <Form.Item*/}
                {/*                label="密码"*/}
                {/*                name="password"*/}
                {/*                rules={[{required: true, message: '请填写密码!'}]}*/}
                {/*            >*/}
                {/*                <Input.Password onChange={*/}
                {/*                    (e) => {*/}
                {/*                        this.setState(*/}
                {/*                            {*/}
                {/*                                password: e.target.value*/}
                {/*                            }*/}
                {/*                        )*/}
                {/*                    }*/}
                {/*                } value={this.state.password}/>*/}
                {/*            </Form.Item>*/}

                {/*            /!*<Form.Item {...tailLayout} name="remember" valuePropName="checked">*!/*/}
                {/*            /!*    <Checkbox>Remember me</Checkbox>*!/*/}
                {/*            /!*</Form.Item>*!/*/}

                {/*            <Form.Item {...tailLayout}>*/}
                {/*                <Button type="primary" onClick={this.login} htmlType="submit">*/}
                {/*                    登陆*/}
                {/*                </Button>*/}
                {/*                <Button onClick={this.showModal}>*/}
                {/*                    初始化*/}
                {/*                </Button>*/}
                {/*            </Form.Item>*/}
                {/*        </Form>*/}
                {/*    </Content>*/}
                {/*    <Footer></Footer>*/}
                {/*</Layout>*/}
                {/*<Modal*/}
                {/*    visible={visible}*/}
                {/*    title="初始化数据"*/}
                {/*    onCancel={this.handleCancel}*/}
                {/*    width={700}*/}
                {/*    footer={[*/}
                {/*        <Button key="back" onClick={this.handleCancel}>*/}
                {/*            提交*/}
                {/*        </Button>,*/}
                {/*    ]}*/}
                {/*>*/}
                {/*    <Form*/}
                {/*        {...layout}*/}
                {/*        name="basic"*/}
                {/*        initialValues={{remember: true}}*/}
                {/*    >*/}
                {/*        <Form.Item label="部门名称">*/}
                {/*            <Input value={this.state.updata.depName} onChange={e => this.setState(*/}
                {/*                {updata: {...this.state.updata, depName: e.target.value}})}/>*/}
                {/*        </Form.Item>*/}
                {/*        <Form.Item label="部门编号">*/}
                {/*            <Input value={this.state.updata.depNo} onChange={e => this.setState(*/}
                {/*                {updata: {...this.state.updata, depNo: e.target.value}})}/>*/}
                {/*        </Form.Item>*/}
                {/*        <Form.Item label="用户名">*/}
                {/*            <Input value={this.state.updata.username} onChange={e => this.setState(*/}
                {/*                {updata: {...this.state.updata, username: e.target.value}})}/>*/}
                {/*        </Form.Item>*/}
                {/*        <Form.Item label="用户号">*/}
                {/*            <Input value={this.state.updata.userNo} onChange={e => this.setState(*/}
                {/*                {updata: {...this.state.updata, userNo: e.target.value}})}/>*/}
                {/*        </Form.Item>*/}
                {/*        <Form.Item label="密码">*/}
                {/*            <Input.Password value={this.state.updata.password} onChange={e => this.setState(*/}
                {/*                {updata: {...this.state.updata, password: e.target.value}})}/>*/}
                {/*        </Form.Item>*/}
                {/*    </Form>*/}
                {/*</Modal>*/}
            </>
        );
    }
}

export default withRouter(index);

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";
class Pojo extends MyPojo {
    getbatchdatagrid = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.controller +'/getbatchdatagrid', params)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }
    constructor() {
        //批次信息
        super('getbatchdatagrid');
        this.controller = 'rpbase';
        this.delRowName = 'fpk';
        this.cName = '生产明细表';
        this.pojoItem = new initItem();
        this.showPK = true;
        this.prefixName = ''
        this.getListActionName = 'getbatchdatagrid'
        this.addPojoItemInitItems([
            {
                comName: 'forderentrypk',
                name: '生产订单',
                width:150,
            },
            {
                comName: 'fpk',
                name: '生产批号',
                width:100,
                isPk:true,
            },
            {
                comName: 'fpk',
                name: '生产批号',
                width:100,
                show:false,
            },
            {
                comName: 'fgoodsnumber',
                name: '存货名称',
                width: 300,
                showLength: 30,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fminventory',
                    action: 'getList',
                    id: 'fnumber',
                    text: '{fcaption}',
                }),
                exComParent: 'fgoodscaption',
            },
            {
                comName: 'fgoodsnumber',
                name: '存货编号',
                showLength:40,
                width:100,
                showSearch:false,
            },
            {
                comName: 'fsucaption',
                name: '单位名称',
                width:100,
                showSearch:false,
            },
            {
                comName: 'fsuqty',
                name: '单位数量',
                width:100,
                showSearch:false,
            },
            {
                comName: 'fpmqty',
                name: '生产数量',
                width:100,
                showSearch:false,
            },
            {
                comName: 'freceiptqty',
                name: '入库数量',
                width:100,
                showSearch:false,
            },
            {
                comName: 'finvoiceqty',
                name: '出库数量',
                width:100,
                showSearch:false,
            },
            {
                comName: 'fstockqty',
                name: '在库数量',
                width:100,
                showSearch:false,
            },
        ]);
    }
}
export default Pojo;

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item} from '../../calzzs/initItems'

class Pojo extends MyPojo {
    constructor() {
        //仓储信息
        super('fmstorage');
        this.controller = 'fm';
        this.cName = '仓储信息';
        this.pojoItem = new initItem();
        this.tableType = TableType.TG;
        this.prefixName = 'data.info';
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk: true,
            },
            {
                comName: 'fnumber',
                name: '编号',
                // tdShow: false,
            },
            {
                comName: 'fcaption',
                name: '名称',
                width:260,
            },
            {
                comName: 'ftypeid',
                name: '类型',
                dataType: DataType.Com,
                state: {
                    1: '总仓',
                    2: '组织仓',
                    3: '仓库',
                    4: '库区',
                    5: '库条',
                    6: '库位',
                },
            },
            {
                comName: 'fmaterialtypeid',
                name: '存放物料类型',
                dataType: DataType.Com,
                state: {
                    1: '产成品',
                    2: '半成品',
                    3: '原料',
                    4: '辅料',
                    5: '包材',
                    6: '宣传用品',
                    7: '配件',
                    8: '其他',
                },
            },
            {
                comName: 'fparentpk',
                name: '父编号',
                isParent: true,
                show:false,
            },
            {
                comName: 'flabelvalues',
                name: '标签卡值',
            },
            {
                comName: 'fpuqty',
                name: '容量',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'fstateid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '未满',
                    2: '已满',
                    3: '停用',
                },
            },
            // {
            //     comName: 'forgpk',
            //     name: '组织编号',
            //     dataType: DataType.ComGETServer,
            //     comF: new ComF({
            //         controller: 'sysorginfo',
            //         action: 'getList',
            //         id: 'fpk',
            //         text: '{fcaption}-{forgaddress};{fmanager};',
            //     }),
            // },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
        ]);
    }
}

export default Pojo;

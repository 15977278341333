import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item, StatesID} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";

class Pojo extends MyPojo {
    getTitleList = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + 'wminvoice' + '/getinfo', params)
                .then((ref) => {
                    resolve(ref['data']['fentryEXlist'] === undefined ? [] : ref['data']['fentryEXlist']);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }

    constructor() {
        //发货单条目
        super('wminvoiceentry');
        this.controller = 'wm';
        this.cName = '发货单条目';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '编号',
                isPk: true,
            },
            {
                comName: 'fpk',
                name: '编号',
                width: 250,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'forderentrypk',
                name: '订单条目',//(对应订单的条目编号)
                isInsert: false,
                isUpdate: false,
                show:false,
            },
            {
                comName: 'finvoicenumber',
                name: '发货单',
                isInsert: false,
                isUpdate: false,
                show:false,
            },
            {
                comName: 'fgoodsnumber',
                name: '存货编号',
                width: 100,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fgoodsnumber',
                name: '存货名称',
                dataType: DataType.ComGETServer,
                width: 400,
                comF: new ComF({
                    controller: 'fminventory',
                    action: 'getList',
                    id: 'fnumber',
                    text: '{fcaption}',
                }),
                showLength: 45,
                exComParent: 'fgoodscaption',
            },
            {
                comName: 'fbatchnumber',
                name: '批号',//(支持多选,逗号分隔)
                comF: new ComF({
                    multiple: true,
                    // isInitialize: false,
                }),
                // isInsert: false,
                // isUpdate: false,
                show: false,
            },
            {
                comName: 'fstorage',
                name: '储位',//(支持多选,逗号分隔)
                width: 300,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fmstorage',
                    action: 'getList',
                    id: 'fcaption',
                    text: '{fcaption}',
                    multiple: true,
                    // isInitialize: false,
                }),
                exComParent: 'fstorage',
                show:false,
            },
            {
                comName: 'fgoodsstateid',
                name: '存货状态',
                dataType: DataType.Com,
                state: StatesID.getPState(),
                defaultValue:1,
                show:false,
            },
            {
                comName: 'fdescription',
                name: '存货状态说明',
                show:false,
            },
            {
                comName: 'fsupk',
                name: '单位',
                width: 80,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fmpackingunit',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}|包装单位数:{fpuqty}|码板单位数:{fpsuqty}|码板包装数:{fpsupuqty}',//件数:{fiuqty}|
                    isInitialize: false,
                }),
                exComParent: 'fsucaption',
            },
            {
                comName: 'fsuqty',
                name: '计划数量',//单位数量
                width: 100,
                dataType: DataType.NUM,
            },
            // {
            //     comName: 'fpupk',
            //     name: '包装单位',
            //     width: 200,
            //     dataType: DataType.ComGETServer,
            //     comF: new ComF({
            //         controller: 'fmpackingunit',
            //         action: 'getList',
            //         id: 'fpk',
            //         text: '{fcaption}',
            //         isInitialize: false,
            //     }),
            //     exComParent: 'fpucaption',
            // },
            // {
            //     comName: 'fpsupk',
            //     name: '码板单位',
            //     width: 200,
            //     dataType: DataType.ComGETServer,
            //     comF: new ComF({
            //         controller: 'fmpackingunit',
            //         action: 'getList',
            //         id: 'fpk',
            //         text: '{fcaption}',
            //         isInitialize: false,
            //     }),
            //     exComParent: 'fpsucaption',
            // },
            {
                comName: 'fmemo',
                name: '备注',
                show:false,
            },
            {
                comName: 'frealqty',
                name: '已出库',//实际数量
                width: 100,
                dataType: DataType.NUM,
            },
            {
                comName: 'fisreturn',
                name: '是否退货',
                width: 100,
                dataType: DataType.Com,
                state: {
                    1: '是',
                    2: '否',
                },
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'freturnqty',
                name: '退货数量',
                dataType: DataType.NUM,
                width: 100,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'freturnok',
                name: '已退货',
                width: 100,
                dataType: DataType.Com,
                state: {
                    1: '是',
                    2: '否',
                },
                isInsert: false,
                isUpdate: false,
            },
            // {
            //     comName: 'fpucountpsu',
            //     name: '包装数/托板',
            //     dataType: DataType.NUM,
            //     isInsert: false,
            //     isUpdate: false,
            //     show:false,
            // },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
        ]);
    }
}

export default Pojo;

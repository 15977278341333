import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {initItem, item} from '../../calzzs/initItems'
class Pojo extends MyPojo {
    constructor() {
        //层码信息
        super('wmtiercode');
        this.controller = 'wm';
        this.cName = '层码信息';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk:true,
            },
            {
                comName: 'fnumber',
                name: '编号',
            },
            {
                comName: 'fsucqty',
                name: '单位码数量',
                dataType: DataType.NUM,
            },
            {
                comName: 'fsuqty',
                name: '单位数量',
                dataType: DataType.NUM,
            },
            {
                comName: 'fpackagecode',
                name: '垛码',
            },
            {
                comName: 'fpackagetime',
                name: '码垛时间',
                dataType: DataType.DataTime,
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
        ]);
    }
}
export default Pojo;

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {initItem, item} from '../../calzzs/initItems'
class Pojo extends MyPojo {
    constructor() {
        //消费者信息
        super('mmconsumer');
        this.controller = 'mm';
        this.cName = '消费者信息';
        this.infoColumn = ''
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fopenid',
                name: 'openid',
                isPk:true,
            },
            {
                comName: 'fname',
                name: '姓名',
            },
            {
                comName: 'ftelephone',
                name: '联系电话',
            },
            {
                comName: 'fsex',
                name: '性别',
                dataType: DataType.Com,
                state: {
                    1: '男',
                    2: '女',
                },
            },
            {
                comName: 'faddress',
                name: '收货地址',
            },
            {
                comName: 'fage',
                name: '年龄段',
                isEx:true,
                exComParent:'fagegroup',
            },
            {
                comName: 'fmemo',
                name: '备注',
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
        ]);
    }
}
export default Pojo;

import React, {useState} from 'react';
import './index.less';
import {Row, Button, Table, Tag, Radio, Modal, Tabs} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import {get} from "../../axios/Response";
import MyTable from "../../my_module/table/table";
// import Pojo from "./pojo"
import MyDataGrid from "../../my_module/datagrid/datagrid";
import MyButton from "../../my_module/MyClass/button";
import Nestgrid from "../../my_module/nestgrid/nestgrid";
import Pojos from "../pojos"
import {getQueryWheres, WhereType} from "../../api/mainPojo";
import {getLocal, getLocalAddDayTime00} from "../../utils/utils";

const {TabPane} = Tabs;

//生产日报表
class index extends React.Component {
    constructor(props) {
        super(props);
        this.onDGRef = this.onDGRef.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        // this.addOpenIntercept = this.addOpenIntercept.bind(this);
        // this.editOpenIntercept = this.editOpenIntercept.bind(this);
        this.state = {
            // addModalVisible: false,
            pojo: Pojos.getPojo('rpproductdaily'),//new Pojo(),
            dg: undefined,
        }
        console.log(this.pojo);
    }

    exTrBus = [
        // new MyButton(
        //     '审批',
        //     'getItemByID11',
        //     undefined,
        //     (row) => {
        //         console.log(row);
        //         return true;
        //     }
        // )
    ]
    onDGRef = (ref) => {
        this.setState({
            dg: ref,
        }, () => {
            //this.state.dg.refInit();
        })
    }
    componentDidUpdate = (prevProps, prevState) => {
    }
    // addModal = () => {
    //     let model =
    //         <Modal title="Basic Modal" visible={this.state.addModalVisible}
    //                onCancel={() => {
    //                    this.setState({
    //                        addModalVisible:false,
    //                    })
    //                }}>
    //             <p>Some contents...</p>
    //             <p>Some contents...</p>
    //             <p>Some contents...</p>
    //         </Modal>;
    //     return model;
    // }
    // addOpenIntercept = (ref) => {
    //     console.log(ref);
    //     // this.setState({
    //     //     addModalVisible:true,
    //     // })
    //     return false;
    // }
    // editOpenIntercept = (ref) => {
    //     console.log(ref);
    //     return false;
    // }
    render() {
        return (
            <div>
                {/*{this.addModal()}*/}
                <MyDataGrid
                    // addOpenIntercept={this.addOpenIntercept}
                    // editOpenIntercept={this.editOpenIntercept}
                    // querywhere={
                    //     getQueryWheres([
                    //         {
                    //             value: [getLocalAddDayTime00(new Date()), getLocalAddDayTime00(new Date(), 1)],
                    //             comname: 'ftacticstime',
                    //             comtype: WhereType.Between(),
                    //         }
                    //     ])
                    // }
                    isOrderBy={false}
                    isDetail={false}
                    testGetListParams={(params) => {
                        console.log(params)
                        let querywhere = []
                        let iffregistertime = false
                        if (params['querywhere'] !== undefined) {
                            querywhere = JSON.parse(params['querywhere'])
                            // console.log(querywhere)
                            for (let k in querywhere) {
                                console.log(querywhere[k]['comname'])
                                if (querywhere[k]['comname'] === 'fregistertime') {
                                    iffregistertime = true
                                }
                            }
                        }
                        console.log(iffregistertime)

                        if (!iffregistertime) {
                            querywhere.push(
                                ...getQueryWheres([
                                    {
                                        value: [getLocalAddDayTime00(new Date()), getLocalAddDayTime00(new Date(), 1)],
                                        comname: 'fregistertime',
                                        comtype: WhereType.Between(),
                                    }
                                ])
                            )
                        }
                        params['querywhere'] = querywhere
                        return params
                    }}
                    initializeList={true}
                    getListName={'getproductdaily'}
                    onRef={this.onDGRef}
                    pojo={this.state.pojo}
                    exTrBus={this.exTrBus}
                    isAdd={false}
                    isEdit={false}
                    isDel={false}
                    isPage={false}
                    isSetTrBus={false}
                    // exHeadBus={[
                    //     new MyButton(
                    //         '同步',
                    //         'maintain',
                    //         () => {
                    //             console.log('synchronization');
                    //             this.state.pojo.synchronization()
                    //                 .then((ref) => {
                    //                     this.state.dg.getList();
                    //                 });
                    //         },
                    //         (row) => {
                    //             console.log(row);
                    //             return true;
                    //         }
                    //     ),
                    // ]}
                />
            </div>
        );
    }
}

export default index;

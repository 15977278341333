import React from "react";
import MForm from "../mform/mform";
import PropTypes from 'prop-types';
import InitParam from "../Common/init_param";
import {Button} from "antd";

class MFormWin extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = undefined;
        this.state = {}
    }

    init() {
        this.formRef.init();
    }

    render() {
        return (
            <div style={{textAlign: 'left'}}>
                <div style={{width: '100%'}}>
                    <Button key="back" onClick={this.props.closeFunc}>
                        关闭
                    </Button>
                    <Button key="submit" type="primary" onClick={() => {
                        this.formRef.submit();
                    }}>
                        提交
                    </Button>
                </div>
                <div style={{margin: '20px 50px 10px 20px'}}>
                    <MForm
                        columnNumber={this.props.columnNumber}
                        parentData={this.props.parentData}
                        clientItem={this.props.clientItem}
                        filter={this.props.filter}
                        submitFunc={this.props.submitFunc}
                        columns={this.props.columns}
                        row={this.props.row}
                        ref={(ref) => {
                            this.formRef = ref;
                        }}
                        data={this.props.data}
                    />
                </div>
            </div>
        )
    }
}

MFormWin.defaultProps = {
    columns: [],
    row: undefined,
    submitFunc: (ref) => {
    },
    exParam: undefined,
    filter: (column) => {
        return true;
    },
    parentData: undefined,
    clientItem: undefined,
    columnNumber: 3,
    formRef: (ref) => {
    },

    title: '表单',
    closeFunc: () => {
    },
    visible: false,
    data: undefined,
}
MFormWin.propTypes = {
    columns: PropTypes.arrayOf(InitParam).isRequired,
    row: PropTypes.object,
    submitFunc: PropTypes.func,
    exParam: PropTypes.object,
    filter: PropTypes.func,
    parentData: PropTypes.object,
    clientItem: PropTypes.object,
    columnNumber: PropTypes.number,
    formRef: PropTypes.func,

    title: PropTypes.string,
    closeFunc: PropTypes.func,
    visible: PropTypes.bool,
    data: PropTypes.object,
}
export default MFormWin;

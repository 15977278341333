import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {initItem, item} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";

class Pojo extends MyPojo {
    //同步数据
    synchronization = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/synchronization', params)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }
    constructor() {
        //组织架构
        super('sysorginfo');
        this.controller = 'sys';
        this.cName = '组织架构';
        this.pojoItem = new initItem();
        this.tableType = TableType.TG;
        this.prefixName = 'data.info';
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk: true,
                isInsert:false,
                isUpdate:false,
            },
            {
                comName: 'fnumber',
                name: '编号',//(同步ERP或自定义或系统生成)
                width:200,
                // isInsert:false,
                // isUpdate:false,
            },
            {
                comName: 'fcaption',
                name: '名称',
            },
            {
                comName: 'fparentpk',
                name: '父pk',
                isParent: true,
                show:false,
            },
            {
                comName: 'flevelid',
                name: '级别',
                dataType: DataType.Com,
                state: {
                    1: '集团',
                    2: '组织',
                    3: '部门/分厂',
                    4: '科室/车间',
                    5: '班组',
                },
                isInsert:false,
                isUpdate:false,
            },
            {
                comName: 'forgcode',
                name: '组织代号',//（对组织级别有效）
                tdShow: false,
            },
            {
                comName: 'forgaddress',
                tdShow: false,
                name: '组织地址',//（对组织级别有效）
            },
            {
                comName: 'fmanager',
                name: '联系人',
            },
            {
                comName: 'ftelephone',
                name: '联系电话',
                tdShow: false,
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
        ]);
    }
}

export default Pojo;

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";
class Pojo extends MyPojo {
    gettacticsrecorddatagrid(params){
        // return this.getList(params,'getpackdatagrid');
        if (this.orderRowName instanceof Array) {
            for (let i = 0; i !== this.orderRowName.length; i++) {
                if (this.columnsMap[this.orderRowName[i]] !== undefined) {
                    params['orderby'] = this.orderRowName[i] + ' ' + this.orderType;
                    break
                }
            }
        }else {
            if (this.columnsMap[this.orderRowName] !== undefined) {
                params['orderby'] = this.orderRowName + ' ' + this.orderType;
            }
        }
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + 'wmcodeopt/gettacticsrecorddatagrid', params)
                .then((res) => {
                    resolve(res);
                })
                .catch((res) => {
                    reject(res);
                });
        });
    }
    constructor() {
        //过账记录
        super('wmtacticsrecord');
        this.controller = 'wm';
        this.cName = '过账记录';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fcodenumber',
                width: 200,
                name: '信息码',
                showLength: 30,
            },
            {
                comName: 'fpk',
                name: '唯一编号',
                width: 200,
                isPk:true,
            },
            {
                comName: 'ftaskpk',
                name: '任务编号',
                width: 250,
            },
            {
                comName: 'fgoodsnumber',
                name: '存货名称',
                width: 300,
                showLength: 30,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fminventory',
                    action: 'getList',
                    id: 'fnumber',
                    text: '{fcaption}',
                }),
                exComParent: 'fgoodscaption',
            },
            {
                comName: 'ftypeid',
                name: '任务类型',
                dataType: DataType.Com,
                state: {
                    1: '入库',
                    2: '出库',
                },
                // tdShow: false,
            },
            {
                comName: 'ftacticstime',
                name: '过账时间',
                dataType: DataType.DataTime,
            },
            {
                comName: 'fsuqty',
                name: '单位数量',
                // tdShow: false,
            },
            {
                comName: 'fstoragepk',
                name: '储位',
                dataType: DataType.ComGETServer,
                comF: {
                    controller: 'fmstorage',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                },
                exComParent: 'fstorage',
            },
            {
                comName: 'foptman',
                name: '操作人',
            },
            {
                comName: 'feqip',
                name: '设备IP',
            },
            {
                comName: 'fstateid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '保存',
                    2: '已过账',
                    3: '已反写',
                },
            },
            // {
            //     comName: 'forgpk',
            //     name: '组织编号',
            // },
            // {
            //     comName: 'ftypeid',
            //     name: '任务类型',
            //     dataType: DataType.Com,
            //     state: {
            //         1: '入库',
            //         2: '出库',
            //     },
            //     // tdShow: false,
            // },
            {
                comName: 'fbatchnumber',
                name: '批号',
                tdShow: false,
            },
            {
                comName: 'fstoragepk',
                name: '储位',
                tdShow: false,
                dataType: DataType.ComGETServer,
                comF: {
                    controller: 'fmstorage',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                },
                exComParent: 'fstorage',
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
        ]);
    }
}
export default Pojo;

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item} from '../../calzzs/initItems'
class Pojo extends MyPojo {
    constructor() {
        //设备维护
        super('emmaintenance');
        this.controller = 'em';
        this.cName = '设备维护';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk:true,
            },
            {
                comName: 'feqpk',
                name: '设备',
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'emequipment',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                }),
                exComParent: 'equipment',
            },
            {
                comName: 'feqpk',
                name: '设备编号',
            },
            {
                comName: 'freason',
                name: '维护原因',
            },
            {
                comName: 'fresolvent',
                name: '解决方法',
                tdShow: false,
            },
            {
                comName: 'fdescribe',
                name: '工作描述',
                tdShow: false,
            },
            {
                comName: 'fmaintainer',
                name: '维护人',
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
        ]);
    }
}
export default Pojo;

import React, {useState} from 'react';
import './index.less';
import {Row, Button, Table, Tag, Radio, Modal, Tabs, message} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import {get} from "../../axios/Response";
import MyTable from "../../my_module/table/table";
// import Pojo from "./pojo"
import MyDataGrid from "../../my_module/datagrid/datagrid";
import MyButton from "../../my_module/MyClass/button";
import Nestgrid from "../../my_module/nestgrid/nestgrid";
import Pojos from "../pojos"
import {getButtomModal} from "../../my_module/MyClass/domUtil";
import FormUtil from "../../my_module/Common/FormUtil";
import {DataType} from "../../api/mainPojo";
import {ComF} from "../../calzzs/initItems";

const {TabPane} = Tabs;

//设备信息
class index extends React.Component {
    constructor(props) {
        super(props);
        this.onDGRef = this.onDGRef.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        // this.addOpenIntercept = this.addOpenIntercept.bind(this);
        // this.editOpenIntercept = this.editOpenIntercept.bind(this);
        //maintenance 设备维护
        this.maintenancefreason = '';
        this.maintenancefresolvent = '';
        this.maintenancefdescribe = '';
        this.maintenancefmaintainer = '';
        //monitoring 设备事件
        this.monitoringfclassesid = 1;
        this.monitoringfsource = '';
        this.monitoringfdescribe = '';
        this.state = {
            // addModalVisible: false,
            pojo: Pojos.getPojo('emequipment'),//new Pojo(),
            dg: undefined,
        }
        console.log(this.pojo);
    }

    exTrBus = [
        // new MyButton(
        //     '审批',
        //     'getItemByID11',
        //     undefined,
        //     (row) => {
        //         console.log(row);
        //         return true;
        //     }
        // )
        getButtomModal(
            {
                title: '设备维护',
                content:
                    [
                        new FormUtil().getFormItem(
                            {
                                column: {
                                    comName: 'freason',
                                    prop: 'freason',
                                    name: '维护原因',
                                    dataType: DataType.String,
                                },
                                onChange: (value, ...data) => {
                                    // console.log(value);
                                    // console.log(data);
                                    this.maintenancefreason = value.target.value;
                                }
                            },
                        ),
                        new FormUtil().getFormItem(
                            {
                                column: {
                                    comName: 'fresolvent',
                                    prop: 'fresolvent',
                                    name: '解决方法',
                                    dataType: DataType.String,
                                },
                                onChange: (value, ...data) => {
                                    // console.log(value);
                                    // console.log(data);
                                    this.maintenancefresolvent = value.target.value;
                                }
                            },
                        ),
                        new FormUtil().getFormItem(
                            {
                                column: {
                                    comName: 'fdescribe',
                                    prop: 'fdescribe',
                                    name: '工作描述',
                                    dataType: DataType.String,
                                },
                                onChange: (value, ...data) => {
                                    // console.log(value);
                                    // console.log(data);
                                    this.maintenancefdescribe = value.target.value;
                                }
                            },
                        ),
                        new FormUtil().getFormItem(
                            {
                                column: {
                                    comName: 'fmaintainer',
                                    prop: 'fmaintainer',
                                    name: '维护人',
                                    dataType: DataType.ComGETServer,
                                    comF: new ComF({
                                        controller: 'sysstaff',
                                        action: 'getList',
                                        id: 'fpk',
                                        text: '{fnumber}-{fname}',
                                    }),
                                },
                                onChange: (value, ...data) => {
                                    // console.log(value);
                                    // console.log(data);
                                    this.maintenancefmaintainer = value.key;
                                }
                            },
                        )
                    ],
                name: 'maintain',
                okFun: (row) => {
                    console.log(this);
                    return new Promise((resolve, reject) => {
                        if (this.maintenancefmaintainer === '') {
                            message.error('请选择维护人');
                            reject(undefined);
                        } else {
                            this.state.pojo.maintenance({
                                feqpk: row['fpk'],
                                freason: this.maintenancefreason,
                                fresolvent: this.maintenancefresolvent,
                                fdescribe: this.maintenancefdescribe,
                                fmaintainer: this.maintenancefmaintainer,
                            })
                                .then((ref) => {
                                    resolve(ref);
                                })
                                .catch((ref) => {
                                    reject(ref);
                                });
                        }
                    });
                },
                thenFun: (ref) => {
                    this.state.dg.getList();
                }
            },
        ),
        getButtomModal(
            {
                title: '设备事件',
                content:
                    [
                        new FormUtil().getFormItem(
                            {
                                column: {
                                    comName: 'fclassesid',
                                    prop: 'fclassesid',
                                    name: '维护原因',
                                    dataType: DataType.Com,
                                    state: {
                                        1: '信息',
                                        2: '通知',
                                        3: '警告',
                                        4: '故障',
                                    },
                                },
                                onChange: (value, ...data) => {
                                    // console.log(value);
                                    // console.log(data);
                                    this.monitoringfclassesid = value;
                                }
                            },
                        ),
                        new FormUtil().getFormItem(
                            {
                                column: {
                                    comName: 'fsource',
                                    prop: 'fsource',
                                    name: '来源',
                                    dataType: DataType.String,
                                },
                                onChange: (value, ...data) => {
                                    // console.log(value);
                                    // console.log(data);
                                    this.monitoringfsource = value.target.value;
                                }
                            },
                        ),
                        new FormUtil().getFormItem(
                            {
                                column: {
                                    comName: 'fdescribe',
                                    prop: 'fdescribe',
                                    name: '描述',
                                    dataType: DataType.String,
                                },
                                onChange: (value, ...data) => {
                                    // console.log(value);
                                    // console.log(data);
                                    this.monitoringfdescribe = value.target.value;
                                }
                            },
                        ),
                    ],
                name: 'maintain',
                okFun: (row) => {
                    console.log(this);
                    return new Promise((resolve, reject) => {
                        this.state.pojo.monitoring({
                            feqpk: row['fpk'],
                            fclassesid: this.monitoringfclassesid,
                            fsource: this.monitoringfsource,
                            fdescribe: this.monitoringfdescribe,
                        })
                            .then((ref) => {
                                resolve(ref);
                            })
                            .catch((ref) => {
                                reject(ref);
                            });
                    });
                },
                thenFun: (ref) => {
                    this.state.dg.getList();
                }
            },
        ),
    ]
    onDGRef = (ref) => {
        this.setState({
            dg: ref,
        }, () => {
            //this.state.dg.refInit();
        })
    }
    componentDidUpdate = (prevProps, prevState) => {
    }
    // addModal = () => {
    //     let model =
    //         <Modal title="Basic Modal" visible={this.state.addModalVisible}
    //                onCancel={() => {
    //                    this.setState({
    //                        addModalVisible:false,
    //                    })
    //                }}>
    //             <p>Some contents...</p>
    //             <p>Some contents...</p>
    //             <p>Some contents...</p>
    //         </Modal>;
    //     return model;
    // }
    // addOpenIntercept = (ref) => {
    //     console.log(ref);
    //     // this.setState({
    //     //     addModalVisible:true,
    //     // })
    //     return false;
    // }
    // editOpenIntercept = (ref) => {
    //     console.log(ref);
    //     return false;
    // }
    render() {
        return (
            <div>
                {/*{this.addModal()}*/}
                <MyDataGrid
                    // addOpenIntercept={this.addOpenIntercept}
                    // editOpenIntercept={this.editOpenIntercept}
                    onRef={this.onDGRef}
                    pojo={this.state.pojo}
                    exTrBus={this.exTrBus}
                    exHeadBus={[
                        getButtomModal(
                            {
                                title: '同步数据',
                                content: '确定同步数据?',
                                name: 'maintain',
                                okFun: (row) => {
                                    return new Promise((resolve, reject) => {
                                        this.state.pojo.synchronization({})
                                            .then((ref) => {
                                                resolve(ref);
                                            })
                                            .catch((ref) => {
                                                reject(ref);
                                            })
                                    });
                                },
                                thenFun: (ref) => {
                                    this.state.dg.getList();
                                }
                            }
                        ),
                    ]}
                />
            </div>
        );
    }
}

export default index;

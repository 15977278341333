import {DataType} from '../api/mainPojo';

export class initItem {
    initItems = [];
    clientItems = [];
}

export class item {
    comName = '';
    name = '';
    key = '';
    isName = false;
    isInsert = true;
    isUpdate = true;
    isPk = false;
    type = DataType.String;
    isUpTime = false;
    isUpPer = false;
    isInTime = false;
    isInPer = false;
    isRandom = false;
    isFileRow = false;
    isParent = false;
    position = 1;
    state = [];
    stateFiltration = (row, data) => {
        return undefined;
    };
    dataType = this.type;
    comF = null;
    isEx = false;
    exCom = '';
    //显示的 扩展字段
    exComParent = '';
    width = 150;
    show = true;
    showLength = 21;
    showSearch = true;
    defaultValue = undefined;
    tdShow = true;
    showTime = true;
    required = true;
    //显示到排序字段下拉
    showOrderSelect = true;
    isSerial = false;
}

export class TreeSelectRef {
    value = '';
    prop = '';
    cProp = '';
    key = '';
    row = undefined;
    _isTreeSelectRef = true;

    constructor({
                    value = '',
                    prop = '',
                    cProp = '',
                    key = '',
                    row = undefined,
                } = {}) {
        this.value = value;
        this.prop = prop;
        this.cProp = cProp;
        this.key = key;
        this.row = row;
    }
}

export class ComF {
    controller = '';
    action = '';
    key = '';
    id = '';
    name = '';
    text = '';
    orderRowName = '';
    orderType = 'ASC';
    exKey = '';
    params = {};
    getInfo = 'getinfo';
    showRow = 'fcaption';
    isInitialize = true;
    multiple = false;

    constructor({
                    controller = '',
                    action = 'getList',
                    key = '',
                    id = '',
                    name = '',
                    text = '',
                    orderRowName = '',
                    orderType = 'ASC',
                    exKey = '',
                    params = {},
                    getInfo = 'getinfo',
                    showRow = 'fcaption',
                    isInitialize = true,
                    multiple = false,
                } = {}) {
        this.controller = controller;
        this.action = action;
        this.key = key;
        this.id = id;
        this.name = name;
        this.text = text;
        this.orderRowName = orderRowName;
        this.orderType = orderType;
        this.exKey = exKey;
        this.params = params;
        this.getInfo = getInfo;
        this.showRow = showRow;
        this.isInitialize = isInitialize;
        this.multiple = multiple;
    }
}

export class clientItem {
    server = '';
    action = '';
    exKey = '';
    tableType = 'DG';
    name = '';
    pKey = '';
    cKey = '';
}

export class StatesID {
    static pState = {
        1: '正常',
        2: '异常码',
        3: '专执',
        4: '试验',
        5: '二次封箱',
        6: '特价',
        7: '旧日期',
        8: '待检',
        9: '封货',
    };
    static getPState = (exID = []) => {
        if (exID.length === 0) {
            return this.pState;
        }
        let ret = {};
        for (let i in this.pState) {
            if (!(exID.indexOf(i) >= 0)) {
                ret[i] = this.pState[i];
            }
        }
        return ret;
    };
}

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";

class Pojo extends MyPojo {
    getgoodsinfo = (fgoodsnumber = '') => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/getgoodsinfo', {
                fgoodsnumber: fgoodsnumber,
            })
                .then((res) => {
                    // console.log(res);
                    resolve(res);
                })
                .catch((res) => {
                    // console.log(res);
                    reject(res);
                });
        });
    }
    generator = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/generator', params)
                .then((res) => {
                    console.log(res);
                    resolve(res);
                })
                .catch((res) => {
                    console.log(res);
                    reject(res);
                });
        });
    }

    constructor() {
        //存货管理设置
        super('fminventoryconfig');
        this.controller = 'fm';
        this.cName = '存货管理设置';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk: true,
            },
            // {
            //     comName: 'fgoodsnumber',
            //     name: '存货名称',
            // },
            {
                showLength: 30,
                comName: 'fgoodsnumber',
                name: '存货编号',
                isInsert: false,
                isUpdate: false,
            },
            {
                width: 300,
                showLength: 30,
                comName: 'fgoodsnumber',
                name: '存货名称',
                dataType: DataType.ComGETServer,
                comF: {
                    controller: 'fminventory',
                    action: 'getList',
                    id: 'fnumber',
                    text: '{fcaption}({fnumber})',
                },
                exComParent: 'fgoodscaption',
            },
            {
                comName: 'fstorekeeperpk',
                name: '仓管员',
                // dataType: DataType.ComGETServer,
                // comF: new ComF({
                //     controller: 'sysstaff',
                //     action: 'getList',
                //     id: 'fpk',
                //     text: '{fnumber}-{fname}',
                // }),
                // exComParent:'fstorekeeper',
            },
            {
                // comName: 'fstoragepk',
                // name: '默认储位编号',
                width: 300,
                showLength: 30,
                comName: 'fstoragepk',
                name: '默认储位',
                dataType: DataType.ComGETServer,
                comF: {
                    controller: 'fmstorage',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                },
                exComParent: 'fstorage',
            },
            {
                comName: 'fistrace',
                name: '是否追溯',
                dataType: DataType.Com,
                state: {
                    1: '是',
                    2: '否',
                },
            },
            {
                comName: 'fwhstateid',
                name: '入库状态',
                dataType: DataType.Com,
                state: {
                    1: '正常',
                    2: '待检',
                },
            },
            {
                comName: 'fisstorage',
                name: '库位管理',
                dataType: DataType.Com,
                state: {
                    1: '是',
                    2: '否',
                },
            },
            {
                comName: 'fisbatch',
                name: '批次管理',
                dataType: DataType.Com,
                state: {
                    1: '是',
                    2: '否',
                },
            },
            {
                comName: 'fsafety',
                name: '安全库存',
                dataType: DataType.NUM,
            },
            {
                comName: 'forgpk',
                name: '组织编号',
                show:false,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fdefine1',
                name: '自定义1',
                show: false,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fdefine2',
                name: '自定义2',
                show: false,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fdefine3',
                name: '自定义3',
                show: false,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fdefine4',
                name: '自定义4',
                show: false,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fdefine5',
                name: '自定义5',
                show: false,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
        ]);
    }
}

export default Pojo;

import React from 'react';
import PropTypes from "prop-types";
import MyPojo from "../MyClass/pojo";
import {WhereType, DataType} from '../../api/mainPojo';
import {Col, Form, Input, Row, Select, DatePicker, Button, InputNumber} from "antd";
import moment from 'moment';
import MTreeSelect from "../mTreeSelect/mTreeSelect";
import './searchform.less';

const {Option} = Select;
const {RangePicker} = DatePicker;
const layout = {
    labelCol: {span: 5},
    wrapperCol: {span: 19},
};
const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

class SearchForm extends React.Component {
    constructor(props) {
        super(props);
        this.selectChange = this.selectChange.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.whereChange = this.whereChange.bind(this);
        this.init = this.init.bind(this);
        this.onClose = this.onClose.bind(this);
        this.upData = this.upData.bind(this);
        this.state = {
            pojo: this.props.pojo,
            columns: this.props.pojo.columns,
            formData: {},
            formItems: {},
        }
    }

    init() {
        this.setState({
            formItems: {}
        }, () => {
            // console.log('初始化');
            this.state.pojo.init()
                .then((res) => {
                    this.setState({
                        pojo: this.props.pojo,
                        columns: this.state.pojo.columns,
                    }, () => {
                        for (let i in this.state.columns) {
                            if (this.state.columns[i].showSearch) {
                                let wheres = this.getWhereTypes(this.state.columns[i].dataType);
                                let whereItems = {};
                                for (let j in wheres) {
                                    whereItems[wheres[j].name] = wheres[j];
                                }
                                if (wheres.length > 0) {
                                    this.state.formItems[this.state.columns[i].propRow] = {
                                        item: this.state.columns[i],
                                        whereItems: whereItems,
                                        checkedWhere: wheres[0],
                                        value: '',
                                    }
                                }
                            }
                        }
                        this.setState({
                            formItems: this.state.formItems,
                        }, () => {
                            //console.log(this.state.formItems);
                        })
                    });
                })
        })
    }

    whereChange(value, option) {
        this.state.formItems[option.formItem.item.propRow].checkedWhere = option.data;
        this.setState({
            formItems: this.state.formItems,
        })
    }

    selectChange(value, option, formItem) {
        this.state.formItems[formItem.item.propRow].checkedWhere.value = value;

        this.setState({
            formItems: this.state.formItems,
        })
    }

    inputChange(value, formItem) {
        this.state.formItems[formItem.item.propRow].checkedWhere.value = value;

        this.setState({
            formItems: this.state.formItems,
        })
    }

    getFormDom() {
        let doms = [];
        for (let i in this.state.formItems) {
            let formItem = this.state.formItems[i];
            if (formItem.item.isPk || formItem.item.isEx) {
                continue;
            }
            let optionsDom = [];
            for (let o in formItem.whereItems) {
                let whereItem = formItem.whereItems[o];
                optionsDom.push(
                    <Option formItem={formItem} data={whereItem} value={whereItem['name']}>{whereItem['title']}</Option>
                )
            }
            let defaultName = formItem.checkedWhere.name;
            let formDom = <Input value={formItem.checkedWhere.value}
                                 onChange={({target: {value}}) => this.inputChange(value, formItem)}/>;
            if (formItem.item.dataType === DataType.DataTime) {
                formDom = <RangePicker
                    onChange={(vals,dateStrings) => {
                        // console.log(vals);
                        // console.log(dateStrings);
                        this.selectChange(dateStrings, undefined, formItem)
                    }}
                    ranges={{
                        Today: [moment(), moment()],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                    }}
                    showTime={formItem.item.showTime}
                    format={formItem.item.showTime ? "YYYY/MM/DD HH:mm:ss" : "YYYY/MM/DD 00:00:00"}
                />
            }
            if (formItem.item.dataType === DataType.Com) {
                let options = [];
                this.props.filtration(formItem);
                if (formItem.item.state instanceof Array) {
                    for (let i = 0; i !== formItem.item.state.length; i++) {
                        options.push(
                            <Option value={i}>{formItem.item.state[i]}</Option>
                        )
                    }
                } else {
                    for (let key in formItem.item.state) {
                        options.push(
                            <Option value={key}>{formItem.item.state[key]}</Option>
                        )
                    }
                }
                let cWhereName = formItem.checkedWhere.name;
                if ([WhereType.In.name, WhereType.NotIn.name].indexOf(cWhereName) >= 0) {
                    formDom =
                        <Select mode={'multiple'} value={formItem.checkedWhere.value}
                                onChange={(value, option) => this.selectChange(value, option, formItem)}>
                            {options}
                        </Select>
                } else {
                    formDom =
                        <Select
                            allowClear
                            onChange={(value, option) => this.selectChange(value, option, formItem)}
                            value={formItem.checkedWhere.value}>
                            {options}
                        </Select>
                }
            }
            if (formItem.item.dataType === DataType.ComGETServer) {
                let options = [];
                this.props.filtration(formItem);
                let cWhereName = formItem.checkedWhere.name;
                let column = formItem.item;
                let comF = column.comF;
                if ([WhereType.In.name, WhereType.NotIn.name].indexOf(cWhereName) >= 0) {
                    // console.log(column);
                    formDom =
                        // <Select mode={'multiple'} value={formItem.checkedWhere.value}
                        //         onChange={(value, option) => this.selectChange(value, option, formItem)}>
                        //     {options}
                        // </Select>
                        <MTreeSelect
                            onChange={(value) => {
                                console.log(value.value);
                                this.selectChange(value.value, value, formItem);
                            }}
                            isInitialize={comF.isInitialize}
                            controller={comF.controller}
                            action={comF.action}
                            text={comF.text}
                            id={comF.id}
                            row={this.row}
                            column={column}
                            multiple={true}
                            onRef={(ref) => {
                                // refFun(column.comName, ref);
                            }}
                        />
                }
            }
            if (formItem.item.dataType === DataType.String) {
                let options = [];
                let cWhereName = formItem.checkedWhere.name;
                if ([WhereType.In.name, WhereType.NotIn.name].indexOf(cWhereName) >= 0) {
                    formDom =
                        <Select allowClear mode="tags" style={{width: '100%'}} placeholder="多选"
                                value={formItem.checkedWhere.value}
                                onChange={(value, option) => this.selectChange(value, option, formItem)}>
                            {options}
                        </Select>
                } else {
                    formDom =
                        <Input value={formItem.checkedWhere.value}
                               onChange={({target: {value}}) => this.inputChange(value, formItem)}/>
                }
            }
            if (formItem.item.dataType === DataType.NUM) {
                formDom =
                    <InputNumber style={{width:'100%'}} value={formItem.checkedWhere.value}
                           onChange={(value) => this.inputChange(value, formItem)}/>
            }
            let span = 12
            if(formItem.item.dataType === DataType.DataTime){
                span = 12
            }
            doms.push(
                <Col className="gutter-row" span={span}>
                    <Form.Item
                        label={formItem.item.name}
                        name={formItem.item.prop}
                    >
                        {/*rules={[{required: true, message: 'Please input your username!'}]}*/}
                        <Row gutter={[0, 0]}>
                            <Col span={9}>
                                <Select mode={''} style={{width: 120}} defaultValue={defaultName}
                                        onChange={this.whereChange}>
                                    {optionsDom}
                                </Select>
                            </Col>
                            <Col span={15}>
                                {formDom}
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            )
        }
        let res =
            <Row gutter={[0, 0]}>
                {doms}
            </Row>
        return res;
    }

    componentDidMount() {
        console.log("组件SearchForm挂载完成");
        this.init();
    }

    onClose() {
        this.props.onClose();
    }

    upData() {
        let upData = {};
        for (let i in this.state.formItems) {
            let formItem = this.state.formItems[i];
            upData[i] = {
                type: formItem.checkedWhere['name'],
                value: formItem.checkedWhere['value'],
            }
        }
        this.props.upData(upData);
    }

    render() {
        let {columns, formData, formItems} = this.state;
        return (
            <div>
                {
                    Object.keys(this.state.formItems).length > 0 &&
                    (
                        <Form
                            {...layout}
                            name="basic"
                            className={'searchfrom'}
                            initialValues={{remember: true}}
                        >
                            {this.getFormDom()}
                            <Form.Item {...tailLayout}>
                                <Button type="primary" onClick={this.upData} htmlType="submit">
                                    提交
                                </Button>
                                <Button htmlType="button" onClick={this.onClose}>
                                    关闭
                                </Button>
                            </Form.Item>
                        </Form>
                    )
                }
            </div>
        )
    }

    getWhereTypes(dataType) {
        switch (dataType) {
            case DataType.Com:
                return [
                    WhereType.EqualTo(),
                    WhereType.NotEqualTo(),
                    WhereType.In(),
                    WhereType.NotIn()
                ];
            case DataType.Request:
                return [
                    WhereType.EqualTo(),
                    WhereType.NotEqualTo(),
                    WhereType.In(),
                    WhereType.NotIn()
                ];
            case DataType.ComSearch:
                return [
                    WhereType.EqualTo(),
                    WhereType.NotEqualTo(),
                    WhereType.In(),
                    WhereType.NotIn()
                ];
            case DataType.Boolean:
                return [
                    WhereType.EqualTo(),
                    WhereType.NotEqualTo(),
                    WhereType.In()
                ];
            case DataType.String:
                return [
                    WhereType.EqualTo(),
                    WhereType.NotEqualTo(),
                    WhereType.In(),
                    WhereType.NotIn(),
                    WhereType.Like(),
                    WhereType.NotLike(),
                ];
            case DataType.NUM:
                return [
                    WhereType.EqualTo(),
                    WhereType.NotEqualTo(),
                    WhereType.GreaterThan(),
                    WhereType.GreaterThanOrEqualTo(),
                    WhereType.LessThan(),
                    WhereType.LessThanOrEqualTo(),
                ];
            case DataType.DataTime:
                return [
                    // WhereType.EqualTo(),
                    // WhereType.NotEqualTo(),
                    WhereType.Between(),
                    WhereType.NotBetween()
                ];
            case DataType.ComGETServer:
                return [
                    // WhereType.EqualTo(),
                    // WhereType.NotEqualTo(),
                    WhereType.In(),
                    WhereType.NotIn()
                ];
            default:
                return [];
        }
    }
}

SearchForm.defaultProps = {
    onClose: (ref) => {
    },
    upData: (ref) => {
    },
    filtration: (row) => {
        return row;
    }
}
SearchForm.propTypes = {
    pojo: PropTypes.instanceOf(MyPojo).isRequired,
    onClose: PropTypes.func,
    upData: PropTypes.func,
    filtration: PropTypes.func,
}
export default SearchForm;

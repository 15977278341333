import React, {useState} from 'react';
import './index.less';
import {Row, Button, Table, Tag, Radio, Modal, Tabs, message} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import {get} from "../../axios/Response";
import MyTable from "../../my_module/table/table";
// import Pojo from "./pojo"
import MyDataGrid from "../../my_module/datagrid/datagrid";
import MyButton from "../../my_module/MyClass/button";
import Nestgrid from "../../my_module/nestgrid/nestgrid";
import Pojos from "../pojos"
import MForm from "../../my_module/mform/mform";

const {TabPane} = Tabs;

//系统设置
class index extends React.Component {
    constructor(props) {
        super(props);
        this.onDGRef = this.onDGRef.bind(this);
        this.getInfo = this.getInfo.bind(this);
        // this.componentDidUpdate = this.componentDidUpdate.bind(this);
        // this.addOpenIntercept = this.addOpenIntercept.bind(this);
        // this.editOpenIntercept = this.editOpenIntercept.bind(this);
        this.formDom = undefined;
        this.state = {
            // addModalVisible: false,
            pojo: Pojos.getPojo('sysconfigure'),//new Pojo(),
            dg: undefined,
            data: undefined,
        }
        console.log(this.pojo);
    }

    exTrBus = [
        // new MyButton(
        //     '审批',
        //     'getItemByID11',
        //     undefined,
        //     (row) => {
        //         console.log(row);
        //         return true;
        //     }
        // )
    ]
    onDGRef = (ref) => {
        this.setState({
            dg: ref,
        }, () => {
            //this.state.dg.refInit();
        })
    }

    getInfo() {
        this.state.pojo.getInfo()
            .then((ref) => {
                console.log(ref);
                this.setState({
                    data: ref.data,
                })
            })
            .catch((ref) => {
                console.log(ref);
            });
    }

    componentDidMount() {
        this.getInfo();
    }

    // addModal = () => {
    //     let model =
    //         <Modal title="Basic Modal" visible={this.state.addModalVisible}
    //                onCancel={() => {
    //                    this.setState({
    //                        addModalVisible:false,
    //                    })
    //                }}>
    //             <p>Some contents...</p>
    //             <p>Some contents...</p>
    //             <p>Some contents...</p>
    //         </Modal>;
    //     return model;
    // }
    // addOpenIntercept = (ref) => {
    //     console.log(ref);
    //     // this.setState({
    //     //     addModalVisible:true,
    //     // })
    //     return false;
    // }
    // editOpenIntercept = (ref) => {
    //     console.log(ref);
    //     return false;
    // }
    render() {
        let form = undefined;
        let {data} = this.state;
        let mainDom = undefined;
        if (data !== undefined) {
            form = (
                <MForm
                    ref={(ref) => {
                        console.log(ref);
                        this.formDom = ref;
                    }}
                    columnNumber={2}
                    columns={this.state.pojo.columns}
                    row={this.state.data}
                    filter={(column) => {
                        console.log(column);
                        let ref = true;
                        if (column.isPk) {
                            ref = false;
                        }
                        return ref;
                    }}
                    submitFunc={(row, exParam) => {
                        console.log(row);
                        this.state.pojo.update(row)
                            .then((ref) => {
                                console.log(ref);
                                message.success(ref.msg);
                                this.getInfo();
                            })
                            .catch((ref) => {
                                console.log(ref);
                            })
                    }}
                />
            )
        }
        ;
        if (form !== undefined) {
            mainDom = (
                <div>
                    <Button type="primary" onClick={() => {
                        this.formDom.submit();
                    }}>提交</Button>
                </div>
            )
        }
        ;
        return (
            <div>
                <div style={{width: '70vw'}}>
                    {mainDom}
                    {form}
                </div>
                {/*{this.addModal()}*/}
                {/*<MyDataGrid*/}
                {/*    // addOpenIntercept={this.addOpenIntercept}*/}
                {/*    // editOpenIntercept={this.editOpenIntercept}*/}
                {/*    onRef={this.onDGRef}*/}
                {/*    pojo={this.state.pojo}*/}
                {/*    exTrBus={this.exTrBus}/>*/}
            </div>
        );
    }
}

export default index;

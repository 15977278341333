import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";

class Pojo extends MyPojo {
    getproductsum = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.controller + '/getproductsum', params)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }

    constructor() {
        //收发货量趋势图
        super('getproductsum');
        this.isToExcel = false
        this.controller = 'rpbase';
        this.delRowName = 'fgoodsnumber';
        this.cName = '产能趋势图';
        this.pojoItem = new initItem();
        this.orderRowName = ''
        this.orderType = 'ASC'
        this.showPK = true;
        this.prefixName = ''
        this.getListActionName = 'getproductsum'
        this.addPojoItemInitItems([
            {
                comName: 'fgoodsnumber',
                name: '存货名称',
                width: 350,
                showLength: 30,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fminventory',
                    action: 'getList',
                    id: 'fnumber',
                    text: '{fcaption}',
                }),
                exComParent: 'fgoodscaption',
            },
            {
                comName: 'fregistertime',
                name: '日期',
                show: false,
                dataType: DataType.DataTime,
                showTime: false,
            },
        ]);
    }
}

export default Pojo;

import React from 'react';
import {Col, Dropdown, Menu, Row} from 'antd';
import './Admin.less';
import NavLeft from "../Navleft/Navleft";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {getSearchList} from '../../utils/utils'
import {MyContext} from "../../my_module/MyClass/TestContext";
import {BrowserRouter} from "react-router-dom";
//使用 useDidCache 和 useDidRecover 来对应 被缓存 和 被恢复 两种生命周期
import {getCachingKeys, dropByCacheKey, useDidCache, useDidRecover} from "react-router-cache-route";
import {Tabs} from 'antd';

import {withRouter} from 'react-router-dom';

const {TabPane} = Tabs;
const data = [];

class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.addTP = this.addTP.bind(this);
        this.setTp = this.setTp.bind(this);
        this.tpOnEdit = this.tpOnEdit.bind(this);
        this.setCollapse = this.setCollapse.bind(this);
        // this.getMenu = this.getMenu.bind(this);
        this.navleftRef = undefined;
        this.headerRef = undefined;
        this.state = {
            tabPanes: [],
            tabPaneKeys: [],
            tabKey: '',
            collapse: false,
        };
    }

    getMenu = (value) => {
        console.log(value);
        return (
            <Menu>
                <Menu.Item onClick={(e) => {
                    console.log(e.domEvent);
                    e.domEvent.stopPropagation();
                    console.log(value);
                    let index = this.state.tabPaneKeys.indexOf(value);
                    // let tabPaneKeys = [value];
                    // let tabPanes = [this.state.tabPanes[index]];
                    let delKeys = [];
                    for (let i = 0; i !== this.state.tabPaneKeys.length; i++) {
                        let key = this.state.tabPaneKeys[i];
                        if (key !== value) {
                            delKeys.push(key);
                        }
                        // this.setState({
                        //     tabPanes: tabPanes,
                        //     tabPaneKeys: tabPaneKeys,
                        // })
                    }
                    this.deltap(delKeys);
                }} key="1">关闭其他标签</Menu.Item>
                <Menu.Item onClick={(e) => {
                    console.log(e.domEvent);
                    e.domEvent.stopPropagation();
                    console.log(value);
                    let index = this.state.tabPaneKeys.indexOf(value);
                    let tabPaneKeys = [];
                    let tabPanes = [];
                    let delKeys = [];
                    for (let i = 0; i !== index; i++) {
                        let key = this.state.tabPaneKeys[i];
                        if (key !== value) {
                            // this.deltap(key);
                            delKeys.push(key);
                        }
                    }
                    this.deltap(delKeys);
                    // for(let i = index; i !== this.state.tabPaneKeys.length; i++){
                    //     tabPaneKeys.push(this.state.tabPaneKeys[i]);
                    //     console.log(this.state.tabPanes[i]);
                    //     console.log(i);
                    //     tabPanes.push(this.state.tabPanes[i]);
                    // }
                    // this.setState({
                    //     tabPanes: tabPanes,
                    //     tabPaneKeys: tabPaneKeys,
                    // },() => {
                    //     console.log(this.state.tabPanes);
                    // })
                }} key="2">关闭右侧标签</Menu.Item>
            </Menu>
        );
    }
    setCollapse = () => {
        const collapse = this.state.collapse;
        this.setState({
            collapse: !collapse,
        })
    }
    addTP = ({page = '', name = '',}) => {
        // console.log(page);
        this.navleftRef.setSelectKeys(page);
        this.headerRef.setPName(name);
        let tabPanes = this.state.tabPanes;
        let tabPaneKeys = this.state.tabPaneKeys;
        if (tabPaneKeys.indexOf(page) < 0) {
            // let cacheKeys = getCachingKeys();
            // if (cacheKeys.indexOf(page) >= 0) {
            //     dropByCacheKey(page);
            // }
            tabPaneKeys.push(page);
            tabPanes.push((
                <TabPane tab={(
                    <Dropdown overlay={this.getMenu(page)} trigger={["contextMenu"]}>
                        <span style={{userSelect: "none"}}>{name}</span>
                    </Dropdown>
                )} key={page}>
                </TabPane>
            ));
        }
        this.setState({
            tabPanes: tabPanes,
            tabPaneKeys: tabPaneKeys,
            tabKey: page,
        })
    }
    setTp = (activeKey) => {
        // console.log(activeKey);
        this.props.history.push({pathname: '/' + SystemInit.homeRoot + '/main/' + activeKey});
        this.setState({
            tabKey: activeKey,
        }, () => {
            // this.props.history.push('/' + SystemInit.homeRoot + '/main');
        });
    }
    componentDidUpdate = () => {
        const siLogin = localStorage.getItem('siLogin_');
        if (siLogin === '1') {
            let cacheKeys = getCachingKeys();
            for (let i = 0; i !== cacheKeys.length; i++) {
                dropByCacheKey(cacheKeys[i]);
            }
            localStorage.setItem('siLogin_', '');
            console.log(getCachingKeys());
            this.setState({
                tabPanes: [],
                tabPaneKeys: [],
                tabKey: '',
            })
        }
        console.log(' 触发了钩子 ');
        console.log(this.props.location);
        // console.log(this.navleftRef);//.current
        // this.navleftRef.current.setPath(this.props.location.pathname);
        if (this.props.location.state !== undefined) {
            let state = this.props.location.state;
            if (state['dKey'] !== undefined && state['goKey'] !== undefined) {
                // console.log(state['dKey']);
                let cacheKeys = getCachingKeys();
                console.log(state['dKey']);
                console.log(cacheKeys);
                for (let i = 0; i !== state['dKey'].length; i++) {
                    let dKey = state['dKey'][i];
                    if (cacheKeys.indexOf(dKey) >= 0) {
                        dropByCacheKey(dKey);
                    }
                }
                this.props.history.push({pathname: '/' + SystemInit.homeRoot + '/main/' + state['goKey']});
            }
        }
    }
    deltap = (targetKeys) => {
        let cacheKeys = getCachingKeys();
        // console.log(cacheKeys);
        // console.log(targetKeys);
        let tabPaneKeys = this.state.tabPaneKeys;
        let tabPanes = this.state.tabPanes;
        for (let i = 0; i !== targetKeys.length; i++) {
            let targetKey = targetKeys[i];
            console.log(targetKeys);
            console.log(cacheKeys);
            if (cacheKeys.indexOf(targetKey) >= 0) {
                dropByCacheKey(targetKey);
            }
            let delKeyIndex = tabPaneKeys.indexOf(targetKey);
            tabPaneKeys.splice(delKeyIndex, 1);
            tabPanes.splice(delKeyIndex, 1);
        }
        // console.log(tabPaneKeys);
        if (tabPaneKeys.length === 0) {
            this.headerRef.setPName("首页");
        }
        this.setState({
            tabPaneKeys: tabPaneKeys,
            tabPanes: tabPanes,
        }, () => {
            if (tabPaneKeys.length > 0) {
                // this.setTp(tabPaneKeys[0]);
                this.props.history.push({
                    pathname: '/' + SystemInit.homeRoot + '/main/', state: {
                        dKey: targetKeys,
                        goKey: tabPaneKeys[0],
                    }
                });
                this.setState({
                    tabKey: tabPaneKeys[0],
                }, () => {
                    // this.props.history.push('/' + SystemInit.homeRoot + '/main');
                });
            } else {
                this.props.history.push({
                    pathname: '/' + SystemInit.homeRoot + '/main/', state: {
                        dKey: targetKeys,
                        goKey: '',
                    }
                });
            }
        })
    }
    tpOnEdit = (targetKey, action) => {
        if (action === 'remove') {
            // console.log(getCachingKeys());
            this.deltap([targetKey]);
        }
    }
    navleftRefaa = (ref) => {
        this.navleftRef = ref;
    }
    headerRefaa = (ref) => {
        this.headerRef = ref;
    }

    render() {
        let {collapse} = this.state;
        let admin_collapse = '';
        let cwidth = {
            width: 'calc(100vw - 210px)',
        };
        if (collapse) {
            admin_collapse = 'admin-collapse';
            cwidth = {
                width: '100vw',
            };
        }
        return (
            <div>
                <div style={{display: 'flex', position: 'relative'}} className={admin_collapse}>
                    <div className={'navleft'}
                         style={{overflow: 'hidden', width: '210px', height: 'calc(100vh - 40px)'}}>
                        <NavLeft onRef={this.navleftRefaa}/>
                    </div>
                    <div style={cwidth}>
                        <div style={{height: '50px'}}>
                            <Header setCollapse={this.setCollapse} onRef={this.headerRefaa}/>
                        </div>
                        <div style={{height: '50px'}}>
                            <Tabs
                                onEdit={this.tpOnEdit}
                                onChange={this.setTp}
                                activeKey={this.state.tabKey}
                                type="editable-card"
                                hideAdd={true}
                            >
                                {this.state.tabPanes}
                            </Tabs>
                        </div>
                        <MyContext.Provider value={{
                            setPage: ({
                                          page = '',
                                          name = '',
                                      } = {}) => {
                                this.addTP({
                                    page: page,
                                    name: name,
                                });
                            }
                        }}>
                            <div style={{
                                minHeight: 'calc(100vh - 140px)',
                                maxHeight: 'calc(100vh - 140px)',
                                overflowX: 'hidden'
                            }}>
                                <div id="content" style={cwidth}>
                                    {this.props.children}
                                </div>
                            </div>
                        </MyContext.Provider>
                    </div>
                </div>
                <div style={{height: '40px', backgroundColor: '#dddddd'}}>
                    <div style={{padding: '10px 0 0 20px'}}>
                        <span style={{
                            fontWeight: '1000',
                        }}>中山银利智能科技股份有限公司</span>
                        <span style={{
                            color: '#326193',
                            paddingLeft: '20px',
                        }}>
                            Copyright 1998 - 2022 YINLI INTELLIGENY. All Rights Reserved    粤ICP备20050541号-4
                        </span>
                    </div>

                </div>
            </div>
            // <Row className="container">
            //
            //     <Col span={21} className="Main">
            //         <Row style={{height: '50px'}}>
            //
            //         </Row>
            //         <Tabs
            //             onEdit={this.tpOnEdit}
            //             onChange={this.setTp}
            //             activeKey={this.state.tabKey}
            //             type="editable-card"
            //             hideAdd={true}
            //         >
            //             {this.state.tabPanes}
            //         </Tabs>
            //         <MyContext.Provider value={{
            //             setPage: ({
            //                           page = '',
            //                           name = '',
            //                       } = {}) => {
            //                 // console.log(page);
            //                 // console.log(name);
            //                 this.addTP({
            //                     page: page,
            //                     name: name,
            //                 });
            //                 // console.log(getCachingKeys());
            //             }
            //         }}>
            //             <Row style={{height: '90vh'}} className="content" justify="center">
            //                 <div id="content" style={{width: 'calc(100vw - 13.66666667vw)'}}>
            //                     {this.props.children}
            //                 </div>
            //             </Row>
            //         </MyContext.Provider>
            //     </Col>
            // </Row>
            // <Row className="container">
            //     <Col span={3} className="nav-left">
            //         <NavLeft onRef={this.navleftRefaa}/>
            //     </Col>
            //     <Col span={21} className="Main">
            //         <Row style={{height: '50px'}}>
            //             <Header onRef={this.headerRefaa}/>
            //         </Row>
            //         <Tabs
            //             onEdit={this.tpOnEdit}
            //             onChange={this.setTp}
            //             activeKey={this.state.tabKey}
            //             type="editable-card"
            //             hideAdd={true}
            //         >
            //             {this.state.tabPanes}
            //         </Tabs>
            //         <MyContext.Provider value={{
            //             setPage: ({
            //                           page = '',
            //                           name = '',
            //                       } = {}) => {
            //                 // console.log(page);
            //                 // console.log(name);
            //                 this.addTP({
            //                     page: page,
            //                     name: name,
            //                 });
            //                 // console.log(getCachingKeys());
            //             }
            //         }}>
            //             <Row style={{height: '90vh'}} className="content" justify="center">
            //                 <div id="content" style={{width: 'calc(100vw - 13.66666667vw)'}}>
            //                     {this.props.children}
            //                 </div>
            //             </Row>
            //         </MyContext.Provider>
            //     </Col>
            // </Row>
        );
    }
}

export default withRouter(Admin);

import React from 'react'
import {Button, Table, Tag, Image, Popover, Typography, Form, Pagination, Spin} from 'antd'
import {
    deepmerge,
    extend,
    getLocalTime,
    getPids,
    getSearchItem,
    getValueByProp, eObjToStr,
    newSearch,
    setRow,
    setValueByProp, getDataReToText, eObjToFormData, objArrToStr
} from '../../utils/utils'
// import {SystemInit} from '../../../public/system_init';
import PropTypes from 'prop-types'

import {DataType, TableType, WhereType} from '../../api/mainPojo'
import MyPojo from "../MyClass/pojo"
import {postFormObj, postObj} from "../../axios/Response"
import MyDataGrid from "../datagrid/datagrid"
import TableUtil from "../Common/TableUtil";
import FormUtil from "../Common/FormUtil";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import ReloadOutlined from "@ant-design/icons/lib/icons/ReloadOutlined";
import NullDom from "../nullDom/nullDom";
import ReactDOM from "react-dom";


const tableUtil = new TableUtil();

class MyTable extends React.Component {
    constructor(props) {
        super(props);
        console.log('MyTable constructor')
        this.init = this.init.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.tableChange = this.tableChange.bind(this);
        this.getExpandable = this.getExpandable.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.expandedRowRender = this.expandedRowRender.bind(this);
        this.rowOnClick = this.rowOnClick.bind(this);
        this.onExpand = this.onExpand.bind(this);
        this.getList = this.getList.bind(this);
        this.setSelectKeys = this.setSelectKeys.bind(this);
        //行编辑
        this.edit = this.edit.bind(this);
        this.onRow = this.onRow.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.editTrAdd = this.editTrAdd.bind(this);
        this.delTr = this.delTr.bind(this);
        this.onValuesChange = this.onValuesChange.bind(this);
        this.paginationChange = this.paginationChange.bind(this);
        this.setFormData = this.setFormData.bind(this);
        this.propsWhereQuery = undefined;
        this.data = [];
        this.selectKeys = [];
        this.selectRow = undefined;
        this.selectKey = '';
        this.selectIndex = -1;
        //行编辑
        //console.log(props);
        //console.log(props.pojo);
        this.formRef = undefined;
        this.editFromItemMap = {};
        this.editShouldUpdate = true;
        this.tableClassName = this.props.isMinHeight ? 'awe' : '';
        this.querywhere = {};
        this.searchQuerywheres = [];
        this.state = {
            loading:false,
            scrollY: 0,
            // formData: undefined,
            //行编辑
            editingIndex: -1,
            editingKey: '',
            editingRow: undefined,
            clientData: {},
            listParams: {},
            initData: [],
            data: [],
            page: props.page,
            rows: props.rows,
            columns: [],
            clientItems: [],
            pagination: this.props.isPage ? {
                current: this.props.current === 0 ? 1 : this.props.current,
                pageSize: 10,
                total: 0,
                hideOnSinglePage: true,
                position: ['bottomLeft'],
                // itemRender: (page, type, originalElement) => {
                //     // console.log(page);
                //     // console.log(type);
                //     // console.log(originalElement);
                //     if (type === 'prev') {
                //         return <div>
                //             <a className="ant-pagination-item itxst">上一页</a>
                //         </div>;
                //     } else if (type === 'next') {
                //         return <div>
                //             <a className="ant-pagination-item itxst">下一页</a>
                //             <ReloadOutlined onClick={() => {
                //                 this.getList();
                //             }}/>
                //         </div>;
                //     }
                //     return originalElement;
                // },
                onChange: this.paginationChange,
            } : false,
            rowSelection: {
                hideSelectAll: true,
                //树形子项受控
                checkStrictly: false,
                renderCell: (checked, record, index, originNode) => {
                    // console.log(checked);
                    // console.log(originNode);
                    if (this.props.rowSelectionType === 'checkbox') {
                        return originNode;
                    }
                    // console.log(index);
                    // console.log(originNode);
                },
                type: this.props.rowSelectionType,
                onChange: (selectedRowKeys, selectedRows) => {
                    this.props.rowSelectionOnChange(selectedRowKeys, selectedRows);
                    this.onSelectChange(selectedRowKeys, selectedRows);
                },
                onSelect: (record, selected, selectedRows, nativeEvent) => {
                    console.log(record);
                    console.log(nativeEvent);
                },
                selectedRowKeys: this.props.selectValues,
                getCheckboxProps: this.props.getCheckboxProps,
            },
            pkProp: '',
            rowKeyFun: this.rowKeyFun,
            expandable: {},
        }
    }

    setSearchQuerywheres(searchQuerywheres) {
        this.searchQuerywheres = searchQuerywheres;
    }

    setPropsWhereQuery(wherequery) {
        this.propsWhereQuery = wherequery;
    }

    paginationChange = (page, pageSize) => {
        if (this.state.pagination) {
            let pagination = this.state.pagination;
            pagination.current = page;
            this.setState({
                pagination: pagination,
            })
        }
    }
    onValuesChange = (changedValues, allValues) => {
        // console.log(this.state.editingRow);
        this.editShouldUpdate = true;
        // console.log(allValues);
        eObjToStr(this.state.editingRow);
        eObjToStr(allValues);
        objArrToStr(allValues);
        let editRow = deepmerge(this.state.editingRow, allValues);
        // data[this.state.editingIndex] = editRow;
        this.setState({
            editingRow: editRow,
            // data: data,
        }, () => {
            // console.log(this.state.editingRow);
            // console.log(this.state.data);
        })
    }
    //新建条目
    editTrAdd = () => {
        let row = {};
        setRow(this.props.pojo.columns, row);
        // row = setValueByProp(row, this.state.pkProp, 'addRow');
        row['isAdd'] = true;
        setValueByProp(row, this.props.pojo.pkColumn.prop,
            Math.random()                        // 生成随机数字, eg: 0.123456
                .toString(36)           // 转化成36进制 : "0.4fzyo82mvyr"
                .slice(-8)// 截取最后八位 : "yo82mvyr"
        );
        // row['key'] = Math.random()                        // 生成随机数字, eg: 0.123456
        //     .toString(36)           // 转化成36进制 : "0.4fzyo82mvyr"
        //     .slice(-8);// 截取最后八位 : "yo82mvyr"
        this.setEditData();
        this.setState({
            editingRow: row,
            editingIndex: 0,
            data: [
                row,
                ...this.state.data,
            ]
        }, () => {
            if (this.formRef !== undefined) {
                this.formRef.setFieldsValue(this.state.editingRow);
            }
        })
    }
    //删除条目
    delTr = (index) => {
        let data = [];
        for (let i = 0; i !== this.state.data.length; i++) {
            data.push(this.state.data[i]);
        }
        data.splice(index, 1);
        this.setState({
            data: data,
        })
    }
    // formRef
    handleKeyPress = (e) => {
        if (e.keyCode === 27) {
            // if (this.state.editingRow !== undefined) {
            //     this.state.editingRow['isEdit'] = false;
            // }
            //必须建立新对象 才能重新渲染
            let data = [];
            for (let i = 0; i !== this.state.data.length; i++) {
                data.push(this.state.data[i]);
            }
            this.setEditData();
            // data[this.state.editingIndex] =
            //     objArrToStr(this.state.editingRow);
            // console.log(data[this.state.editingIndex]);
            // this.props.onEditingRow(this.state.editingRow, this.state.data);
            this.setState({
                editingKey: '',
                editingRow: undefined,
                editingIndex: -1,
                data: data,
            }, () => {
                if (this.formRef !== undefined) {
                    this.formRef.setFieldsValue(undefined);
                }
            })
        }
    }
    setEditData = () => {
        let editingRow = this.state.editingRow;
        if (editingRow !== undefined && this.editFromItemMap !== undefined) {
            for (let key in this.editFromItemMap) {
                const editFromItem = this.editFromItemMap[key];
                if (editFromItem.props !== undefined && editFromItem.props.value !== undefined) {
                    const value = editFromItem.props.value;
                    if (editFromItem.props.value._isTreeSelectRef) {
                        const column = editFromItem.props.column;
                        setValueByProp(editingRow, column.prop, value.key);
                        setValueByProp(editingRow, column.exComParent, value.value);
                    } else if (editFromItem.props.column !== undefined) {
                        const column = editFromItem.props.column;
                        if (column.dataType === DataType.ComGETServer) {
                            const stValue = editFromItem.getValue(value);
                            if (stValue !== undefined) {
                                setValueByProp(editingRow, column.prop, stValue.key);
                                setValueByProp(editingRow, column.exComParent, stValue.value);
                            }
                        }
                    }
                }
            }
        }
    }
    getPIds = () => {
        // console.log(this.selectKeys);
        if (this.props.pojo.parentColumn === undefined) {
            return [];
        }
        if (this.props.pojo.tableType === TableType.TG) {
            return getPids({
                tree: this.data,
                idProp: this.props.pojo.pkColumn.prop,
                pidProp: this.props.pojo.parentColumn.prop,
                ids: this.selectKeys
            })
        } else {
            return [];
        }
    }
    onRow = (record, index) => {
        return {
            onClick: (e) => {
                this.rowOnClick(e, record, index);
            },
            onDoubleClick: (e) => {
                this.setEditData();
                this.editShouldUpdate = true;
                this.editFromItemMap = {};
                if (this.state.editingIndex !== index) {
                    objArrToStr(this.state.editingRow);
                    if (this.props.isOpenEdit) {
                        this.setState({
                            editingRow: record,
                            editingKey: this.rowKeyFun(record),
                            editingIndex: index,
                        }, () => {
                            if (this.formRef !== undefined) {
                                this.formRef.setFieldsValue(this.state.editingRow);
                            }
                        })
                    }
                }
            },
        }
    }
    getFormData = () => {
        if (this.formRef !== undefined) {
            return this.formRef.getFieldsValue();
        } else {
            return null;
        }
    }

    setFormData(formData) {
        this.setState({
            formData: formData,
        }, () => {
            this.formRef.setFieldsValue(formData);
        })

    }

    // easyTableRow = ({...props}) => {
    //     console.log(props);
    //     return (
    //         <tr
    //             className={props.className}
    //             style={props.style}
    //         >{props.children}</tr>
    //     );
    // }
    //重写 td dom
    easyTableCell = ({...props}) => {
        let colspan = props.colSpan === undefined ? 1 : props.colSpan;
        if (props.record !== undefined && props.record.isAdd !== undefined) {
            if (this.props.pojo.pkColumn.comName === props.column.comName) {
                return (
                    <td
                        className={props.className}
                        style={props.style}
                        colSpan={colspan}
                    />
                )
            }
        }
        // console.log(props.record);
        // console.log(colspan);
        if (props.record !== undefined) {
            if (props.index === this.state.editingIndex) {
                // console.log(props);
                // console.log(props.record['isEdit']);
                let formUtil = new FormUtil({
                    row: props.record,//this.state.formData === undefined ? props.record : this.state.formData,
                });
                let ref = undefined;
                if (props.column.isUpdate) {
                    ref = (
                        <td
                            className={props.className}
                            style={props.style}
                            colspan={colspan}
                        >
                            {formUtil.getFormItem({
                                refFun: (comName, ref) => {
                                    // console.log(props);
                                    // console.log('refFun');
                                    // console.log(comName);
                                    this.editFromItemMap[comName] = ref;
                                    if (props.isEnd) {
                                        // console.log(this.editFromItemMap);
                                        // console.log(this.formRef);
                                        // this.props.editingTreeSelectRefFun(comName, this.editFromItemMap);
                                    }
                                },
                                shouldUpdate: ({prevValues = undefined, curValues = undefined}) => {
                                    if (this.editShouldUpdate) {
                                        this.editShouldUpdate = false;
                                        // console.log(prevValues);
                                        let formData = this.formRef.getFieldsValue();
                                        this.props.editShouldUpdate(prevValues, curValues, formData)
                                            .then((ref) => {
                                                // this.setState({
                                                //     editingRow: formData,
                                                // });
                                                this.formRef.setFieldsValue(formData);
                                            });
                                        // console.log(this.formRef);
                                        // console.log(this.formRef.getFieldsValue());
                                        // console.log(prevValues);
                                        // console.log(curValues);
                                    }
                                    // console.log(prevValues);
                                    // console.log(curValues);
                                },
                                column: props.column,
                                showLabel: false,
                                onChange: (value, ...data) => {
                                    // this.editShouldUpdate = true;
                                    // let formData = this.formRef.getFieldsValue();
                                    // this.props.editingOnChange(value, props.column, formData, data);
                                    // this.setState({
                                    //     editingRow: formData,
                                    // })
                                    // // console.log(props.record);
                                    // // console.log(formData);
                                    // this.formRef.setFieldsValue(formData);
                                }
                            })}
                        </td>
                    )
                } else {
                    ref = <td>{props.children}</td>;
                }
                return ref;
                // console.log(props.column);
            }
        }
        return (
            <td
                className={props.className}
                style={props.style}
                colspan={colspan}
            >{props.children}</td>
        );
    };
    rowOnClick = (e, record, selectIndex) => {
        // console.log(selectIndex);
        if (this.props.getCheckboxProps(record) === undefined || !this.props.getCheckboxProps(record).disabled) {
            let value = getValueByProp(record, this.state.pkProp);
            this.selectRow = record;
            this.selectKey = value;
            this.selectIndex = selectIndex;
            let index = this.state.rowSelection.selectedRowKeys.indexOf(value);
            let selectKeyIndex = this.selectKeys.indexOf(value);
            if (!(selectKeyIndex >= 0)) {
                this.selectKeys.push(value);
            }
            if (!(index >= 0)) {
                //不能理解 但只能这样写
                if (this.props.rowSelectionType === 'radio') {
                    this.state.rowSelection.selectedRowKeys = [
                        value,
                    ];
                } else {
                    this.state.rowSelection.selectedRowKeys = [
                        value,
                        ...this.state.rowSelection.selectedRowKeys
                    ];
                }
                // this.state.rowSelection.selectedRowKeys.push();
                this.setState({
                    rowSelection: this.state.rowSelection,
                });
            }
            this.props.onRowClick(record);
        }
    }
    setSelectKeys = (keys) => {
        this.setState({
            rowSelection: {
                ...this.state.rowSelection,
                selectedRowKeys: keys
            }
        })
    }
    rowKeyFun = (record) => {
        //console.log(record);
        //console.log(this.state.pkProp);
        if (this.props.exPk === '') {
            return getValueByProp(record, this.state.pkProp);
        } else {
            return getValueByProp(record, this.props.exPk);
        }
    }

    init = () => {
        this.setState({
            initData: this.props.pojo.columns,
            clientItems: this.props.pojo.clientItems,
            pkProp: this.props.pkProp !== '' ? this.props.pojo.columnsMap[this.props.pkProp].prop :
                (this.props.pojo.pkColumn === undefined ? '' : this.props.pojo.pkColumn.prop),
        }, () => {
            this.getColumns();
            this.getExpandable();
        })

    }

    getList(params = {}, pagination = {}) {
        // if(!(params instanceof Array) && params !== undefined){
        //     params = [params];
        // }
        // console.log('getList');
        this.setState({
            loading:true,
        })
        console.log(params);
        console.log(pagination);
        console.log(this.state.pagination);

        let current = 0;
        if (pagination['current'] !== undefined) {
            current = pagination['current'];
        } else {
            current = this.state.pagination.current;
        }
        this.editShouldUpdate = true;
        let pageItem = {};
        if (this.props.pojo.tableType === TableType.GD) {
            if (this.props.isPage) {
                pageItem = {
                    rows: this.state.pagination.pageSize,
                    page: current,
                }
            }
        }
        // console.log(this.state.pagination.current);
        this.props.setCurrentFun(this.state.pagination.current);
        //console.log(this.state.pagination);
        this.state.rowSelection.selectedRowKeys = [];
        // console.log(this.state.listParams);
        // let listParams = this.state.listParams;
        // if (listParams['querywhere'] !== undefined) {
        //     listParams['querywhere'] = [];
        // }
        // console.log(this.querywhere);
        // console.log(params);
        // console.log(this.state.listParams);

        // if(this.querywhere['querywhere'] !== undefined){
        //
        // }
        this.querywhere['querywhere'] = [];
        // console.log(this.querywhere);
        if (this.propsWhereQuery !== undefined && this.propsWhereQuery.length > 0) {
            this.querywhere['querywhere'] = [
                ...this.propsWhereQuery,
            ];
        }
        // console.log(params);
        if (params !== undefined && params['querywhere'] !== undefined) {
            this.querywhere['querywhere'] = [
                ...params['querywhere'],
                ...this.querywhere['querywhere'],
            ];
        }
        this.querywhere['querywhere'] = [
            ...this.querywhere['querywhere'],
            ...this.searchQuerywheres,
        ]
        // console.log(this.propsWhereQuery);
        // console.log(this.querywhere);
        // this.querywhere = {
        //     ...this.querywhere,
        //     ...params,
        // };
        // if ((this.querywhere['querywhere'] !== undefined && this.querywhere['querywhere'] !== '') &&
        //     (params['querywhere'] !== undefined && params['querywhere'] !== '')) {
        //     let l1 = [];
        //     try {
        //         l1 = JSON.parse(this.querywhere['querywhere']);
        //     } catch (e) {
        //         l1 = this.querywhere['querywhere'];
        //     }
        //     let l1n = [];
        //     for(let i in l1){
        //         if(l1[i] instanceof Array){
        //             // l1[i] = l1[i][0];
        //             for(let j in l1[i]){
        //                 l1n.push(l1[i][j]);
        //             }
        //         }
        //     }
        //     if(l1n.length > 0){
        //         l1 = l1n;
        //     }
        //     console.log(l1);
        //     let l2 = [];
        //     try {
        //         l2 = JSON.parse(params['querywhere']);
        //     } catch (e) {
        //         l2 = params['querywhere'];
        //     }
        //     let l2n = [];
        //     for(let i in l2){
        //         if(l2[i] instanceof Array){
        //             // l2[i] = l2[i][0];
        //             for(let j in l2[i]){
        //                 l2n.push(l2[i][j]);
        //             }
        //         }
        //     }
        //     if(l2n.length > 0){
        //         l2 = l2n;
        //     }
        //     console.log(l2);
        //
        //     for (let i in l2) {
        //         let isIn = false;
        //         for (let j in l1) {
        //             if (l1[j]['comname'] === l2[i]['comname']) {
        //                 isIn = true;
        //             }
        //         }
        //         if (!isIn) {
        //             l1.push(l2[i]);
        //         }
        //     }
        //     console.log(l1);
        //     this.querywhere['querywhere'] = JSON.stringify(l1);
        //     if(l1.length > 0){
        //         pageItem['page'] = 1;
        //     }
        // }
        // console.log(this.querywhere);
        this.querywhere['querywhere'] = JSON.stringify(this.querywhere['querywhere']);
        if (!(params instanceof Array) && params !== undefined) {
            this.querywhere = {
                ...this.querywhere,
                ...params,
            }
        }
        this.setState({
            listParams: {
                // ...listParams,
                ...this.querywhere,
                ...pageItem,
            },
            pagination: this.props.isPage ? {
                ...this.state.pagination,
                ...pagination,
            } : false,
            rowSelection: {
                ...this.state.rowSelection,
                // ...{
                //     selectedRowKeys: [],
                // }
            }
        }, () => {
            let pParams = {};
            if (this.props.parentData !== undefined && this.props.clientItem !== undefined) {
                let sArr = [
                    getSearchItem(
                        this.props.parentData[this.props.clientItem['pKey']],
                        this.props.clientItem['cKey'],
                        WhereType.EqualTo
                    )
                ];
                pParams = newSearch(sArr);
            }
            pParams = {
                ...pParams,
                ...this.state.listParams,
            }
            let tParams = JSON.parse(JSON.stringify(pParams))
            tParams = this.props.testGetListParams(tParams)
            this.props.pojo[this.props.getListName](tParams)
                .then((res) => {
                    this.setState({
                        loading:false,
                    })
                    let rows = res.data;
                    if (res.data === undefined) {
                        rows = res;
                        res['data'] = {};
                    } else {
                        rows = res.data.rows;
                    }
                    if (rows === undefined) {
                        rows = res.data;
                    }
                    if (!(rows instanceof Array)) {
                        rows = [rows];
                    }
                    for (let k = 0; k !== rows.length; k++) {
                        rows[k]['key'] = this.rowKeyFun(rows[k]);
                    }
                    this.data = rows;
                    // console.log(this.data);
                    //console.log(res);
                    this.setState({
                        data: rows,
                        pagination: {
                            ...this.state.pagination,
                            ...{
                                total: res.data.total === undefined ? rows.length : res.data.total
                            }
                        }
                    });
                    //钩子
                    this.props.onListHook(rows);
                    // console.log(rows);
                    //console.log(this.state);
                })
                .catch((res) => {
                    this.setState({
                        loading:false,
                    })
                    console.log(res);
                });
        })
    }

    tableChange(...e) {
        // console.log(e);
        if (e[3].action === 'paginate') {
            this.setState({
                pagination: {
                    ...this.state.pagination,
                    ...e[0],
                }
            }, () => {
                this.getList();
            })
        }
    }

    edit = (record) => {
        this.setState({
            editingKey: getValueByProp(record, 'key'),//this.props.pojo.pkColumn.prop
        }, () => {
            console.log(this.state);
        })
    }

    getColumns = () => {
        {
            console.log('getColumns');
            let useColumns = [];
            let columns = [];
            let isUpdateEnd = 0;
            for (let i in this.state.initData) {
                let item = this.state.initData[i];
                if (item.show && item.tdShow) {
                    //item.prop = 'info.' + item.prop;
                    //console.log(item);
                    if ((!item.isPk || this.props.pojo.showPK) && (!this.props.isOpenEdit || (this.props.isOpenEdit && item.isInsert && item.isUpdate))) {
                        if (this.props.showRowNames.length > 0 && !this.props.showRowNames.includes(item.comName)) {
                            continue;
                        }
                        if(!this.props.testShowCom(item)){
                            continue
                        }
                        //编辑column
                        // columns.push(await tableUtil.getColumnRender(item, this.props.pojo, this.rowKeyFun));
                        useColumns.push({
                            item: item,
                            pojo: this.props.pojo,
                            rowKeyFun: this.rowKeyFun,
                            isEnd: false,
                            index: i,
                        })
                        if (item.isUpdate) {
                            isUpdateEnd = i;
                        }
                    }
                }
            }
            let column = undefined;
            if (this.props.isSetTrBus) {
                column = {
                    title: '操作',
                    key: 'rowTrBus',
                    fixed: 'right',
                    render: (text, record, index) => {
                        // console.log(record);
                        let trBus = this.props.setTrBus(
                            {
                                record: record,
                            }
                        );
                        return (
                            trBus
                        )
                    },
                    editT: false,
                    width: this.props.rowTrBusWidth,
                }
                // columns.push(column);
            }
            for (let i in useColumns) {
                let userColumn = useColumns[i];
                if (userColumn.index === isUpdateEnd) {
                    columns.push(tableUtil.getColumnRender(userColumn.item, userColumn.pojo, userColumn.rowKeyFun, true));
                } else {
                    columns.push(tableUtil.getColumnRender(userColumn.item, userColumn.pojo, userColumn.rowKeyFun));
                }
            }
            if (column !== undefined) {
                columns.push(column);
            }
            // console.log(columns);
            // console.log(columns);
            this.setState(Object.assign({}, this.state, {
                columns: columns
            }));
            // return columns;
        }
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    expandedRowRender = (...e) => {
        let record = e[0];
        if (record.description !== undefined && record.description !== '') {
            return record.description;
        } else if (this.state.clientItems.length > 0) {
            let key = this.rowKeyFun(record);
            // if (e[3]) {
            //
            // }
            return this.state.clientData[key];
        } else {
        }
        // return <Table dataSource={dataSource} columns={columns} />;
    }

    rowExpandable(record) {
        if ((record.description !== undefined && record.description !== '')) {
            return true;
        } else if (this.state.clientItems.length > 0) {
            return this.props.rowExpandable(record);
        } else {
            return false
        }
    }

    getExpandable() {
        this.setState({
            clientItems: this.props.pojo.clientItems,
        }, () => {
            if (this.props.ifExpandable || this.state.clientItems.length > 0) {
                //console.log(this.props.ifExpandable);
                this.state.expandable = {
                    expandedRowRender: (...e) => this.expandedRowRender(...e),
                    rowExpandable: (record) => this.rowExpandable(record),
                }
                this.setState({
                    expandable: this.state.expandable
                }, () => {
                    console.log(this.state.expandable);
                });
            }
        });
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({selectedRowKeys});
        this.selectKeys = selectedRowKeys;
        this.state.rowSelection.selectedRowKeys = selectedRowKeys;
        this.setState({
            rowSelection: this.state.rowSelection,
        })
    };
    onCIRef = (ref) => {
        // console.log('onciref');
        ref.refInit();
    }
    //展开事件
    onExpand = async (expanded, record) => {
        let key = this.rowKeyFun(record);
        if (expanded) {
            for (let k = 0; k !== this.state.clientItems.length; k++) {
                let clientItem = this.state.clientItems[k];
                let pojo = new MyPojo(clientItem.server);
                await pojo.init()
                    .then((ref) => {
                        this.state.clientData[key] =
                            <MyDataGrid onRef={this.onCIRef} is parentData={record} clientItem={clientItem}
                                        isSearch={false} pojo={pojo} exTrBus={[]}/>;
                        this.setState({
                            clientData: this.state.clientData,
                        })
                    })
                    .catch((ref) => {
                        console.log(ref);
                    })
            }
        }
    };

    render() {
        const {
            pagination, columns, data, expandable, rowSelection
        } = this.state;
        // console.log(data);
        //console.log(this.props.pojo);
        //console.log(columns);
        if (this.props.parentData === undefined && this.props.clientItem === undefined) {

        }
        // console.log(this.state.rowSelection);
        // console.log('mtable....');
        // console.log(columns);
        return (
            <div className={this.tableClassName} style={{width: '100%'}}>
                <NullDom onRef={(ref) => {
                    console.log(ref);
                    // console.log(ReactDOM.findDOMNode(ref).getBoundingClientRect());
                    let domNode = ReactDOM.findDOMNode(ref);
                    // console.log(domNode.getBoundingClientRect());
                    this.setState({
                        scrollY: domNode.getBoundingClientRect().top,
                    }, () => {
                        // console.log(domNode);
                    })
                }}/>
                <Form
                    ref={(ref) => {
                        this.formRef = ref;
                        if (this.formRef !== undefined && this.formRef !== null) {
                            // console.log(this.treeSelectMap);
                        }
                    }}
                    name="basic"
                    style={{width: '100%'}}
                    onValuesChange={this.onValuesChange}
                    onFieldsChange={(changedFields, allFields) => {
                        // console.log(changedFields);
                        // console.log(allFields);
                        this.editShouldUpdate = true;
                    }}
                >
                    <Spin spinning={this.state.loading} delay={500}>
                    <Table
                        // ref={(ref) => {
                        //     console.log('123skjdhf123lkjsdlfjh');
                        //     let domNode = ReactDOM.findDOMNode(ref);
                        //     console.log(domNode);
                        // }}
                        style={{minHeight: 'calc(100vh - ' + this.state.scrollY + 'px - 40px - 48px - 39px)'}}
                        //可编辑行
                        components={{
                            body: {
                                cell: this.easyTableCell,
                                // row:this.easyTableRow,
                            },
                        }}
                        //可编辑行
                        expandable={expandable}
                        rowSelection={rowSelection}
                        rowKey={this.rowKeyFun}
                        pagination={false}
                        size={'small'}
                        onRow={this.onRow}
                        scroll={{
                            x: '100%',
                            y: 'calc(100vh - ' + this.state.scrollY + 'px - 40px - 48px - 39px)'
                        }}//, y: '10vh'
                        dataSource={data}
                        onChange={this.tableChange}
                        onExpand={this.onExpand}
                        // rowKey={this.props.pojo.pkColumn.prop}
                        columns={columns}
                        footer={() => {
                            if (this.props.showFooter) {
                                if (this.props.isPage) {
                                    return (
                                        <div style={{
                                            width: '100%',
                                            textAlign: 'left',
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}>
                                            <Pagination
                                                showTotal={(total, range) => {
                                                    return (
                                                        <div>
                                                            {range[0]}-{range[1]},总数:{total}条
                                                        </div>
                                                    )
                                                }}
                                                showSizeChanger
                                                onShowSizeChange={(current, size) => {
                                                    console.log(current);
                                                    console.log(size);
                                                    pagination.pageSize = size;
                                                    this.setState({
                                                        pagination: pagination,
                                                    })
                                                }}
                                                onChange={(page, pageSize) => {
                                                    this.paginationChange(page, pageSize);
                                                    this.getList();
                                                }}
                                                // onShowSizeChange={onShowSizeChange}
                                                current={pagination.current}
                                                pageSize={pagination.pageSize}
                                                total={pagination.total}
                                            />

                                        </div>
                                    );
                                }
                            } else {
                                return undefined;
                            }
                        }}
                    />
                    </Spin>
                </Form>
            </div>

        )
    }

    //在 componentWillReceiveProps 钩子中定义键盘监听事件：
    componentWillReceiveProps(nextProps) {
        document.addEventListener('keydown', this.handleKeyPress);
    }

    //在 componentWillUnmount 钩子中移除键盘监听事件：
    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    getData = () => {
        let refs = [];
        for (let i = 0; i !== this.state.data.length; i++) {
            let item = this.state.data[i];
            if (item['isAdd'] !== undefined && item['isAdd']) {
                setValueByProp(item, this.props.pojo.pkColumn.prop, '');
            }
            refs.push(getValueByProp(item, this.props.pojo.prefixName));
        }
        return refs;
    }
}

MyTable.defaultProps = {
    //页码
    page: 1,
    //每页条目数
    rows: 10,
    //显示列数组 如果是[] 就获取 url/init
    onRef: (ref) => {
    },
    //是否显示展开
    ifExpandable: false,
    isSetTrBus: false,
    showRowNames: [],
    nowShowRowNames: [],
    setTrBus: (row) => {
        return undefined
    },
    onRowClick: (row) => {
    },
    rowExpandable: (row) => {
        return true;
    },
    //设置是否可选择
    getCheckboxProps: (row) => {
    },
    onEditingRow: (row) => {
    },
    parentData: undefined,
    clientItem: undefined,
    //checkbox
    rowSelectionType: 'radio',
    rowSelectionOnChange: (selectedRowKeys, selectedRows) => {
    },
    onListHook: (rows) => {
    },
    getListName: 'getList',
    selectValues: [],
    pkProp: '',
    isPage: true,
    exPk: '',
    isOpenEdit: false,
    showFooter: true,
    editingOnChange: (value, column, row, ...data) => {
    },
    editingTreeSelectRefFun: (comName, treeSelectMap) => {
    },
    editShouldUpdate: (prev, cur) => {
        return new Promise((resolve, reject) => {
            reject(null);
        });
    },
    isMinHeight: true,
    rowTrBusWidth: 250,
    current: 0,
    setCurrentFun: (current) => {
    },
    testShowCom: (item) => {
        console.log(item)
        return true
    },
    testGetListParams: (params) => {
        return params
    },
}
MyTable.propTypes = {
    page: PropTypes.number,
    rows: PropTypes.number,
    pojo: PropTypes.instanceOf(MyPojo).isRequired,
    onRef: PropTypes.func,
    ifExpandable: PropTypes.bool,
    setTrBus: PropTypes.func,
    isSetTrBus: PropTypes.bool,
    showRowNames: PropTypes.arrayOf(PropTypes.string),
    nowShowRowNames: PropTypes.arrayOf(PropTypes.string),
    onRowClick: PropTypes.func,
    rowExpandable: PropTypes.func,
    getCheckboxProps: PropTypes.func,
    onEditingRow: PropTypes.func,
    parentData: PropTypes.object,
    clientItem: PropTypes.object,
    rowSelectionType: PropTypes.string,
    rowSelectionOnChange: PropTypes.func,
    onListHook: PropTypes.func,
    getListName: PropTypes.string,
    selectValues: PropTypes.arrayOf(PropTypes.string),
    pkProp: PropTypes.string,
    isPage: PropTypes.bool,
    exPk: PropTypes.string,
    isOpenEdit: PropTypes.bool,
    showFooter: PropTypes.bool,
    editingOnChange: PropTypes.func,
    editingTreeSelectRefFun: PropTypes.func,
    editShouldUpdate: PropTypes.func,
    isMinHeight: PropTypes.bool,
    rowTrBusWidth: PropTypes.number,
    current: PropTypes.number,
    setCurrentFun: PropTypes.func,
    testShowCom: PropTypes.func,
    testGetListParams: PropTypes.func,
}
export default MyTable;

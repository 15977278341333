import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item, StatesID} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";
class CheckoutSUMpojo extends MyPojo {
    getstateinventory(params){
        // return this.getList(params,'getpackdatagrid');
        if (this.orderRowName instanceof Array) {
            for (let i = 0; i !== this.orderRowName.length; i++) {
                if (this.columnsMap[this.orderRowName[i]] !== undefined) {
                    params['orderby'] = this.orderRowName[i] + ' ' + this.orderType;
                    break
                }
            }
        } else {
            if (this.columnsMap[this.orderRowName] !== undefined) {
                params['orderby'] = this.orderRowName + ' ' + this.orderType;
            }
        }
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + 'wmcheckout/getstateinventory', params)
                .then((res) => {
                    resolve(res);
                })
                .catch((res) => {
                    reject(res);
                });
        });
    }
    getrealinventory(params){
        // return this.getList(params,'getpackdatagrid');
        if (this.orderRowName instanceof Array) {
            for (let i = 0; i !== this.orderRowName.length; i++) {
                if (this.columnsMap[this.orderRowName[i]] !== undefined) {
                    params['orderby'] = this.orderRowName[i] + ' ' + this.orderType;
                    break
                }
            }
        } else {
            if (this.columnsMap[this.orderRowName] !== undefined) {
                params['orderby'] = this.orderRowName + ' ' + this.orderType;
            }
        }
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + 'wmcheckout/getrealinventory', params)
                .then((res) => {
                    resolve(res);
                })
                .catch((res) => {
                    reject(res);
                });
        });
    }
    constructor() {
        //实时总库存
        super('wmcheckout');
        this.controller = 'wm';
        this.cName = '实时总库存';
        this.orderRowName = 'fgoodsnumber'
        this.prefixName = 'info';
        this.pojoItem = new initItem();
        this.showPK = true;
        this.addPojoItemInitItems([
            {
                comName: 'fgoodsnumber',
                name: '存货编号',
                isPk:true,
                showOrderSelect: false,
            },
            {
                comName: 'fgoodsnumber',
                name: '存货名称',
                width: 300,
                showLength: 30,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fminventory',
                    action: 'getList',
                    id: 'fnumber',
                    text: '{fcaption}',
                }),
                exComParent: 'fgoodscaption',
            },
            {
                comName: 'fsuqty',
                name: '单位总数量',
                width: 80,
                dataType: DataType.NUM,
            },
            {
                comName: 'finspectedqty',
                name: '待检数',
                width: 80,
                dataType: DataType.NUM,
            },
            {
                comName: 'fsealedqty',
                name: '封货数',
                width: 80,
                dataType: DataType.NUM,
            },
            {
                comName: 'fpickingqty',
                name: '可拣数',
                width: 80,
                dataType: DataType.NUM,
            },
            {
                comName: 'fnormalqty',
                name: '正常数',
                width: 80,
                dataType: DataType.NUM,
            },
            {
                comName: 'fycmqty',
                name: '异常码',
                width: 100,
                dataType: DataType.NUM,
            },
            {
                comName: 'fdesignateqty',
                name: '专执',
                width: 80,
                dataType: DataType.NUM,
            },
            {
                comName: 'ftestqty',
                name: '试验',
                width: 80,
                dataType: DataType.NUM,
            },
            {
                comName: 'fresealqty',
                name: '二次封箱',
                width: 110,
                dataType: DataType.NUM,
            },
            {
                comName: 'fspecialqty',
                name: '特价',
                width: 80,
                dataType: DataType.NUM,
            },
            {
                comName: 'folddateqty',
                name: '旧日期',
                width: 100,
                dataType: DataType.NUM,
            },
        ]);
    }
}
export default CheckoutSUMpojo;

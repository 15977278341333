import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {initItem, item} from '../../calzzs/initItems'
class Pojo extends MyPojo {
    constructor() {
        //登录日志
        super('sysloginlog');
        this.controller = 'sys';
        this.cName = '登录日志';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk:true,
            },
            {
                comName: 'fstaffno',
                name: '员工编号',
            },
            {
                comName: 'fip',
                name: '登录IP',
                showLength: 30,
            },
            {
                comName: 'fmarkid',
                name: '标志',
                dataType: DataType.Com,
                state: {
                    1: '登录',
                    2: '登出',
                },
            },
            {
                comName: 'fopttime',
                name: '操作时间',
                dataType: DataType.DataTime,
            },
        ]);
    }
}
export default Pojo;

export default class SelectTablePojo {
    tableController = '';
    tableAction = '';
    tablePk = '';
    selectController = '';
    selectAction = '';
    selectText = '';
    selectId = '';
    selectParams = {
        page: 0,
        rows: 0,
    };
    showRowNames = [];
    prefixName = '';
    title = '';
    selectOnChange = (value) => {
    }
    exTbParams = (id) => {
        return {};
    }
    getCheckboxProps = (row) => {}

    constructor({
                    tableController = '',
                    tableAction = '',
                    tablePk = '',
                    selectController = '',
                    selectAction = '',
                    selectText = '',
                    selectId = '',
                    selectParams = undefined,
                    exTbParams = undefined,
                    showRowNames = [],
                    getCheckboxProps = undefined,
                    selectOnChange = undefined,
                    prefixName = '',
                    title = '',
                } = {}) {
        this.tableController = tableController;
        this.tableAction = tableAction;
        this.tablePk = tablePk;
        this.selectController = selectController;
        this.selectAction = selectAction;
        this.selectText = selectText;
        this.selectId = selectId;
        if (selectParams !== undefined) {
            this.selectParams = selectParams;
        }
        if (exTbParams !== undefined) {
            this.exTbParams = exTbParams;
        }
        this.showRowNames = showRowNames;
        if (getCheckboxProps !== undefined) {
            this.getCheckboxProps = getCheckboxProps;
        }
        if (selectOnChange !== undefined) {
            this.selectOnChange = selectOnChange;
        }
        this.prefixName = prefixName;
        this.title = title;
    }
}

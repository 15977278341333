import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item, StatesID} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";

class Pojo extends MyPojo {
    getsuitscodelist(params) {
        if (this.orderRowName instanceof Array) {
            for (let i = 0; i !== this.orderRowName.length; i++) {
                if (this.columnsMap[this.orderRowName[i]] !== undefined) {
                    params['orderby'] = this.orderRowName[i] + ' ' + this.orderType;
                    break
                }
            }
        } else {
            if (this.columnsMap[this.orderRowName] !== undefined) {
                params['orderby'] = this.orderRowName + ' ' + this.orderType;
            }
        }
        // return this.getList(params,'getpackdatagrid');
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + 'wmcodeopt/getsuitscodelist', params)
                .then((res) => {
                    resolve(res);
                })
                .catch((res) => {
                    reject(res);
                });
        });
    }

    constructor() {
        //码表信息
        super('wmcodeopt');
        this.controller = 'wm';
        this.cName = '码表信息';
        // this.orderRowName = 'fregistertime'
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '编号',
                isPk: true,
                showOrderSelect: false,
                show: false,
                showSearch: false,
            },
            {
                comName: 'fpk',
                name: '编码',
                showLength: 45,
                width: 300,
            },
            {
                comName: 'fgoodsnumber',
                name: '存货编号',
                showLength: 30,
            },
            {
                comName: 'fgoodsnumber',
                name: '存货名称',
                dataType: DataType.ComGETServer,
                width: 400,
                comF: new ComF({
                    controller: 'fminventory',
                    action: 'getList',
                    id: 'fnumber',
                    text: '{fcaption}',
                }),
                showLength: 45,
                exComParent: 'fgoodscaption',
            },
            {
                comName: 'fbatchnumber',
                name: '批号',
            },
            {
                comName: 'fgoodsstateid',
                name: '存货状态',
                dataType: DataType.Com,
                state: StatesID.getPState(),
                defaultValue:1,
            },
            {
                comName: 'ftypeid',
                name: '类型',
                dataType: DataType.Com,
                state: {
                    1: '垛码',
                    2: '包装码',
                    3: '单位码',
                    4: '件码',
                },
            },
            {
                comName: 'fregistertime',
                name: '登记时间',
                dataType: DataType.DataTime,
            },
            {
                comName: 'fstateid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '保存',
                    2: '注册',
                },
            },
            {
                comName: 'fverificationcode',
                name: '验证码',
                tdShow: false,
            },
            {
                comName: 'fpackcode',
                name: '包装码',
                showLength: 45,
                width: 250,
            },
            {
                comName: 'fpackagecode',
                name: '垛码',
            },
        ]);
    }
}

export default Pojo;

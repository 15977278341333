import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item} from '../../calzzs/initItems'
class Pojo extends MyPojo {
    constructor() {
        //采购需求计划条目
        super('smplanentry');
        this.controller = 'sm';
        this.cName = '采购需求计划条目';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk:true,
            },
            {
                comName: 'fgoodsnumber',
                name: '存货名称',
                width: 300,
                showLength: 30,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fminventory',
                    action: 'getList',
                    id: 'fnumber',
                    text: '{fcaption}',
                }),
                exComParent: 'fgoodscaption',
            },
            {
                comName: 'fremark',
                name: '摘要',
            },
            {
                comName: 'fsucaption',
                name: '计量单位',
            },
            {
                comName: 'fsupk',
                name: '计量单位编号',
            },
            {
                comName: 'fpupk',
                name: '包装单位编号',
            },
            {
                comName: 'fpsupk',
                name: '码板单位编号',
            },
            {
                comName: 'fsuqty',
                name: '计量单位数量',
                dataType: DataType.NUM,
            },
            {
                comName: 'fpuqty',
                name: '包装单位数量',
                dataType: DataType.NUM,
            },
            {
                comName: 'fpsuqty',
                name: '码板单位数量',
                dataType: DataType.NUM,
            },
            {
                comName: 'fstockqty',
                name: '库存',
                dataType: DataType.NUM,
            },
            {
                comName: 'frequiretime',
                name: '需求日期',
                dataType: DataType.DataTime,
            },
            {
                comName: 'fplanpk',
                name: '计划编号',
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
        ]);
    }
}
export default Pojo;

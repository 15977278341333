import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";

class Pojo extends MyPojo {
    getstockdaily = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.controller + '/getstockdaily', params)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }

    constructor() {
        //出入库日报
        super('getstockdaily');
        this.isToExcel = true
        this.controller = 'rpbase';
        this.delRowName = 'fbatchnumber';
        this.cName = '出入库日报';
        this.pojoItem = new initItem();
        this.orderRowName = ''
        this.showPK = true;
        this.prefixName = ''
        this.getListActionName = 'getstockdaily'
        this.addPojoItemInitItems([
            {
                comName: 'fbatchnumber',
                name: '生产批号',
                width: 100,
                // isPk: true,
            },
            {
                comName: 'fgoodsnumber',
                name: '存货名称',
                width: 300,
                showLength: 30,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fminventory',
                    action: 'getList',
                    id: 'fnumber',
                    text: '{fcaption}',
                }),
                exComParent: 'fgoodscaption',
            },
            {
                comName: 'ftypeid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '入库',
                    2: '出库',
                },
                show: false,
            },
            {
                comName: 'fgoodsnumber',
                name: '存货编号',
                showLength: 40,
                width: 100,
                showSearch: false,
            },
            {
                comName: 'daily',
                name: '过账日期',
                showSearch: false,
                dataType: DataType.DataTime,
                showTime: false,
            },
            {
                comName: 'ftacticstime',
                name: '日期',
                show: false,
                dataType: DataType.DataTime,
                showTime: false,
            },
            // {
            //     comName: 'fstorage',
            //     name: '储位名称',
            //     showSearch: false,
            //     width: 100,
            // },
            {
                comName: 'fstoragepk',
                name: '储位名称',
                dataType: DataType.ComGETServer,
                comF: {
                    controller: 'fmstorage',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                },
                exComParent: 'fstorage',
                show: false,
                width: 100,
            },
            {
                comName: 'freceiptqty',
                name: '入库数量',
                showSearch: false,
                width: 100,
            },
            {
                comName: 'finvoiceqty',
                name: '出库数量',
                showSearch: false,
                width: 100,
            },
        ]);
    }
}

export default Pojo;

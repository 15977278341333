import React, {useState} from 'react';
import './index.less';
import {Row, Button, Table, Tag, Radio, Modal, Tabs, message, Form, Select, Input} from 'antd';
// import {DownloadOutlined} from '@ant-design/icons';
import {get} from "../../axios/Response";
import MyTable from "../../my_module/table/table";
// import Pojo from "./pojo"
import MyDataGrid from "../../my_module/datagrid/datagrid";
import MyButton from "../../my_module/MyClass/button";
import Nestgrid from "../../my_module/nestgrid/nestgrid";
import Pojos from "../pojos"
import TitleDetailTablePojo from "../../my_module/Common/TitleDetailTablePojo";
import Title_detail_table from "../../my_module/title_detail_table/title_detail_table";
import {ObjToObjItem, ObjType, ToObjFun} from "../../api/ObjUtil";
import {getSearchList, setValueByProp} from "../../utils/utils";
import {getQueryWhereItem, WhereType} from "../../api/mainPojo";

import {DownOutlined, UserOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import MyModel from "../../my_module/myModel/myModel";
import {getButtomModal} from "../../my_module/MyClass/domUtil";

const {TabPane} = Tabs;


//生产订单管理
class index extends React.Component {
    constructor(props) {
        super(props);
        this.onDGRef = this.onDGRef.bind(this);
        this.onDDGRef = this.onDDGRef.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        // this.setTP = this.setTP.bind(this);
        this.onTopChange = this.onTopChange.bind(this);
        this.drawerContext = undefined;
        // this.addOpenIntercept = this.addOpenIntercept.bind(this);
        // this.editOpenIntercept = this.editOpenIntercept.bind(this);
        this.selectFgoodsnumber = '';
        this.fminventoryconfigPojo = Pojos.getPojo('fminventoryconfig');
        this.state = {
            // addModalVisible: false,
            pojo: Pojos.getPojo('pmorder'),//new Pojo(),
            dPojo: Pojos.getPojo('pmorderentry'),
            dg: undefined,
            ddg: undefined,
            showDDG: 'none',
            opTP: false,
            tp: undefined,
            tpKey: '1',
            dgRow: undefined,
            tdT: undefined,
            setpmqtyModel: undefined,
            detailRow: undefined,
        }
        console.log(this.pojo);
    }

    exHeadBus = [
        new MyButton(
            '同步数据',
            'synchronization',
            () => {
                console.log('synchronization');
                this.state.pojo.synchronization()
                    .then((ref) => {
                        this.state.dg.getList();
                    });
            },
            false,
            // (row) => {
            //     console.log(row);
            //     return true;
            // }
        ),
    ]

    exTrBus = [
        // new MyButton(
        //     '下达任务',
        //     'ordertask',
        //     (row) => {
        //         // console.log(row);
        //         confirm({
        //             title: '下达任务',
        //             icon: <ExclamationCircleOutlined/>,
        //             content: '确定下达?',
        //             onOk: () => {
        //                 this.state.pojo.ordertask({
        //                     fnumber: row['fnumber'],
        //                 })
        //                     .then((ref) => {
        //                         message.success(ref.msg);
        //                         this.state.dg.getList();
        //                     })
        //                     .catch((ref) => {
        //                         message.error(ref.msg);
        //                     })
        //             },
        //             onCancel: () => {
        //                 console.log('Cancel');
        //             },
        //         });
        //     },
        //     false,
        //     (row) => {
        //         // console.log(row);
        //         return true;
        //     }
        // ),
        getButtomModal(
            {
                title: '审核',
                content: '确定审核?',
                name: 'ordertask',
                okFun: (row) => {
                    return new Promise((resolve, reject) => {
                        this.state.pojo.verify({
                            fnumber: row['fnumber'],
                            ftypeid: 1,
                        })
                            .then((ref) => {
                                resolve(ref);
                            })
                            .catch((ref) => {
                                reject(ref);
                            })
                    });
                },
                thenFun: (ref) => {
                    this.state.dg.getList();
                }
            }
        ),

        getButtomModal(
            {
                title: '下达任务',
                content: '确定下达?',
                name: 'ordertask',
                okFun: (row) => {
                    return new Promise((resolve, reject) => {
                        this.state.pojo.ordertask({
                            fnumber: row['fnumber'],
                        })
                            .then((ref) => {
                                resolve(ref);
                            })
                            .catch((ref) => {
                                reject(ref);
                            })
                    });
                },
                thenFun: (ref) => {
                    this.state.dg.getList();
                }
            }
        ),
        getButtomModal(
            {
                title: '关闭',
                content: '确定关闭?',
                name: 'close',
                okFun: (row) => {
                    return new Promise((resolve, reject) => {
                        this.state.pojo.close({
                            fnumber: row['fnumber'],
                        })
                            .then((ref) => {
                                resolve(ref);
                            })
                            .catch((ref) => {
                                reject(ref);
                            })
                    });
                },
                thenFun: (ref) => {
                    this.state.dg.getList();
                },
                testFunc: (row) => {
                    // console.log(row);
                    return row['info']['fstateid'] === 3;
                },
            }
        ),
    ];
    detailExTrBus = [
        new MyButton(
            '设置生产数量',
            'setpmqty',
            (row) => {
                // console.log(row);
                this.state.setpmqtyModel.showModal();
            },
            false,
            (row) => {
                // console.log(row);
                return true;
            }
        ),
    ]
    onDGRef = (ref) => {
        this.setState({
            dg: ref,
        }, () => {
            //this.state.dg.refInit();
        })
    }
    onDDGRef = (ref) => {
        this.setState({
            ddg: ref,
        }, () => {
            //this.state.dg.refInit();
        })
    }

    onTDTRef = (ref) => {
        console.log(ref);
        this.setState({
            tdT: ref,
        }, () => {
            //this.state.dg.refInit();
        })
    }

    componentDidMount() {
        let aa = {
            aa: 'qwe',
            bb: 'ert',
            cc: [
                {
                    cw: 'fghfgh',
                    rt: 'vbcbvc',
                    dd: 123123,

                }
            ]
        };
        let cc = {
            aacc: '',
            bbcc: '',
            cccc: [
                {
                    cwcc: '',
                    rtcc: '',
                    ddcc: 0,

                }
            ]
        };
        let otoitems = [
            new ObjToObjItem('aa', 'aacc'),
            new ObjToObjItem('bb', 'bbcc'),
            new ObjToObjItem('cc', 'cccc', ObjType.Array, [
                new ObjToObjItem('cw', 'cwcc')
            ]),
        ];
        new ToObjFun(aa, cc, otoitems).getData();
        console.log(cc);
    }

    componentDidUpdate = (prevProps, prevState) => {
    }
    // addModal = () => {
    //     let model =
    //         <Modal title="Basic Modal" visible={this.state.addModalVisible}
    //                onCancel={() => {
    //                    this.setState({
    //                        addModalVisible:false,
    //                    })
    //                }}>
    //             <p>Some contents...</p>
    //             <p>Some contents...</p>
    //             <p>Some contents...</p>
    //         </Modal>;
    //     return model;
    // }
    // addOpenIntercept = (ref) => {
    //     console.log(ref);
    //     // this.setState({
    //     //     addModalVisible:true,
    //     // })
    //     return false;
    // }
    // editOpenIntercept = (ref) => {
    //     console.log(ref);
    //     return false;
    // }
    // setTP = ({
    //              ref = undefined
    //          } = {}) => {
    //     this.setState({
    //         tp: (
    //
    //         ),
    //         tpKey: 'edit',
    //     });
    // }
    onTopChange = (tpKey) => {
        this.setState({
            tpKey: tpKey,
        });
    };
    onRowClick = (row) => {
        // console.log(row);

    }
    editShouldUpdate = (prev, cur, formData) => {
        return new Promise((resolve, reject) => {
            if (prev['info'] === undefined || this.selectFgoodsnumber !== cur['info']['fgoodsnumber']['key']) {
                // console.log(cur);
                if (cur['info']['fgoodsnumber']._isTreeSelectRef) {
                    this.selectFgoodsnumber = cur['info']['fgoodsnumber']['key'];
                } else {
                    this.selectFgoodsnumber = cur['info']['fgoodsnumber'];
                }
                // console.log(this.selectFgoodsnumber);
                // console.log(this.state.tdT.state.detailTable.editFromItemMap);
                // console.log(cur['info']);
                let editFromItemMap = this.state.tdT.state.detailTable.editFromItemMap;
                let getQuery = getQueryWhereItem([
                    {
                        value: this.selectFgoodsnumber,
                        comname: 'fgoodsnumber',
                        comtype: WhereType.EqualTo,
                    }
                ]);
                // console.log(getQuery);
                editFromItemMap['fsupk'].init(getQuery);
                // editFromItemMap['fpupk'].init(getQuery);
                // editFromItemMap['fpsupk'].init(getQuery);
                if (formData !== undefined && this.selectFgoodsnumber !== '') {
                    this.fminventoryconfigPojo.getgoodsinfo(this.selectFgoodsnumber)
                        .then((ref) => {
                            // formData['fsupk'] = ref.data['fpsupk'];
                            setValueByProp(formData, this.state.dPojo.getProp('fsupk'), ref.data['fsupk']);
                            editFromItemMap['fsupk'].setValue(ref.data['fsupk']);
                            // setValueByProp(formData, this.state.dPojo.getProp('fpupk'), ref.data['fpupk']);
                            // editFromItemMap['fpupk'].setValue(ref.data['fpupk']);
                            // setValueByProp(formData, this.state.dPojo.getProp('fpsupk'), ref.data['fpsupk']);
                            // editFromItemMap['fpsupk'].setValue(ref.data['fpsupk']);
                            resolve(null);
                        })
                        .catch((ref) => {
                            console.log(ref);
                            reject(ref);
                        });
                } else {
                    reject(null);
                }
                //
                // if (value._isTreeSelectRef !== undefined) {
                //     let prop = value.cProp;
                //     let item = value.row;
                //     if (prop === 'info.fsupk') {
                //         row.info['fsuqty'] = item['info']['fsuqty'];
                //     }
                // }
                // editFromItemMap['fsupk'].init(getQuery);
                console.log('存货名称修改了');
            } else {
                reject(null);
            }
        });
        // console.log(prev);
        // console.log(cur);

    }
    setpmqtyModelRef = (ref) => {
        this.setState({
            setpmqtyModel: ref,
        })
    }

    render() {
        let tpItem = undefined;
        if (this.state.opTP) {
            tpItem = this.state.tp;
        }
        let detailTable = undefined;
        return (
            <div style={{height: '100%'}} ref={(drawerContext) => {
                this.drawerContext = drawerContext;
            }}>
                <MyModel
                    title={'设置生产数量'}
                    onRef={this.setpmqtyModelRef}
                    handleOk={() => {
                        // this.state.pojo.generator({
                        //     ftypeid:this.ftypeid,
                        // })
                        //     .then((ref) => {
                        //         this.state.dg.getList();
                        //         this.state.generatorModel.handleCancel();
                        //     });
                    }}
                    child={(
                        <Form.Item
                            label="类型"
                            name="类型"
                        >
                            <Input defaultValue="26888888"/>
                        </Form.Item>
                    )}
                />
                <div>
                    <MyDataGrid
                        showDetail={(row) => {
                            // this.state.ddg.getList({
                            //     fnumber: row.fnumber,
                            // })
                            //
                            // this.setState({
                            //     showDDG: '',
                            // })
                            // this.detailRow = row;
                            this.setState({
                                detailRow: row,
                            })
                        }}
                        editTestFun={(row) => {
                            // console.log(row);
                            return row.info.fstateid === 1;
                        }}
                        delTestFun={(row) => {
                            return row.info.fstateid === 1;
                        }}
                        // isMinHeight={false}
                        exHeadBus={this.exHeadBus}
                        // drawerContext={this.drawerContext}
                        // min_h={false}
                        onRowClick={this.onRowClick}
                        addOpenIntercept={() => {
                            // this.setState({
                            //     opTP: true,
                            // });
                            // this.setTP();
                            this.state.dg.setShowEx(true);
                            this.setState({
                                dgRow: undefined,
                                showDDG: 'none',
                            })
                            return false;
                        }}
                        editOpenIntercept={(ref) => {
                            // this.setState({
                            //     opTP: true,
                            // });
                            // this.setTP({
                            //     ref: ref,
                            // });
                            this.state.dg.setShowEx(true);
                            this.setState({
                                dgRow: ref,
                                showDDG: 'none',
                            });
                            return false;
                        }}
                        exBody={(
                            // <TabPane
                            //     tab={'设置'}
                            //     key="edit">
                            <Title_detail_table
                                editShouldUpdate={(prev, cur, formData) => {
                                    return this.editShouldUpdate(prev, cur, formData);
                                }}
                                onRef={this.onTDTRef}
                                editingTreeSelectRefFun={(comName, editFromItemMap) => {
                                    // console.log('editingTreeSelectRefFun');
                                    console.log(comName);
                                    console.log(editFromItemMap);
                                    // console.log(this.state.tdT.state.detailTable.getFormData());
                                }}
                                editingOnChange={(value, column, row, editFromItemMap, ...data) => {
                                    console.log(editFromItemMap);
                                    // console.log(column);
                                    // console.log(value);
                                    // console.log(row);
                                    // console.log(treeSelectMap);
                                    // if (column.comName === 'fgoodsnumber') {
                                    //     let fminventoryconfig = Pojos.getPojo('fminventoryconfig');
                                    //     fminventoryconfig.getgoodsinfo(value.key)
                                    //         .then((ref) => {
                                    //             console.log(ref);
                                    //         })
                                    //         .catch((ref) => {
                                    //             console.log(ref);
                                    //         });
                                    //     console.log(editFromItemMap['fsupk']);
                                    //     let getQuery = getQueryWhereItem([
                                    //         {
                                    //             value: value.key,
                                    //             comname: 'fgoodsnumber',
                                    //             comtype: WhereType.EqualTo,
                                    //         }
                                    //     ]);
                                    //     editFromItemMap['fsupk'].init(getQuery);
                                    // }
                                    // if (value._isTreeSelectRef !== undefined) {
                                    //     let prop = value.cProp;
                                    //     let item = value.row;
                                    //     if (prop === 'info.fsupk') {
                                    //         row.info['fsuqty'] = item['info']['fsuqty'];
                                    //     }
                                    //     // editFromItemMap['fsuqty'].value = item['info']['fsuqty'];
                                    // }
                                    // console.log(data);
                                }}
                                onClose={() => {
                                    // this.setState({
                                    //     opTP: false,
                                    //     tpKey: '1',
                                    // })
                                    this.state.dg.setShowEx(false);
                                    this.setState({
                                        showDDG: 'none',
                                    })
                                }}
                                tdtPojo={new TitleDetailTablePojo({
                                    titleController: 'pmorder',
                                    titleRowNames: [
                                        'fnumber',
                                        'fproductiondate',
                                        'fsourcenumber',
                                        'fcustomer',
                                        'fdeptpk',
                                        'fmemo',
                                    ],
                                    detailController: 'pmorderentry',
                                    detailAction: 'getTitleList',
                                    titleRow: this.state.dgRow,
                                    getDetailPRowName: 'fnumber',
                                    detailPRowName: 'fnumber',
                                })}
                            />
                            // </TabPane>
                        )}
                        onRef={this.onDGRef}
                        pojo={this.state.pojo}
                        isTab={true}
                        bodyWidth={'100%'}
                        detailBodyWidth={'100%'}
                        bodyClass={'box-column'}
                        detailLocation={'r'}
                        // detailBody={
                        //     <div className={'detail'} style={{display: this.state.showDDG}}>
                        //         <MyDataGrid
                        //             // exTrBus={this.detailExTrBus}
                        //             isMinHeight={false}
                        //             isSetTrBus={false}
                        //             isTab={false}
                        //             isPage={false}
                        //             // addOpenIntercept={this.addOpenIntercept}
                        //             // editOpenIntercept={this.editOpenIntercept}
                        //             initializeList={false}
                        //             onRef={this.onDDGRef}
                        //             pojo={this.state.dPojo}
                        //             getListName={'getTitleList'}
                        //         />
                        //     </div>
                        // }
                        // style={{display: this.state.showDDG}}
                        // className={'detail'}
                        detailDBody={this.state.detailRow === undefined ? undefined :
                            <div>
                                <MyDataGrid
                                    // exTrBus={this.detailExTrBus}
                                    isAdd={false}
                                    isSearch={false}
                                    isMinHeight={false}
                                    isSetTrBus={false}
                                    isTab={false}
                                    isPage={false}
                                    // addOpenIntercept={this.addOpenIntercept}
                                    // editOpenIntercept={this.editOpenIntercept}
                                    initializeList={false}
                                    onRef={(ref) => {
                                        ref.getList({
                                            fnumber: this.state.detailRow.fnumber,
                                        })
                                    }}
                                    pojo={this.state.dPojo}
                                    getListName={'getTitleList'}
                                />
                            </div>
                        }
                        exTrBus={this.exTrBus}/>
                </div>
                {/*{this.addModal()}*/}

                {/*{tpItem}*/}
            </div>
        );
    }
}

export default index;

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {initItem, item} from '../../calzzs/initItems'
class Pojo extends MyPojo {
    constructor() {
        //供应商信息
        super('smsupplier');
        this.controller = 'sm';
        this.cName = '供应商信息';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk:true,
            },
            {
                comName: 'fcaption',
                name: '供应商名称',
            },
            {
                comName: 'fcode',
                name: '供应商代号',
            },
            {
                comName: 'fpassword',
                name: '密码',
            },
            {
                comName: 'faddress',
                name: '供应商地址',
            },
            {
                comName: 'ftelephone',
                name: '联系电话',
            },
            {
                comName: 'fcontacts',
                name: '联系人',
            },
            {
                comName: 'fmemo',
                name: '备注',
            },
            {
                comName: 'fstatusid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '启用',
                    2: '停用',
                },
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
        ]);
    }
}
export default Pojo;

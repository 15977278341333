import React from "react";
import {TreeSelect} from "antd";
import Pojos from "../../ylwmspages/pojos"
import PropTypes from "prop-types";
import {breadthTreeSearch, getTreeSelectData, getValueByProp} from "../../utils/utils";
import {TableType} from "../../api/mainPojo";
import {TreeSelectRef} from "../../calzzs/initItems";

class MTreeSelect extends React.Component {
    constructor(props) {
        super(props);
        // console.log(props);
        this.init = this.init.bind(this);
        this.onChange = this.onChange.bind(this);
        this.setValue = this.setValue.bind(this);
        this.state = {
            mTreeSelect: [],
            data: [],
            treeData: [],
            serverPojo: undefined,
            row: this.props.row,
            value: '',
        };
        // this.init();
    }

    init = async (params = {}) => {
        // console.log(this.props);
        // console.log(this.state.value);
        let serverPojo = Pojos.getPojo(this.props.controller);
        serverPojo.init();
        let page = {};
        if (serverPojo.tableType === TableType.GD) {
            page = {
                page: 0,
                rows: 0,
            }
        }
        await serverPojo[this.props.action](
            {
                ...params,
                ...page,
                ...this.props.params
            }
        )
            .then((ref) => {
                let data = ref.data.rows;
                if (data === undefined) {
                    data = ref.data;
                }
                if (!(data instanceof Array)) {
                    data = [data];
                }
                let prop = serverPojo.prefixName === '' ? '' : serverPojo.prefixName + '.';
                let treeData = getTreeSelectData(
                    data,
                    this.props.id,
                    this.props.text,
                    serverPojo,
                    [],
                    prop);
                // console.log(this.props.value);
                this.setState({
                    serverPojo: serverPojo,
                    data: data,
                    treeData: treeData,
                }, () => {
                    // console.log(this.state.mTreeSelect);
                });
            })
            .catch((ref) => {
                return undefined;
            })
    }
    setValue = (value) => {
        this.setState({
            value: value,
        },() => {
            // this.props.onChange(value);
        });
    }
    getValue = (value) => {
        if(this.state.serverPojo === undefined){
            return undefined;
        }
        let node = breadthTreeSearch(
            {
                tree: this.state.data,
                prop: this.state.serverPojo.getProp(this.props.id),
                value: this.state.value,
            }
        );
        let showValue = '';
        if (this.props.multiple) {
            showValue = value;
        } else {
            showValue = getValueByProp(node, this.state.serverPojo.getProp(this.props.column.comF.showRow));
        }
        return new TreeSelectRef({
            value: showValue,
            prop: this.props.column.exComParent,
            cProp: this.props.column.prop,
            key: value,
            row: node,
        });
    }
    onChange(newCurrency, label, extra) {

        let sValue = newCurrency
        if (this.props.multiple) {
            sValue = newCurrency.toString();
        }
        // console.log(label);
        // console.log(this.props.column);
        this.setState({
            value: newCurrency,
        })
        if (this.props.column !== undefined) {
            let node = breadthTreeSearch(
                {
                    tree: this.state.data,
                    prop: this.state.serverPojo.getProp(this.props.id),
                    value: newCurrency,
                }
            );
            // console.log(this.state.data);
            // console.log(this.state.serverPojo.getProp(this.props.id));
            // console.log(newCurrency);
            // console.log(node);
            let showValue = '';
            if (this.props.multiple) {
                showValue = newCurrency;
            } else {
                showValue = getValueByProp(node, this.state.serverPojo.getProp(this.props.column.comF.showRow));
            }
            let tsRef = new TreeSelectRef({
                value: showValue,
                prop: this.props.column.exComParent,
                cProp: this.props.column.prop,
                key: sValue,
                row: node,
            })
            this.props.onChange(tsRef);
        } else {
            this.props.onChange(newCurrency);
        }
    }

    componentDidMount() {
        //console.log(this.selectRef);
        // console.log("组件MTreeSelect挂载完成");
        if (this.props.column !== undefined) {
            // console.log(this.props.column.prop);
            // console.log(this.props.row);
            let sValue = '';
            if (this.props.row !== undefined) {
                sValue = getValueByProp(this.props.row, this.props.column.prop);
            }
            this.setState({
                value: sValue === '' ? undefined : sValue,
            }, () => {
                if (this.props.isInitialize) {
                    this.init();
                }
            })
        } else {
            if (this.props.isInitialize) {
                this.init();
            }
        }
        this.props.onRef(this);
        // this.setState({
        //     value:this.props.value,
        // },() => {
        //
        // })

        //this.selectRef.current.props.value = '0';
    }

    render() {
        // console.log(this.state.mTreeSelect);
        // console.log(this.state.value);
        return (
            <TreeSelect
                treeNodeFilterProp={'title'}
                multiple={this.props.multiple}
                allowClear
                treeLine={{
                    showLine: true,
                    showIcon: false,
                }}
                treeIcon={false}
                style={{width: '100%'}}
                value={this.state.value}
                dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                treeData={this.state.treeData}
                placeholder="请选择"
                onChange={this.onChange}
                treeDefaultExpandAll
            />
        )
    }
}

MTreeSelect.defaultProps = {
    // pojo: undefined,
    controller: '',
    action: '',
    text: '',
    id: '',
    params: undefined,
    row: undefined,
    onChange: (value) => {
    },
    column: undefined,
    isInitialize: true,
    onRef: (ref) => {
    },
    multiple: false,
}
MTreeSelect.propTypes = {
    // pojo: PropTypes.instanceOf(MyPojo).isRequired,
    controller: PropTypes.string,
    action: PropTypes.string,
    text: PropTypes.string,
    id: PropTypes.string,
    params: PropTypes.object,
    row: PropTypes.object,
    onChange: PropTypes.func,
    column: PropTypes.object,
    isInitialize: PropTypes.bool,
    onRef: PropTypes.func,
    multiple: PropTypes.bool,
}
export default MTreeSelect;

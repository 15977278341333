import React, {useState} from 'react';
import './index.less';
import {Row, Button, Table, Tag, Radio, Modal, Tabs, Select, Form, Input} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import {get} from "../../axios/Response";
import MyTable from "../../my_module/table/table";
// import Pojo from "./pojo"
import MyDataGrid from "../../my_module/datagrid/datagrid";
import MyButton from "../../my_module/MyClass/button";
import Nestgrid from "../../my_module/nestgrid/nestgrid";
import Pojos from "../pojos"
import MyModel from "../../my_module/myModel/myModel";

const {Option} = Select;
const {TabPane} = Tabs;

//包装单位信息
class index extends React.Component {
    constructor(props) {
        super(props);
        this.onDGRef = this.onDGRef.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.generatorModelRef = this.generatorModelRef.bind(this);
        // this.addOpenIntercept = this.addOpenIntercept.bind(this);
        // this.editOpenIntercept = this.editOpenIntercept.bind(this);
        this.state = {
            // addModalVisible: false,
            pojo: Pojos.getPojo('fmpackingunit'),//new Pojo(),
            dg: undefined,
            generatorModel: undefined,
        }
        this.ftypeid = '1';
        this.fcaption = '';
        console.log(this.pojo);
    }

    generatorModelRef = (ref) => {
        this.setState({
            generatorModel: ref,
        })
    }
    exTrBus = [
        // new MyButton(
        //     '审批',
        //     'getItemByID11',
        //     undefined,
        //     (row) => {
        //         console.log(row);
        //         return true;
        //     }
        // )
    ]
    onDGRef = (ref) => {
        this.setState({
            dg: ref,
        }, () => {
            //this.state.dg.refInit();
        })
    }
    componentDidUpdate = (prevProps, prevState) => {
    }
    // addModal = () => {
    //     let model =
    //         <Modal title="Basic Modal" visible={this.state.addModalVisible}
    //                onCancel={() => {
    //                    this.setState({
    //                        addModalVisible:false,
    //                    })
    //                }}>
    //             <p>Some contents...</p>
    //             <p>Some contents...</p>
    //             <p>Some contents...</p>
    //         </Modal>;
    //     return model;
    // }
    // addOpenIntercept = (ref) => {
    //     console.log(ref);
    //     // this.setState({
    //     //     addModalVisible:true,
    //     // })
    //     return false;
    // }
    // editOpenIntercept = (ref) => {
    //     console.log(ref);
    //     return false;
    // }
    render() {
        return (
            <div>
                {/*{this.addModal()}*/}
                {/*<MyModel*/}
                {/*    title={'生成数据'}*/}
                {/*    onRef={this.generatorModelRef}*/}
                {/*    handleOk={() => {*/}
                {/*        this.state.pojo.generator({*/}
                {/*            ftypeid: this.ftypeid,*/}
                {/*            fcaption: this.fcaption,*/}
                {/*        })*/}
                {/*            .then((ref) => {*/}
                {/*                this.state.dg.getList();*/}
                {/*                this.state.generatorModel.handleCancel();*/}
                {/*            });*/}
                {/*    }}*/}
                {/*    child={(*/}
                {/*        <Form*/}
                {/*            name="basic"*/}
                {/*            labelCol={{ span: 5 }}*/}
                {/*            wrapperCol={{ span: 16 }}*/}
                {/*            initialValues={{ remember: true }}*/}
                {/*        >*/}
                {/*            <Form.Item*/}
                {/*                label="类型"*/}
                {/*                name="类型"*/}
                {/*            >*/}
                {/*                <Select defaultValue="1" style={{width: 120}} onChange={(value) => {*/}
                {/*                    this.ftypeid = value;*/}
                {/*                }}>*/}
                {/*                    <Option value="1">计量单位</Option>*/}
                {/*                    <Option value="2">包装单位</Option>*/}
                {/*                    <Option value="3">码板单位</Option>*/}
                {/*                </Select>*/}
                {/*            </Form.Item>*/}
                {/*            <Form.Item*/}
                {/*                label="单位名称"*/}
                {/*                name="单位名称"*/}
                {/*            >*/}
                {/*                <Input style={{width: 120}} onChange={(e) => {*/}
                {/*                    // console.log(e.target.value);*/}
                {/*                    this.fcaption = e.target.value;*/}
                {/*                }}/>*/}
                {/*            </Form.Item>*/}
                {/*        </Form>*/}
                {/*    )}*/}
                {/*/>*/}
                <MyDataGrid
                    columnNumber={2}
                    // addOpenIntercept={this.addOpenIntercept}
                    // editOpenIntercept={this.editOpenIntercept}
                    onRef={this.onDGRef}
                    pojo={this.state.pojo}
                    exTrBus={this.exTrBus}
                    exHeadBus={[
                        // new MyButton(
                        //     '生成数据',
                        //     'maintain',
                        //     () => {
                        //         if (this.state.generatorModel !== undefined) {
                        //             this.state.generatorModel.showModal();
                        //         }
                        //     },
                        //     (row) => {
                        //         console.log(row);
                        //         return true;
                        //     }
                        // ),
                    ]}
                />
            </div>
        );
    }
}

export default index;

import React, {useState} from 'react';
import './index.less';
import {Row, Button, Table, Tag, Radio, Modal, Tabs, message, Form} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import {get} from "../../axios/Response";
import MyTable from "../../my_module/table/table";
// import Pojo from "./pojo"
import MyDataGrid from "../../my_module/datagrid/datagrid";
import MyButton from "../../my_module/MyClass/button";
import Nestgrid from "../../my_module/nestgrid/nestgrid";
import Pojos from "../pojos"
import {DataType, getQueryWhereItem, WhereType} from "../../api/mainPojo";
import Title_detail_table from "../../my_module/title_detail_table/title_detail_table";
import TitleDetailTablePojo from "../../my_module/Common/TitleDetailTablePojo";
import {getButtomModal} from "../../my_module/MyClass/domUtil";
import FormUtil from "../../my_module/Common/FormUtil";
import {ComF} from "../../calzzs/initItems";
import {setValueByProp} from "../../utils/utils";

const {TabPane} = Tabs;

//抽奖规则
class index extends React.Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.onDGRef = this.onDGRef.bind(this);
        this.onDDGRef = this.onDDGRef.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.formUtil = undefined;
        //下达任务参数
        this.option3fplatformpk = '';
        this.option3fexecutemanpk = '';
        //装车参数
        this.option4Storekeeperpk = '';
        this.option4fdriver = '';
        this.option4fporter = '';
        let pojo = Pojos.getPojo('mmlotteryrule');
        if (this.props.data.winData !== undefined) {
            pojo = Pojos.getPojo(this.props.data.winData.key);
            pojo.cName = this.props.data.winData.name;
            pojo.exTableName = this.props.data.winData.key;
        }
        this.queryData = [];
        if (this.props.data.queryData !== undefined) {
            this.queryData = this.props.data.queryData;
        }
        this.editData = {};
        if (this.props.data.editData !== undefined) {
            this.editData = this.props.data.editData;
        }
        // console.log(this.props.data);
        this.fminventoryconfigPojo = Pojos.getPojo('fminventoryconfig');
        this.state = {
            // addModalVisible: false,
            pojo: pojo,//new Pojo(),
            dPojo: Pojos.getPojo('mmlotteryruleentry'),
            dg: undefined,
            ddg: undefined,
            showDDG: 'none',
            opTP: false,
            tp: undefined,
            tpKey: '1',
            dgRow: undefined,
            tdT: undefined,
            detailRow: undefined,
        }
        this.xdform = React.createRef();
        // console.log(this.pojo);
    }

    exTrBus = [
        // new MyButton(
        //     '审批',
        //     'getItemByID11',
        //     undefined,
        //     (row) => {
        //         console.log(row);
        //         return true;
        //     }
        // )
        getButtomModal(
            {
                title: '下达任务',
                content:
                    (<Form
                        ref={this.xdform}
                        {...{
                            labelCol: {span: 8},
                            wrapperCol: {span: 16},
                        }}
                    >
                        {
                            [
                                new FormUtil().getFormItem(
                                    {
                                        column: {
                                            required: true,
                                            comName: 'fplatformpk',
                                            prop: 'fplatformpk',
                                            name: '月台',
                                            dataType: DataType.ComGETServer,
                                            comF: new ComF({
                                                controller: 'fmplatform',
                                                action: 'getList',
                                                id: 'fpk',
                                                text: '{fcaption}',
                                            }),
                                        },
                                        onChange: (value, ...data) => {
                                            // console.log(value);
                                            // console.log(data);
                                            this.option3fplatformpk = value.key;
                                        }
                                    },
                                ),
                                new FormUtil().getFormItem(
                                    {
                                        column: {
                                            comName: 'fexecutemanpk',
                                            prop: 'fexecutemanpk',
                                            name: '执行人',
                                            dataType: DataType.ComGETServer,
                                            comF: new ComF({
                                                controller: 'sysstaff',
                                                action: 'getList',
                                                id: 'fpk',
                                                text: '{fnumber}-{fname}',
                                            }),
                                        },
                                        onChange: (value, ...data) => {
                                            // console.log(value);
                                            // console.log(data);
                                            this.option3fexecutemanpk = value.key;
                                        }
                                    },
                                )
                            ]
                        }
                    </Form>),
                name: 'assign',
                okFun: (row) => {
                    this.xdform.current.validateFields()
                        .then((ref) => {
                            return new Promise((resolve, reject) => {
                                this.state.pojo.assign({
                                    fnumber: row['fnumber'],
                                    fplatformpk: this.option3fplatformpk,
                                    fexecutemanpk: this.option3fexecutemanpk,
                                })
                                    .then((ref) => {
                                        resolve(ref);
                                    })
                                    .catch((ref) => {
                                        reject(ref);
                                    });
                            })
                                .catch((ref) => {
                                    console.log(ref);
                                });
                            // if (this.option3fplatformpk === '') {
                            //     message.error('请选择月台');
                            //     reject(undefined);
                            // } else if (this.option3fexecutemanpk === '') {
                            //     message.error('请选择执行人');
                            //     reject(undefined);
                            // } else {
                            //     this.state.pojo.assign({
                            //         fnumber: row['fnumber'],
                            //         fplatformpk: this.option3fplatformpk,
                            //         fexecutemanpk: this.option3fexecutemanpk,
                            //     })
                            //         .then((ref) => {
                            //             resolve(ref);
                            //         })
                            //         .catch((ref) => {
                            //             reject(ref);
                            //         });
                            // }
                        });
                },
                thenFun: (ref) => {
                    this.state.dg.getList();
                }
            },
        ),
        getButtomModal(
            {
                title: '关闭',
                content:
                    ('是否关闭'),
                name: 'close',
                okFun: (row) => {
                    return new Promise((resolve, reject) => {
                        this.state.pojo.close({
                            fpk: row['fpk'],
                        })
                            .then((ref) => {
                                resolve(ref);
                            })
                            .catch((ref) => {
                                reject(ref);
                            });
                    });
                },
                thenFun: (ref) => {
                    this.state.dg.getList();
                }
            },
        ),
        getButtomModal(
            {
                title: '执行',
                content:
                    ('是否执行'),
                name: 'execute',
                okFun: (row) => {
                    return new Promise((resolve, reject) => {
                        this.state.pojo.execute({
                            fpk: row['fpk'],
                        })
                            .then((ref) => {
                                resolve(ref);
                            })
                            .catch((ref) => {
                                reject(ref);
                            });
                    });
                },
                thenFun: (ref) => {
                    this.state.dg.getList();
                }
            },
        ),
        getButtomModal(
            {
                title: '审核',
                content: '确定审核?',
                name: 'verify',
                okFun: (row) => {
                    return new Promise((resolve, reject) => {
                        this.state.pojo.verify({
                            fnumber: row['fnumber'],
                        })
                            .then((ref) => {
                                resolve(ref);
                            })
                            .catch((ref) => {
                                reject(ref);
                            })
                    });
                },
                thenFun: (ref) => {
                    this.state.dg.getList();
                }
            }
        ),
    ]
    onDGRef = (ref) => {
        this.setState({
            dg: ref,
        }, () => {
            //this.state.dg.refInit();
        })
    }
    onDDGRef = (ref) => {
        this.setState({
            ddg: ref,
        }, () => {
            //this.state.dg.refInit();
        })
    }
    onTDTRef = (ref) => {
        console.log(ref);
        this.setState({
            tdT: ref,
        }, () => {
            //this.state.dg.refInit();
        })
    }
    componentDidUpdate = (prevProps, prevState) => {
    }

    componentDidMount() {

    }

    onTopChange = (tpKey) => {
        this.setState({
            tpKey: tpKey,
        });
    };
    onRowClick = (row) => {
        // console.log(row);

    }
    /*
    * prev 之前
    * cur 之后
    */
    editShouldUpdate = (prev, cur, formData) => {
        return new Promise((resolve, reject) => {
            reject(null);
        });
    }

    render() {
        let tpItem = undefined;
        if (this.state.opTP) {
            tpItem = this.state.tp;
        }
        let detailTable = undefined;
        return (
            <div style={{height: '100%'}} ref={(drawerContext) => {
                this.drawerContext = drawerContext;
            }}>
                <div>
                    <MyDataGrid
                        showDetail={(row) => {
                            // this.state.ddg.getList({
                            //     fnumber: row.fnumber,
                            // })
                            //
                            // this.setState({
                            //     showDDG: '',
                            // })
                            this.setState({
                                detailRow: row,
                            })
                        }}
                        querywhere={this.queryData}
                        // isMinHeight={false}
                        onRowClick={this.onRowClick}
                        addOpenIntercept={() => {
                            this.state.dg.setShowEx(true);
                            this.setState({
                                dgRow: undefined,
                                showDDG: 'none',
                            })
                            return false;
                        }}
                        editOpenIntercept={(ref) => {
                            this.state.dg.setShowEx(true);
                            this.setState({
                                dgRow: ref,
                                showDDG: 'none',
                            });
                            return false;
                        }}
                        exBody={(
                            <Title_detail_table
                                titleExData={this.editData}
                                editShouldUpdate={(prev, cur, formData) => {
                                    return this.editShouldUpdate(prev, cur, formData);
                                }}
                                onRef={this.onTDTRef}
                                editingTreeSelectRefFun={(comName, editFromItemMap) => {
                                }}
                                editingOnChange={(value, column, row, editFromItemMap, ...data) => {
                                }}
                                onClose={() => {
                                    this.state.dg.setShowEx(false);
                                    this.setState({
                                        showDDG: 'none',
                                    })
                                }}
                                tdtPojo={new TitleDetailTablePojo({
                                    titleController: 'mmlotteryrule',//'wminvoice',//this.props.data.winData.key
                                    titleRowNames: [
                                        'fcaption',
                                        'fstarttime',
                                        'fendtime',
                                        'fplancodeqty',
                                        'fopenidqty',
                                    ],
                                    detailController: 'mmlotteryruleentry',
                                    detailAction: 'getTitleList',
                                    titleRow: this.state.dgRow,
                                    getDetailPRowName: 'fpk',
                                    detailPRowName: 'fpk',
                                })}
                            />
                        )}
                        onRef={this.onDGRef}
                        pojo={this.state.pojo}
                        isTab={true}
                        bodyWidth={'100%'}
                        detailBodyWidth={'100%'}
                        bodyClass={'box-column'}
                        detailLocation={'r'}
                        detailDBody={this.state.detailRow === undefined ? undefined :
                            <div>
                                <MyDataGrid
                                    isAdd={false}
                                    isSearch={false}
                                    isPage={false}
                                    isMinHeight={false}
                                    isSetTrBus={false}
                                    isTab={false}
                                    initializeList={false}
                                    onRef={(ref) => {
                                        ref.getList({
                                            fpk: this.state.detailRow.fpk,
                                        })
                                    }}
                                    pojo={this.state.dPojo}
                                    getListName={'getTitleList'}
                                    exTrBus={this.exTrBus}
                                />
                            </div>
                        }
                        exTrBus={this.exTrBus}/>
                </div>
                {/*{this.addModal()}*/}

                {/*{tpItem}*/}
            </div>
        );
    }
}

export default index;

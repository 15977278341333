import MyButton from "./button";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {message, Modal} from "antd";
import React from "react";

const {confirm} = Modal;

export const getButtomModal = ({
                                   title = '',
                                   content = '',
                                   name = '',
                                   icon = <ExclamationCircleOutlined/>,
                                   okFun = (row) => {
                                   },
                                   thenFun = (ref) => {
                                   },
                                   testFunc = (row) => {
                                       return true;
                                   },
                                   confirma = false,
                                   func = (row) => {
                                   },
                                   isConfirm = false,
                               } = {}) => {
    console.log(title);
    const mmodel = (row) => confirm({
        // key: Math.random(),
        title: title,
        destroyOnClose: true,
        icon: <ExclamationCircleOutlined/>,
        content: content,
        onOk: () => {
            return new Promise((resolve, reject) => {
                okFun(row)
                    .then((ref) => {
                        message.success(ref.msg);
                        thenFun(ref);
                        resolve();
                        // this.state.dg.getList();
                    })
                    .catch((ref) => {
                        reject();
                        // message.error(ref.msg);
                    });
            });
        },
        onCancel: () => {
            console.log('Cancel');
        },
    });
    // mmodel().destroy();
    // mmodel().update({
    //     content: content,
    // });
    return new MyButton(
        title,
        name,
        (row) => {
            if(!isConfirm){
                console.log(content);
                mmodel(row);
            }
            func(row);
        },
        confirma,
        (row) => {
            // console.log(row);
            return true;
        },
        undefined,
        testFunc,
    );
}

import React, {useState} from 'react';
import './index.less';
import {Row, Button, Table, Tag, Radio, Modal, Tabs, message} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import {get} from "../../axios/Response";
import MyTable from "../../my_module/table/table";
// import Pojo from "./pojo"
import MyDataGrid from "../../my_module/datagrid/datagrid";
import MyButton from "../../my_module/MyClass/button";
import Nestgrid from "../../my_module/nestgrid/nestgrid";
import Pojos from "../pojos"
import {getQueryWhereItem, WhereType} from "../../api/mainPojo";

const {TabPane} = Tabs;

class index extends React.Component {
    constructor(props) {
        super(props);
        this.onDGRef = this.onDGRef.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        // this.addOpenIntercept = this.addOpenIntercept.bind(this);
        // this.editOpenIntercept = this.editOpenIntercept.bind(this);
        this.state = {
            // addModalVisible: false,
            pojo: Pojos.getPojo('sysstaff'),//new Pojo(),
            dg: undefined,
            queryWhere: [],
        }
        console.log(this.pojo);
        this.depItem = undefined;
    }

    exTrBus = [
        // new MyButton(
        //     '审批',
        //     'getItemByID11',
        //     undefined,
        //     (row) => {
        //         console.log(row);
        //         return true;
        //     }
        // ),
    ]
    onDGRef = (ref) => {
        this.setState({
            dg: ref,
        }, () => {
            //this.state.dg.refInit();
        })
    }
    componentDidUpdate = (prevProps, prevState) => {
    }
    // addModal = () => {
    //     let model =
    //         <Modal title="Basic Modal" visible={this.state.addModalVisible}
    //                onCancel={() => {
    //                    this.setState({
    //                        addModalVisible:false,
    //                    })
    //                }}>
    //             <p>Some contents...</p>
    //             <p>Some contents...</p>
    //             <p>Some contents...</p>
    //         </Modal>;
    //     return model;
    // }
    // addOpenIntercept = (ref) => {
    //     console.log(ref);
    //     // this.setState({
    //     //     addModalVisible:true,
    //     // })
    //     return false;
    // }
    // editOpenIntercept = (ref) => {
    //     console.log(ref);
    //     return false;
    // }

    render() {
        return (
            <div>
                {/*{this.addModal()}*/}
                <MyDataGrid
                    isMinHeight={false}
                    showFooter={true}
                    addSubmitInterceptor={(ref) => {
                        if (this.depItem === undefined || this.depItem.fnumber === undefined || this.depItem.fnumber === '') {
                            message.error('请选择部门！')
                            return false;
                        }
                        ref['fdeptpk'] = this.depItem.fnumber;
                        return true;
                    }}
                    addOpenIntercept={(ref) => {
                        return true;
                    }}
                    // bodyWidth={'69%'}
                    // detailBodyWidth={'29%'}
                    bodyClass={'box-row'}
                    detailLocation={'l'}
                    // addOpenIntercept={this.addOpenIntercept}
                    // editOpenIntercept={this.editOpenIntercept}
                    onRef={this.onDGRef}
                    pojo={this.state.pojo}
                    exTrBus={this.exTrBus}
                    initializeList={false}
                    showReload={false}
                    searchFun={(queryWheres) => {
                        console.log(this.state.queryWhere);
                        return [
                            ...queryWheres,
                            ...this.state.queryWhere,
                        ];
                    }}
                    searchIntercept={() => {
                        return this.state.queryWhere.length > 0;
                    }}
                    detailBody={
                        <MyDataGrid
                            // addOpenIntercept={this.addOpenIntercept}
                            // editOpenIntercept={this.editOpenIntercept}
                            isSetTrBus={false}
                            isTab={false}
                            // onRef={this.onDGRef}
                            pojo={Pojos.getPojo('sysorginfo')}
                            exTrBus={this.exTrBus}
                            isAdd={false}
                            isEdit={false}
                            isSearch={false}
                            showRowNames={[
                                // 'fnumber',
                                'fcaption',
                                // 'flevelid'
                            ]}
                            onRowClick={(ref) => {
                                this.depItem = ref;
                                if (this.state.dg !== undefined) {
                                    let queryWhereItem = getQueryWhereItem(
                                        [
                                            {
                                                value: ref.fnumber,
                                                comname: 'fdeptpk',
                                                comtype: WhereType.EqualTo,
                                            }
                                        ]
                                    );
                                    this.state.dg.getList(JSON.parse(JSON.stringify(queryWhereItem)));
                                    this.setState({
                                        queryWhere:queryWhereItem['querywhere']
                                    })
                                }
                            }}
                        />
                    }
                />
            </div>
        );
    }
}

export default index;

class MyButton {
    title = '';
    name = '';
    func = (row) => {
    };
    testFunc = (row) => {
        return true;
    };
    //false 与权限挂钩 true 不挂钩
    confirm = false;
    type = 'primary';
    danger = false;
    style = undefined;

    constructor(title = '', name = '', func = this.func, confirm = false, danger = false, style = undefined, testFunc = this.testFunc) {
        this.title = title;
        this.name = name;
        this.confirm = confirm;
        this.danger = danger;
        if (func !== undefined) {
            this.func = func;
        }
        if (testFunc !== undefined) {
            this.testFunc = testFunc;
        }
        this.style = style;
    }
}

export default MyButton;

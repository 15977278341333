import React from 'react';
import ReactDOM from 'react-dom';
import {
    Button,
    Table,
    Tag,
    Image,
    Radio,
    Menu,
    Dropdown,
    Modal,
    message,
    Drawer,
    Tabs,
    Divider,
    Select,
    Spin, Form
} from 'antd';
import {DownOutlined, UserOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {getValueByProp, setValueByProp} from '../../utils/utils';
// import {SystemInit} from '../../../public/system_init';
import PropTypes from 'prop-types';
import MyPojo from "../MyClass/pojo";
import MyButton from "../MyClass/button";
import MyTable from "../table/table";
import Pojo from "../../pages/rightsgroup/pojo";
import {getActions} from '../../utils/rightUtils'
import FormModal from "../formModal/form_modal";
import MForm from "../mform/mform";
import {getQueryWhereItem, TableType} from "../../api/mainPojo";

import './datagrid.less';
import SearchForm from "../searchform/searchform";
import ReloadOutlined from "@ant-design/icons/lib/icons/ReloadOutlined";
import MDetailWin from "../mDetailWin/mDetailWin";
import MFormWin from "../mFormWin/mFormWin";
import NullDom from "../nullDom/nullDom";
import DeliveredProcedureOutlined from "@ant-design/icons/lib/icons/DeliveredProcedureOutlined";
import {getCsv} from "../../utils/csvUtils";

const {confirm} = Modal;
const {TabPane} = Tabs;
const {Option} = Select;

class Datagrid extends React.Component {
    constructor(props) {
        console.log("Datagrid创建")
        super(props);
        this.onMyTableRef = this.onMyTableRef.bind(this);
        this.init = this.init.bind(this);
        this.setTrBus = this.setTrBus.bind(this);
        this.buClick = this.buClick.bind(this);
        this.showSearchDrawer = this.showSearchDrawer.bind(this);
        this.refInit = this.refInit.bind(this);
        this.searchUpData = this.searchUpData.bind(this);
        this.onMyTableRef = this.onMyTableRef.bind(this);
        this.setShowEx = this.setShowEx.bind(this);
        this.setShowDetailDom = this.setShowDetailDom.bind(this);
        this.addSubmit = this.addSubmit.bind(this);
        this.editSubmit = this.editSubmit.bind(this);
        this.addChildSubmit = this.addChildSubmit.bind(this);
        this.addFormModalRef = undefined;
        this.addFormRef = React.createRef();
        this.editFormModalRef = undefined;
        this.editFormRef = React.createRef();
        this.addChildFormModalRef = undefined;
        this.addChildFormRef = React.createRef();
        this.table = undefined;
        this.selectRow = undefined;
        this.busName = {
            add: 'maintain',
            addNex: 'maintain',
            edit: 'maintain',
            del: 'maintain',
            search: 'search',
        }
        // this.busName = {
        //     add: 'create',
        //     addNex: 'create',
        //     edit: 'update',
        //     del: 'delete',
        //     search: 'list',
        // }
        this.searchQuerywheres = [];
        this.current = 0;
        this.state = {
            loading: false,
            table: undefined,
            pojo: props.pojo,
            headBus: [],
            trBus: [],
            addFormModal: undefined,
            editFormModal: undefined,
            addChildFormModal: undefined,
            searchVisible: false,
            isShowEx: false,
            isShowDetailDom: false,
            detailDom: undefined,
            detailRow: undefined,
            isShowAddFormWin: false,
            addFormWinDom: undefined,
            isShowEditFormWin: false,
            editFormWinDom: undefined,
            isShowChildFormWin: false,
            childFormWinDom: undefined,
        }
    }

    setCurrent = (current) => {
        this.current = current;
    }
    setShowEx = (isShowEx) => {
        this.setState({
            isShowEx: isShowEx,
        })
    }
    setShowDetailDom = (isShowDetailDom, row) => {
        if ((isShowDetailDom === false) || (isShowDetailDom === true && row !== undefined)) {
            this.setState({
                isShowDetailDom: isShowDetailDom,
                detailRow: row,
            }, () => {
                if (!this.state.isShowDetailDom) {
                    this.table.setSearchQuerywheres(this.searchQuerywheres)
                } else {
                    this.searchQuerywheres = this.state.table.searchQuerywheres
                }
                // console.log(this.state.table.searchQuerywheres)
                this.props.showDetail(row)
            })
        }
    }
    getMenu = () => {
        return (
            <Menu onClick={this.onMenuClick}>
                {/*<Menu.Item data={{id: 0}} key={0}>*/}
                {/*    <Tag color="#55acee">*/}
                {/*        Twitter*/}
                {/*    </Tag>*/}
                {/*</Menu.Item>*/}
                {/*<Menu.Item data={{id: 1}} key={1}>*/}
                {/*    <Tag color="#cd201f">*/}
                {/*        Youtube*/}
                {/*    </Tag>*/}
                {/*</Menu.Item>*/}
                {/*<Menu.Item data={{id: 2}} key={2}>*/}
                {/*    <Tag color="#3b5999">*/}
                {/*        Facebook*/}
                {/*    </Tag>*/}
                {/*</Menu.Item>*/}
            </Menu>
        )
    };
    onMenuClick = ({item, key, keyPath, domEvent}) => {
        console.log(item);
        console.log(key);
        console.log(keyPath);
        console.log(domEvent);
    }
    showSearchDrawer = () => {
        this.setState({
            searchVisible: !this.state.searchVisible,
        });
    };
    onSearchDrawerClose = () => {
        this.setState({
            searchVisible: false,
        });
    };
    addSubmit = (values) => {
        let obj = values;
        if (this.props.pojo.prefixName !== '') {
            obj = getValueByProp(values, this.props.pojo.prefixName);
        }
        // console.log(obj);
        // console.log(this.props);
        if (!this.props.addSubmitInterceptor(obj)) {
            return;
        }
        //只传允许新增的
        // let idata = {};
        // for (let k in this.props.pojo.columns) {
        //     let item = this.props.pojo.columns[k];
        //     if (item.isInsert) {
        //         idata[item['comName']] = obj[item['comName']];
        //     }
        // }
        // obj = idata;
        if (this.props.parentData !== undefined && this.props.clientItem !== undefined) {
            obj[this.props.clientItem['cKey']] = this.props.parentData[this.props.clientItem['pKey']];
        }
        this.props.pojo.create(obj)
            .then((res) => {
                console.log(res);
                message.success(res.msg);
                this.table.getList({}, {
                    current: 1,
                });
                this.setState({
                    isShowAddFormWin: false,
                })
                // this.addFormModalRef.init();
                // this.addFormRef.current.init();
                // this.addFormModalRef.current.closeModal();
            })
            .catch((res) => {
                console.log(res);
                // message.error(res.msg);
            });
    }
    editSubmit = (values) => {
        let obj = values;
        if (this.props.pojo.prefixName !== '') {
            obj = getValueByProp(values, this.props.pojo.prefixName);
        }
        //只传允许修改的
        // // let idata = {};
        // // for (let k in this.props.pojo.columns) {
        // //     let item = this.props.pojo.columns[k];
        // //     if (item.isUpdate) {
        // //         idata[item['comName']] = obj[item['comName']];
        // //     }
        // // }
        // idata[this.props.pojo.pkColumn['comName']] = obj[this.props.pojo.pkColumn['comName']];
        // obj = idata;
        this.props.pojo.update(obj, {
            // id: obj[this.props.pojo.pkColumn['comName']]
        })
            .then((res) => {
                this.selectRow = undefined;
                // console.log(res);
                message.success(res.msg);
                this.table.getList({}, {
                    current: 1,
                });
                this.setState({
                    isShowEditFormWin: false,
                })
                // this.editFormRef.current.init();
                // this.editFormModalRef.current.closeModal();
            })
            .catch((res) => {
                console.log(res);
                // message.error(res.msg);
            });
    }
    addChildSubmit = (values, setRow) => {
        if (this.props.pojo.parentColumn !== undefined) {
            values = setValueByProp(values, this.props.pojo.parentColumn.prop, getValueByProp(setRow, this.props.pojo.pkColumn.prop));
        }
        let obj = values;
        if (this.props.pojo.prefixName !== '') {
            obj = getValueByProp(values, this.props.pojo.prefixName);
        }
        this.props.pojo.create(obj)
            .then((res) => {
                // console.log(res);
                setRow = undefined;
                message.success(res.msg);
                this.table.getList();
                // this.addChildFormModalRef.current.closeModal();
                this.setState({
                    isShowChildFormWin: false,
                })
            })
            .catch((res) => {
                console.log(res);
                // message.error(res.msg);
            });
    }

    addFormFilter(column) {
        if (column.isPk ||
            column.isUpPer ||
            column.isUpTime ||
            column.isInPer ||
            column.isInTime ||
            column.isParent ||
            !column.isInsert ||
            column.isRandom) {
            return false;
        } else {
            return true;
        }
    }

    editFormFilter(column) {
        if (column.isPk ||
            column.isUpPer ||
            column.isUpTime ||
            column.isInPer ||
            column.isInTime ||
            column.isParent ||
            !column.isUpdate ||
            column.isRandom) {
            return false;
        } else {
            return true;
        }
    }

    init() {
        let headBuItems = [];
        if (this.props.isAdd) {
            headBuItems.push(
                new MyButton(
                    '新增',
                    this.busName.add,
                    (ref) => {
                        if (this.props.addOpenIntercept(ref)) {
                            // console.log(ref);
                            // let from =
                            //     <MForm
                            //         columnNumber={this.props.columnNumber}
                            //         parentData={this.props.parentData}
                            //         clientItem={this.props.clientItem}
                            //         filter={this.addFormFilter}
                            //         submitFunc={this.addSubmit}
                            //         columns={this.state.pojo.addColumns}
                            //         ref={this.addFormRef}/>
                            // this.setState({
                            //     addFormModal: <FormModal
                            //         submitFunc={
                            //             () => {
                            //                 this.addFormRef.current.submit();
                            //             }}
                            //         ref={(ref) => {
                            //             this.addFormModalRef = ref
                            //         }}
                            //         form={from}/>
                            // }, () => {
                            //     this.addFormModalRef.openModal();
                            // })
                            // console.log(this.state.pojo);
                            this.setState({
                                addFormWinDom: (
                                    <MFormWin
                                        columnNumber={this.props.columnNumber}
                                        parentData={this.props.parentData}
                                        clientItem={this.props.clientItem}
                                        filter={this.addFormFilter}
                                        submitFunc={(values) => {
                                            this.addSubmit(values);
                                        }}
                                        closeFunc={() => {
                                            this.setState({
                                                isShowAddFormWin: false,
                                            })
                                        }}
                                        columns={this.state.pojo.addColumns}
                                        ref={(ref) => {
                                            console.log(ref);
                                            this.addFormModalRef = ref;
                                        }}
                                        data={{
                                            type: 'add',
                                        }}
                                    />
                                ),
                                isShowAddFormWin: true,
                            })
                        }
                    },
                    false,
                    false,
                ),
            )
        }
        if (this.props.isSearch) {
            headBuItems.push(
                new MyButton(
                    '查询',
                    this.busName.search,
                    (ref) => {
                        console.log(ref);
                        this.showSearchDrawer();
                    },
                    true,
                    true,
                    {
                        background: "#FF6347",
                        borderColor: "#FF6347"
                    }
                ),
            )
        }
        headBuItems.push(...this.props.exHeadBus);
        let headBus = this.getBusToMyBu(headBuItems);
        // console.log(this.props.exHeadBus);
        this.setState({
            headBus: headBus,
        }, () => {
            // console.log(this.state.headBus);
        })
        // console.log(this.state.headBus);
    }

    setTrBus = ({
                    record = undefined,
                } = {}) => {
        // console.log(record);
        // this.selectRow = record === undefined ? this.state.table.selectRow : record;
        let setRow = record === undefined ? this.state.table.selectRow : record;
        let trBuItems = [];
        if (this.props.isEdit) {
            trBuItems.push(
                new MyButton(
                    '修改',
                    this.busName.edit,
                    (ref) => {
                        if (setRow === undefined) {
                            message.error('请选择记录！')
                            return;
                        }
                        if (this.props.editOpenIntercept(setRow)) {
                            // console.log(ref);
                            // console.log(this.props.pojo.filterEditColumns(row));
                            this.props.pojo.filterEditColumns(setRow);
                            // let from =
                            //     <MForm
                            //         parentData={this.props.parentData}
                            //         clientItem={this.props.clientItem}
                            //         row={setRow}
                            //         filter={this.editFormFilter}
                            //         submitFunc={this.editSubmit}
                            //         columns={this.state.pojo.editColumns}
                            //         ref={this.editFormRef}
                            //     />
                            // this.setState({
                            //     editFormModal: <FormModal submitFunc={
                            //         () => {
                            //             this.editFormRef.current.submit();
                            //         }} ref={this.editFormModalRef} form={from}/>
                            // }, () => {
                            //     //console.log(this.editFormModalRef);
                            //     this.editFormModalRef.current.openModal();
                            // })
                            console.log(this.state.pojo);
                            this.setState({
                                editFormWinDom: (
                                    <MFormWin
                                        columnNumber={this.props.columnNumber}
                                        parentData={this.props.parentData}
                                        clientItem={this.props.clientItem}
                                        filter={this.editFormFilter}
                                        submitFunc={(values) => {
                                            this.editSubmit(values);
                                            // this.setState({
                                            //     isShowEditFormWin: false,
                                            // })
                                        }}
                                        closeFunc={() => {
                                            this.setState({
                                                isShowEditFormWin: false,
                                            })
                                        }}
                                        columns={this.state.pojo.editColumns}
                                        row={setRow}
                                        ref={(ref) => {
                                            console.log(ref);
                                            this.editFormModalRef = ref;
                                        }}
                                        data={{
                                            type: 'edit',
                                        }}
                                    />
                                ),
                                isShowEditFormWin: true,
                            })
                        }
                    },
                    false,
                    false,
                    {
                        background: "#228B22",
                        borderColor: "#228B22"
                    },
                    (row) => {
                        return this.props.editTestFun(row);
                    }
                ),
            )
        }
        if (this.props.isDel) {
            trBuItems.push(
                new MyButton(
                    '删除',
                    this.busName.del,
                    (ref) => {
                        if (setRow === undefined) {
                            message.error('请选择记录！')
                            return;
                        }
                        //console.log(ref);
                        confirm({
                            title: '删除',
                            icon: <ExclamationCircleOutlined/>,
                            content: '是否删除记录',
                            okText: '确定',
                            okType: 'danger',
                            cancelText: '取消',
                            onOk: () => {
                                // console.log('OK');
                                // console.log(this.props.pojo.pkColumn);
                                let delParams = {};
                                if (this.props.pojo.delRowName !== '') {
                                    delParams[this.props.pojo.delRowName] = getValueByProp(setRow, this.props.pojo.columnsMap[this.props.pojo.delRowName].prop);
                                } else {
                                    delParams[this.props.pojo.pkColumn['comName']] = getValueByProp(setRow, this.props.pojo.pkColumn.prop);
                                }
                                // delParams[this.props.pojo.pkColumn.propRow] = getValueByProp(row, this.props.pojo.pkColumn.prop);
                                this.props.pojo.delete(delParams)
                                    .then((res) => {
                                        // console.log(res);
                                        setRow = undefined;
                                        message.success(res.msg);
                                        this.table.getList();
                                    })
                                    .catch((res) => {
                                        console.log(res);
                                        message.error(res.msg);
                                    });
                            },
                            onCancel: () => {
                                console.log('Cancel');
                            },
                        });
                    },
                    false,
                    true,
                    undefined,
                    (row) => {
                        return this.props.delTestFun(row);
                    }
                ),
            )
        }
        if (this.props.isDetail) {
            trBuItems.push(
                new MyButton(
                    '查看',
                    this.busName.search,
                    (ref) => {
                        if (setRow === undefined) {
                            message.error('请选择记录！')
                            return;
                        }
                        this.setShowDetailDom(true, setRow);
                        // this.props.showDetail(setRow);
                    },
                    true,
                    true,
                    undefined,
                ),
            )
        }
        // if (this.props.detailBody !== undefined) {
        //     trBuItems.push(
        //         new MyButton(
        //             '查看',
        //             this.busName.del,
        //             (ref) => {
        //                 if (setRow === undefined) {
        //                     message.error('请选择记录！')
        //                     return;
        //                 }
        //                 this.props.showDetail(setRow);
        //             },
        //             false,
        //             true,
        //             undefined,
        //         ),
        //     )
        // }
        let trBus = this.getBusToMyBu(trBuItems, setRow);
        // console.log(trBus);

        let trChildBu = new MyButton(
            '新增下级',
            this.busName.addNex,
            (ref) => {
                if (setRow === undefined) {
                    message.error('请选择记录！')
                    return;
                }
                //console.log(ref);
                // let from = <MForm filter={this.addFormFilter} submitFunc={addChildSubmit} exParam={setRow}
                //                   columns={this.state.pojo.addColumns}
                //                   ref={this.addChildFormRef}/>
                // this.setState({
                //     addChildFormModal: <FormModal submitFunc={
                //         () => {
                //             this.addChildFormRef.current.submit();
                //         }} ref={this.addChildFormModalRef} form={from}/>
                // }, () => {
                //     console.log(this.addChildFormModalRef);
                //     this.addChildFormModalRef.current.openModal();
                // })
                this.setState({
                    childFormWinDom: (
                        <MFormWin
                            columnNumber={this.props.columnNumber}
                            parentData={this.props.parentData}
                            clientItem={this.props.clientItem}
                            filter={this.addFormFilter}
                            submitFunc={(values) => {
                                this.addChildSubmit(values, setRow);
                                // this.setState({
                                //     isShowChildFormWin: false,
                                // })
                            }}
                            closeFunc={() => {
                                this.setState({
                                    isShowChildFormWin: false,
                                })
                            }}
                            columns={this.state.pojo.addColumns}
                            exParam={setRow}
                            ref={(ref) => {
                                console.log(ref);
                                this.addChildFormModalRef = ref;
                            }}
                        />
                    ),
                    isShowChildFormWin: true,
                })
            }
        );
        let dropdowns = [];
        if (this.props.pojo.tableType === TableType.TG) {
            dropdowns = this.getDropdownsToMyBu([trChildBu, ...this.props.exTrBus], setRow);
        } else {
            dropdowns = this.getDropdownsToMyBu(this.props.exTrBus, setRow);
        }
        //console.log(this.props.exTrBus);

        if (dropdowns !== undefined) {
            trBus.push(dropdowns);
        }
        //console.log(trBus);
        // if (this.props.isTrBusToTitleBus) {
        //     this.setState({
        //         trBus: trBus
        //     }, () => {
        //         console.log(this.state.trBus);
        //     })
        // }
        // console.log(this.state.trBus);
        return trBus;
    }

    getBusToMyBu(bus, row = undefined) {
        // console.log(bus);
        bus = getActions(this.state.pojo, bus, row);
        let retBus = [];
        for (let i in bus) {
            if (bus[i].testFunc(row)) {
                retBus.push(
                    <Button onClick={() => bus[i].func(row)} danger={bus[i].danger}
                            type="primary" style={bus[i].style}>{bus[i].title}</Button>
                )
            }
        }
        return retBus;
    }

    buClick = ({key, item}) => {
        console.log(key);
        console.log(item.props.item);
        console.log(item.props.data);
        item.props.item.func(item.props.data);
        //console.log(row);
    }

    getDropdownsToMyBu(bus, row = undefined) {
        // console.log(bus);
        // console.log(this.state.pojo);
        bus = getActions(this.state.pojo, bus, row);
        // console.log(bus);
        let retBus = [];
        for (let i in bus) {
            if (bus[i].testFunc(row)) {
                retBus.push(
                    <Menu.Item item={bus[i]} data={row} key={bus[i].name} icon={<UserOutlined/>}>
                        <span>{bus[i].title}</span>
                    </Menu.Item>
                )
            }
        }
        // console.log(retBus);
        let retDropdown = undefined;
        if (retBus.length > 0) {
            let menu = (
                <Menu onClick={this.buClick}>
                    {retBus}
                </Menu>
            )
            retDropdown = (
                <Dropdown overlay={menu}>
                    <Button>
                        更多 <DownOutlined/>
                    </Button>
                </Dropdown>
            )
        }
        return retDropdown;
    }


    // componentDidMount() {
    //     this.props.onRef(this);
    //     this.init();
    // }

    componentDidMount() {
        this.props.onRef && this.props.onRef(this);
        this.init();
    }

    onMyTableRef = (ref) => {
        this.table = ref;
        this.setState({
            table: ref,
        }, () => {

        })
        this.refInit();
    }
    refInit = () => {
        // console.log(this.props.querywhere);
        this.setState({
            pojo: this.state.pojo,
            loingOK: true,
        }, () => {
            this.table.init();
            if (this.props.initializeList) {
                if (this.props.querywhere.length > 0) {
                    this.table.setPropsWhereQuery(this.props.querywhere);
                    // this.table.getList({
                    //     querywhere: JSON.stringify(this.props.querywhere),
                    // });
                    this.table.getList();
                } else {
                    this.table.getList();
                }
            }
        });
        // this.state.pojo.init()
        //     .then((res) => {
        //         // console.log(res);
        //
        //     })
        //     .catch((res) => {
        //         console.log(res);
        //     });
    }
    getList = (params = {}) => {
        if (this.searchQuerywheres.length > 0) {
            if (params['querywhere'] !== undefined) {
                params['querywhere'] = [
                    ...params['querywhere'],
                    ...this.searchQuerywheres,
                ];
            } else {
                params['querywhere'] = this.searchQuerywheres;
            }
        }
        // params = this.props.testGetListParams(params)
        // console.log(params)
        // params['querywhere'] = params['querywhere'];
        this.table.getList(params);
    }
    onRowClick = (row) => {
        this.props.onRowClick(row);
        if (this.props.isTrBusToTitleBus) {
            this.setTrBus(row);
        }
    }

    searchUpData(data) {
        if (!this.props.searchIntercept()) {
            this.setState({
                searchVisible: false
            });
            return;
        }
        let searchData = [];
        for (let k in data) {
            console.log(data[k]);
            // console.log(data[k].value.length);
            if (data[k].value instanceof Array) {
                let isK = false
                console.log(data[k])
                for (let j in data[k].value) {
                    if (data[k].value[j] === '') {
                        isK = true
                        break
                    }
                }
                if (isK) {
                    data[k].value = []
                }
            }
            if (data[k].value !== undefined && !(data[k].value.length === 0)) {
                let item = {};
                item = {
                    value: data[k].value,
                    comname: k,
                    comtype: data[k].type,
                };
                searchData.push(item);
            }
        }
        let listQuerywheres = [
            ...this.props.searchFun(searchData),
            // ...this.props.querywhere,
        ];
        this.searchQuerywheres = searchData;
        console.log(listQuerywheres);
        if (this.table !== undefined) {
            this.table.setSearchQuerywheres(listQuerywheres);
            this.table.getList(
                {
                    // querywhere: listQuerywheres,//JSON.stringify(
                },
                {
                    current: 1,
                    rows: 20,
                    page: 0,
                }
            )
        } else {
            this.props.pojo[this.props.getListName]({
                querywhere: listQuerywheres,
            })
                .then((res) => {
                    let rows = res.data;
                    if (res.data === undefined) {
                        rows = res;
                        res['data'] = {};
                    } else {
                        rows = res.data.rows;
                    }
                    if (rows === undefined) {
                        rows = res.data;
                    }
                    if (!(rows instanceof Array)) {
                        rows = [rows];
                    }
                    // for (let k = 0; k !== rows.length; k++) {
                    //     rows[k]['key'] = this.rowKeyFun(rows[k]);
                    // }
                    this.data = rows;
                    // console.log(this.data);
                    //console.log(res);
                    this.setState({
                        data: rows,
                        pagination: {
                            ...this.state.pagination,
                            ...{
                                total: res.data.total === undefined ? rows.length : res.data.total
                            }
                        }
                    });
                    this.props.onListHook(rows)
                })
                .catch((ref) => {
                    console.log(ref)
                })
        }

        this.setState({
            searchVisible: false,
            searchQuerywheres: listQuerywheres,
        });
    }

    render() {
        const {headBus, addFormModal, editFormModal, addChildFormModal, table} = this.state;
        let className = 'site-drawer-render-in-current-wrapper';
        if (this.props.parentData !== undefined && this.props.clientItem !== undefined) {
            className = 'site-drawer-render-in-current-wrapper-client';
        }
        if (!this.props.isMinHeight && this.props.bodyClass === 'box-column') {
            className = 'site-drawer-render-in-current-wrapper-client-no-min-h';
        }
        let detailBody = undefined;
        if (this.props.detailBody !== undefined) {
            if (this.props.bodyClass === 'box-column') {
                className += ' detail';
            }
            detailBody = (
                // <div style={{width: this.props.detailBodyWidth}}>
                //     {this.props.detailBody}
                // </div>

                <div style={{flexGrow: '1', minWidth: 'calc(40vw - 210px)', maxWidth: 'calc(40vw - 210px)'}}>
                    {this.props.detailBody}
                </div>
            )
        }
        let bus = headBus;
        if (this.props.isTrBusToTitleBus) {
            bus = [
                ...headBus,
                ...this.setTrBus()
            ];
        }
        let busBody = undefined;
        if ((table !== undefined || this.props.myDom !== undefined) && bus.length > 0) {
            busBody = (
                <div style={{
                    float: 'left',
                }}>
                    {bus}
                </div>
            )
        }
        let reloadDom = undefined;
        let toExcelButDom = undefined
        if (this.props.pojo.isToExcel) {
            //导出
            toExcelButDom = (
                <DeliveredProcedureOutlined style={{fontSize: '20px', paddingLeft: '20px'}}
                                            onClick={() => {
                                                this.setState({
                                                    loading: true,
                                                })
                                                // console.log(this.table.querywhere)
                                                let pData = {
                                                    ...this.table.querywhere,
                                                    page: 0,
                                                    rows: 0,
                                                }
                                                if (this.props.pojo.orderRowName !== '') {
                                                    pData['orderby'] = this.props.pojo.orderRowName + ' ' + this.props.pojo.orderType
                                                }
                                                this.props.pojo[this.props.getListName](
                                                    pData
                                                )
                                                    .then((ref) => {
                                                        console.log(ref)
                                                        message.success(ref.msg)
                                                        this.setState({
                                                            loading: false,
                                                        })
                                                        getCsv({
                                                            pojo: this.props.pojo,
                                                            rows: ref.data.rows,
                                                            fileName: this.props.pojo.cName,
                                                            columns: this.props.pojo.columns,
                                                        })
                                                    })
                                                    .catch((ref) => {
                                                        message.error(ref.msg)
                                                        this.setState({
                                                            loading: false,
                                                        })
                                                        console.log(ref)
                                                    })
                                            }}/>
            )
        }
        if (this.props.showReload) {
            reloadDom = (
                <div style={{marginRight: '20px'}}>
                    <ReloadOutlined style={{fontSize: '20px', paddingLeft: '20px'}}
                                    onClick={() => {
                                        if (this.state.table !== undefined) {
                                            if (this.props.querywhere.length > 0) {
                                                this.table.getList({
                                                    querywhere: (
                                                        [
                                                            // this.props.querywhere,
                                                            ...this.searchQuerywheres,
                                                        ]
                                                    ),
                                                });
                                            } else {
                                                this.table.getList();
                                            }
                                            // this.state.table.getList();
                                        }
                                    }}/>
                    {toExcelButDom}
                </div>
            )
        }
        let hDom = undefined;
        let orderByDom = undefined
        if (this.props.isOrderBy) {
            orderByDom = (
                <>
                    <Select
                        onChange={(value) => {
                            console.log(value)
                            let pojo = this.state.pojo
                            pojo.orderRowName = value
                            this.setState({
                                pojo: pojo,
                            }, () => {
                                this.getList()
                            })
                        }}
                        defaultValue={this.state.pojo.orderRowName}
                        style={{width: 120}}>
                        {
                            this.state.pojo.columns.map((item, index) => (
                                item.isEx === true || item.showOrderSelect === false || item.show === false ? '' :
                                    <li key={item.comName}>{item.name}</li>
                            ))
                        }
                    </Select>
                    <Select
                        onChange={(value) => {
                            console.log(value)
                            let pojo = this.state.pojo
                            pojo.orderType = value
                            this.setState({
                                pojo: pojo,
                            }, () => {
                                this.getList()
                            })
                        }}
                        defaultValue={this.state.pojo.orderType} style={{width: 120}}>
                        <Option value="ASC">升序</Option>
                        <Option value="DESC">降序</Option>
                    </Select>
                </>
            )
        }
        if (busBody !== undefined || reloadDom !== undefined) {
            hDom = (
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    height: '35px'
                }}>

                    <div style={{flex: 'auto', textAlign: 'left'}}>
                        {busBody}
                        {orderByDom}
                    </div>
                    {reloadDom}
                </div>
            );
        }
        // detailBody = (
        //     <div style={{flexGrow: '1'}}>
        //         {detailBody}
        //     </div>
        // );
        // width: this.props.bodyWidth,
        let minHeight = 'calc(100vh - 140px)';
        if (!this.props.isMinHeight) {
            minHeight = '';
        }
        let fS = {flexGrow: '1', overflow: 'auto', maxWidth: 'calc(100vw - 210px)'};
        if (detailBody !== undefined) {
            fS = {flexGrow: '1', minWidth: 'calc(70vw - 210px)', maxWidth: 'calc(70vw - 210px)'};
        }
        let tableBody = (
            <div style={{display: 'flex', minHeight: minHeight, maxHeight: minHeight}} className={this.props.bodyClass}>
                {
                    this.props.detailLocation === 'l' ? detailBody : undefined
                }
                <div style={fS}>
                    {hDom}
                    <div>
                        <Spin spinning={this.state.loading} delay={500}>
                            <MyTable
                                testGetListParams={this.props.testGetListParams}
                                testShowCom={this.props.testShowCom}
                                setCurrentFun={this.setCurrent}
                                current={this.current}
                                isPage={this.props.isPage}
                                isMinHeight={this.props.isMinHeight && this.props.detailBody === undefined}
                                showFooter={this.props.showFooter}
                                rowSelectionOnChange={(selectedRowKeys, selectedRows) => {
                                    // console.log(selectedRowKeys);
                                }}
                                onRowClick={this.onRowClick}
                                showRowNames={this.props.showRowNames}
                                rowExpandable={this.props.rowExpandable}
                                pojo={this.props.pojo}
                                isSetTrBus={!this.props.isTrBusToTitleBus && this.props.isSetTrBus}
                                setTrBus={this.setTrBus}
                                parentData={this.props.parentData}
                                clientItem={this.props.clientItem}
                                getListName={this.props.getListName}
                                onRef={this.onMyTableRef}
                                rowTrBusWidth={320}
                                // rowTrBusWidth={(this.props.detailBody !== undefined || this.props.detailDBody !== undefined) ? 350 : 250}
                            >
                            </MyTable>
                        </Spin>
                    </div>
                </div>
                {
                    this.props.detailLocation === 'r' ? detailBody : undefined
                }
            </div>
        )
        let bodyCs = (
            // <TabPane
            //     tab={this.props.pojo.cName}
            //     key="1">
            <div>
                {tableBody}
            </div>
            // </TabPane>
        );
        let tabBody = (
            // <Tabs
            //     // type="card"
            //     defaultActiveKey="1">
            <div>
                {bodyCs}
            </div>
            // </Tabs>
        );
        let detailDom = (
            <MDetailWin
                onClose={() => {
                    this.setShowDetailDom(false, undefined);
                }}
                detailBody={this.props.detailDBody}
                pojo={this.props.pojo}
                row={this.state.detailRow}
            />
        );
        console.log(this.state);
        if (!this.props.isTab) {
            bodyCs = tableBody;
            tabBody = bodyCs;
        }
        if (this.props.exBody !== undefined && this.state.isShowEx) {
            bodyCs = this.props.exBody;
            tabBody = bodyCs;
        }
        if (this.state.isShowDetailDom && this.state.detailRow !== undefined) {
            bodyCs = detailDom;
            tabBody = bodyCs;
        }
        if (this.state.isShowAddFormWin && this.state.addFormWinDom !== undefined) {
            tabBody = bodyCs = this.state.addFormWinDom;
        }
        if (this.state.isShowEditFormWin && this.state.editFormWinDom !== undefined) {
            tabBody = bodyCs = this.state.editFormWinDom;
        }
        if (this.state.isShowChildFormWin && this.state.childFormWinDom !== undefined) {
            tabBody = bodyCs = this.state.childFormWinDom;
        }
        if (this.props.myDom !== undefined) {
            console.log(busBody)
            tabBody = bodyCs = (
                <>
                    {hDom}
                    {this.props.myDom}
                </>
            )
        }
        return (
            <div>
                {
                    addFormModal !== undefined && addFormModal
                }
                {
                    editFormModal !== undefined && editFormModal
                }
                {
                    addChildFormModal !== undefined && addChildFormModal
                }

                <div key={"radio_d"}>
                    <div className={className}>
                        <Drawer
                            // title="查询"
                            placement="top"
                            closable={false}
                            height={'70vh'}
                            onClose={this.onSearchDrawerClose}
                            visible={this.state.searchVisible}
                            // getContainer={false}
                            getContainer={this.props.drawerContext === undefined ? false : () => {
                                return this.props.drawerContext;
                            }}
                            style={{position: 'absolute'}}
                        >
                            <SearchForm
                                onClose={this.onSearchDrawerClose}
                                upData={this.searchUpData}
                                pojo={this.props.pojo}
                                filtration={this.props.searchFormFiltration}
                            />
                        </Drawer>
                        {tabBody}
                    </div>
                </div>
            </div>
        )
    }
}

Datagrid.defaultProps = {
    searchFormFiltration: (row) => {
        return row;
    },
    columnNumber: 3,
    isAdd: true,
    isEdit: true,
    isDel: true,
    isDetail: true,
    isSearch: true,
    exHeadBus: [],
    exTrBus: [],
    showRowNames: [],
    onRef: (ref) => {
    },
    onRowClick: (row) => {
    },
    rowExpandable: (row) => {
        return true;
    },
    parentData: undefined,
    clientItem: undefined,
    addOpenIntercept: (ref) => {
        return true;
    },
    editOpenIntercept: (ref) => {
        return true;
    },
    //附加按钮 位置
    isTrBusToTitleBus: false,
    isSetTrBus: true,
    exBody: undefined,
    getListName: 'getList',
    //是否初始化列表
    initializeList: true,
    min_h: true,
    drawerContext: undefined,
    detailBody: undefined,
    isTab: true,
    bodyWidth: '100%',
    detailBodyWidth: '100%',
    //box-row
    bodyClass: 'box-column',
    //l as left or r as right
    detailLocation: 'r',
    //新增事件过滤
    addSubmitInterceptor: (ref) => {
        return true;
    },
    showFooter: true,
    isMinHeight: true,
    isPage: true,
    querywhere: [],
    editTestFun: () => {
        return true;
    },
    delTestFun: () => {
        return true;
    },
    showDetail: (row) => {
    },
    detailDBody: undefined,
    showReload: true,
    searchFun: (queryWheres) => {
        return queryWheres;
    },
    //查询拦截器
    searchIntercept: () => {
        return true;
    },
    testShowCom: (item) => {
        console.log(item)
        return true
    },
    testGetListParams: (params) => {
        return params
    },
    isOrderBy: true,
    myDom: undefined,
    onListHook: (rows) => {
    },
}
Datagrid.propTypes = {
    searchFormFiltration: PropTypes.func,
    columnNumber: PropTypes.number,
    pojo: PropTypes.instanceOf(MyPojo).isRequired,
    isAdd: PropTypes.bool,
    isEdit: PropTypes.bool,
    isDel: PropTypes.bool,
    isDetail: PropTypes.bool,
    isSearch: PropTypes.bool,
    exHeadBus: PropTypes.arrayOf(MyButton),
    exTrBus: PropTypes.arrayOf(MyButton),
    onRef: PropTypes.func,
    showRowNames: PropTypes.arrayOf(PropTypes.string),
    onRowClick: PropTypes.func,
    rowExpandable: PropTypes.func,
    parentData: PropTypes.object,
    clientItem: PropTypes.object,
    addOpenIntercept: PropTypes.func,
    editOpenIntercept: PropTypes.func,
    isTrBusToTitleBus: PropTypes.bool,
    isSetTrBus: PropTypes.bool,
    exBody: PropTypes.node,
    getListName: PropTypes.string,
    initializeList: PropTypes.bool,
    min_h: PropTypes.bool,
    drawerContext: PropTypes.object,
    detailBody: PropTypes.object,
    isTab: PropTypes.bool,
    bodyWidth: PropTypes.string,
    detailBodyWidth: PropTypes.string,
    bodyClass: PropTypes.string,
    detailLocation: PropTypes.string,
    addSubmitInterceptor: PropTypes.func,
    showFooter: PropTypes.bool,
    isMinHeight: PropTypes.bool,
    isPage: PropTypes.bool,
    querywhere: PropTypes.array,
    editTestFun: PropTypes.func,
    delTestFun: PropTypes.func,
    showDetail: PropTypes.func,
    detailDBody: PropTypes.object,
    showReload: PropTypes.bool,
    searchFun: PropTypes.func,
    searchIntercept: PropTypes.func,
    testShowCom: PropTypes.func,
    testGetListParams: PropTypes.func,
    isOrderBy: PropTypes.bool,
    myDom: PropTypes.object,
    onListHook: PropTypes.func,
}
export default Datagrid;

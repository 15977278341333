//设备信息
import emequipment from './emequipment/pojo'
//设备维护
import emmaintenance from './emmaintenance/pojo'
//设备事件
import emmonitoring from './emmonitoring/pojo'
//BOM表
import fmbom from './fmbom/pojo'
//BOM表条目
import fmbomentry from './fmbomentry/pojo'
//物料信息
import fminventory from './fminventory/pojo'
//存货管理设置
import fminventoryconfig from './fminventoryconfig/pojo'
//标签卡信息
import fmlabelcard from './fmlabelcard/pojo'
//包装单位信息
import fmpackingunit from './fmpackingunit/pojo'
//月台信息
import fmplatform from './fmplatform/pojo'
//工序信息
import fmprocess from './fmprocess/pojo'
//产线信息
import fmproductline from './fmproductline/pojo'
//仓储信息
import fmstorage from './fmstorage/pojo'
//承运公司信息
import lvmcarrier from './lvmcarrier/pojo'
//物流信息
import lvmlogistics from './lvmlogistics/pojo'
//客户(项目)信息
import mmcustomer from './mmcustomer/pojo'
//销售计划
import mmplan from './mmplan/pojo'
//销售计划条目
import mmplanentry from './mmplanentry/pojo'
//销售订单
import mmsale from './mmsale/pojo'
//销售订单条目
import mmsaleentry from './mmsaleentry/pojo'
//追朔防窜记录
import mmtracerecord from './mmtracerecord/pojo'
//生产订单管理
import pmorder from './pmorder/pojo'
//生产订单条目
import pmorderentry from './pmorderentry/pojo'
//工序监测过程
import pmprocesscheck from './pmprocesscheck/pojo'
//工序上料
import pmprocessloading from './pmprocessloading/pojo'
//工序记录
import pmprocessrecord from './pmprocessrecord/pojo'
//生产任务管理
import pmtask from './pmtask/pojo'
//采购需求计划
import smplan from './smplan/pojo'
//采购需求计划条目
import smplanentry from './smplanentry/pojo'
//采购订单
import smpurchase from './smpurchase/pojo'
//采购订单条目
import smpurchaseentry from './smpurchaseentry/pojo'
//供应商信息
import smsupplier from './smsupplier/pojo'
//调拨订单
import smtransfer from './smtransfer/pojo'
//调拨订单条目
import smtransferentry from './smtransferentry/pojo'
//系统设置
import sysconfigure from './sysconfigure/pojo'
//功能项信息
import sysfeature from './sysfeature/pojo'
//职能信息
import sysfunction from './sysfunction/pojo'
//编号管理
import sysinvnumber from './sysinvnumber/pojo'
//登录日志
import sysloginlog from './sysloginlog/pojo'
//操作日志
import sysoptlog from './sysoptlog/pojo'
//组织架构
import sysorginfo from './sysorginfo/pojo'
//权限组
import sysrolegroup from './sysrolegroup/pojo'
//组权限
import sysrolegroupright from './sysrolegroupright/pojo'
//组用户
import sysrolegroupusers from './sysrolegroupusers/pojo'
//员工信息
import sysstaff from './sysstaff/pojo'
//调整单管理
import wmchange from './wmchange/pojo'
//盘点单管理
import wmcheck from './wmcheck/pojo'
//结存表管理
import wmcheckout from './wmcheckout/pojo'
//实时总库存
import checkoutSUMpojo from './wmcheckout/checkoutSUMpojo'
//发货单管理
import wminvoice from './wminvoice/pojo'
//发货单条目
import wminvoiceentry from './wminvoiceentry/pojo'
//件码信息
import wmitemcode from './wmitemcode/pojo'
//垛码信息
import wmpackagecode from './wmpackagecode/pojo'
//包装码信息
import wmpackcode from './wmpackcode/pojo'
//收货单管理
import wmreceipt from './wmreceipt/pojo'
//收货单条目
import wmreceiptentry from './wmreceiptentry/pojo'
//出入库记录
import wmstockrecord from './wmstockrecord/pojo'
//过账策略管理
import wmtactics from './wmtactics/pojo'
//过账记录
import wmtacticsrecord from './wmtacticsrecord/pojo'
//出入库任务
import wmtask from './wmtask/pojo'
//层码信息
import wmtiercode from './wmtiercode/pojo'
//单位码信息
import wmunitcode from './wmunitcode/pojo'
//码表信息
import wmcodeopt from './wmcodeopt/pojo'
//消费者信息
import mmconsumer from './mmconsumer/pojo'
//抽奖码查询记录
import mmlotteryrecord from './mmlotteryrecord/pojo'
//奖品信息
import mmprize from './mmprize/pojo'
//中奖信息
import mmlotteryinfo from './mmlotteryinfo/pojo'
//抽奖规则
import mmlotteryrule from './mmlotteryrule/pojo'
//抽奖规则明细
import mmlotteryruleentry from './mmlotteryrule/MMlotteryruleentryPojo'
//批次信息
import rpbatchinfo from './rpbatchinfo/pojo'
//批次信息
import rprecall from './rprecall/pojo'
//出入库日报
import rpstockdaily from './rpstockdaily/pojo'
//出入库日报
import rpproductdaily from './rpproductdaily/pojo'
//产成品统计列表
import rpproduct from './rpproduct/pojo'
//收发货量趋势图
import getstocksum from './getstocksum/pojo'
//产能趋势图
import getproductsum from './getproductsum/pojo'
//查询量趋势图
import getfeelingsum from './getfeelingsum/pojo'
//产成品统计表
import getproducttotal from './getproducttotal/pojo'

class Pojos {
    static pojos = {
        //设备信息
        'emequipment': new emequipment(),
        //设备维护
        'emmaintenance': new emmaintenance(),
        //设备事件
        'emmonitoring': new emmonitoring(),
        //BOM表
        'fmbom': new fmbom(),
        //BOM表条目
        'fmbomentry': new fmbomentry(),
        //物料信息
        'fminventory': new fminventory(),
        //存货管理设置
        'fminventoryconfig': new fminventoryconfig(),
        //标签卡信息
        'fmlabelcard': new fmlabelcard(),
        //包装单位信息
        'fmpackingunit': new fmpackingunit(),
        //月台信息
        'fmplatform': new fmplatform(),
        //工序信息
        'fmprocess': new fmprocess(),
        //产线信息
        'fmproductline': new fmproductline(),
        //仓储信息
        'fmstorage': new fmstorage(),
        //承运公司信息
        'lvmcarrier': new lvmcarrier(),
        //物流信息
        'lvmlogistics': new lvmlogistics(),
        //客户(项目)信息
        'mmcustomer': new mmcustomer(),
        //销售计划
        'mmplan': new mmplan(),
        //销售计划条目
        'mmplanentry': new mmplanentry(),
        //销售订单
        'mmsale': new mmsale(),
        //销售订单条目
        'mmsaleentry': new mmsaleentry(),
        //追朔防窜记录
        'mmtracerecord': new mmtracerecord(),
        //生产订单管理
        'pmorder': new pmorder(),
        //生产订单条目
        'pmorderentry': new pmorderentry(),
        //工序监测过程
        'pmprocesscheck': new pmprocesscheck(),
        //工序上料
        'pmprocessloading': new pmprocessloading(),
        //工序记录
        'pmprocessrecord': new pmprocessrecord(),
        //生产任务管理
        'pmtask': new pmtask(),
        //采购需求计划
        'smplan': new smplan(),
        //采购需求计划条目
        'smplanentry': new smplanentry(),
        //采购订单
        'smpurchase': new smpurchase(),
        //采购订单条目
        'smpurchaseentry': new smpurchaseentry(),
        //供应商信息
        'smsupplier': new smsupplier(),
        //调拨订单
        'smtransfer': new smtransfer(),
        //调拨订单条目
        'smtransferentry': new smtransferentry(),
        //系统设置
        'sysconfigure': new sysconfigure(),
        //功能项信息
        'sysfeature': new sysfeature(),
        //职能信息
        'sysfunction': new sysfunction(),
        //编号管理
        'sysinvnumber': new sysinvnumber(),
        //登录日志
        'sysloginlog': new sysloginlog(),
        //操作日志
        'sysoptlog': new sysoptlog(),
        //组织架构
        'sysorginfo': new sysorginfo(),
        //权限组
        'sysrolegroup': new sysrolegroup(),
        //组权限
        'sysrolegroupright': new sysrolegroupright(),
        //组用户
        'sysrolegroupusers': new sysrolegroupusers(),
        //员工信息
        'sysstaff': new sysstaff(),
        //调整单管理
        'wmchange': new wmchange(),
        //盘点单管理
        'wmcheck': new wmcheck(),
        //结存表管理
        'wmcheckout': new wmcheckout(),
        //实时库存
        'wmcheckout_getstateinventory': new wmcheckout(
            {
                iniForFilter:(row) => {
                    if(row['comName'] === 'fcheckoutdate'　|| row['comName'] === 'fstateid'){
                        row['tdShow'] = true;
                        row['isInsert'] = true;
                        row['isUpdate'] = true;
                    }
                }
            }
        ),
        //实时总库存
        'checkoutSUMpojo': new checkoutSUMpojo(),
        //发货单管理
        'wminvoice': new wminvoice(),
        //销售出库单
        'wminvoice_ftypeid_EqualTo_1': new wminvoice(
            {
                iniForFilter:(row) => {
                    if(row['comName'] === 'fcustomer'){
                        row['tdShow'] = true;
                        row['isInsert'] = true;
                        row['isUpdate'] = true;
                    }
                }
            }
        ),
        //调拨出库单
        'wminvoice_ftypeid_EqualTo_2': new wminvoice(),
        //生产领料单
        'wminvoice_ftypeid_EqualTo_3': new wminvoice(
            {
                iniForFilter:(row) => {
                    if(row['comName'] === 'fcustomer'){
                        row['name'] = '生产车间';
                        row['tdShow'] = true;
                        row['isInsert'] = true;
                        row['isUpdate'] = true;
                    }
                }
            }
        ),
        //抽检出库单
        'wminvoice_ftypeid_EqualTo_4': new wminvoice(),
        //破损出库单
        'wminvoice_ftypeid_EqualTo_5': new wminvoice(),
        //返工出库单
        'wminvoice_ftypeid_EqualTo_6': new wminvoice(),
        //其他出库单
        'wminvoice_ftypeid_EqualTo_7': new wminvoice(),
        //发货单条目
        'wminvoiceentry': new wminvoiceentry(),
        //件码信息
        'wmitemcode': new wmitemcode(),
        //垛码信息
        'wmpackagecode': new wmpackagecode(),
        //包装码信息
        'wmpackcode': new wmpackcode(),
        //收货单管理
        'wmreceipt': new wmreceipt(),
        //生产入库单
        'wmreceipt_ftypeid_EqualTo_1': new wmreceipt(),
        //调拨入库单
        'wmreceipt_ftypeid_EqualTo_2': new wmreceipt(
            {
                iniForFilter:(row) => {
                    if(row['comName'] === 'fsupplier'){
                        row['name'] = '调出组织';
                        row['tdShow'] = true;
                        row['isInsert'] = true;
                        row['isUpdate'] = true;
                    }
                }
            }
        ),
        //采购入库单
        'wmreceipt_ftypeid_EqualTo_3': new wmreceipt(
            {
                iniForFilter:(row) => {
                    if(row['comName'] === 'fsupplier'){
                        row['tdShow'] = true;
                        row['isInsert'] = true;
                        row['isUpdate'] = true;
                    }
                }
            }
        ),
        //其他入库单
        'wmreceipt_ftypeid_EqualTo_4': new wmreceipt(
            {
                iniForFilter:(row) => {
                    if(row['comName'] === 'fmemo'){
                        row['tdShow'] = true;
                        row['isInsert'] = true;
                        row['isUpdate'] = true;
                    }
                }
            }
        ),
        //收货单条目
        'wmreceiptentry': new wmreceiptentry(),
        //出入库记录
        'wmstockrecord': new wmstockrecord(),
        //过账策略管理
        'wmtactics': new wmtactics(),
        //过账记录
        'wmtacticsrecord': new wmtacticsrecord(),
        //出入库任务
        'wmtask': new wmtask(),
        //入库任务
        'wmtask_ftypeid_LessThanOrEqualTo_4': new wmtask(),
        //出库任务
        'wmtask_ftypeid_GreaterThanOrEqualTo_5': new wmtask(),
        //层码信息
        'wmtiercode': new wmtiercode(),
        //单位码信息
        'wmunitcode': new wmunitcode(),
        //码表信息
        'wmcodeopt': new wmcodeopt(),
        //消费者信息
        'mmconsumer': new mmconsumer(),
        //抽奖码查询记录
        'mmlotteryrecord': new mmlotteryrecord(),
        //奖品信息
        'mmprize': new mmprize(),
        //中奖信息
        'mmlotteryinfo': new mmlotteryinfo(),
        //抽奖规则
        'mmlotteryrule': new mmlotteryrule(),
        //抽奖规则明细
        'mmlotteryruleentry': new mmlotteryruleentry(),
        //批次信息
        'rpbatchinfo': new rpbatchinfo(),
        //召回列表
        'rprecall': new rprecall(),
        //出入库日报
        'rpstockdaily': new rpstockdaily(),
        //生产日报表
        'rpproductdaily': new rpproductdaily(),
        //产成品统计列表
        'rpproduct': new rpproduct(),
        //收发货量趋势图
        'getstocksum': new getstocksum(),
        //产能趋势图
        'getproductsum': new getproductsum(),
        //查询量趋势图
        'getfeelingsum': new getfeelingsum(),
        //产成品统计表
        'getproducttotal': new getproducttotal(),
    }

    static getPojo(name) {
        let pojo = this.pojos[name];
        pojo.init();
        return pojo;
    }
}
export default Pojos;

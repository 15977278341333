import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";

class Pojo extends MyPojo {
    createcode_sm = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls['wm'] + 'wmcodeopt' + '/createcode_sm', params)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }
    //审批退货
    createproductin = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/createproductin', params)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }
    //审批退货
    rtverify = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/rtverify', params)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }
    //设置退货标志
    setreturn = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/setreturn', params)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }
    //下达任务
    assign = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/assign', params)
                .then((res) => {
                    console.log(res);
                    resolve(res);
                })
                .catch((res) => {
                    console.log(res);
                    reject(res);
                });
        });
    }
    //装车
    execute = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/execute', params)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }
    //到达
    reach = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/reach', params)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }
    //审核
    verify = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/verify', params)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }

    constructor({
                    iniForFilter = (row) => {
                    }
                } = {}) {
        //收货单管理
        super('wmreceipt', {
            iniForFilter: iniForFilter
        });
        this.controller = 'wm';
        this.cName = '收货单管理';
        this.pojoItem = new initItem();
        this.orderRowName = 'fnumber';
        this.orderType = 'DESC';
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                show: false,
                isInsert: false,
                isUpdate: false,
                showSearch: false,
            },
            {
                comName: 'fnumber',
                name: '单号',
                isPk: true,
                show: false,
            },
            {
                comName: 'fnumber',
                name: '收货单号',
            },
            // {
            //     comName: 'scfnumber',
            //     name: '生产单号',
            //     isEx:true,
            //     exComParent:'fnumber',
            // },
            // {
            //     comName: 'scfstate',
            //     name: '单据状态',
            //     isEx:true,
            //     exComParent:'fstate',
            // },
            {
                comName: 'scfgoodscaption',
                name: '存货名称',
                isEx: true,
                exComParent: 'fgoodscaption',
            },
            {
                comName: 'scfsuqty',
                name: '计划数量',
                isEx: true,
                exComParent: 'fsuqty',
            },
            {
                comName: 'scfrealqty',
                name: '入库数量',
                isEx: true,
                exComParent: 'frealqty',
            },
            {
                comName: 'freceiptdate',
                name: '收货日期',
                dataType: DataType.DataTime,
                showTime: false,
                show:false,
            },
            {
                comName: 'ftypeid',
                name: '单据类别',
                dataType: DataType.Com,
                state: {
                    1: '生产入库单',
                    2: '调拨入库单',
                    3: '采购入库单',
                    4: '其他入库单',
                },
                tdShow: false,
                isInsert: false,
                isUpdate: false,
                show: false,
            },
            {
                comName: 'fordernumber',
                name: '订单单据',//(生产订单、调拨订单、采购订单、其他)
            },
            {
                comName: 'fsupplier',
                name: '供应商',
                tdShow: false,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fdeliveryman',
                name: '送货人',
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'sysstaff',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fnumber}-{fname}',
                }),
                tdShow: false,
            },
            {
                comName: 'fsalesman',
                name: '下单人',
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'sysstaff',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fnumber}-{fname}',
                }),
                tdShow: false,
            },
            {
                comName: 'fplace',
                name: '收货地',
                tdShow: false,
            },
            {
                comName: 'fmemo',
                name: '备注',
                tdShow: false,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'freachtime',
                name: '到达时间',
                dataType: DataType.DataTime,
                tdShow: false,
            },
            {
                comName: 'forderman',
                name: '下达人',//(下达收货任务，且分配月台和验收员)
                tdShow: false,
            },
            {
                comName: 'fordertime',
                name: '下达时间',
                dataType: DataType.DataTime,
                tdShow: false,
            },
            // {
            //     comName: 'fchecktime',
            //     name: '验货时间',
            //     dataType: DataType.DataTime,
            //     tdShow: false,
            // },
            {
                comName: 'fmanageman',
                name: '调度人',//(安排仓管员、分配司机、搬运进行卸货入库)
                tdShow: false,
            },
            {
                comName: 'ffromtime',
                name: '开始时间',
                dataType: DataType.DataTime,
                tdShow: false,
            },
            {
                comName: 'ftotime',
                name: '结束时间',
                dataType: DataType.DataTime,
                tdShow: false,
            },
            {
                comName: 'fverifytime',
                name: '审核时间',
                dataType: DataType.DataTime,
                tdShow: false,
            },
            {
                comName: 'fverifyman',
                name: '审核人',
                tdShow: false,
            },
            {
                comName: 'fstateid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '保存',
                    2: '到场',
                    3: '下达',
                    4: '卸货',
                    5: '收货审核',
                },
            },
            {
                comName: 'forgpk',
                name: '组织',
                isInsert: false,
                isUpdate: false,
                show: false,
                tdShow: false,
                showSearch: false,
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
        ]);
    }
}

export default Pojo;

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {initItem, item} from '../../calzzs/initItems'

class Pojo extends MyPojo {
    constructor() {
        //抽奖码查询记录
        super('mmlotteryrecord');
        this.controller = 'mm';
        this.cName = '抽奖码查询记录';
        this.infoColumn = ''
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk: true,
            },
            {
                comName: 'consumer',
                isEx: true,
                exComParent: 'consumer',
                name: '姓名',
            },
            {
                comName: 'faddress',
                name: '扫码地点',
                width: 250,
            },
            {
                comName: 'flotterycode',
                name: '抽奖码',
                tdShow: false,
            },
            {
                comName: 'fopenid',
                name: 'openid',
                tdShow: false,
            },
            {
                comName: 'fopttime',
                name: '操作时间',
                dataType: DataType.DataTime,
            },
            {
                comName: 'fstateid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '正常',
                    2: '异常',
                },
            },
        ]);
    }
}

export default Pojo;

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";

class Pojo extends MyPojo {
    //同步数据
    synchronization = () => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/synchronization', {})
                .then((res) => {
                    console.log(res);
                    resolve(res);
                })
                .catch((res) => {
                    console.log(res);
                    reject(res);
                });
        });
    }
    //关闭任务
    close = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/close', params)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }
    //下达任务
    ordertask = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/ordertask', params)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }
    //审核
    verify = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/verify', params)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }

    constructor() {
        //生产订单管理
        super('pmorder');
        this.orderRowName = 'fnumber';
        this.orderType = 'DESC';
        this.controller = 'pm';
        this.cName = '生产订单管理';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                show: false,
                isInsert: false,
                showSearch: false,
                isUpdate: false,
            },
            {
                comName: 'fnumber',
                name: '单号',
                isPk: true,
                show: false,
            },
            {
                comName: 'fnumber',
                name: '单号',
            },
            {
                comName: 'fsourcenumber',
                name: '依据单据',
                tdShow: false,
            },
            {
                comName: 'fcustomer',
                name: '客户名称',
                tdShow: false,
            },
            {
                comName: 'fgoodscaption',
                name: '存货名称',
                isEx: true,
                exComParent: 'fgoodscaption',
            },
            {
                comName: 'ftaskstate',
                name: '生产任务',
                isEx: true,
                exComParent: 'ftaskstate',
            },
            {
                comName: 'fsuqty',
                name: '计划数量',
                isEx: true,
                exComParent: 'fsuqty',
            },
            {
                comName: 'fpmqty',
                name: '生产数量',
                isEx: true,
                exComParent: 'fpmqty',
            },
            {
                comName: 'fdeptpk',
                name: '生产部门',
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'sysorginfo',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',//-{forgaddress};{fmanager};
                }),
                exComParent: 'fdepartment',
                tdShow: false,
            },
            {
                comName: 'fstateid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '保存',
                    2: '审核',
                    3: '执行中',
                    4: '关闭',
                    5: '审核4',
                    6: '审核5',
                    7: '审核6',
                },
            },
            {
                comName: 'fproductiondate',
                name: '生产日期',
                dataType: DataType.DataTime,
                showTime: false,
            },
            {
                comName: 'fmemo',
                name: '备注',
                tdShow: false,
            },
            {
                comName: 'fverifyman1',
                name: '审核人',
                tdShow: false,
            },
            {
                comName: 'fverifytime1',
                name: '审核时间',
                dataType: DataType.DataTime,
                tdShow: false,
            },
            {
                comName: 'fverifyman2',
                name: '下达人',
                tdShow: false,
            },
            {
                comName: 'fverifytime2',
                name: '下达时间',
                dataType: DataType.DataTime,
                tdShow: false,
            },
            {
                comName: 'fverifyman3',
                name: '关闭人',
                tdShow: false,
            },
            {
                comName: 'fverifytime3',
                name: '关闭时间',
                dataType: DataType.DataTime,
                tdShow: false,
            },
            // {
            //     comName: 'fverifyman4',
            //     name: '审核人4',
            // },
            // {
            //     comName: 'fverifytime4',
            //     name: '审核时间4',
            //     dataType: DataType.DataTime,
            // },
            // {
            //     comName: 'fverifyman5',
            //     name: '审核人5',
            // },
            // {
            //     comName: 'fverifytime5',
            //     name: '审核时间5',
            //     dataType: DataType.DataTime,
            // },
            // {
            //     comName: 'fverifyman6',
            //     name: '审核人6',
            // },
            // {
            //     comName: 'fverifytime6',
            //     name: '审核时间6',
            //     dataType: DataType.DataTime,
            // },
            {
                comName: 'forgpk',
                name: '组织',
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'sysorginfo',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}-{forgaddress};{fmanager};',
                }),
                show: false,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
        ]);
    }
}

export default Pojo;

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item, StatesID} from '../../calzzs/initItems'

class Pojo extends MyPojo {
    constructor() {
        //出入库任务
        super('wmtask');
        this.controller = 'wm';
        this.cName = '出入库任务';
        this.orderRowName = 'fpk';
        this.orderType = 'DESC';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk: true,
            },
            {
                comName: 'fpk',
                name: '编号',
                width:250,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'ftypeid',
                name: '任务类型',
                dataType: DataType.Com,
                state: {
                    1: '生产入库',
                    2: '调拨入库',
                    3: '采购入库',
                    4: '其他入库',
                    5: '销售出库',
                    6: '调拨出库',
                    7: '生产领料',
                    8: '抽检出库',
                    9: '破损出库',
                    10: '返工出库',
                    11: '其他出库',
                },
            },
            // {
            //     comName: 'fentrypk',
            //     name: '货单条目编号',
            // },
            {
                comName: 'fstateid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '保存',
                    2: '执行',
                    3: '完成',
                },
            },
            {
                comName: 'fgoodsnumber',
                name: '存货编号',
                showLength: 30,
            },
            {
                comName: 'fgoodsnumber',
                name: '存货名称',
                width: 300,
                showLength: 30,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fminventory',
                    action: 'getList',
                    id: 'fnumber',
                    text: '{fcaption}',
                }),
                exComParent: 'fgoodscaption',
            },
            {
                comName: 'fbatchnumber',
                name: '批号',//(支持多选,逗号分隔)
            },
            {
                comName: 'fsupk',
                name: '单位',
                width: 200,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fmpackingunit',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',//{fgoodsnumber}({fcaption})代号:{fpucode}
                    isInitialize: false,
                }),
                exComParent: 'fsucaption',
            },
            {
                comName: 'fsuqty',
                name: '计划数量',//单位数量
                dataType: DataType.NUM,
            },
            {
                comName: 'fpupk',
                name: '包装单位',
                width: 200,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fmpackingunit',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                    isInitialize: false,
                }),
                exComParent: 'fpucaption',
                tdShow: false,
                isInsert: false,
                isUpdate: false,
                show:false,
            },
            {
                comName: 'fpuqty',
                name: '包装数量',
                dataType: DataType.NUM,
                tdShow: false,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fpsupk',
                name: '码板单位',
                width: 200,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fmpackingunit',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                    isInitialize: false,
                }),
                exComParent: 'fpsucaption',
                tdShow: false,
                isInsert: false,
                isUpdate: false,
                show:false,
            },
            {
                comName: 'fpsuqty',
                name: '码板数量',
                dataType: DataType.NUM,
                tdShow: false,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fmantissaqty',
                name: '尾数',
                dataType: DataType.NUM,
                tdShow: false,
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'faddqty',
                name: '补货数量',
                dataType: DataType.NUM,
            },
            {
                comName: 'freturnqty',
                name: '退货数量',
                dataType: DataType.NUM,
            },
            {
                comName: 'fcodeqty',
                name: '扫码数量',
                dataType: DataType.NUM,
            },
            {
                comName: 'ffinishqty',
                name: '完成数量',
                dataType: DataType.NUM,
            },
            {
                comName: 'fsumweight',
                name: '总重量',
                dataType: DataType.NUM,
                tdShow: false,
            },
            {
                comName: 'fdescription',
                name: '备注说明',
                tdShow: false,
            },
            {
                comName: 'fplatformpk',
                name: '月台',
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fmplatform',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                }),
                exComParent: 'fplatform',
            },
            {
                comName: 'fstorage',
                name: '储位',//编号(支持多选,逗号分隔)
                width: 300,
                showLength: 30,
            },
            {
                comName: 'fexecutemanpk',
                name: '执行人',
                tdShow: false,
                show:false,
                // isEx:true,
            },
            {
                comName: 'fstorekeeperpk',
                name: '仓管员编号',
                tdShow: false,
                show:false,
            },
            {
                comName: 'fdriver',
                name: '运输司机',
                tdShow: false,
                show:false,
            },
            {
                comName: 'fporter',
                name: '搬运工',
                tdShow: false,
                show:false,
            },
            {
                comName: 'fgoodsstateid',
                name: '存货状态',
                dataType: DataType.Com,
                state: StatesID.getPState(),
            },
            {
                comName: 'fstarttime',
                name: '开始时间',
                dataType: DataType.DataTime,
                tdShow: false,
            },
            {
                comName: 'fendtime',
                name: '结束时间',
                dataType: DataType.DataTime,
                tdShow: false,
            },
            {
                comName: 'forgpk',
                name: '组织编号',
                isInsert: false,
                isUpdate: false,
                show: false,
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
        ]);
    }
}

export default Pojo;

import React, {useState} from 'react';
import './index.less';
import {Row, Button, Table, Tag, Radio, Modal, Tabs, message, Form} from 'antd';
import {DownloadOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {get} from "../../axios/Response";
import MyTable from "../../my_module/table/table";
// import Pojo from "./pojo"
import MyDataGrid from "../../my_module/datagrid/datagrid";
import MyButton from "../../my_module/MyClass/button";
import Nestgrid from "../../my_module/nestgrid/nestgrid";
import Pojos from "../pojos"
import {DataType, getQueryWhereItem, WhereType} from "../../api/mainPojo";
import Title_detail_table from "../../my_module/title_detail_table/title_detail_table";
import TitleDetailTablePojo from "../../my_module/Common/TitleDetailTablePojo";
import {getButtomModal} from "../../my_module/MyClass/domUtil";
import FormUtil from "../../my_module/Common/FormUtil";
import {ComF} from "../../calzzs/initItems";
import {downloadFun, getCsvStr, getValueByProp, setValueByProp} from "../../utils/utils";

const {TabPane} = Tabs;
const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};

//收货单管理
class index extends React.Component {
    constructor(props) {
        super(props);
        this.onDGRef = this.onDGRef.bind(this);
        this.onDDGRef = this.onDDGRef.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        // this.addOpenIntercept = this.addOpenIntercept.bind(this);
        // this.editOpenIntercept = this.editOpenIntercept.bind(this);
        let pojo = Pojos.getPojo('wmreceipt');
        if (this.props.data.winData !== undefined) {
            pojo = Pojos.getPojo(this.props.data.winData.key);
            pojo.cName = this.props.data.winData.name;
            pojo.exTableName = this.props.data.winData.key;
        }
        this.queryData = [];
        if (this.props.data.queryData !== undefined) {
            this.queryData = this.props.data.queryData;
        }
        this.editData = {};
        if (this.props.data.editData !== undefined) {
            this.editData = this.props.data.editData;
        }
        this.fminventoryconfigPojo = Pojos.getPojo('fminventoryconfig');
        this.state = {
            // addModalVisible: false,
            pojo: pojo,//new Pojo(),
            dPojo: Pojos.getPojo('wmreceiptentry'),
            dg: undefined,
            ddg: undefined,
            showDDG: 'none',
            opTP: false,
            tp: undefined,
            tpKey: '1',
            dgRow: undefined,
            tdT: undefined,
            detailRow: undefined,
        }
        this.xdform = React.createRef();
        this.detailDGref = undefined;
        this.fqty = 0;
        this.ftypeid = 1;
        this.detailExTrBus = [];
        console.log(this.props.data.winData.key);
        this.xdTitle = '下达';//下达任务
        if (this.props.data.winData.key === 'wmreceipt_ftypeid_EqualTo_3') {
            this.xdTitle = '下达';//验货任务
        }
        console.log(this.xdTitle);
        this.detailExTrBus.push(
            getButtomModal(
                {
                    title: '设置退货',
                    content: '确定设置退货?',
                    name: 'ordertask',
                    okFun: (row) => {
                        return new Promise((resolve, reject) => {
                            this.state.pojo.setreturn({
                                freceiptentrypk: row['fpk'],
                            })
                                .then((ref) => {
                                    resolve(ref);
                                })
                                .catch((ref) => {
                                    reject(ref);
                                })
                        });
                    },
                    thenFun: (ref) => {
                        console.log('thenFun');
                        this.detailDGref.getList({
                            fnumber: this.state.detailRow.fnumber,
                        })
                    },
                    confirma: true,
                }
            ),
        );
        this.detailExTrBus.push(
            getButtomModal(
                {
                    title: '审批退货',
                    content: '确定审批退货?',
                    name: 'ordertask',
                    okFun: (row) => {
                        return new Promise((resolve, reject) => {
                            this.state.pojo.rtverify({
                                freceiptentrypk: row['fpk'],
                            })
                                .then((ref) => {
                                    resolve(ref);
                                })
                                .catch((ref) => {
                                    reject(ref);
                                })
                        });
                    },
                    thenFun: (ref) => {
                        console.log('thenFun');
                        this.detailDGref.getList({
                            fnumber: this.state.detailRow.fnumber,
                        })
                    },
                    confirma: true,
                }
            ),
        );
        if (this.props.data.winData.key === 'wmreceipt_ftypeid_EqualTo_3') {
            this.detailExTrBus.push(
                getButtomModal(
                    {
                        title: '生成码',
                        // content: '确定生成2W个码?',
                        content:
                            (<Form {...layout}>
                                {
                                    [
                                        new FormUtil().getFormItem(
                                            {
                                                column: {
                                                    comName: 'ftypeid',
                                                    prop: 'ftypeid',
                                                    name: '类型',
                                                    dataType: DataType.Com,
                                                    defaultValue: 1,
                                                    state: {
                                                        1: '垛码',
                                                        2: '包装码',
                                                        3: '单位码',
                                                    },
                                                },
                                                onChange: (value, ...data) => {
                                                    // console.log(value);
                                                    // console.log(data);
                                                    this.ftypeid = value === null ? 1 : value;
                                                }
                                            },
                                        ),
                                        new FormUtil().getFormItem(
                                            {
                                                dvalue: this.fqty,
                                                column: {
                                                    comName: 'fqty',
                                                    prop: 'fqty',
                                                    name: '数量',
                                                    dataType: DataType.NUM,
                                                },
                                                onChange: (value, ...data) => {
                                                    // console.log(value);
                                                    // console.log(data);
                                                    this.fqty = value === null ? 0 : value;
                                                }
                                            },
                                        ),
                                    ]
                                }
                            </Form>),
                        name: 'getsuitscodelist',
                        okFun: (row) => {
                            return new Promise((resolve, reject) => {
                                if (this.ftypeid === 0) {
                                    message.error('请选择类型!');
                                    reject(undefined);
                                } else {
                                    this.state.pojo.createcode_sm({
                                        ftypeid: this.ftypeid,
                                        fqty: this.fqty,
                                        fpk: row['fpk'],
                                    })
                                        .then((ref) => {
                                            // console.log(ref);
                                            let index = 0;
                                            getCsvStr(
                                                (rows, fields) => {
                                                    let dataE = [];
                                                    // console.log(dataE);
                                                    for (let i = 0; i !== rows.length; i++) {
                                                        const row = rows[i];
                                                        let _fsubcodes = row['fsubcodes'];
                                                        let _fnestcodes = row['fnestcodes'];
                                                        if (_fsubcodes !== undefined && _fsubcodes.length > 0) {
                                                            for (let k = 0; k !== _fsubcodes.length; k++) {
                                                                dataE[index] = [];
                                                                for (let j in fields) {
                                                                    const key = fields[j];
                                                                    const value = getValueByProp(row, key);
                                                                    dataE[index].push(value);
                                                                }
                                                                dataE[index].push(_fsubcodes[k]);
                                                                index++;
                                                            }
                                                        } else if (_fnestcodes !== undefined && _fnestcodes.length > 0) {
                                                            for (let k in _fnestcodes) {
                                                                let _fnestcode = _fnestcodes[k];
                                                                let nfsubcodes = _fnestcode['fsubcodes'];
                                                                if (nfsubcodes !== undefined && nfsubcodes.length > 0) {
                                                                    for (let z = 0; z !== nfsubcodes.length; z++) {
                                                                        dataE[index] = [];
                                                                        for (let j in fields) {
                                                                            const key = fields[j];
                                                                            const value = getValueByProp(row, key);
                                                                            // const svalue = getValueByProp(_fnestcode, key);
                                                                            dataE[index].push(value);
                                                                            // dataE[index].push(svalue);
                                                                        }
                                                                        dataE[index].push(nfsubcodes[z]);
                                                                        index++;
                                                                    }
                                                                }
                                                            }
                                                        } else {
                                                            dataE[index] = [];
                                                            for (let j in fields) {
                                                                const key = fields[j];
                                                                const value = getValueByProp(row, key);
                                                                dataE[i].push(value);
                                                            }
                                                            index++;
                                                        }
                                                    }
                                                    return dataE;
                                                },
                                                ref.data,
                                                [],
                                                ['fbatchnumber', 'fcode', 'fgoodscaption'],
                                                '生成码',
                                                downloadFun,
                                            )
                                            resolve(ref);
                                        })
                                        .catch((ref) => {
                                            reject(ref);
                                        })
                                }
                            });
                        },
                        thenFun: (ref) => {
                            //this.state.dg.getList();
                        },
                        confirma: false,
                    }
                ),
            )
        }
        console.log(this.detailExTrBus);
        console.log(this.pojo);
    }

    exTrBus = () => {
        return [
            // new MyButton(
            //     '审批',
            //     'getItemByID11',
            //     undefined,
            //     (row) => {
            //         console.log(row);
            //         return true;
            //     }
            // )

            getButtomModal(
                {
                    title: '到达',
                    content: '确定到达?',
                    name: 'reach',
                    okFun: (row) => {
                        return new Promise((resolve, reject) => {
                            this.state.pojo.reach({
                                fnumber: row['fnumber'],
                            })
                                .then((ref) => {
                                    resolve(ref);
                                })
                                .catch((ref) => {
                                    reject(ref);
                                })
                        });
                    },
                    thenFun: (ref) => {
                        this.state.dg.getList();
                    }
                }
            ),
            getButtomModal(
                {
                    title: this.xdTitle,
                    content:
                        (<Form
                            ref={(ref) => {
                                console.log(ref);
                                this.xdform = ref;
                            }}
                            {...{
                                labelCol: {span: 8},
                                wrapperCol: {span: 16},
                            }}
                        >
                            {
                                [
                                    new FormUtil().getFormItem(
                                        {
                                            column: {
                                                required: true,
                                                comName: 'fplatformpk',
                                                prop: 'fplatformpk',
                                                name: '月台',
                                                dataType: DataType.ComGETServer,
                                                comF: new ComF({
                                                    controller: 'fmplatform',
                                                    action: 'getList',
                                                    id: 'fpk',
                                                    text: '{fcaption}',
                                                }),
                                            },
                                            onChange: (value, ...data) => {
                                                // console.log(value);
                                                // console.log(data);
                                                this.option3fplatformpk = value.key;
                                            }
                                        },
                                    ),
                                    new FormUtil().getFormItem(
                                        {
                                            column: {
                                                required: false,
                                                comName: 'fexecutemanpk',
                                                prop: 'fexecutemanpk',
                                                name: '验货人',
                                                dataType: DataType.ComGETServer,
                                                comF: new ComF({
                                                    controller: 'sysstaff',
                                                    action: 'getList',
                                                    id: 'fname',//fpk
                                                    text: '{fnumber}-{fname}',
                                                }),
                                            },
                                            onChange: (value, ...data) => {
                                                // console.log(value);
                                                // console.log(data);
                                                this.option3fexecutemanpk = value.key;
                                            }
                                        },
                                    )
                                ]
                            }</Form>),
                    name: 'assign',
                    okFun: (row) => {
                        // console.log(this.xdform);
                        return new Promise((resolve, reject) => {
                            this.xdform.validateFields()
                                .then((ref) => {
                                    this.state.pojo.assign({
                                        fnumber: row['fnumber'],
                                        fplatformpk: this.option3fplatformpk,
                                        fexecutemanpk: this.option3fexecutemanpk,
                                    })
                                        .then((ref) => {
                                            resolve(ref);
                                        })
                                        .catch((ref) => {
                                            reject(ref);
                                        });
                                })
                                .catch((ref) => {
                                    reject(ref);
                                });

                        });
                    },
                    thenFun: (ref) => {
                        this.state.dg.getList();
                    }
                },
            ),
            getButtomModal(
                {
                    title: '卸货',
                    content:
                        (<Form {...{
                            labelCol: {span: 8},
                            wrapperCol: {span: 16},
                        }}>
                            {
                                [
                                    new FormUtil().getFormItem(
                                        {
                                            column: {
                                                required: false,
                                                comName: 'Storekeeperpk',
                                                prop: 'Storekeeperpk',
                                                name: '仓管员',
                                                dataType: DataType.ComGETServer,
                                                comF: new ComF({
                                                    controller: 'sysstaff',
                                                    action: 'getList',
                                                    id: 'fname',//fpk
                                                    text: '{fnumber}-{fname}',
                                                }),
                                            },
                                            onChange: (value, ...data) => {
                                                // console.log(value);
                                                // console.log(data);
                                                this.option4Storekeeperpk = value.key;
                                            }
                                        },
                                    ),
                                    new FormUtil().getFormItem(
                                        {
                                            column: {
                                                required: false,
                                                comName: 'fdriver',
                                                prop: 'fdriver',
                                                name: '运输司机',
                                                dataType: DataType.ComGETServer,
                                                comF: new ComF({
                                                    controller: 'sysstaff',
                                                    action: 'getList',
                                                    id: 'fname',//fpk
                                                    text: '{fnumber}-{fname}',
                                                }),
                                            },
                                            onChange: (value, ...data) => {
                                                // console.log(value);
                                                // console.log(data);
                                                this.option4fdriver = value.key;
                                            }
                                        },
                                    ),
                                    new FormUtil().getFormItem(
                                        {
                                            column: {
                                                required: false,
                                                comName: 'fporter',
                                                prop: 'fporter',
                                                name: '搬运工',
                                                dataType: DataType.ComGETServer,
                                                comF: new ComF({
                                                    controller: 'sysstaff',
                                                    action: 'getList',
                                                    id: 'fname',//fpk
                                                    text: '{fnumber}-{fname}',
                                                }),
                                            },
                                            onChange: (value, ...data) => {
                                                // console.log(value);
                                                // console.log(data);
                                                this.option4fporter = value.key;
                                            }
                                        },
                                    ),
                                ]
                            }
                        </Form>),
                    name: 'execute',
                    okFun: (row) => {
                        return new Promise((resolve, reject) => {
                            this.state.pojo.execute({
                                fnumber: row['fnumber'],
                                Storekeeperpk: this.option4Storekeeperpk,
                                fdriver: this.option4fdriver,
                                fporter: this.option4fporter,
                            })
                                .then((ref) => {
                                    resolve(ref);
                                })
                                .catch((ref) => {
                                    reject(ref);
                                });
                        });
                        // return new Promise((resolve, reject) => {
                        //     if (this.option4Storekeeperpk === '') {
                        //         message.error('请选择仓管员');
                        //         reject(undefined);
                        //     } else if (this.option4fdriver === '') {
                        //         message.error('请选择运输司机');
                        //         reject(undefined);
                        //     } else if (this.option4fporter === '') {
                        //         message.error('请选择搬运工');
                        //         reject(undefined);
                        //     } else {
                        //         this.state.pojo.execute({
                        //             fnumber: row['fnumber'],
                        //             Storekeeperpk: this.option4Storekeeperpk,
                        //             fdriver: this.option4fdriver,
                        //             fporter: this.option4fporter,
                        //         })
                        //             .then((ref) => {
                        //                 resolve(ref);
                        //             })
                        //             .catch((ref) => {
                        //                 reject(ref);
                        //             });
                        //     }
                        // });
                    },
                    thenFun: (ref) => {
                        this.state.dg.getList();
                    }
                },
            ),
            getButtomModal(
                {
                    testFunc:(row) => {
                        return this.props.data.winData.key === 'wmreceipt_ftypeid_EqualTo_1';
                    },
                    title: '推单',
                    content: '确定推单?',
                    name: 'createproductin',
                    okFun: (row) => {
                        return new Promise((resolve, reject) => {
                            this.state.pojo.createproductin({
                                fnumber: row['fnumber'],
                            })
                                .then((ref) => {
                                    resolve(ref);
                                })
                                .catch((ref) => {
                                    reject(ref);
                                })
                        });
                    },
                    thenFun: (ref) => {
                        this.state.dg.getList();
                    }
                }
            ),
            getButtomModal(
                {
                    title: '审核',
                    content: '确定审核?',
                    name: 'verify',
                    okFun: (row) => {
                        return new Promise((resolve, reject) => {
                            this.state.pojo.verify({
                                fnumber: row['fnumber'],
                            })
                                .then((ref) => {
                                    resolve(ref);
                                })
                                .catch((ref) => {
                                    reject(ref);
                                })
                        });
                    },
                    thenFun: (ref) => {
                        this.state.dg.getList();
                    }
                }
            ),
        ];
    }

    onDGRef = (ref) => {
        this.setState({
            dg: ref,
        }, () => {
            //this.state.dg.refInit();
        })
    }
    onDDGRef = (ref) => {
        this.setState({
            ddg: ref,
        }, () => {
            //this.state.dg.refInit();
        })
    }
    onTDTRef = (ref) => {
        console.log(ref);
        this.setState({
            tdT: ref,
        }, () => {
            //this.state.dg.refInit();
        })
    }
    onTopChange = (tpKey) => {
        this.setState({
            tpKey: tpKey,
        });
    };
    onRowClick = (row) => {
        // console.log(row);

    }
    editShouldUpdate = (prev, cur, formData) => {
        return new Promise((resolve, reject) => {
            if (prev['info'] === undefined || this.selectFgoodsnumber !== cur['info']['fgoodsnumber']['key']) {
                console.log(cur);
                if (cur['info']['fgoodsnumber']._isTreeSelectRef) {
                    this.selectFgoodsnumber = cur['info']['fgoodsnumber']['key'];
                } else {
                    this.selectFgoodsnumber = cur['info']['fgoodsnumber'];
                }
                // console.log(this.selectFgoodsnumber);
                // console.log(this.state.tdT.state.detailTable.editFromItemMap);
                // console.log(cur['info']);
                let editFromItemMap = this.state.tdT.state.detailTable.editFromItemMap;
                let getQuery = getQueryWhereItem([
                    {
                        value: this.selectFgoodsnumber,
                        comname: 'fgoodsnumber',
                        comtype: WhereType.EqualTo,
                    }
                ]);
                // console.log(getQuery);
                editFromItemMap['fsupk'].init(getQuery);
                // editFromItemMap['fpupk'].init(getQuery);
                // editFromItemMap['fpsupk'].init(getQuery);
                if (formData !== undefined && this.selectFgoodsnumber !== '') {
                    this.fminventoryconfigPojo.getgoodsinfo(this.selectFgoodsnumber)
                        .then((ref) => {
                            // formData['fsupk'] = ref.data['fpsupk'];
                            setValueByProp(formData, this.state.dPojo.getProp('fsupk'), ref.data['fsupk']);
                            editFromItemMap['fsupk'].setValue(ref.data['fsupk']);
                            // setValueByProp(formData, this.state.dPojo.getProp('fpupk'), ref.data['fpupk']);
                            // editFromItemMap['fpupk'].setValue(ref.data['fpupk']);
                            // setValueByProp(formData, this.state.dPojo.getProp('fpsupk'), ref.data['fpsupk']);
                            // editFromItemMap['fpsupk'].setValue(ref.data['fpsupk']);
                            resolve(null);
                        })
                        .catch((ref) => {
                            console.log(ref);
                            reject(ref);
                        });
                } else {
                    reject(null);
                }
                // editFromItemMap['fpupk'].init(getQuery);
                // editFromItemMap['fpsupk'].init(getQuery);
                console.log('存货名称修改了');
            } else {
                reject(null);
            }
        });
    }
    componentDidUpdate = (prevProps, prevState) => {
    }

    render() {
        console.log(this.xdTitle);
        return (
            <div style={{height: '100%'}} ref={(drawerContext) => {
                this.drawerContext = drawerContext;
            }}>
                <div>
                    <MyDataGrid
                        testShowCom={(row) => {
                            let comss = [
                                'scfnumber',
                                'scfstate',
                                'scfgoodscaption',
                                'scftaskstate',
                                'scfsuqty',
                                'scfrealqty',
                            ]
                            if(this.props.data.winData.key !== 'wmreceipt_ftypeid_EqualTo_1'){
                                if(comss.indexOf(row.comName) >= 0){
                                    return false
                                }else{
                                    return true
                                }
                            }else{
                                return true
                            }
                        }}
                        showDetail={(row) => {
                            // this.state.ddg.getList({
                            //     fnumber: row.fnumber,
                            // })
                            //
                            // this.setState({
                            //     showDDG: '',
                            // })
                            this.setState({
                                detailRow: row,
                            })
                        }}
                        querywhere={this.queryData}
                        isMinHeight={false}
                        onRowClick={this.onRowClick}
                        addOpenIntercept={() => {
                            this.state.dg.setShowEx(true);
                            this.setState({
                                dgRow: undefined,
                                showDDG: 'none',
                            })
                            return false;
                        }}
                        editOpenIntercept={(ref) => {
                            this.state.dg.setShowEx(true);
                            this.setState({
                                dgRow: ref,
                                showDDG: 'none',
                            });
                            return false;
                        }}
                        exBody={(
                            <Title_detail_table
                                titleExData={this.editData}
                                editShouldUpdate={(prev, cur, formData) => {
                                    return this.editShouldUpdate(prev, cur, formData);
                                }}
                                onRef={this.onTDTRef}
                                editingTreeSelectRefFun={(comName, editFromItemMap) => {
                                }}
                                editingOnChange={(value, column, row, editFromItemMap, ...data) => {
                                }}
                                onClose={() => {
                                    this.state.dg.setShowEx(false);
                                    this.setState({
                                        showDDG: 'none',
                                    })
                                }}
                                tdtPojo={new TitleDetailTablePojo({
                                    titleController: this.props.data.winData.key,//'wmreceipt',
                                    titleRowNames: [
                                        'fnumber',
                                        'freceiptdate',
                                        // 'ftypeid',
                                        'fordernumber',
                                        'fsupplier',
                                        'fdeliveryman',
                                        'fsalesman',
                                        'fplace',
                                        'fmemo',
                                    ],
                                    detailController: 'wmreceiptentry',
                                    detailAction: 'getTitleList',
                                    titleRow: this.state.dgRow,
                                    getDetailPRowName: 'fnumber',
                                    detailPRowName: 'fnumber',
                                })}
                            />
                        )}
                        onRef={this.onDGRef}
                        pojo={this.state.pojo}
                        isTab={true}
                        bodyWidth={'100%'}
                        detailBodyWidth={'100%'}
                        bodyClass={'box-column'}
                        detailLocation={'r'}
                        // detailBody={
                        //     <div style={{display: this.state.showDDG}}>
                        //         <MyDataGrid
                        //             isPage={false}
                        //             isMinHeight={false}
                        //             isSetTrBus={false}
                        //             isTab={false}
                        //             initializeList={false}
                        //             onRef={this.onDDGRef}
                        //             pojo={this.state.dPojo}
                        //             getListName={'getTitleList'}
                        //             exTrBus={this.exTrBus}/>
                        //     </div>
                        // }
                        detailDBody={this.state.detailRow === undefined ? undefined :
                            <div>
                                <MyDataGrid
                                    isPage={false}
                                    isMinHeight={false}
                                    // isSetTrBus={false}
                                    isEdit={false}
                                    isAdd={false}
                                    isSearch={false}
                                    isDel={false}
                                    isDetail={false}
                                    exTrBus={this.detailExTrBus}
                                    isTab={false}
                                    initializeList={false}
                                    onRef={(ref) => {
                                        this.detailDGref = ref;
                                        this.detailDGref.getList({
                                            fnumber: this.state.detailRow.fnumber,
                                        })
                                    }}
                                    pojo={this.state.dPojo}
                                    getListName={'getTitleList'}
                                    // exTrBus={this.exTrBus}
                                />
                            </div>
                        }
                        exTrBus={this.exTrBus()}/>
                </div>
                {/*{this.addModal()}*/}

                {/*{tpItem}*/}
            </div>
        );
    }
}

export default index;

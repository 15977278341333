import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item} from '../../calzzs/initItems'
class Pojo extends MyPojo {
    constructor() {
        //工序记录
        super('pmprocessrecord');
        this.controller = 'pm';
        this.cName = '工序记录';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk:true,
            },
            {
                comName: 'fprocesspk',
                name: '工序',
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fmprocess',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                }),
                exComParent: 'fprocess',
            },
            {
                comName: 'ftaskpk',
                name: '生产批号',
            },
            {
                comName: 'fcodenumber',
                name: '信息码',
            },
            {
                comName: 'fdatavalue1',
                name: '数据值1',
                tdShow: false,
            },
            {
                comName: 'fdatavalue2',
                name: '数据值2',
                tdShow: false,
            },
            {
                comName: 'fdatavalue3',
                name: '数据值3',
                tdShow: false,
            },
            {
                comName: 'fphoto1',
                name: '图片1',
                tdShow: false,
            },
            {
                comName: 'fphoto2',
                name: '图片2',
                tdShow: false,
            },
            {
                comName: 'fphoto3',
                name: '图片3',
                tdShow: false,
            },
            {
                comName: 'fresponsible',
                name: '责任人',
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
        ]);
    }
}
export default Pojo;

import React from 'react';
import {MyContext} from "../MyClass/TestContext";
import {Route} from "react-router-dom";

class Activation extends React.Component {
    constructor(props) {
        super(props);
        this.setProp = this.setProp.bind(this);
        this.getRef = this.getRef.bind(this);
        this.state = {
            prop: '',
            cref: React.createRef(),
        };
    }

    setProp = (prop) => {
        this.setState({
            prop: prop,
        }, () => {
            console.log(this.state.prop);
        });
    }
    getRef = (ref) => {
        this.setState({
            cref: ref,
        }, () => {
            console.log(this.state.cref);
        })
    }

    render() {
        // console.log(this.props.children);
        return (
            <>
                {this.props.children}
                {/*{*/}
                {/*    React.cloneElement(*/}
                {/*        this.props.children,*/}
                {/*        {*/}
                {/*            ref: this.getRef*/}
                {/*        }*/}
                {/*    )*/}
                {/*}*/}
            </>
        )
    }
}

export default Activation;

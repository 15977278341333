import React, {useState} from 'react';
import './index.less';
import {Row, Button, Table, Tag, Radio, Modal, Tabs, message, Form} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import {get} from "../../axios/Response";
import MyTable from "../../my_module/table/table";
// import Pojo from "./pojo"
import MyDataGrid from "../../my_module/datagrid/datagrid";
import MyButton from "../../my_module/MyClass/button";
import Nestgrid from "../../my_module/nestgrid/nestgrid";
import Pojos from "../pojos"
import {getButtomModal} from "../../my_module/MyClass/domUtil";
import {downloadFun, getCsvStr, getValueByProp} from "../../utils/utils";
import FormUtil from "../../my_module/Common/FormUtil";
import {DataType} from "../../api/mainPojo";
import {ComF} from "../../calzzs/initItems";

const {TabPane} = Tabs;
const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};

//码表信息
class index extends React.Component {
    constructor(props) {
        super(props);
        this.onDGRef = this.onDGRef.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        // this.addOpenIntercept = this.addOpenIntercept.bind(this);
        // this.editOpenIntercept = this.editOpenIntercept.bind(this);
        // this.ftypeid = 1;
        this.fsuitscodeqty = 0;
        this.fpackqty = 0;
        this.funitqty = 0;
        this.fischeck = 1;
        this.sysInfo = undefined;
        this.state = {
            // addModalVisible: false,
            pojo: Pojos.getPojo('wmcodeopt'),//new Pojo(),
            dg: undefined,
            ftypeid: 1,
            isModalVisible: false,
            confirmLoading: false,
        }
        console.log(this.pojo);
        this.getSysInfo();
    }

    getSysInfo = () => {
        let sysPojo = Pojos.getPojo('sysconfigure');
        sysPojo.getInfo()
            .then((ref) => {
                console.log(ref);
                this.sysInfo = ref.data;
                console.log(this.sysInfo);
            })
            .catch((ref) => {
                console.log(ref);
            });
    }
    exTrBus = [
        // new MyButton(
        //     '审批',
        //     'getItemByID11',
        //     undefined,
        //     (row) => {
        //         console.log(row);
        //         return true;
        //     }
        // )
    ]
    onDGRef = (ref) => {
        this.setState({
            dg: ref,
        }, () => {
            //this.state.dg.refInit();
        })
    }
    componentDidUpdate = (prevProps, prevState) => {
    }
    okFun = () => {
        return new Promise((resolve, reject) => {
            this.setState({
                confirmLoading:true,
            });
            if (this.state.ftypeid === 0) {
                message.error('请选择类型!');
                reject(undefined);
            } else if (this.fsuitscodeqty === 0) {
                message.error('请输入套码数!');
                reject(undefined);
            } else {
                this.state.pojo.getsuitscodelist({
                    ftypeid: this.state.ftypeid,
                    fsuitscodeqty: this.fsuitscodeqty,
                    fpackqty: this.fpackqty === null ? 0 : this.fpackqty,
                    funitqty: this.funitqty === null ? 0 : this.funitqty,
                    fischeck: this.fischeck === null ? 1 : this.fischeck,
                })
                    .then((ref) => {
                        // console.log(ref);
                        let index = 0;
                        getCsvStr(
                            (rows, fields) => {
                                let dataE = [];
                                // console.log(dataE);
                                for (let i = 0; i !== rows.length; i++) {
                                    const row = rows[i];
                                    let _fsubcodes = row['fsubcodes'];
                                    // console.log(_fsubcodes);
                                    let _fnestcodes = row['fnestcodes'];
                                    if (_fsubcodes !== undefined && _fsubcodes.length > 0) {
                                        for (let k in _fsubcodes) {
                                            dataE[index] = [];
                                            dataE[index].push(parseInt(i) + 1);
                                            for (let j in fields) {
                                                const key = fields[j];
                                                const value = getValueByProp(row, key);
                                                dataE[index].push(value);
                                            }
                                            for (let j in _fsubcodes[k]) {
                                                if (j === 'fcode') {
                                                    _fsubcodes[k][j] = this.sysInfo['furl'] + _fsubcodes[k][j];
                                                }
                                                dataE[index].push(_fsubcodes[k][j]);
                                            }

                                            index++;
                                        }
                                        console.log(dataE);
                                    } else if (_fnestcodes !== undefined && _fnestcodes.length > 0) {
                                        for (let k in _fnestcodes) {
                                            let _fnestcode = _fnestcodes[k];
                                            let nfsubcodes = _fnestcode['fsubcodes'];
                                            if (nfsubcodes !== undefined && nfsubcodes.length > 0) {
                                                for (let k in nfsubcodes) {
                                                    dataE[index] = [];
                                                    dataE[index].push(parseInt(i) + 1);
                                                    for (let j in fields) {
                                                        const key = fields[j];
                                                        const value = getValueByProp(row, key);
                                                        const svalue = getValueByProp(_fnestcode, key);
                                                        dataE[index].push(value);
                                                        dataE[index].push(svalue);
                                                    }
                                                    dataE[index].push(nfsubcodes[k]);
                                                    index++;
                                                }
                                            }
                                        }
                                    } else {
                                        dataE[index] = [];
                                        for (let j in fields) {
                                            const key = fields[j];
                                            const value = getValueByProp(row, key);
                                            dataE[index].push(index + 1);
                                            dataE[index].push(value);
                                        }
                                        index++;
                                    }
                                }
                                return dataE;
                            },
                            ref.data,
                            [],
                            ['fcode'],
                            '生成码',
                            downloadFun,
                        )
                        this.setState({
                            confirmLoading:false,
                            isModalVisible:false,
                        });
                        resolve(ref);
                    })
                    .catch((ref) => {
                        this.setState({
                            confirmLoading:false,
                        });
                        reject(ref);
                    })
            }
        });
    }
    // addModal = () => {
    //     let model =
    //         <Modal title="Basic Modal" visible={this.state.addModalVisible}
    //                onCancel={() => {
    //                    this.setState({
    //                        addModalVisible:false,
    //                    })
    //                }}>
    //             <p>Some contents...</p>
    //             <p>Some contents...</p>
    //             <p>Some contents...</p>
    //         </Modal>;
    //     return model;
    // }
    // addOpenIntercept = (ref) => {
    //     console.log(ref);
    //     // this.setState({
    //     //     addModalVisible:true,
    //     // })
    //     return false;
    // }
    // editOpenIntercept = (ref) => {
    //     console.log(ref);
    //     return false;
    // }
    render() {
        // console.log(this.state.ftypeid);
        let formItems = [
            new FormUtil().getFormItem(
                {
                    dvalue: this.state.ftypeid,
                    defaultValue: this.state.ftypeid,
                    column: {
                        comName: 'ftypeid',
                        prop: 'ftypeid',
                        name: '类型',
                        dataType: DataType.Com,
                        defaultValue: 1,
                        state: {
                            1: '垛码',
                            2: '包装码',
                            3: '单位码',
                        },
                    },
                    onChange: (value, ...data) => {
                        console.log(value);
                        // console.log(data);
                        // this.ftypeid = value === null ? 1 : value;
                        this.setState({
                            ftypeid: value === null ? 1 : value,
                        })
                    }
                },
            ),
            new FormUtil().getFormItem(
                {
                    dvalue: this.fsuitscodeqty,
                    column: {
                        comName: 'fsuitscodeqty',
                        prop: 'fsuitscodeqty',
                        name: '请输入套码数',
                        dataType: DataType.NUM,
                    },
                    onChange: (value, ...data) => {
                        // console.log(value);
                        // console.log(data);
                        this.fsuitscodeqty = value === null ? 0 : value;
                    }
                },
            ),

        ];
        if (this.state.ftypeid === 1) {
            formItems.push(
                new FormUtil().getFormItem(
                    {
                        dvalue: this.fpackqty,
                        column: {
                            comName: 'fpackqty',
                            prop: 'fpackqty',
                            name: '包装码数/每套',
                            dataType: DataType.NUM,
                        },
                        onChange: (value, ...data) => {
                            // console.log(value);
                            // console.log(data);
                            this.fpackqty = value === null ? 0 : value;
                        }
                    },
                ),
            );
        }
        if (this.state.ftypeid === 2) {
            formItems.push(
                new FormUtil().getFormItem(
                    {
                        dvalue: this.funitqty,
                        column: {
                            comName: 'funitqty',
                            prop: 'funitqty',
                            name: '单位码数/每套',
                            dataType: DataType.NUM,
                        },
                        onChange: (value, ...data) => {
                            // console.log(value);
                            // console.log(data);
                            this.funitqty = value === null ? 0 : value;
                        }
                    },
                ),
            );
            formItems.push(
                new FormUtil().getFormItem(
                    {
                        column: {
                            comName: 'fischeck',
                            prop: 'fischeck',
                            name: '验证码',
                            dataType: DataType.Com,
                            defaultValue: 1,
                            state: {
                                1: '是',
                                2: '否',
                            },
                        },
                        onChange: (value, ...data) => {
                            // console.log(value);
                            // console.log(data);
                            this.fischeck = value === null ? 1 : value;
                        }
                    },
                ),
            );
        }
        if (this.state.ftypeid === 3) {
            formItems.push(
                new FormUtil().getFormItem(
                    {
                        column: {
                            comName: 'fischeck',
                            prop: 'fischeck',
                            name: '验证码',
                            dataType: DataType.Com,
                            defaultValue: 1,
                            state: {
                                1: '是',
                                2: '否',
                            },
                        },
                        onChange: (value, ...data) => {
                            // console.log(value);
                            // console.log(data);
                            this.fischeck = value === null ? 1 : value;
                        }
                    },
                ),
            );
        }
        console.log(formItems);
        return (
            <div>
                <Modal title="生成码" confirmLoading={this.state.confirmLoading} visible={this.state.isModalVisible} onOk={this.okFun} onCancel={() => {
                    this.setState({
                        isModalVisible: false,
                    })
                }}>
                    <Form {...layout}>
                        {formItems}
                    </Form>
                </Modal>
                {/*{this.addModal()}*/}
                <MyDataGrid
                    // addOpenIntercept={this.addOpenIntercept}
                    // editOpenIntercept={this.editOpenIntercept}
                    exHeadBus={[
                        getButtomModal(
                            {
                                title: '生成码',
                                // content: '确定生成2W个码?',
                                name: 'getsuitscodelist',
                                isConfirm: true,
                                func: (row) => {
                                    this.setState({
                                        isModalVisible: true,
                                    })
                                },
                                okFun: (row) => {

                                },
                                thenFun: (ref) => {
                                    this.state.dg.getList();
                                },
                                confirma: false,
                            }
                        ),]
                    }
                    onRef={this.onDGRef}
                    // isSetTrBus={true}
                    isAdd={false}
                    isEdit={false}
                    isDel={false}
                    pojo={this.state.pojo}
                    exTrBus={this.exTrBus}/>
            </div>
        );
    }
}

export default index;

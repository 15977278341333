import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {ComF, initItem, item} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";

class Pojo extends MyPojo {
    getTitleList = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + 'mmlotteryrule' + '/getinfo', params)
                .then((ref) => {
                    resolve(ref['data']['fentryEXlist'] === undefined ? [] : ref['data']['fentryEXlist']);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }

    constructor() {
        //抽奖规则明细
        super('mmlotteryruleentry');
        this.controller = 'mm';
        this.cName = '抽奖规则明细';
        this.infoColumn = ''
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk: true,
            },
            {
                comName: 'fgoodsnumber',
                name: '存货',
                width: 300,
                showLength: 30,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'fminventory',
                    action: 'getList',
                    id: 'fnumber',
                    text: '{fcaption}',
                }),
                exComParent: 'fgoodscaption',
            },
            {
                comName: 'flotteryrulepk',
                name: '规则',
                show: false,
            },
            {
                comName: 'fplancodeqty',
                dataType: DataType.NUM,
                name: '计划基数',
            },
            {
                comName: 'fcodeqty',
                dataType: DataType.NUM,
                name: '目前基数',
                isInsert: false,
                isUpdate: false,
            },
            {
                comName: 'fplanqty',
                name: '计划中奖数量',
            },
            {
                comName: 'fqty',
                name: '已中奖数量',
                isInsert: false,
                isUpdate: false,

            },
            {
                comName: 'fregion',
                name: '区域',
            },
            {
                comName: 'fprizepk',
                name: '奖品',
                width: 300,
                showLength: 30,
                dataType: DataType.ComGETServer,
                comF: new ComF({
                    controller: 'mmprize',
                    action: 'getList',
                    id: 'fpk',
                    text: '{fcaption}',
                }),
                exComParent: 'fprize',
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
        ]);
    }
}

export default Pojo;

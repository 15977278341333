import {DatePicker, Form, Input, InputNumber, Select, Table} from "antd";
import React from "react";
import {DataType} from "../../api/mainPojo";
import {formateDate, getValueByProp, setRowValue, setValueByProp} from "../../utils/utils";
import MTreeSelect from "../mTreeSelect/mTreeSelect";
import moment from 'moment';
import MyDatePicker from "../myDatePicker/myDatePicker";
import MyUpload from "../myUpload/myUpload";

export default class FormUtil {
    row = undefined;

    constructor({
                    row = undefined,
                } = {}) {
        this.row = row;
        // console.log(this.row);
    }

    getFormItem = ({
                       column = undefined,
                       dvalue = undefined,
                       showLabel = true,
                       onChange = (value, ...data) => {
                           // console.log(value);
                           // console.log(data);
                       },
                       refFun = (comName, ref) => {
                           // console.log(comName);
                           // console.log(ref);
                           // console.log(ref.state.value);
                       },
                       shouldUpdate = ({prevValues = undefined, curValues = undefined} = {}) => {
                       },
                       data = undefined,
                   } = {}) => {
        let column_name = showLabel ? column.name : '';
        let formItemDom = undefined;
        switch (column.dataType) {
            case DataType.String:
                let strComF = column.comF;
                let isMultiple = false;
                if (strComF !== undefined && strComF !== null) {
                    isMultiple = strComF.multiple;
                }
                if (isMultiple) {
                    let strValue = getValueByProp(this.row, column.prop);
                    if (!Array.isArray(strValue)) {
                        if (strValue !== '') {
                            setValueByProp(this.row, column.prop, strValue.split(','));
                        } else {
                            setValueByProp(this.row, column.prop, []);
                        }
                    }
                    formItemDom = (
                        <Form.Item
                            label={column_name}
                            name={column.prop.split('.')}
                            // rules={[{required: true, message: 'Please input your username!'}]}
                        >
                            {/*<Input*/}
                            {/*    ref={(ref) => refFun(column.comName, ref)}*/}
                            {/*    onChange={(value) => {*/}
                            {/*        //.target.value*/}
                            {/*        onChange(value);*/}
                            {/*    }}*/}
                            {/*/>*/}
                            <Select
                                mode="tags"
                                // size={size}
                                placeholder=""
                                onChange={(value) => {
                                    console.log(value);
                                }}
                                // defaultValue={['a10', 'c12']}
                                // onChange={handleChange}
                            >
                                {/*{children}*/}
                            </Select>
                        </Form.Item>
                    );
                } else {
                    formItemDom = (
                        <Form.Item
                            label={column_name}
                            name={column.prop.split('.')}
                            // rules={[{required: true, message: 'Please input your username!'}]}
                        >
                            <Input
                                ref={(ref) => refFun(column.comName, ref)}
                                onChange={(value) => {
                                    //.target.value
                                    onChange(value);
                                }}
                            />
                        </Form.Item>
                    );
                }
                break;
            case DataType.NUM:
                formItemDom = (
                    <Form.Item
                        label={column_name}
                        name={column.prop.split('.')}
                        // rules={[{required: true, message: 'Please input your username!'}]}
                    >
                        <InputNumber
                            style={{width: '100%'}}
                            defaultValue={dvalue === undefined ? getValueByProp(this.row, column.prop) : dvalue}
                            ref={(ref) => refFun(column.comName, ref)}
                            onChange={(value) => {
                                onChange(value);
                            }}
                        />
                    </Form.Item>
                );
                break;
            case DataType.Img:
                formItemDom = (
                    <Form.Item
                        label={column_name}
                        name={column.prop.split('.')}
                        // rules={[{required: true, message: 'Please input your username!'}]}
                    >
                        <MyUpload
                            style={{width: '100%'}}
                            // defaultValue={dvalue === undefined ? getValueByProp(this.row, column.prop) : dvalue}
                            ref={(ref) => refFun(column.comName, ref)}
                            onChange={(value) => {
                                // console.log(value);
                                // onChange(value);
                            }}
                        />
                    </Form.Item>
                );
                break;
            case DataType.DataTime:
                // let ref = moment(formateDate(new Date()), 'YYYY-MM-DD');
                // if (Object.keys(this.row).length > 0) {
                //     let value = getValueByProp(this.row, column.prop);
                //     if(value._isAMomentObject === undefined) {
                //         if (value === 253402099200000 || value === '9999-12-30 23:59:59') {
                //             value = formateDate(new Date());
                //         } else {
                //             value = formateDate(value);
                //         }
                //         // console.log(value);
                //         ref = moment(value, 'YYYY-MM-DD HH:mm:ss');
                //         setValueByProp(this.row, column.prop, ref);
                //     }
                // }
                formItemDom = (
                    <Form.Item
                        label={column_name}
                        name={column.prop.split('.')}
                        // rules={[{required: true, message: 'Please input your username!'}]}
                    >
                        {/*defaultValue={ref} value={ref}*/}
                        {/*<DatePicker*/}
                        {/*    ref={(ref) => refFun(column.comName, ref)}*/}
                        {/*    format={'YYYY-MM-DD HH:mm:ss'}*/}
                        {/*    showTime*/}
                        {/*    onChange={(date, dateString) => {*/}
                        {/*        // console.log(dateString);*/}
                        {/*        onChange(date, dateString);*/}
                        {/*    }}*/}
                        {/*/>*/}
                        <MyDatePicker
                            showTime={column.showTime}
                            ref={(ref) => {
                                refFun(column.comName, ref);
                                if (ref !== null) {
                                    ref.sValue(getValueByProp(this.row, column.prop));
                                }
                            }}
                            onChange={(value) => {
                                onChange(value);
                            }}
                        />
                    </Form.Item>
                );
                break;
            case DataType.Com:
                let options = [];
                let myState = column.stateFiltration === undefined ? undefined : column.stateFiltration(this.row, data);
                column.state = myState === undefined ? column.state : myState;
                let value = getValueByProp(this.row, column.prop) === undefined ? '' : getValueByProp(this.row, column.prop).toString();
                // console.log(value);
                // if(value !== undefined){
                //     value = value.toString();
                // }

                if (column.state instanceof Array) {
                    for (let i = 0; i !== column.state.length; i++) {
                        // options.push(
                        //     <Option value={i}>{column.state[i]}</Option>
                        // )
                        options.push({
                            label: column.state[i],
                            value: i,
                        });
                    }
                } else {
                    for (let key in column.state) {
                        if (this.row !== undefined) {
                            if (value === '' || value === '0' || value === 0) {
                                value = parseInt(key);
                                if (Object.keys(this.row).length > 0) {
                                    if (getValueByProp(this.row, column.prop) !== undefined) {
                                        // console.log('getValueByProp');
                                        this.row = setValueByProp(this.row, column.prop, value);
                                    }
                                }
                            }
                        } else {
                            value = column.defaultValue === undefined ? 1 : column.defaultValue;
                        }
                        options.push({
                            label: column.state[key],
                            value: parseInt(key),
                        });
                    }
                }
                // console.log(this.row);
                // console.log(column);
                console.log(options);
                console.log(value);
                formItemDom = (
                    <Form.Item
                        label={column_name}
                        name={column.prop.split('.')}
                        rules={[{required: true, message: '!'}]}
                    >
                        <Select
                            ref={(ref) => refFun(column.comName, ref)}
                            value={value}
                            options={options}
                            onChange={(value) => {
                                onChange(value);
                            }}
                            defaultValue={value}
                        >
                            {/*{options}*/}
                        </Select>
                    </Form.Item>
                )
                break;
            case DataType.ComGETServer:
                let comF = column.comF;
                // let csValue = '';
                // if (Object.keys(this.row).length > 0) {
                //     if (getValueByProp(this.row, column.prop) !== undefined) {
                //         csValue = getValueByProp(this.row, column.prop);
                //     }
                // }
                // console.log(this.row);
                if (comF.multiple) {
                    let strValue = getValueByProp(this.row, column.prop);
                    if (!Array.isArray(strValue)) {
                        if (strValue !== undefined && strValue !== '') {
                            setValueByProp(this.row, column.prop, strValue.split(','));
                        } else {
                            setValueByProp(this.row, column.prop, []);
                        }
                    }
                }

                formItemDom = (
                    <Form.Item
                        label={column_name}
                        name={column.prop.split('.')}
                        rules={[{required: column.required, message: '请选择' + column.name + '信息!'}]}
                    >
                        <MTreeSelect
                            onChange={(value) => {
                                onChange(value);
                            }}
                            isInitialize={comF.isInitialize}
                            controller={comF.controller}
                            action={comF.action}
                            text={comF.text}
                            id={comF.id}
                            row={this.row}
                            column={column}
                            multiple={comF.multiple}
                            onRef={(ref) => {
                                refFun(column.comName, ref);
                            }}
                        />
                    </Form.Item>
                )
                break;
            case DataType.ComTable:
                options = [];
                value = getValueByProp(this.row, column.prop);//.toString()
                if (column.state instanceof Array) {
                    for (let i = 0; i !== column.state.length; i++) {
                        // options.push(
                        //     <Option value={i}>{column.state[i]}</Option>
                        // )
                        options.push({
                            label: column.state[i],
                            value: i,
                        });
                    }
                } else {
                    for (let key in column.state) {
                        if (value === '' || value === '0' || value === 0) {
                            value = parseInt(key);
                        }
                        if (Object.keys(this.row).length > 0) {
                            // console.log(getValueByProp(this.row, column.prop));
                            if (getValueByProp(this.row, column.prop) !== undefined) {
                                console.log(column);
                                setValueByProp(this.row, column.prop, value);
                            }
                            // console.log(this.row);
                        }
                        // options.push(
                        //     <Option value={key}>{column.state[key]}</Option>
                        // )
                        options.push({
                            label: column.state[key],
                            value: parseInt(key),
                        });
                    }
                }
                formItemDom = (
                    <Form.Item
                        label={column_name}
                        name={column.prop.split('.')}
                        rules={[{required: true, message: '!'}]}
                    >
                        <Select
                            ref={(ref) => refFun(column.comName, ref)}
                            onChange={(value) => {
                                onChange(value);
                            }}
                            value={value}
                            options={options}
                            dropdownRender={menu => (
                                <div>
                                    {/*{menu}*/}
                                    <Table dataSource={options} columns={[
                                        {
                                            title: '姓名',
                                            dataIndex: 'label',
                                            key: 'label',
                                        },
                                        {
                                            title: '年龄',
                                            dataIndex: 'value',
                                            key: 'value',
                                        },
                                    ]}/>;
                                </div>
                            )}
                        >
                        </Select>
                    </Form.Item>
                )
                break;
            default:
                formItemDom = undefined;
        }
        if (formItemDom === undefined) {
            return undefined;
        } else {
            if (shouldUpdate !== undefined) {
                return (
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) => {
                            // console.log(prevValues);
                            // console.log(curValues);
                            // console.log(prevValues !== curValues);
                            // console.log(JSON.stringify(prevValues));
                            // console.log(JSON.stringify(curValues));
                            shouldUpdate({
                                    prevValues: prevValues,
                                    curValues: curValues,
                                }
                            );
                            return prevValues !== curValues;
                        }}
                    >
                        {
                            ({getFieldValue}) => {
                                // console.log(getFieldValue('info'));
                                return (
                                    formItemDom
                                )
                            }
                        }
                    </Form.Item>
                )
            } else {
                return (
                    <Form.Item
                        noStyle
                    >
                        {
                            ({getFieldValue}) => {
                                // console.log(getFieldValue('info'));
                                return (
                                    formItemDom
                                )
                            }
                        }
                    </Form.Item>
                )
            }
        }
    }
}
// 旧代码
// if (column.dataType === DataType.String) {
//     formItems.push(
//         <Form.Item
//             label={column.name}
//             name={column.prop.split('.')}
//             // rules={[{required: true, message: 'Please input your username!'}]}
//         >
//             <Input/>
//         </Form.Item>
//     )
// }
// if (column.dataType === DataType.NUM) {
//     formItems.push(
//         <Form.Item
//             label={column.name}
//             name={column.prop.split('.')}
//             // rules={[{required: true, message: 'Please input your username!'}]}
//         >
//             <Input/>
//         </Form.Item>
//     )
// }
// if (column.dataType === DataType.ComTable) {
//     let options = [];
//     let value = getValueByProp(this.row, column.prop);//.toString()
//     if (column.state instanceof Array) {
//         for (let i = 0; i !== column.state.length; i++) {
//             // options.push(
//             //     <Option value={i}>{column.state[i]}</Option>
//             // )
//             options.push({
//                 label: column.state[i],
//                 value: i,
//             });
//         }
//     } else {
//         for (let key in column.state) {
//             if (value === '' || value === '0' || value === 0) {
//                 value = parseInt(key);
//             }
//             if (Object.keys(this.row).length > 0) {
//                 console.log(getValueByProp(this.row, column.prop));
//                 if (getValueByProp(this.row, column.prop) !== undefined) {
//                     setValueByProp(this.row, column.prop, value);
//                 }
//                 console.log(this.row);
//             }
//             // options.push(
//             //     <Option value={key}>{column.state[key]}</Option>
//             // )
//             options.push({
//                 label: column.state[key],
//                 value: parseInt(key),
//             });
//         }
//     }
//     // if(this.state.comTableState[column.prop] === undefined) {
//     //     this.state.comTableState[column.prop] = false;
//     //     this.setState({
//     //         comTableState: this.state.comTableState,
//     //     })
//     // }
//     formItems.push(
//         <Form.Item
//             label={column.name}
//             name={column.prop.split('.')}
//             rules={[{required: true, message: '!'}]}
//         >
//             <Select
//                 value={value}
//                 // onFocus={() => {
//                 //     this.state.comTableState[column.prop] = true;
//                 //     this.setState({
//                 //         comTableState:this.state.comTableState,
//                 //     })
//                 // }}
//                 // onBlur={() => {
//                 //     this.state.comTableState[column.prop] = false;
//                 //     this.setState({
//                 //         comTableState:this.state.comTableState,
//                 //     })
//                 // }}
//                 options={options}
//                 // open={this.state.comTableState[column.prop]}
//                 dropdownRender={menu => (
//                     <div>
//                         {/*{menu}*/}
//                         <Table dataSource={options} columns={[
//                             {
//                                 title: '姓名',
//                                 dataIndex: 'label',
//                                 key: 'label',
//                             },
//                             {
//                                 title: '年龄',
//                                 dataIndex: 'value',
//                                 key: 'value',
//                             },
//                         ]}/>;
//                     </div>
//                 )}
//             >
//                 {/*{options}*/}
//             </Select>
//         </Form.Item>
//     )
// }
// if (column.dataType === DataType.Com) {
//     let options = [];
//     let value = getValueByProp(this.row, column.prop).toString();
//     // console.log(value);
//     // if(value !== undefined){
//     //     value = value.toString();
//     // }
//     if (column.state instanceof Array) {
//         for (let i = 0; i !== column.state.length; i++) {
//             // options.push(
//             //     <Option value={i}>{column.state[i]}</Option>
//             // )
//             options.push({
//                 label: column.state[i],
//                 value: i,
//             });
//         }
//     } else {
//         for (let key in column.state) {
//             if (value === '' || value === '0' || value === 0) {
//                 value = parseInt(key);
//                 if (Object.keys(this.row).length > 0) {
//                     if (getValueByProp(this.row, column.prop) !== undefined) {
//                         // console.log('getValueByProp');
//                         this.row = setValueByProp(this.row, column.prop, value);
//                     }
//                 }
//             }
//             options.push({
//                 label: column.state[key],
//                 value: parseInt(key),
//             });
//         }
//     }
//     // console.log(options);
//     // console.log(getValueByProp(this.row,column.prop));
//     // console.log(JSON.stringify(this.row));
//     // console.log(value);
//     // console.log(column.prop);
//     formItems.push(
//         <Form.Item
//             label={column.name}
//             name={column.prop.split('.')}
//             rules={[{required: true, message: '!'}]}
//         >
//             <Select
//                 value={value}
//                 options={options}
//             >
//                 {/*{options}*/}
//             </Select>
//         </Form.Item>
//     )
// }
// if (column.dataType === DataType.ComGETServer) {
//     let comF = column.comF;
//     formItems.push(
//         <Form.Item
//             label={column.name}
//             name={column.prop.split('.')}
//             rules={[{required: true, message: '!'}]}
//         >
//             <MTreeSelect
//                 controller={comF.controller}
//                 action={comF.action}
//                 text={comF.text}
//                 id={comF.id}
//             ></MTreeSelect>
//         </Form.Item>
//     )
// }

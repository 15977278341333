import React, {useState} from 'react';
import {Row, Col, Divider, Table} from 'antd';
import './index.less';
const columns = [
    {
        title: 'Full Name',
        width: 100,
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
    },
    {
        title: 'Age',
        width: 100,
        dataIndex: 'age',
        key: 'age',
        fixed: 'left',
    },
    { title: 'Column 1', width: 100, dataIndex: 'address', key: '1' },
    { title: 'Column 2', width: 100, dataIndex: 'address', key: '2' },
    { title: 'Column 3', width: 100, dataIndex: 'address', key: '3' },
    { title: 'Column 4', width: 100, dataIndex: 'address', key: '4' },
    { title: 'Column 5', width: 100, dataIndex: 'address', key: '5' },
    { title: 'Column 6', width: 100, dataIndex: 'address', key: '6' },
    { title: 'Column 7', width: 100, dataIndex: 'address', key: '7' },
    { title: 'Column 8', width: 100, dataIndex: 'address', key: '8' },
    { title: 'Column 8', width: 100, dataIndex: 'address', key: '8' },
    { title: 'Column 8', width: 100, dataIndex: 'address', key: '8' },
    { title: 'Column 8', width: 100, dataIndex: 'address', key: '8' },
    { title: 'Column 8', width: 100, dataIndex: 'address', key: '8' },
    { title: 'Column 8', width: 100, dataIndex: 'address', key: '8' },
    { title: 'Column 8', width: 100, dataIndex: 'address', key: '8' },
    { title: 'Column 8', width: 100, dataIndex: 'address', key: '8' },
    {
        title: 'Action',
        key: 'operation',
        fixed: 'right',
        width: 100,
        render: () => <a>action</a>,
    },
];

const data = [
    {
        key: '1',
        name: 'John Brown',
        age: 32,
        address: 'New York Park',
    },
    {
        key: '2',
        name: 'Jim Green',
        age: 40,
        address: 'London Park',
    },
];
class index extends React.Component {
    constructor(props) {
        super(props);
    };

    render() {
        return (
            <Table columns={columns} dataSource={data} scroll={{ x: 1300 }} />
        )
    };
}

export default index;

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {initItem, item} from '../../calzzs/initItems'

class Pojo extends MyPojo {
    constructor() {
        //追朔防窜记录
        super('mmtracerecord');
        this.controller = 'mm';
        this.cName = '信息码查询记录';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk: true,
            },
            {
                comName: 'ftracecode',
                name: '追溯码',
                width: 250,
                showLength: 30,
            },
            {
                comName: 'ftypeid',
                name: '类别',
                width: 80,
                dataType: DataType.Com,
                state: {
                    1: '防伪',
                    2: '追溯',
                    3: '防窜',
                },
            },
            // {
            //     comName: 'fqutryqty',
            //     name: '查询次数',
            //     dataType: DataType.NUM,
            // },
            {
                comName: 'faddress',
                name: '扫码地点',
            },
            {
                comName: 'fopenid',
                name: 'openid',
                tdShow: false,
            },
            {
                comName: 'fstateid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '正常',
                    2: '异常',
                    // 3: '预警',
                    // 4: '伪造',
                },
            },
            {
                comName: 'fopttime',
                name: '操作时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                // tdShow: false,
            },
            // {
            //     comName: 'fcreatetime',
            //     name: '创建时间',
            //     dataType: DataType.DataTime,
            //     isInsert:false,
            //     isUpdate:false,
            //     tdShow: false,
            // },
            // {
            //     comName: 'fcreateman',
            //     name: '创建人',
            //     isInsert:false,
            //     isUpdate:false,
            //     tdShow: false,
            // },
            // {
            //     comName: 'fmodifytime',
            //     name: '修改时间',
            //     dataType: DataType.DataTime,
            //     isInsert:false,
            //     isUpdate:false,
            //     tdShow: false,
            // },
            // {
            //     comName: 'fmodifyman',
            //     name: '修改人',
            //     isInsert:false,
            //     isUpdate:false,
            //     tdShow: false,
            // },
        ]);
    }
}

export default Pojo;

import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import App from './App';
import Admin from "./layout/Admin/Admin";
import home from './pages/home/index';
import PageCommon from './my_module/Common/page_common';
// import sysstaff from './ylwmspages/sysstaff/index'
// import sysrolegroup from './ylwmspages/sysrolegroup/index'
//设备信息
import emequipment from './ylwmspages/emequipment/index'
//设备维护
import emmaintenance from './ylwmspages/emmaintenance/index'
//设备事件
import emmonitoring from './ylwmspages/emmonitoring/index'
//BOM表
import fmbom from './ylwmspages/fmbom/index'
//BOM表条目
import fmbomentry from './ylwmspages/fmbomentry/index'
//存货信息
import fminventory from './ylwmspages/fminventory/index'
//存货管理设置
import fminventoryconfig from './ylwmspages/fminventoryconfig/index'
//标签卡信息
import fmlabelcard from './ylwmspages/fmlabelcard/index'
//存货单位信息
import fmpackingunit from './ylwmspages/fmpackingunit/index'
//月台信息
import fmplatform from './ylwmspages/fmplatform/index'
//工序信息
import fmprocess from './ylwmspages/fmprocess/index'
//产线信息
import fmproductline from './ylwmspages/fmproductline/index'
//仓储信息
import fmstorage from './ylwmspages/fmstorage/index'
//承运公司信息
import lvmcarrier from './ylwmspages/lvmcarrier/index'
//物流信息
import lvmlogistics from './ylwmspages/lvmlogistics/index'
//客户(项目)信息
import mmcustomer from './ylwmspages/mmcustomer/index'
//销售计划
import mmplan from './ylwmspages/mmplan/index'
//销售计划条目
import mmplanentry from './ylwmspages/mmplanentry/index'
//销售订单
import mmsale from './ylwmspages/mmsale/index'
//销售订单条目
import mmsaleentry from './ylwmspages/mmsaleentry/index'
//追朔防窜记录
import mmtracerecord from './ylwmspages/mmtracerecord/index'
//生产订单管理
import pmorder from './ylwmspages/pmorder/index'
//生产订单条目
import pmorderentry from './ylwmspages/pmorderentry/index'
//工序监测过程
import pmprocesscheck from './ylwmspages/pmprocesscheck/index'
//工序上料
import pmprocessloading from './ylwmspages/pmprocessloading/index'
//工序记录
import pmprocessrecord from './ylwmspages/pmprocessrecord/index'
//生产任务管理
import pmtask from './ylwmspages/pmtask/index'
//采购需求计划
import smplan from './ylwmspages/smplan/index'
//采购需求计划条目
import smplanentry from './ylwmspages/smplanentry/index'
//采购订单
import smpurchase from './ylwmspages/smpurchase/index'
//采购订单条目
import smpurchaseentry from './ylwmspages/smpurchaseentry/index'
//供应商信息
import smsupplier from './ylwmspages/smsupplier/index'
//调拨订单
import smtransfer from './ylwmspages/smtransfer/index'
//调拨订单条目
import smtransferentry from './ylwmspages/smtransferentry/index'
//系统设置
import sysconfigure from './ylwmspages/sysconfigure/index'
//功能项信息
import sysfeature from './ylwmspages/sysfeature/index'
//职能信息
import sysfunction from './ylwmspages/sysfunction/index'
//编号管理
import sysinvnumber from './ylwmspages/sysinvnumber/index'
//登录日志
import sysloginlog from './ylwmspages/sysloginlog/index'
//操作日志
import sysoptlog from './ylwmspages/sysoptlog/index'
//组织架构
import sysorginfo from './ylwmspages/sysorginfo/index'
//权限组
import sysrolegroup from './ylwmspages/sysrolegroup/index'
//组权限
import sysrolegroupright from './ylwmspages/sysrolegroupright/index'
//组用户
import sysrolegroupusers from './ylwmspages/sysrolegroupusers/index'
//员工信息
import sysstaff from './ylwmspages/sysstaff/index'
//存货调整单管理
import wmchange from './ylwmspages/wmchange/index'
//盘点单管理
import wmcheck from './ylwmspages/wmcheck/index'
//结存表管理
import wmcheckout from './ylwmspages/wmcheckout/index'
//发货单管理
import wminvoice from './ylwmspages/wminvoice/index'
//发货单条目
import wminvoiceentry from './ylwmspages/wminvoiceentry/index'
//件码信息
import wmitemcode from './ylwmspages/wmitemcode/index'
//垛码信息
import wmpackagecode from './ylwmspages/wmpackagecode/index'
//包装码信息
import wmpackcode from './ylwmspages/wmpackcode/index'
//收货单管理
import wmreceipt from './ylwmspages/wmreceipt/index'
//收货单条目
import wmreceiptentry from './ylwmspages/wmreceiptentry/index'
//出入库记录
import wmstockrecord from './ylwmspages/wmstockrecord/index'
//过账策略管理
import wmtactics from './ylwmspages/wmtactics/index'
//过账记录
import wmtacticsrecord from './ylwmspages/wmtacticsrecord/index'
//出入库任务
import wmtask from './ylwmspages/wmtask/index'
//层码信息
import wmtiercode from './ylwmspages/wmtiercode/index'
//单位码信息
import wmunitcode from './ylwmspages/wmunitcode/index'
//码表信息
import wmcodeopt from './ylwmspages/wmcodeopt/index'
//消费者信息
import mmconsumer from './ylwmspages/mmconsumer/index'
//抽奖码查询记录
import mmlotteryrecord from './ylwmspages/mmlotteryrecord/index'
//奖品信息
import mmprize from './ylwmspages/mmprize/index'
//抽奖规则
import mmlotteryrule from './ylwmspages/mmlotteryrule/index'
//中奖信息
import mmlotteryinfo from './ylwmspages/mmlotteryinfo/index'
//批次信息
import rpbatchinfo from './ylwmspages/rpbatchinfo/index'
//召回列表
import rprecall from './ylwmspages/rprecall/index'
//出入库日报
import rpstockdaily from './ylwmspages/rpstockdaily/index'
//生产日报表
import rpproductdaily from './ylwmspages/rpproductdaily/index'
//产成品统计列表
import rpproduct from './ylwmspages/rpproduct/index'
//收发货量趋势图
import getstocksum from './ylwmspages/getstocksum/index'
//产能趋势图
import getproductsum from './ylwmspages/getproductsum/index'
//查询量趋势图
import getfeelingsum from './ylwmspages/getfeelingsum/index'
//产成品统计表
import getproducttotal from './ylwmspages/getproducttotal/index'

import Login from './pages/login/index';


import tableTest from './ylwmspages/test2/tabletest';

import {MyContext} from './my_module/MyClass/TestContext';
import Activation from "./my_module/Common/activation";
import CacheRoute, {CacheSwitch, getCachingKeys} from "react-router-cache-route";
import {WhereType} from "./api/mainPojo";

const TableTest = PageCommon(tableTest);


const rootPath = SystemInit.homeRoot;
// const rootPathMain = SystemInit.homeRoot + '/main'
const rootPathMain = 'main'


const Home = PageCommon(home);
// const Sysstaff = PageCommon(sysstaff);
// const Sysrolegroup = PageCommon(sysrolegroup);
console.log('********************************************')
console.log(wmcodeopt)
//码表信息
const Wmcodeopt = PageCommon(wmcodeopt);
//设备信息
const Emequipment = PageCommon(emequipment);
//设备维护
const Emmaintenance = PageCommon(emmaintenance);
//设备事件
const Emmonitoring = PageCommon(emmonitoring);
//BOM表
const Fmbom = PageCommon(fmbom);
//BOM表条目
const Fmbomentry = PageCommon(fmbomentry);
//存货信息
const Fminventory = PageCommon(fminventory);
//存货管理设置
const Fminventoryconfig = PageCommon(fminventoryconfig);
//标签卡信息
const Fmlabelcard = PageCommon(fmlabelcard);
//存货单位信息
const Fmpackingunit = PageCommon(fmpackingunit);
//月台信息
const Fmplatform = PageCommon(fmplatform);
//工序信息
const Fmprocess = PageCommon(fmprocess);
//产线信息
const Fmproductline = PageCommon(fmproductline);
//仓储信息
const Fmstorage = PageCommon(fmstorage);
//承运公司信息
const Lvmcarrier = PageCommon(lvmcarrier);
//物流信息
const Lvmlogistics = PageCommon(lvmlogistics);
//客户(项目)信息
const Mmcustomer = PageCommon(mmcustomer);
//销售计划
const Mmplan = PageCommon(mmplan);
//销售计划条目
const Mmplanentry = PageCommon(mmplanentry);
//销售订单
const Mmsale = PageCommon(mmsale);
//销售订单条目
const Mmsaleentry = PageCommon(mmsaleentry);
//追朔防窜记录
const Mmtracerecord = PageCommon(mmtracerecord);
//生产订单管理
const Pmorder = PageCommon(pmorder);
//生产订单条目
const Pmorderentry = PageCommon(pmorderentry);
//工序监测过程
const Pmprocesscheck = PageCommon(pmprocesscheck);
//工序上料
const Pmprocessloading = PageCommon(pmprocessloading);
//工序记录
const Pmprocessrecord = PageCommon(pmprocessrecord);
//生产任务管理
const Pmtask = PageCommon(pmtask);
//采购需求计划
const Smplan = PageCommon(smplan);
//采购需求计划条目
const Smplanentry = PageCommon(smplanentry);
//采购订单
const Smpurchase = PageCommon(smpurchase);
//采购订单条目
const Smpurchaseentry = PageCommon(smpurchaseentry);
//供应商信息
const Smsupplier = PageCommon(smsupplier);
//调拨订单
const Smtransfer = PageCommon(smtransfer);
//调拨订单条目
const Smtransferentry = PageCommon(smtransferentry);
//系统设置
const Sysconfigure = PageCommon(sysconfigure);
//功能项信息
const Sysfeature = PageCommon(sysfeature);
//职能信息
const Sysfunction = PageCommon(sysfunction);
//编号管理
const Sysinvnumber = PageCommon(sysinvnumber);
//登录日志
const Sysloginlog = PageCommon(sysloginlog);
//操作日志
const Sysoptlog = PageCommon(sysoptlog);
//组织架构
const Sysorginfo = PageCommon(sysorginfo);
//权限组
const Sysrolegroup = PageCommon(sysrolegroup);
//组权限
const Sysrolegroupright = PageCommon(sysrolegroupright);
//组用户
const Sysrolegroupusers = PageCommon(sysrolegroupusers);
//员工信息
const Sysstaff = PageCommon(sysstaff);
//存货调整单管理
const Wmchange = PageCommon(wmchange);
//盘点单管理
const Wmcheck = PageCommon(wmcheck);
//结存表管理
const Wmcheckout = PageCommon(wmcheckout);
//实时状态库存
const Wmcheckout_getstateinventory = PageCommon(wmcheckout,{
    winData:{
        name:'实时库存',
        key:'wmcheckout_getstateinventory',
    },
    funItem:{
        name:'getstateinventory',
    }
});
//实时库存
const Wmcheckout_getrealinventory = PageCommon(wmcheckout,{
    winData:{
        name:'实时总库存',
        key:'wmcheckout_getrealinventory',
    },
    funItem:{
        name:'getrealinventory',
    }
});
//发货单管理
const Wminvoice = PageCommon(wminvoice);
//销售出库单 wminvoice_ftypeid_EqualTo_1
const Wminvoice_ftypeid_EqualTo_1 = PageCommon(wminvoice, {
    queryData: [{
        comname: 'ftypeid',
        comtype: WhereType.EqualTo.name,
        value: 1,
    }],
    editData:{
        ftypeid:1,
    },
    winData:{
        name:'销售出库单',
        key:'wminvoice_ftypeid_EqualTo_1',
    }
});
//调拨出库单 wminvoice_ftypeid_EqualTo_2
const Wminvoice_ftypeid_EqualTo_2 = PageCommon(wminvoice, {
    queryData: [{
        comname: 'ftypeid',
        comtype: WhereType.EqualTo.name,
        value: 2,
    }],
    editData:{
        ftypeid:2,
    },
    winData:{
        name:'调拨出库单',
        key:'wminvoice_ftypeid_EqualTo_2',
    }
});
//生产领料单 wminvoice_ftypeid_EqualTo_3
const Wminvoice_ftypeid_EqualTo_3 = PageCommon(wminvoice, {
    queryData: [{
        comname: 'ftypeid',
        comtype: WhereType.EqualTo.name,
        value: 3,
    }],
    editData:{
        ftypeid:3,
    },
    winData:{
        name:'生产领料单',
        key:'wminvoice_ftypeid_EqualTo_3',
    }
});
//抽检出库单 wminvoice_ftypeid_EqualTo_4
const Wminvoice_ftypeid_EqualTo_4 = PageCommon(wminvoice, {
    queryData: [{
        comname: 'ftypeid',
        comtype: WhereType.EqualTo.name,
        value: 4,
    }],
    editData:{
        ftypeid:4,
    },
    winData:{
        name:'抽检出库单',
        key:'wminvoice_ftypeid_EqualTo_4',
    }
});
//破损出库单 wminvoice_ftypeid_EqualTo_5
const Wminvoice_ftypeid_EqualTo_5 = PageCommon(wminvoice, {
    queryData: [{
        comname: 'ftypeid',
        comtype: WhereType.EqualTo.name,
        value: 5,
    }],
    editData:{
        ftypeid:5,
    },
    winData:{
        name:'破损出库单',
        key:'wminvoice_ftypeid_EqualTo_5',
    }
});
//返工出库单 wminvoice_ftypeid_EqualTo_6
const Wminvoice_ftypeid_EqualTo_6 = PageCommon(wminvoice, {
    queryData: [{
        comname: 'ftypeid',
        comtype: WhereType.EqualTo.name,
        value: 6,
    }],
    editData:{
        ftypeid:6,
    },
    winData:{
        name:'返工出库单',
        key:'wminvoice_ftypeid_EqualTo_6',
    }
});
//其他出库单 wminvoice_ftypeid_EqualTo_7
const Wminvoice_ftypeid_EqualTo_7 = PageCommon(wminvoice, {
    queryData: [{
        comname: 'ftypeid',
        comtype: WhereType.EqualTo.name,
        value: 7,
    }],
    editData:{
        ftypeid:7,
    },
    winData:{
        name:'其他出库单',
        key:'wminvoice_ftypeid_EqualTo_7',
    }
});
//发货单条目
const Wminvoiceentry = PageCommon(wminvoiceentry);
//件码信息
const Wmitemcode = PageCommon(wmitemcode,{
    winData:{
        name:'件码信息',
        key:'wmcodeopt_getitemdatagrid',
    },
    funItem:{
        name:'getitemdatagrid',
    }
});
//垛码信息
const Wmpackagecode = PageCommon(wmpackagecode,{
    winData:{
        name:'垛码信息',
        key:'wmcodeopt_getpackagedatagrid',
    },
    funItem:{
        name:'getpackagedatagrid',
    }
});
//包装码信息
const Wmpackcode = PageCommon(wmpackcode,{
    winData:{
        name:'包装码信息',
        key:'wmcodeopt_getpackdatagrid',
    },
    funItem:{
        name:'getpackdatagrid',
    }
});
//收货单管理
const Wmreceipt = PageCommon(wmreceipt);
//生产入库单 wmreceipt_ftypeid_EqualTo_1
const Wmreceipt_ftypeid_EqualTo_1 = PageCommon(wmreceipt, {
    queryData: [{
        comname: 'ftypeid',
        comtype: WhereType.EqualTo.name,
        value: 1,
    }],
    editData:{
        ftypeid:1,
    },
    winData:{
        name:'生产入库单',
        key:'wmreceipt_ftypeid_EqualTo_1',
    }
});
//调拨入库单 wmreceipt_ftypeid_EqualTo_2
const Wmreceipt_ftypeid_EqualTo_2 = PageCommon(wmreceipt, {
    queryData: [{
        comname: 'ftypeid',
        comtype: WhereType.EqualTo.name,
        value: 2,
    }],
    editData:{
        ftypeid:2,
    },
    winData:{
        name:'调拨入库单',
        key:'wmreceipt_ftypeid_EqualTo_2',
    }
});
//采购入库单 wmreceipt_ftypeid_EqualTo_3
const Wmreceipt_ftypeid_EqualTo_3 = PageCommon(wmreceipt, {
    queryData: [{
        comname: 'ftypeid',
        comtype: WhereType.EqualTo.name,
        value: 3,
    }],
    editData:{
        ftypeid:3,
    },
    winData:{
        name:'采购入库单',
        key:'wmreceipt_ftypeid_EqualTo_3',
    }
});
//其他入库单 wmreceipt_ftypeid_EqualTo_4
const Wmreceipt_ftypeid_EqualTo_4 = PageCommon(wmreceipt, {
    queryData: [{
        comname: 'ftypeid',
        comtype: WhereType.EqualTo.name,
        value: 4,
    }],
    editData:{
        ftypeid:4,
    },
    winData:{
        name:'其他入库单',
        key:'wmreceipt_ftypeid_EqualTo_4',
    }
});
//收货单条目
const Wmreceiptentry = PageCommon(wmreceiptentry);
//出入库记录
const Wmstockrecord = PageCommon(wmstockrecord);
//过账策略管理
const Wmtactics = PageCommon(wmtactics);
//过账记录
const Wmtacticsrecord = PageCommon(wmtacticsrecord,{
    winData:{
        name:'信息码过账',
        key:'wmcodeopt_gettacticsrecorddatagrid',
    },
    funItem:{
        name:'gettacticsrecorddatagrid',
    }
});
//出入库任务
const Wmtask = PageCommon(wmtask);
const Wmtask_ftypeid_LessThanOrEqualTo_4 = PageCommon(wmtask, {
    queryData: [{
        comname: 'ftypeid',
        comtype: WhereType.LessThanOrEqualTo.name,
        value: 4,
    }],
    editData:{
        ftypeid:4,
    },
    winData:{
        name:'入库任务',
        key:'wmtask_ftypeid_LessThanOrEqualTo_4',
    }
});
const Wmtask_ftypeid_GreaterThanOrEqualTo_5 = PageCommon(wmtask, {
    queryData: [{
        comname: 'ftypeid',
        comtype: WhereType.GreaterThanOrEqualTo.name,
        value: 5,
    }],
    editData:{
        ftypeid:5,
    },
    winData:{
        name:'出库任务',
        key:'wmtask_ftypeid_GreaterThanOrEqualTo_5',
    }
});
//层码信息
const Wmtiercode = PageCommon(wmtiercode);
//单位码信息
const Wmunitcode = PageCommon(wmunitcode,{
    winData:{
        name:'单位码信息',
        key:'wmcodeopt_getunitdatagrid',
    },
    funItem:{
        name:'getunitdatagrid',
    }
});
//消费者信息
const Mmconsumer = PageCommon(mmconsumer);
//抽奖码查询记录
const Mmlotteryrecord = PageCommon(mmlotteryrecord);
//奖品信息
const Mmprize = PageCommon(mmprize);
//中奖信息
const Mmlotteryinfo = PageCommon(mmlotteryinfo);
//抽奖规则
const Mmlotteryrule = PageCommon(mmlotteryrule);
//批次信息
const Rpbatchinfo = PageCommon(rpbatchinfo);
//召回列表
const Rprecall = PageCommon(rprecall)
//出入库日报
const Rpstockdaily = PageCommon(rpstockdaily)
//生产日报表
const Rpproductdaily = PageCommon(rpproductdaily)
//产成品统计列表
const Rpproduct = PageCommon(rpproduct)
//收发货量趋势图
const Getstocksum = PageCommon(getstocksum)
//产能趋势图
const Getproductsum = PageCommon(getproductsum)
//查询量趋势图
const Getfeelingsum = PageCommon(getfeelingsum)
//产成品统计表
const Getproducttotal = PageCommon(getproducttotal)


class IRouter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagesState: {},
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log(this.props);
    }

    render() {
        return (
            <BrowserRouter>
                <App>
                    <Route exact path={'/' + rootPath}>
                        <Login/>
                    </Route>
                    <CacheRoute path={'/' + rootPathMain} render={() =>
                        <Admin>
                            <CacheSwitch>
                                <CacheRoute cacheKey={'home'} key={'home'}
                                            path={'/' + rootPathMain + '/home'} component={Home}/>

                                {/*<CacheRoute key={'sysstaff'} path={'/' + rootPathMain + '/sysstaff'}>*/}
                                {/*    <Sysstaff/>*/}
                                {/*</CacheRoute>*/}
                                {/*<CacheRoute key={'sysrolegroup'} path={'/' + rootPathMain + '/sysrolegroup'}>*/}
                                {/*    <Sysrolegroup/>*/}
                                {/*</CacheRoute>*/}
                                <CacheRoute cacheKey={'tableTest'} key={'tableTest'}
                                            path={'/' + rootPathMain + '/tableTest'} component={TableTest}/>
                                {/*设备信息*/}
                                <CacheRoute cacheKey={'emequipment'} key={'emequipment'}
                                            path={'/' + rootPathMain + '/emequipment'} component={Emequipment}/>

                                {/*设备维护*/}
                                <CacheRoute cacheKey={'emmaintenance'} key={'emmaintenance'}
                                            path={'/' + rootPathMain + '/emmaintenance'} component={Emmaintenance}/>

                                {/*设备事件*/}
                                <CacheRoute cacheKey={'emmonitoring'} key={'emmonitoring'}
                                            path={'/' + rootPathMain + '/emmonitoring'} component={Emmonitoring}/>

                                {/*BOM表*/}
                                <CacheRoute cacheKey={'fmbom'} key={'fmbom'} path={'/' + rootPathMain + '/fmbom'}
                                            component={Fmbom}/>

                                {/*BOM表条目*/}
                                <CacheRoute cacheKey={'fmbomentry'} key={'fmbomentry'}
                                            path={'/' + rootPathMain + '/fmbomentry'} component={Fmbomentry}/>

                                {/*存货信息*/}
                                <CacheRoute cacheKey={'fminventory'} key={'fminventory'}
                                            path={'/' + rootPathMain + '/fminventory'} component={Fminventory}/>

                                {/*存货管理设置*/}
                                <CacheRoute cacheKey={'fminventoryconfig'} key={'fminventoryconfig'}
                                            path={'/' + rootPathMain + '/fminventoryconfig'}
                                            component={Fminventoryconfig}/>

                                {/*标签卡信息*/}
                                <CacheRoute cacheKey={'fmlabelcard'} key={'fmlabelcard'}
                                            path={'/' + rootPathMain + '/fmlabelcard'} component={Fmlabelcard}/>

                                {/*存货单位信息*/}
                                <CacheRoute cacheKey={'fmpackingunit'} key={'fmpackingunit'}
                                            path={'/' + rootPathMain + '/fmpackingunit'} component={Fmpackingunit}/>

                                {/*月台信息*/}
                                <CacheRoute cacheKey={'fmplatform'} key={'fmplatform'}
                                            path={'/' + rootPathMain + '/fmplatform'} component={Fmplatform}/>

                                {/*工序信息*/}
                                <CacheRoute cacheKey={'fmprocess'} key={'fmprocess'}
                                            path={'/' + rootPathMain + '/fmprocess'} component={Fmprocess}/>

                                {/*产线信息*/}
                                <CacheRoute cacheKey={'fmproductline'} key={'fmproductline'}
                                            path={'/' + rootPathMain + '/fmproductline'} component={Fmproductline}/>

                                {/*仓储信息*/}
                                <CacheRoute cacheKey={'fmstorage'} key={'fmstorage'}
                                            path={'/' + rootPathMain + '/fmstorage'} component={Fmstorage}/>

                                {/*承运公司信息*/}
                                <CacheRoute cacheKey={'lvmcarrier'} key={'lvmcarrier'}
                                            path={'/' + rootPathMain + '/lvmcarrier'} component={Lvmcarrier}/>

                                {/*物流信息*/}
                                <CacheRoute cacheKey={'lvmlogistics'} key={'lvmlogistics'}
                                            path={'/' + rootPathMain + '/lvmlogistics'} component={Lvmlogistics}/>

                                {/*客户(项目)信息*/}
                                <CacheRoute cacheKey={'mmcustomer'} key={'mmcustomer'}
                                            path={'/' + rootPathMain + '/mmcustomer'} component={Mmcustomer}/>

                                {/*销售计划*/}
                                <CacheRoute cacheKey={'mmplan'} key={'mmplan'} path={'/' + rootPathMain + '/mmplan'}
                                            component={Mmplan}/>

                                {/*销售计划条目*/}
                                <CacheRoute cacheKey={'mmplanentry'} key={'mmplanentry'}
                                            path={'/' + rootPathMain + '/mmplanentry'} component={Mmplanentry}/>

                                {/*销售订单*/}
                                <CacheRoute cacheKey={'mmsale'} key={'mmsale'} path={'/' + rootPathMain + '/mmsale'}
                                            component={Mmsale}/>

                                {/*销售订单条目*/}
                                <CacheRoute cacheKey={'mmsaleentry'} key={'mmsaleentry'}
                                            path={'/' + rootPathMain + '/mmsaleentry'} component={Mmsaleentry}/>

                                {/*追朔防窜记录*/}
                                <CacheRoute cacheKey={'mmtracerecord'} key={'mmtracerecord'}
                                            path={'/' + rootPathMain + '/mmtracerecord'} component={Mmtracerecord}/>

                                {/*生产订单管理*/}
                                <CacheRoute cacheKey={'pmorder'} key={'pmorder'} path={'/' + rootPathMain + '/pmorder'}
                                            component={Pmorder}/>

                                {/*生产订单条目*/}
                                <CacheRoute cacheKey={'pmorderentry'} key={'pmorderentry'}
                                            path={'/' + rootPathMain + '/pmorderentry'} component={Pmorderentry}/>

                                {/*工序监测过程*/}
                                <CacheRoute cacheKey={'pmprocesscheck'} key={'pmprocesscheck'}
                                            path={'/' + rootPathMain + '/pmprocesscheck'} component={Pmprocesscheck}/>

                                {/*工序上料*/}
                                <CacheRoute cacheKey={'pmprocessloading'} key={'pmprocessloading'}
                                            path={'/' + rootPathMain + '/pmprocessloading'}
                                            component={Pmprocessloading}/>

                                {/*工序记录*/}
                                <CacheRoute cacheKey={'pmprocessrecord'} key={'pmprocessrecord'}
                                            path={'/' + rootPathMain + '/pmprocessrecord'} component={Pmprocessrecord}/>

                                {/*生产任务管理*/}
                                <CacheRoute cacheKey={'pmtask'} key={'pmtask'} path={'/' + rootPathMain + '/pmtask'}
                                            component={Pmtask}/>

                                {/*采购需求计划*/}
                                <CacheRoute cacheKey={'smplan'} key={'smplan'} path={'/' + rootPathMain + '/smplan'}
                                            component={Smplan}/>

                                {/*采购需求计划条目*/}
                                <CacheRoute cacheKey={'smplanentry'} key={'smplanentry'}
                                            path={'/' + rootPathMain + '/smplanentry'} component={Smplanentry}/>

                                {/*采购订单*/}
                                <CacheRoute cacheKey={'smpurchase'} key={'smpurchase'}
                                            path={'/' + rootPathMain + '/smpurchase'} component={Smpurchase}/>

                                {/*采购订单条目*/}
                                <CacheRoute cacheKey={'smpurchaseentry'} key={'smpurchaseentry'}
                                            path={'/' + rootPathMain + '/smpurchaseentry'} component={Smpurchaseentry}/>

                                {/*供应商信息*/}
                                <CacheRoute cacheKey={'smsupplier'} key={'smsupplier'}
                                            path={'/' + rootPathMain + '/smsupplier'} component={Smsupplier}/>

                                {/*调拨订单*/}
                                <CacheRoute cacheKey={'smtransfer'} key={'smtransfer'}
                                            path={'/' + rootPathMain + '/smtransfer'} component={Smtransfer}/>

                                {/*调拨订单条目*/}
                                <CacheRoute cacheKey={'smtransferentry'} key={'smtransferentry'}
                                            path={'/' + rootPathMain + '/smtransferentry'} component={Smtransferentry}/>

                                {/*系统设置*/}
                                <CacheRoute cacheKey={'sysconfigure'} key={'sysconfigure'}
                                            path={'/' + rootPathMain + '/sysconfigure'} component={Sysconfigure}/>

                                {/*功能项信息*/}
                                <CacheRoute cacheKey={'sysfeature'} key={'sysfeature'}
                                            path={'/' + rootPathMain + '/sysfeature'} component={Sysfeature}/>

                                {/*职能信息*/}
                                <CacheRoute cacheKey={'sysfunction'} key={'sysfunction'}
                                            path={'/' + rootPathMain + '/sysfunction'} component={Sysfunction}/>

                                {/*编号管理*/}
                                <CacheRoute cacheKey={'sysinvnumber'} key={'sysinvnumber'}
                                            path={'/' + rootPathMain + '/sysinvnumber'} component={Sysinvnumber}/>

                                {/*登录日志*/}
                                <CacheRoute cacheKey={'sysloginlog'} key={'sysloginlog'}
                                            path={'/' + rootPathMain + '/sysloginlog'} component={Sysloginlog}/>

                                {/*操作日志*/}
                                <CacheRoute cacheKey={'sysoptlog'} key={'sysoptlog'}
                                            path={'/' + rootPathMain + '/sysoptlog'} component={Sysoptlog}/>

                                {/*组织架构*/}
                                <CacheRoute cacheKey={'sysorginfo'} key={'sysorginfo'}
                                            path={'/' + rootPathMain + '/sysorginfo'} component={Sysorginfo}/>

                                {/*权限组*/}
                                <CacheRoute cacheKey={'sysrolegroup'} key={'sysrolegroup'}
                                            path={'/' + rootPathMain + '/sysrolegroup'} component={Sysrolegroup}/>

                                {/*组权限*/}
                                <CacheRoute cacheKey={'sysrolegroupright'} key={'sysrolegroupright'}
                                            path={'/' + rootPathMain + '/sysrolegroupright'}
                                            component={Sysrolegroupright}/>

                                {/*组用户*/}
                                <CacheRoute cacheKey={'sysrolegroupusers'} key={'sysrolegroupusers'}
                                            path={'/' + rootPathMain + '/sysrolegroupusers'}
                                            component={Sysrolegroupusers}/>

                                {/*员工信息*/}
                                <CacheRoute cacheKey={'sysstaff'} key={'sysstaff'}
                                            path={'/' + rootPathMain + '/sysstaff'} component={Sysstaff}/>

                                {/*存货调整单管理*/}
                                <CacheRoute cacheKey={'wmchange'} key={'wmchange'}
                                            path={'/' + rootPathMain + '/wmchange'} component={Wmchange}/>

                                {/*盘点单管理*/}
                                <CacheRoute cacheKey={'wmcheck'} key={'wmcheck'} path={'/' + rootPathMain + '/wmcheck'}
                                            component={Wmcheck}/>

                                {/*结存表管理*/}
                                <CacheRoute cacheKey={'wmcheckout'} key={'wmcheckout'}
                                            path={'/' + rootPathMain + '/wmcheckout'} component={Wmcheckout}/>

                                {/*实时状态库存*/}
                                <CacheRoute cacheKey={'wmcheckout_getstateinventory'} key={'wmcheckout_getstateinventory'}
                                            path={'/' + rootPathMain + '/wmcheckout_getstateinventory'} component={Wmcheckout_getstateinventory}/>

                                {/*实时库存*/}
                                <CacheRoute cacheKey={'wmcheckout_getrealinventory'} key={'wmcheckout_getrealinventory'}
                                            path={'/' + rootPathMain + '/wmcheckout_getrealinventory'} component={Wmcheckout_getrealinventory}/>

                                {/*发货单管理*/}
                                <CacheRoute cacheKey={'wminvoice'} key={'wminvoice'}
                                            path={'/' + rootPathMain + '/wminvoice'} component={Wminvoice}/>

                                {/*销售出库单*/}
                                <CacheRoute cacheKey={'wminvoice_ftypeid_EqualTo_1'} key={'wminvoice_ftypeid_EqualTo_1'}
                                            path={'/' + rootPathMain + '/wminvoice_ftypeid_EqualTo_1'} component={Wminvoice_ftypeid_EqualTo_1}/>

                                {/*调拨出库单*/}
                                <CacheRoute cacheKey={'wminvoice_ftypeid_EqualTo_2'} key={'wminvoice_ftypeid_EqualTo_2'}
                                            path={'/' + rootPathMain + '/wminvoice_ftypeid_EqualTo_2'} component={Wminvoice_ftypeid_EqualTo_2}/>

                                {/*生产领料单*/}
                                <CacheRoute cacheKey={'wminvoice_ftypeid_EqualTo_3'} key={'wminvoice_ftypeid_EqualTo_3'}
                                            path={'/' + rootPathMain + '/wminvoice_ftypeid_EqualTo_3'} component={Wminvoice_ftypeid_EqualTo_3}/>

                                {/*抽检出库单*/}
                                <CacheRoute cacheKey={'wminvoice_ftypeid_EqualTo_4'} key={'wminvoice_ftypeid_EqualTo_4'}
                                            path={'/' + rootPathMain + '/wminvoice_ftypeid_EqualTo_4'} component={Wminvoice_ftypeid_EqualTo_4}/>

                                {/*破损出库单*/}
                                <CacheRoute cacheKey={'wminvoice_ftypeid_EqualTo_5'} key={'wminvoice_ftypeid_EqualTo_5'}
                                            path={'/' + rootPathMain + '/wminvoice_ftypeid_EqualTo_5'} component={Wminvoice_ftypeid_EqualTo_5}/>

                                {/*返工出库单*/}
                                <CacheRoute cacheKey={'wminvoice_ftypeid_EqualTo_6'} key={'wminvoice_ftypeid_EqualTo_6'}
                                            path={'/' + rootPathMain + '/wminvoice_ftypeid_EqualTo_6'} component={Wminvoice_ftypeid_EqualTo_6}/>

                                {/*其他出库单*/}
                                <CacheRoute cacheKey={'wminvoice_ftypeid_EqualTo_7'} key={'wminvoice_ftypeid_EqualTo_7'}
                                            path={'/' + rootPathMain + '/wminvoice_ftypeid_EqualTo_7'} component={Wminvoice_ftypeid_EqualTo_7}/>

                                {/*发货单条目*/}
                                <CacheRoute cacheKey={'wminvoiceentry'} key={'wminvoiceentry'}
                                            path={'/' + rootPathMain + '/wminvoiceentry'} component={Wminvoiceentry}/>

                                {/*件码信息*/}
                                <CacheRoute cacheKey={'wmcodeopt_getitemdatagrid'} key={'wmcodeopt_getitemdatagrid'}
                                            path={'/' + rootPathMain + '/wmcodeopt_getitemdatagrid'} component={Wmitemcode}/>

                                {/*垛码信息*/}
                                <CacheRoute cacheKey={'wmcodeopt_getpackagedatagrid'} key={'wmcodeopt_getpackagedatagrid'}
                                            path={'/' + rootPathMain + '/wmcodeopt_getpackagedatagrid'} component={Wmpackagecode}/>

                                {/*包装码信息*/}
                                <CacheRoute cacheKey={'wmcodeopt_getpackdatagrid'} key={'wmcodeopt_getpackdatagrid'}
                                            path={'/' + rootPathMain + '/wmcodeopt_getpackdatagrid'} component={Wmpackcode}/>

                                {/*收货单管理*/}
                                <CacheRoute cacheKey={'wmreceipt'} key={'wmreceipt'}
                                            path={'/' + rootPathMain + '/wmreceipt'} component={Wmreceipt}/>

                                {/*生产入库单*/}
                                <CacheRoute cacheKey={'wmreceipt_ftypeid_EqualTo_1'} key={'wmreceipt_ftypeid_EqualTo_1'}
                                            path={'/' + rootPathMain + '/wmreceipt_ftypeid_EqualTo_1'} component={Wmreceipt_ftypeid_EqualTo_1}/>

                                {/*调拨入库单*/}
                                <CacheRoute cacheKey={'wmreceipt_ftypeid_EqualTo_2'} key={'wmreceipt_ftypeid_EqualTo_2'}
                                            path={'/' + rootPathMain + '/wmreceipt_ftypeid_EqualTo_2'} component={Wmreceipt_ftypeid_EqualTo_2}/>

                                {/*采购入库单*/}
                                <CacheRoute cacheKey={'wmreceipt_ftypeid_EqualTo_3'} key={'wmreceipt_ftypeid_EqualTo_3'}
                                            path={'/' + rootPathMain + '/wmreceipt_ftypeid_EqualTo_3'} component={Wmreceipt_ftypeid_EqualTo_3}/>

                                {/*其他入库单*/}
                                <CacheRoute cacheKey={'wmreceipt_ftypeid_EqualTo_4'} key={'wmreceipt_ftypeid_EqualTo_4'}
                                            path={'/' + rootPathMain + '/wmreceipt_ftypeid_EqualTo_4'} component={Wmreceipt_ftypeid_EqualTo_4}/>

                                {/*收货单条目*/}
                                <CacheRoute cacheKey={'wmreceiptentry'} key={'wmreceiptentry'}
                                            path={'/' + rootPathMain + '/wmreceiptentry'} component={Wmreceiptentry}/>

                                {/*出入库记录*/}
                                <CacheRoute cacheKey={'wmstockrecord'} key={'wmstockrecord'}
                                            path={'/' + rootPathMain + '/wmstockrecord'} component={Wmstockrecord}/>

                                {/*过账策略管理*/}
                                <CacheRoute cacheKey={'wmtactics'} key={'wmtactics'}
                                            path={'/' + rootPathMain + '/wmtactics'} component={Wmtactics}/>

                                {/*过账记录*/}
                                <CacheRoute cacheKey={'wmcodeopt_gettacticsrecorddatagrid'} key={'wmcodeopt_gettacticsrecorddatagrid'}
                                            path={'/' + rootPathMain + '/wmcodeopt_gettacticsrecorddatagrid'} component={Wmtacticsrecord}/>

                                {/*出入库任务*/}
                                <CacheRoute cacheKey={'wmtask'} key={'wmtask'} path={'/' + rootPathMain + '/wmtask'}
                                            component={Wmtask}/>

                                {/*出入库任务*/}
                                <CacheRoute cacheKey={'wmtask'} key={'wmtask'} path={'/' + rootPathMain + '/wmtask'}
                                            component={Wmtask}/>

                                {/*入库任务*/}
                                <CacheRoute cacheKey={'wmtask_ftypeid_LessThanOrEqualTo_4'} key={'wmtask_ftypeid_LessThanOrEqualTo_4'} path={'/' + rootPathMain + '/wmtask_ftypeid_LessThanOrEqualTo_4'}
                                            component={Wmtask_ftypeid_LessThanOrEqualTo_4}/>

                                {/*出库任务*/}
                                <CacheRoute cacheKey={'wmtask_ftypeid_GreaterThanOrEqualTo_5'} key={'wmtask_ftypeid_GreaterThanOrEqualTo_5'} path={'/' + rootPathMain + '/wmtask_ftypeid_GreaterThanOrEqualTo_5'}
                                            component={Wmtask_ftypeid_GreaterThanOrEqualTo_5}/>

                                {/*层码信息*/}
                                <CacheRoute cacheKey={'wmtiercode'} key={'wmtiercode'}
                                            path={'/' + rootPathMain + '/wmtiercode'} component={Wmtiercode}/>

                                {/*单位码信息*/}
                                <CacheRoute cacheKey={'wmcodeopt_getunitdatagrid'} key={'wmcodeopt_getunitdatagrid'}
                                            path={'/' + rootPathMain + '/wmcodeopt_getunitdatagrid'} component={Wmunitcode}/>

                                {/*码表信息*/}
                                <CacheRoute cacheKey={'wmcodeopt'} key={'wmcodeopt'}
                                            path={'/' + rootPathMain + '/wmcodeopt'} component={Wmcodeopt}/>

                                {/*消费者信息*/}
                                <CacheRoute cacheKey={'mmconsumer'} key={'mmconsumer'}
                                            path={'/' + rootPathMain + '/mmconsumer'} component={Mmconsumer}/>

                                {/*抽奖码查询记录*/}
                                <CacheRoute cacheKey={'mmlotteryrecord'} key={'mmlotteryrecord'}
                                            path={'/' + rootPathMain + '/mmlotteryrecord'} component={Mmlotteryrecord}/>

                                {/*奖品信息*/}
                                <CacheRoute cacheKey={'mmprize'} key={'mmprize'}
                                            path={'/' + rootPathMain + '/mmprize'} component={Mmprize}/>

                                {/*中奖信息*/}
                                <CacheRoute cacheKey={'mmlotteryinfo'} key={'mmlotteryinfo'}
                                            path={'/' + rootPathMain + '/mmlotteryinfo'} component={Mmlotteryinfo}/>

                                {/*抽奖规则*/}
                                <CacheRoute cacheKey={'mmlotteryrule'} key={'mmlotteryrule'}
                                            path={'/' + rootPathMain + '/mmlotteryrule'} component={Mmlotteryrule}/>

                                {/*批次信息*/}
                                <CacheRoute cacheKey={'getbatchdatagrid'} key={'getbatchdatagrid'}
                                            path={'/' + rootPathMain + '/getbatchdatagrid'} component={Rpbatchinfo}/>

                                {/*召回列表*/}
                                <CacheRoute cacheKey={'getrecall'} key={'getrecall'}
                                            path={'/' + rootPathMain + '/getrecall'} component={Rprecall}/>

                                {/*出入库日报*/}
                                <CacheRoute cacheKey={'getstockdaily'} key={'getstockdaily'}
                                            path={'/' + rootPathMain + '/getstockdaily'} component={Rpstockdaily}/>

                                {/*生产日报表*/}
                                <CacheRoute cacheKey={'getproductdaily'} key={'getproductdaily'}
                                            path={'/' + rootPathMain + '/getproductdaily'} component={Rpproductdaily}/>

                                {/*产成品统计列表*/}
                                <CacheRoute cacheKey={'getproductlist'} key={'getproductlist'}
                                            path={'/' + rootPathMain + '/getproductlist'} component={Rpproduct}/>

                                {/*收发货量趋势图*/}
                                <CacheRoute cacheKey={'getstocksum'} key={'getstocksum'}
                                            path={'/' + rootPathMain + '/getstocksum'} component={Getstocksum}/>

                                {/*产能趋势图*/}
                                <CacheRoute cacheKey={'getproductsum'} key={'getproductsum'}
                                            path={'/' + rootPathMain + '/getproductsum'} component={Getproductsum}/>

                                {/*查询量趋势图*/}
                                <CacheRoute cacheKey={'getfeelingsum'} key={'getfeelingsum'}
                                            path={'/' + rootPathMain + '/getfeelingsum'} component={Getfeelingsum}/>

                                {/*产成品统计表*/}
                                <CacheRoute cacheKey={'getproducttotal'} key={'getproducttotal'}
                                            path={'/' + rootPathMain + '/getproducttotal'} component={Getproducttotal}/>
                            </CacheSwitch>
                        </Admin>
                    }>
                    </CacheRoute>
                </App>
            </BrowserRouter>
        );
    }
}

export default IRouter;

import React from 'react';
import './Footer.less';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <div className="fonter">
                (推荐使用谷歌游览器，可以获得更佳操作页面体验)
            </div>
        );
    }
}

export default Footer;

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {initItem, item} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";

class Pojo extends MyPojo {
    execute = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/execute', params)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }
    close = (params) => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/close', params)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }

    constructor() {
        //抽奖规则
        super('mmlotteryrule');
        this.controller = 'mm';
        this.cName = '抽奖规则';
        this.infoColumn = ''
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk: true,
            },
            {
                comName: 'fcaption',
                name: '名称',
            },
            // {
            //     comName: 'fplancodeqty',
            //     dataType: DataType.NUM,
            //     name: '计划基数',
            // },
            // {
            //     comName: 'fcodeqty',
            //     dataType: DataType.NUM,
            //     name: '目前基数',
            //     isInsert: false,
            //     isUpdate: false,
            // },
            {
                comName: 'fstarttime',
                name: '开始时间',
                dataType: DataType.DataTime,
            },
            {
                comName: 'fendtime',
                name: '结束时间',
                dataType: DataType.DataTime,
            },
            {
                comName: 'fopenidqty',
                name: '抽奖次数/帐号',
                dataType: DataType.NUM,
            },
            // {
            //     comName: 'faddress',
            //     name: '扫码地点',
            // },
            // {
            //     comName: 'flotterycode',
            //     name: '抽奖码',
            // },
            // {
            //     comName: 'fopenid',
            //     name: 'openid',
            // },
            // {
            //     comName: 'fopttime',
            //     name: '操作时间',
            // },
            {
                comName: 'fstateid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '保存',
                    2: '执行',
                    3: '关闭',
                },
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
        ]);
    }
}

export default Pojo;

export default class SelectTableBt {
    name = '';
    fun = (s1Key, t1Keys, s2Key, t2Keys) => {
    }

    constructor({
                    name = '',
                    fun = (s1Key, t1Keys, s2Key, t2Keys) => {
                    },
                } = {}) {
        this.name = name;
        this.fun = fun;
    }
}

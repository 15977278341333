import React from 'react';
import InitParam from "../Common/init_param";
import {Col, Form, Input, Row, Select, Table, TreeSelect} from "antd";
import PropTypes from 'prop-types';
import {
    formateDate,
    setRowValue,
    deepmerge,
    getValueByProp,
    setValueByProp,
    setRow,
    eObjToStr
} from "../../utils/utils";

import FormUtil from "../Common/FormUtil";

const {Option} = Select;
const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};
const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

class MForm extends React.Component {
    constructor(props) {
        super(props);
        this.init = this.init.bind(this);
        this.row = {};
        // console.log(props.columns);
        this.formRef = React.createRef();
        this.state = {
            formItems: [],
            comTableState: {},
        }
    }

    init = async () => {
        let formItems = [];
        // console.log(this.props.row);
        // console.log(this.row);
        if (this.props.row === undefined || Object.keys(this.props.row).length === 0) {
            //初始化
            setRow(this.props.columns, this.row);//{};
            // this.setRow();
        } else {
            this.row = this.props.row;
        }
        if (this.formRef.current !== null) {
            this.formRef.current.setFieldsValue(this.row);
        }
        // console.log(this.props.columns);
        let formUtil = new FormUtil({row: this.row});
        for (let i in this.props.columns) {
            let column = this.props.columns[i];
            // console.log(column);
            if (this.props.filter(column)) {
                let formItem = (
                    formUtil.getFormItem({
                        column: column,
                        showLabel: true,
                        data: this.props.data,
                    })
                );
                formItems.push(
                    <Col span={24 / this.props.columnNumber}>{formItem}</Col>
                );
            }
        }
        // console.log(JSON.stringify(this.row));
        // console.log(this.row);
        this.setState({
            formItems: formItems,
        })
        //return formItems;
    }

    getTreeSelectData(data, id, text, comF, serverPojo, treeData, prop) {
        let children = 'children';
        for (let i = 0; i !== data.length; i++) {
            let item = data[i];
            let label = comF.text;
            let re = /\{[^\}]+\}/g;
            let encode = comF.text.match(re);
            for (let k in encode) {
                let encodeValue = encode[k].replace(/\{/g, '').replace(/\}/g, '');
                let itemProp = prop + encodeValue;
                let encodeText = getValueByProp(data[i], itemProp);
                // console.log(encode);
                label = label.replace(new RegExp(encode[k], 'g'), encodeText);
            }
            let treeItem = {
                title: label,
                value: getValueByProp(item, prop + id),
            }
            if (item[children] !== undefined && item[children].length > 0) {
                treeItem[children] = [];
                this.getTreeSelectData(item[children], id, text, comF, serverPojo, treeItem[children], prop);
            }
            treeData.push(
                treeItem
            )
        }
        return treeData;
    }

    componentDidMount() {
        //console.log(this.selectRef);
        console.log("组件Ball挂载完成");
        console.log('mForm componentDidMount');
        this.init();
        //this.selectRef.current.props.value = '0';
    }

    componentWillReceiveProps(p) {
        //console.log(this.selectRef);
        console.log("组件将要接收新的属性");
        console.log('mForm componentWillReceiveProps');
        this.init();
        //this.selectRef.current.props.value = '0';
    }

    componentDidUpdate = (prevProps, prevState) => {
        // console.log(prevProps);
        // console.log(prevState);
        // console.log(this.props.row);
        // console.log(this.formRef);
        if (this.props.row === undefined || Object.keys(this.props.row).length === 0) {
            // this.init();
        } else {
            this.row = this.props.row;
        }
        if (this.formRef.current !== null) {
            this.formRef.current.setFieldsValue(this.row);
        }
    }
    onValuesChange = (changedValues, allValues) => {
        //console.log(changedValues);
        //console.log(allValues);
    }
    submit = () => {
        this.formRef.current.submit();
    }
    onFinish = (values) => {
        eObjToStr(values);
        this.row = deepmerge(this.row, values);
        this.props.submitFunc(this.row, this.props.exParam);
    };

    render() {
        console.log(this.state.formItems);
        return (
            <Form
                onFinish={this.onFinish}
                ref={this.formRef}
                {...layout}
                name="basic"
                initialValues={{}}
                onValuesChange={this.onValuesChange}
                // onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
            >
                <Row>
                    {this.state.formItems}
                </Row>
            </Form>
        )
    }
}

MForm.defaultProps = {
    columns: [],
    row: undefined,
    submitFunc: (ref) => {
    },
    exParam: undefined,
    filter: (column) => {
        return true;
    },
    parentData: undefined,
    clientItem: undefined,
    columnNumber: 1,
    data: undefined,
}
MForm.propTypes = {
    columns: PropTypes.arrayOf(InitParam).isRequired,
    row: PropTypes.object,
    submitFunc: PropTypes.func,
    exParam: PropTypes.object,
    filter: PropTypes.func,
    parentData: PropTypes.object,
    clientItem: PropTypes.object,
    columnNumber: PropTypes.number,
    data: PropTypes.object,
}
export default MForm;

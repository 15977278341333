import moment from "moment";
import {getLocal, getLocalTime, getValueByProp} from "./utils";
import {DataType} from "../api/mainPojo";

export const downloadFun = (fileName, str) => {
    let dtStr = moment(new Date(), 'yyyyMMddhhmm')
    let blob = new Blob([str], {type: "text/plain;charset=utf-8"})
    blob = new Blob([String.fromCharCode(0xFEFF), blob], {type: blob.type})
    let object_url = window.URL.createObjectURL(blob);
    let link = document.createElement("a");
    link.href = object_url;
    link.download = dtStr + fileName + '.csv'; // "导出.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
export const getCsv = ({
                           pojo = undefined,
                           rows = [],
                           columns = [],
                           fileName = '',
                       } = {}) => {
    // console.log(columns)
    // console.log(rows)
    let str = ''
    for (let i = 0; i !== columns.length; i++) {
        if (columns[i].show && !columns[i].isPk) {
            str += columns[i].name + ','
        }
    }
    str += '\n'
    for (let j = 0; j !== rows.length; j++) {
        for (let i = 0; i !== columns.length; i++) {
            if (columns[i].show && !columns[i].isPk) {
                // str += columns[i].name
                // console.log(rows[j])
                if (columns[i].isEx || columns[i].dataType === DataType.ComGETServer) {
                    str += getValueByProp(rows[j], columns[i].exComParent).toString() + ','
                } else {
                    // console.log(columns[i].name)
                    let value = pojo.getColumnValue(rows[j], columns[i].comName)
                    // console.log(value)
                    if (columns[i].dataType === DataType.DataTime) {
                        str += (columns[i].showTime ? getLocalTime(value) : getLocal(value)) + ','
                    } else if (columns[i].dataType === DataType.Com) {
                        str += columns[i].state[value] + ','
                    } else {
                        str += pojo.getColumnValue(rows[j], columns[i].comName).toString() + ','
                    }
                }
            }
        }
        str += '\n'
    }
    // console.log(str)
    downloadFun(fileName, str)
}

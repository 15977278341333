export const DataType = {
    Com: 'Com',
    //预查询 关联下拉
    Request: 'Request',
    //多行文本
    LongText: 'LongText',
    ComSearch: 'ComSearch',
    Boolean: 'Boolean',
    MyF: 'MyF',
    String: 'String',
    NUM: 'Int',
    DataTime: 'DataTime',
    ComGETServer: 'ComGETServer',
    //下拉表格
    ComTable: 'ComTable',
    Img: 'Img',
}
export const TableType = {
    //treeTable
    TG: 'TG',
    //gridTable
    GD: 'DG',
}
export const getQueryWhereItem = (values) => {
    let querywhere = [];
    for (let i = 0; i !== values.length; i++) {
        let value = values[i];
        querywhere.push({
            value: value.value,
            comname: value.comname,
            comtype: value.comtype.name,
        })
    }
    return {
        querywhere: querywhere
    };
}
export const getQueryWheres = (values) => {
    let querywhere = [];
    for (let i = 0; i !== values.length; i++) {
        let value = values[i];
        querywhere.push({
            value: value.value,
            comname: value.comname,
            comtype: value.comtype.name,
        })
    }
    return querywhere;
}
export const getQueryWhereValue = (comname, value) => {
    return {
        comtype: WhereType.EqualTo(),
        value: value,
        comname: comname,
    }
}
export const WhereType = {
    EqualTo: () => {
        return {
            name: 'EqualTo',
            title: '等于',
            value: '',
        }
    },
    NotEqualTo: () => {
        return {
            name: 'NotEqualTo',
            title: '不等于',
            value: '',
        }
    },
    GreaterThan: () => {
        return {
            name: 'GreaterThan',
            title: '大于',
            value: '',
        }
    },
    GreaterThanOrEqualTo: () => {
        return {
            name: 'GreaterThanOrEqualTo',
            title: '大于等于',
            value: '',
        }
    },
    LessThan: () => {
        return {
            name: 'LessThan',
            title: '小于',
            value: '',
        }
    },
    LessThanOrEqualTo: () => {
        return {
            name: 'LessThanOrEqualTo',
            title: '小于等于',
            value: '',
        }
    },
    Search: () => {
        return {
            name: 'Search',
            title: '模糊查询',
            value: '',
        }
    },
    NotSearch: () => {
        return {
            name: 'NotSearch',
            title: '反模糊查询',
            value: '',
        }
    },
    Like: () => {
        return {
            name: 'Like',
            title: '模糊查询',
            value: '',
        }
    },
    NotLike: () => {
        return {
            name: 'NotLike',
            title: '反模糊查询',
            value: '',
        }
    },
    In: () => {
        return {
            name: 'In',
            title: '包含',
            value: [],
        }
    },
    NotIn: () => {
        return {
            name: 'NotIn',
            title: '非包含',
            value: [],
        }
    },
    Between: () => {
        return {
            name: 'Between',
            title: '范围',
            value: [],
        }
    },
    NotBetween: () => {
        return {
            name: 'NotBetween',
            title: '范围外',
            value: [],
        }
    },
}

import axios from 'axios'
import {message} from 'antd'
import {eObjToStr} from "../utils/utils";
// import { useHistory } from 'react-router-dom'
/*
 *请求前拦截
 *用于处理需要请求前的操作
 */
axios.interceptors.request.use(
    config => {
        // loading = Loading.service({
        //     text: "正在加载中......",
        //     fullscreen: true
        // });
        // if (store.state.token) {
        //     //config.headers["Authorization"] = "Bearer " + store.state.token;
        // }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
/*
 *请求响应拦截
 *用于处理数据返回后的操作
 */
axios.interceptors.response.use(
    response => {
        // if (response.headers !== undefined) {
        //     if (response.headers.token) {
        //         localStorage.setItem('token', response.headers.token);
        //     }
        //     if (response.headers.sessionid) {
        //         localStorage.setItem('sessionid', response.headers.sessionid);
        //     }
        // }
        return new Promise((resolve, reject) => {
            //请求成功后关闭加载框
            // if (loading) {
            //     loading.close();
            // }
            const res = response.data;
            // console.log(res);
            // let history = useHistory()
            if (res.code === 0) {
                resolve(res);
            } else if (res.code === 3) {
                // window.history.pushState({}, '', '/ylwms/');
                // history.push({pathname: '/ylwms/'})
                // console.log(window.location.href);

                window.location.href = SystemInit.path;
                //messages("error", res.msg);
            } else if (res.code === 650) {
                //messages("error", res.msg);
            } else if (res.code === 620) {
                // router.replace({
                //     path: "/login",
                //     query: {
                //         redirect: router.currentRoute.fullPath
                //     }
                // });
            } else {
                message.error(res.msg);
                reject(res)
            }
        })
    },
    error => {
        if (error.headers !== undefined) {
            console.warn(error.headers);
            // localStorage.setItem('token', error.headers.token);
            // localStorage.setItem('sessionid', error.headers.sessionid);
            //请求成功后关闭加载框
            // if (loading) {
            //     loading.close();
            // }
            //断网处理或者请求超时
            if (!error.response) {
                //请求超时
                if (error.message.includes("timeout")) {
                    console.log("超时了");
                    //messages("error", "请求超时，请检查互联网连接");
                } else {
                    //断网，可以展示断网组件
                    console.log("断网了");
                    //messages("error", "请检查网络是否已连接");
                }
                return Promise.reject(error);
            }
            const status = error.response.status;
            switch (status) {
                case 500:
                    //messages("error", "服务器内部错误");
                    break;
                case 404:
                    // messages(
                    //     "error",
                    //     "未找到远程服务器"
                    // );
                    break;
                case 401:
                    // messages("warning", "用户登陆过期，请重新登陆");
                    // store.state.commit('COMMIT_TOKEN', '')
                    // setTimeout(() => {
                    //     router.replace({
                    //         path: "/login",
                    //         query: {
                    //             redirect: router.currentRoute.fullPath
                    //         }
                    //     });
                    // }, 1000);
                    break;
                case 400:
                    //messages("error", "数据异常，详情请咨询服务热线");
                    break;
                default:
                //messages("error", error.response.data.message);
            }
            return Promise.reject(error);
        }
    }
);

export function isArray(obj) {
    return typeof obj === 'object' && obj.constructor === Array;
}

/*
 *get方法，对应get请求
 *@param {String} url [请求的url地址]
 *@param {Object} params [请求时候携带的参数]
 */
export function get(url, params) {
    eObjToStr(params);
    let s = '&';
    if (url.indexOf('?') === -1) {
        s = '?';
    }
    for (let index in params) {
        url = url + s + index + '=' + params[index];
        s = '&';
    }
    //axios.defaults.timeout = 60000;
    let headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token'),
        'sessionid': localStorage.getItem('sessionid'),
        'devicetype': 'web'
    }
    console.warn(url);
    console.warn(headers);
    return new Promise((resolve, reject) => {
        console.warn(headers);
        axios
            .get(url, {headers: headers})
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            });
    });
}

/*
 *post方法，对应post请求
 *@param {String} url [请求的url地址]
 *@param {Object} params [请求时候携带的参数]
 */
export function post(url, params) {
    eObjToStr(params);
    //axios.defaults.headers["Content-Type"] =
    //    "application/x-www-form-urlencoded;charset=UTF-8";
    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'token': localStorage.getItem('token'),
        'sessionid': localStorage.getItem('sessionid'),
        'devicetype': 'web'
    };
    console.warn(headers);
    return new Promise((resolve, reject) => {
        axios
            .post(url, params, {headers: headers})
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    });
}

export function postFormObj(url, params) {
    let formData = new FormData();
    eObjToStr(params)
    Object.keys(params).forEach(function (index) {
        //console.log(index, params[index]);
        if (isArray(params[index])) {
            for (let key in params[index]) {
                formData.append(index, params[index][key]);
            }
        } else {
            formData.append(index, params[index]);
        }
    });
    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'token': localStorage.getItem('token'),
        'sessionid': localStorage.getItem('sessionid'),
        'devicetype': 'web'
    };
    console.warn(headers);
    //axios.defaults.headers["Content-Type"] =
    //    "application/x-www-form-urlencoded;charset=UTF-8";
    return new Promise((resolve, reject) => {
        axios
            .post(url, formData, {headers: headers})
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    });
}

/*
 *post方法，对应post请求
 *@param {String} url [请求的url地址]
 *@param {Object} obj [请求时候携带的参数]
 */
export function postObj(url, obj, params = {}) {
    axios.defaults.headers["Content-Type"] =
        "application/json";
    let headers = {
        'token': localStorage.getItem('token'),
        'sessionid': localStorage.getItem('sessionid'),
        'devicetype': 'web'
    };
    let s = '&';
    if (url.indexOf('?') === -1) {
        s = '?';
    }
    for (let index in params) {
        url = url + s + index + '=' + params[index];
        s = '&';
    }
    console.warn(headers);
    eObjToStr(obj);
    return new Promise((resolve, reject) => {
        axios
            .post(url, JSON.stringify(
                obj
            ), {headers: headers})
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    });
}

import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {initItem, item} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";
class Pojo extends MyPojo {
    synchronization = () => {
        return new Promise((resolve, reject) => {
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/synchronization', {})
                .then((res) => {
                    console.log(res);
                    resolve(res);
                })
                .catch((res) => {
                    console.log(res);
                    reject(res);
                });
        });
    }
    constructor() {
        //物料信息
        super('fminventory');
        this.controller = 'fm';
        this.delRowName = 'fnumber';
        this.cName = '存货信息';
        this.pojoItem = new initItem();
        this.showPK = true;
        this.addPojoItemInitItems([
            // {
            //     comName: 'fpk',
            //     name: '唯一编号',
            //     isPk:true,
            // },
            {
                comName: 'fnumber',
                name: '存货编号',
                isPk:true,
                show:false,
            },
            {
                comName: 'fnumber',
                name: '存货编号',
            },
            {
                comName: 'fcaption',
                name: '存货名称',
                showLength:40,
                width:350,
            },
            {
                comName: 'fspecification',
                name: '品规型号',
            },
            {
                comName: 'ftypeid',
                name: '存货类型',
                dataType: DataType.Com,
                state: {
                    1: '产成品',
                    2: '半成品',
                    3: '原料',
                    4: '辅料',
                    5: '包材',
                    6: '宣传用品',
                    7: '配件',
                    8: '其他',
                },
            },
            {
                comName: 'fclassification',
                name: '类型分类',
            },
            {
                comName: 'fshelflife',
                name: '有效期',
            },
            {
                comName: 'fstateid',
                name: '状态',
                dataType: DataType.Com,
                state: {
                    1: '正常',
                    2: '停用',
                },
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert:false,
                isUpdate:false,
                tdShow: false,
            },
        ]);
    }
}
export default Pojo;

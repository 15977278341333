import React, {useState} from 'react';
import './index.less';
import {Row, Button, Table, Tag, Radio, Modal, Tabs, message} from 'antd';
import Select_Tables from "../../my_module/select_tables/select_tables";
import {DownloadOutlined} from '@ant-design/icons';
import {get, postObj} from "../../axios/Response";
import MyTable from "../../my_module/table/table";
// import Pojo from "./pojo"
import MyDataGrid from "../../my_module/datagrid/datagrid";
import MyButton from "../../my_module/MyClass/button";
import Nestgrid from "../../my_module/nestgrid/nestgrid";
import Pojos from "../pojos"
import {getQueryWhereItem, getQueryWhereValue} from "../../api/mainPojo";
import SelectTablePojo from "../../my_module/Common/SelectTablePojo";
import SelectTableBt from "../../my_module/Common/SelectTableBt";

const {TabPane} = Tabs;

//组用户
class index extends React.Component {
    constructor(props) {
        super(props);
        this.onSTRef = this.onSTRef.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        // this.addOpenIntercept = this.addOpenIntercept.bind(this);
        // this.editOpenIntercept = this.editOpenIntercept.bind(this);
        this.state = {
            // addModalVisible: false,
            pojo: Pojos.getPojo('sysrolegroupusers'),//new Pojo(),
            st: undefined,
        }
        console.log(this.pojo);
    }

    exTrBus = [
        // new MyButton(
        //     '审批',
        //     'getItemByID11',
        //     undefined,
        //     (row) => {
        //         console.log(row);
        //         return true;
        //     }
        // )
    ]
    onSTRef = (ref) => {
        this.setState({
            st: ref,
        }, () => {
            //this.state.dg.refInit();
        })
    }
    componentDidUpdate = (prevProps, prevState) => {
    }
    // addModal = () => {
    //     let model =
    //         <Modal title="Basic Modal" visible={this.state.addModalVisible}
    //                onCancel={() => {
    //                    this.setState({
    //                        addModalVisible:false,
    //                    })
    //                }}>
    //             <p>Some contents...</p>
    //             <p>Some contents...</p>
    //             <p>Some contents...</p>
    //         </Modal>;
    //     return model;
    // }
    // addOpenIntercept = (ref) => {
    //     console.log(ref);
    //     // this.setState({
    //     //     addModalVisible:true,
    //     // })
    //     return false;
    // }
    // editOpenIntercept = (ref) => {
    //     console.log(ref);
    //     return false;
    // }
    render() {
        return (
            <div>
                {/*{this.addModal()}*/}
                <Select_Tables
                    t1Pojo={new SelectTablePojo({
                        tableController: 'sysstaff',
                        tableAction: 'getList',
                        selectController: 'sysorginfo',
                        selectAction: 'getList',
                        selectText: '{fcaption}-{fcreateman}',
                        selectId: 'fpk',
                        title:'部门',
                        exTbParams: (id) => {
                            return getQueryWhereItem([
                                getQueryWhereValue('fdeptpk', id)
                            ])
                        },
                        getCheckboxProps: (row) => ({
                            disabled: row.frolegrouppk !== ''
                        }),
                        showRowNames: ['fname', 'fnumber'],
                    })}
                    t2Pojo={new SelectTablePojo({
                        tablePk: 'info.fstaffpk',
                        tableController: 'sysrolegroupusers',
                        tableAction: 'getgroupusers',
                        selectController: 'sysrolegroup',
                        selectAction: 'getList',
                        selectText: '{fcaption}',//-{fcreateman}
                        selectId: 'fpk',
                        title:'权限组',
                        exTbParams: (id) => {
                            return {
                                frolegrouppk: id,
                            }
                        },
                        getCheckboxProps: (row) => {
                            console.log(row);
                        },
                        showRowNames: ['staffname', 'staffnumber'],
                    })}
                    sBus={[
                        new SelectTableBt({
                            name: '分配组',
                            fun: (s1Key, t1Keys, s2Key, t2Keys) => {
                                if (s2Key === '') {
                                    message.error('请选择组');
                                    return;
                                }
                                if (t1Keys.length === 0) {
                                    message.error('请选择待分配用户');
                                    return;
                                }
                                postObj(SystemInit.serviceUrls.sys + 'sysrolegroupusers' + '/setgroupusers', {
                                    frolegrouppk: s2Key,
                                    foptionid: 1,
                                    fstaffpks: t1Keys
                                })
                                    .then((ref) => {
                                        message.success(ref.msg);
                                        this.state.st.t1GetList();
                                        this.state.st.t2GetList();
                                    })
                            }
                        }),
                        new SelectTableBt({
                            name: '取消分配组',
                            fun: (s1Key, t1Keys, s2Key, t2Keys) => {
                                if (s2Key === '') {
                                    message.error('请选择组');
                                    return;
                                }
                                if (t2Keys.length === 0) {
                                    message.error('请选择待取消用户');
                                    return;
                                }
                                postObj(SystemInit.serviceUrls.sys + 'sysrolegroupusers' + '/setgroupusers', {
                                    frolegrouppk: s2Key,
                                    foptionid: 2,
                                    fstaffpks: t2Keys
                                })
                                    .then((ref) => {
                                        message.success(ref.msg);
                                        this.state.st.t1GetList();
                                        this.state.st.t2GetList();
                                    })
                            }
                        }),
                    ]}
                    onRef={this.onSTRef}
                >
                </Select_Tables>
            </div>
        );
    }
}

export default index;

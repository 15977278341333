import MyPojo from "../../my_module/MyClass/pojo"
import {DataType, TableType} from "../../api/mainPojo"
import {initItem, item} from '../../calzzs/initItems'
import {postObj} from "../../axios/Response";

class Pojo extends MyPojo {
    constructor() {
        //组用户
        super('sysrolegroupusers');
        this.controller = 'sys';
        this.cName = '组用户';
        this.pojoItem = new initItem();
        this.addPojoItemInitItems([
            {
                comName: 'fpk',
                name: '唯一编号',
                isPk: true,
            },
            {
                comName: 'fstaffpk',
                name: '员工编号',
            },
            {
                comName: 'frolegrouppk',
                name: '角色组编号',
            },
            {
                comName: 'fcreatetime',
                name: '创建时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fcreateman',
                name: '创建人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifytime',
                name: '修改时间',
                dataType: DataType.DataTime,
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'fmodifyman',
                name: '修改人',
                isInsert: false,
                isUpdate: false,
                tdShow: false,
            },
            {
                comName: 'staffname',
                name: '名称',
                isEx: true,
                dataType: DataType.String,
                exComParent: 'staffname',
            },
            {
                comName: 'staffnumber',
                name: '工号',
                isEx: true,
                dataType: DataType.String,
                exComParent: 'staffnumber',
            },
        ]);
    }

    getgroupusers = (obj) => {
        return new Promise((resolve, reject) => {
            //组唯一编号
            let frolegrouppk = obj['frolegrouppk'] === undefined ? '' : obj['frolegrouppk']
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/getgroupusers', {
                frolegrouppk: frolegrouppk,
            })
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }
    setgroupusers = (frolegrouppk, fstaffpks) => {
        return new Promise((resolve, reject) => {
            //组唯一编号
            // let frolegrouppk = obj['frolegrouppk'] === undefined ? '' : obj['frolegrouppk']
            postObj(SystemInit.serviceUrls[this.controller] + this.tableName + '/setgroupusers', {
                frolegrouppk: frolegrouppk,
                fstaffpks: fstaffpks,
                foptionid: 1,
            })
                .then((ref) => {
                    resolve(ref);
                })
                .catch((ref) => {
                    reject(ref);
                })
        });
    }
}

export default Pojo;
